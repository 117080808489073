import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import Filter from "./Filter";
import PersonIcon from '@material-ui/icons/Person';
import { StyledTableCell, StyledTableRow, WrapperStyle } from "./dealReportUseStyles";
import moment from "moment";
import Utils, { activeStatus, activeStatusClass, getAccountData } from "../../../helpers/Utils";
import { Skeleton } from "@material-ui/lab";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import { contactStageSearch } from "../../../api/productsApi";
import TableLoaderSkeleton from "./TableLoaderSkeleton";
import GlobalEmptyPage from "./GlobalEmptyPage";
import { EmptyInboxIcon } from "./invoicePageIcons";
const useStyles = makeStyles((theme) => ({
  ProductTable: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
  productTitle: {
    maxWidth: '135px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'end'
  },
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '50vh',
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "#2c3e50",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#346fef",
      borderRadius: "10px",
    }}

}))
const defaultDealClosingEndDate = moment().format('YYYY-MM-DD 23:59:59');
const defaultDealClosingStartDate = moment().subtract(3, 'years').format('YYYY-MM-DD 00:00:00');

const DealReport = () => {
  const [data, setData] = useState([]);
  const [totalDealValue, setTotalDealValue] = useState(0);
  const [totalDealCreated, setTotalDealCreated] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    page: 1,
    perPage: 20,
    userIds: [getAccountData("userId")],
    dealStatus: "WIN",
    dealCreatedAtStart: null,
    dealCreatedAtEnd: null,
    dealCloseStartDate: null,
    dealCloseEndDate: null
  });
  const classes = useStyles();

  useEffect(() => {
    getTableData().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const convertDate = (date) => {
    try {
      return window.globalTimezoneConversionToDifferentTimezone(moment(date).format('YYYY-MM-DD HH:mm:ss'), getAccountData('userTimezoneRaw'), "UTC", "YYYY-MM-DD HH:mm:ss");
    } catch (e) {
      return date;
    }
  };

  const getTableData = async () => {
    try {
      setLoading(true);

      let submitData = {
        page: formData.page,
        perPage: formData.perPage,
        dealCreatedAtStart: formData.dealCreatedAtStart ? convertDate(formData.dealCreatedAtStart) : null,
        dealCreatedAtEnd: formData.dealCreatedAtEnd ? convertDate(formData.dealCreatedAtEnd) : null,
        dealCloseStartDate: formData.dealCloseStartDate ? convertDate(formData.dealCloseStartDate) : (formData.dealStatus !== 'ACTIVE' && formData.dealStatus !== null && formData.dealCreatedAtStart === null) ? convertDate(defaultDealClosingStartDate) : null,
        dealCloseEndDate: formData.dealCloseEndDate ? convertDate(formData.dealCloseEndDate) : (formData.dealStatus !== 'ACTIVE' && formData.dealStatus !== null && formData.dealCreatedAtStart === null) ? convertDate(defaultDealClosingEndDate) : null,
        userIds: formData.userIds,
        dealStatus: formData.dealStatus,
        search: formData.search ?? null
      }
      const resp = await contactStageSearch(submitData);

      if (resp?.success) {
        setData(resp.data);
        setTotal(resp.total);
        setTotalDealValue(resp.totalDealValue);
        setTotalDealCreated(resp.total);
      } else {
        setData([]);
        setTotal(0);
        setTotalDealValue(0);
        setTotalDealCreated(0);
      }
    } catch (err) {
      setData([]);
      setTotal(0);
      setTotalDealValue(0);
      setTotalDealCreated(0);
    } finally {
      setLoading(false);
    }
  };

  const RenderDashboardContent = () => {
    if (loading) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <div className="dashboardItem">
              <div className="dashboardText">
                <Skeleton animation="wave" height={20} />
                <Skeleton animation="wave" height={30} width="80%" />
              </div>
              <Skeleton
                animation="wave"
                variant="rect"
                width={40}
                height={40}
                style={{ borderRadius: 5 }}
              />
            </div>
          </Grid>

          <Grid item xs={12} md={3}>
            <div className="dashboardItem">
              <div className="dashboardText">
                <Skeleton animation="wave" height={20} />
                <Skeleton animation="wave" height={30} width="80%" />
              </div>
              <Skeleton
                animation="wave"
                variant="rect"
                width={40}
                height={40}
                style={{ borderRadius: 5 }}
              />
            </div>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <div className="dashboardItem">
              <div className="dashboardText">
                <Typography className="textTitle" variant="body1">
                  Total Deal Value:
                </Typography>
                <Typography className="textValue" variant="body1">
                  {Utils.formatCurrency(totalDealValue, "$")}
                </Typography>
              </div>

              <div className="dashboardIcon">
                <MonetizationOnIcon/>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={3}>
            <div className="dashboardItem">
              <div className="dashboardText">
                <Typography className="textTitle" variant="body1">
                  Total Deals:
                </Typography>
                <Typography className="textValue" variant="body1">
                  {totalDealCreated}
                </Typography>
              </div>

              <div className="dashboardIcon">
                <EmojiEventsIcon />
              </div>
            </div>
          </Grid>
        </Grid>
      );
    }
  };
  const RenderTableContent = () => {
    if (loading) {
      return (
        <div style={{ background: 'white' }}>
          <TableLoaderSkeleton numberOfRows={10} columns={9} minWidth={120} />
        </div>
      );
    } else if (data?.length === 0) {
      return (
        <GlobalEmptyPage
          icon={<EmptyInboxIcon />}
          title={"No data available"}
        />
      );
    } else if (data?.length > 0) {
      return (
        <div className={classes.root}>

        
        <TableContainer className={classes.container} component={Paper} >
          <Table stickyHeader className={"dealReportTable"} aria-label="a dense table stickyHeader">
            <TableHead>
              <TableRow>
                <StyledTableCell>Contact</StyledTableCell>
                <StyledTableCell>Company</StyledTableCell>
                <StyledTableCell>Pipeline</StyledTableCell>
                <StyledTableCell>Stage</StyledTableCell>
                <StyledTableCell>Lead Source</StyledTableCell>
                <StyledTableCell>Products</StyledTableCell>
                <StyledTableCell align="center">Deal Value</StyledTableCell>
                <StyledTableCell align="center">Deal Added</StyledTableCell>
                <StyledTableCell align="center">Deal Closed</StyledTableCell>
                <StyledTableCell align="center">Deal Status</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell scope="row">
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 8 }}
                    >
                      <div className="contactItem">
                        <PersonIcon />
                        <a
                          href={`/contacts/${row.contactId}`}
                          target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#316AFF', fontWeight: 'bold' }}>
                          {row.fullName && row.fullName.trim() !== "" ? <span>{row.fullName}</span> : <span>N/A</span>}
                        </a>
                      </div>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box style={{ width: '170px' }} title={row.companyName}>
                      {row.companyName}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>{row.pipelineTitle}</StyledTableCell>
                  <StyledTableCell>{row.stageTitle}</StyledTableCell>
                  <StyledTableCell>{row.sourceTitle || 'Single'}</StyledTableCell>
                  <StyledTableCell style={{ width: '210px' }}>
                    {(row.dealProducts && (Array.isArray(row.dealProducts))) ? (
                      row.dealProducts.map((product) => (
                        <div className={classes.ProductTable} key={index}>
                          <BootstrapTooltip arrow title={product.userProductTitle}>
                            <div className={classes.productTitle}>{product.userProductTitle}</div>
                          </BootstrapTooltip>
                          <div style={{width:'70px'}}>
                            {`: ${Utils.formatCurrency(product.dealProductValue
                            , "$")}`}
                            </div>
                        </div>
                      ))
                    ) : (
                      <span>{''}</span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {Utils.formatCurrency(row.dealValue, "$")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {
                      window.globalTimezoneConversionToDifferentTimezone(row.dealCreatedDate, "UTC", "", "", false)
                    }
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {
                      window.globalTimezoneConversionToDifferentTimezone(row.dealClosedDate, "UTC", "", "", false)
                    }
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Chip
                      className={
                        activeStatusClass
                        (row.dealStatus)}
                      size="small"
                      label={activeStatus(row.dealStatus)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            component="div"
            count={total}
            page={(formData.page - 1)}
            rowsPerPage={formData.perPage}
            onPageChange={(event, page) => {
              setFormData((prevState) => ({ ...prevState, page: (page + 1) }));
            }}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onRowsPerPageChange={(event) => {
              setFormData((prevState) => ({ ...prevState, page: 1, perPage: event.target.value }));
            }}
          />
        </div>
      );
    }
  };

  return (
    <WrapperStyle>
      <Typography variant="h5" style={{fontWeight:'600', fontSize:'20px'}} className="title">
        Sales Report
      </Typography>

      <div className="dealReportDashboard">
        {RenderDashboardContent()}
      </div>

      <div className="deal-report-filter">
        <Filter setFormData={setFormData} formData={formData} />
      </div>

      {RenderTableContent()}
    </WrapperStyle>
  );
};

export default DealReport;
