// pipene header
export const SELECTED_PIPELINE_ID = 'SELECTED_PIPELINE_ID'
// export const STORE_PIPELINES = 'STORE_PIPELINE_ID'
export const STORE_PIPELINES = 'STORE_PIPELINES'
export const RELOAD_PIPELINE_FLAG = 'RELOAD_PIPELINE_FLAG'
export const RELOAD_STAGE_LIST = 'RELOAD_STAGE_LIST'
export const HANDLE_STORE_DATA = 'HANDLE_STORE_DATA'

export const CREATE_PIPELINE = 'CREATE_PIPELINE'
export const CEHCK_PIPELINE_DELETE_OR_NOT = 'CEHCK_PIPELINE_DELETE_OR_NOT'
export const DELETE_PIPELINE_AND_MOVE = 'DELETE_PIPELINE_AND_MOVE'
export const UPDATE_PIPELINE = 'UPDATE_PIPELINE'
export const GET_USER_STAGE_BY_PIPELINE = 'GET_USER_STAGE_BY_PIPELINE'
export const SORT_USER_STAGE = 'SORT_USER_STAGE'
export const DELETE_AND_MOVE_TO_STAGE = 'DELETE_AND_MOVE_TO_STAGE'
export const USER_STAGE_SHOW_IN_CHART = 'USER_STAGE_SHOW_IN_CHART'
export const ADD_USER_STAGE_SUBMIT = 'ADD_USER_STAGE_SUBMIT'
export const UPDATE_PIPELINE_SHOW_OPTION = 'UPDATE_PIPELINE_SHOW_OPTION'
