import { useEffect } from "react";

function useDelayCallback(callback, array, time = 1000) {
  let timer;

  useEffect(() => {
    timer = setTimeout(() => {
      callback();
    }, time);

    return () => {
      clearTimeout(timer);
    };
  }, array);

  return null;
}

export default useDelayCallback;
