import React, {useState} from "react";
import {Modal} from "@material-ui/core";
import {
    StyledButton,
    StyledEmailInput,
    StyledEmailInputGroup,
    StyledEmailInputLabel,
    StyledExportContent,
    StyledExportWrapper, StyledUl
} from "./dealReportUseStyles";
import Utils from "../../../helpers/Utils";
import { productSalesExport } from "../../../api/productsApi";
import { USER_SELECTOR } from "../helper/coreConstant";

const ExportDealReportModal = (props) => {
    const [emails, setEmails] = useState("");
    const [emailsArray, setEmailsArray] = useState([]);
    const [errors, setErrors] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [accepted, setAccepted] = useState(false);

    const handleChange = (event) => {
        if (errors){
            setErrors("");
        }

        setEmails(event.target.value);
    };

    const handleSubmit = async () => {
      try{
          setSubmitting(true);
          const emailsArray = [];

          const splitEmails = emails.split(",");

          for(let i = 0;i < splitEmails.length; i++){
              let email = splitEmails[i];

              if (email.trim() !== ""){
                  emailsArray.push(email);
              }else if (email.trim() === ""){
                  setErrors("Remove extra comma");
                  break;
              }
          }

          setEmailsArray(emailsArray);

          const invalidEmails = emailsArray.filter((email) => (!Utils.validateEmail(email)));

          if (invalidEmails.length > 0) {
              setErrors(`Invalid emails {${invalidEmails.join(", ")}}`);
              return;
          }

          let submitData = {
              userProductId: props.userProductId,
              userIds: props.selectedUserIds,
              emails: emailsArray
          }

          if (props.selectedUserIds[0] === USER_SELECTOR.ALL){
            submitData.userIds = props.teamUsers.map((team)=>(team.id));
          }

          const resp = await productSalesExport(submitData);

          if (resp?.success) {
              setAccepted(true);
              setErrors("");
              window.showNotification("success", resp?.message);
          } else {
              console.error('Failed to export contacts:', resp.message);
              window.showNotification("error", resp?.message);
          }
      }catch (err){
          console.log(err);
      }finally {
          setSubmitting(false);
      }
    };

    const handleClose = () => {
        props.onClose();
    };

    return(
        <Modal
            open={props.open}
            onClose={handleClose}
            className={"global-xs-modal add_list_modal"}
            style={{ display: "grid", placeContent: "center" }}
        >
            <StyledExportWrapper>
                {
                    accepted ?
                        <StyledExportContent>
                            <h1>Consider it done!</h1>
                            <div>
                                <StyledEmailInputLabel>
                                    The report is on its way to the email address listed below.<br/>
                                    It may take up to 5 minutes to arrive in your inbox so please be patient. 
                                </StyledEmailInputLabel>
                                <StyledUl>
                                    {
                                        emailsArray.map((email)=> (
                                            <li>{email}</li>
                                        ))
                                    }
                                </StyledUl>
                            </div>
                            <StyledButton style={{ marginTop: "10px", float: "right" }} onClick={handleClose}>Done</StyledButton>
                        </StyledExportContent> :
                        <StyledExportContent>
                            <h1>Export Deal Report</h1>
                            <StyledEmailInputGroup>
                                <StyledEmailInputLabel>
                                    Enter emails with comma (,) separated
                                </StyledEmailInputLabel>
                                <StyledEmailInput type="text" value={emails} onChange={handleChange} placeholder={"example1@gmail.com,example2@gmail.com,..."}/>
                                {
                                    errors &&
                                    <StyledEmailInputLabel style={{ color: "red" }}>
                                        {errors}
                                    </StyledEmailInputLabel>
                                }
                                <StyledEmailInputLabel style={{ fontStyle: "italic", textAlign: "start" }}>
                                    ***The exported file will be sent to these emails after the export process is complete.
                                </StyledEmailInputLabel>
                            </StyledEmailInputGroup>
                            <StyledButton disabled={submitting || !emails.trim()} style={{ marginTop: "10px", float: "right" }} onClick={handleSubmit}>Export</StyledButton>
                            <StyledButton style={{ marginTop: "10px", float: "right", marginRight: "5px", backgroundColor: "red" }} onClick={handleClose}>Cancel</StyledButton>
                        </StyledExportContent>
                }

            </StyledExportWrapper>
        </Modal>
    );
}

export default ExportDealReportModal;