import React, { useState } from "react";
import {
    Box,
    CircularProgress,
    Collapse,
    makeStyles,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@material-ui/core";
import AddOrEditSalesGoalModal from "../AddOrEditSalesGoalModal";
import { CustomButton } from "../../productService/content/productBody/CustomButton";
import AddIcon from "@material-ui/icons/Add";
import { useSalesGoalContext } from "./SalesGoalsContext";
import EachGoalRow from "./EachGoalRow";
import SalesGoalsTableSkeleton from "./SalesGoalTableSkeleton";
import {useSelector} from "react-redux";
import {EMPTY_DATA_IMG} from "../../../../constants/CoreConstants";
import { userSaleGoalStats } from "../../../../api/productsApi";

const useStyles = makeStyles({
    
    root: {
      width: '100%',
    },
   container: {
    maxHeight: 650,
    overflowX: 'hidden',
      "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "6px",
      backgroundColor: "#2c3e50",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#346fef",
      borderRadius: "6px",
    },
    },
    tableHead: {
        fontWeight: 600,
        fontSize: "16px",
        color: "white",
        borderRadius: "0px !important",
        backgroundColor: "#346fef",
    },
  });

const SalesGoalTable = () => {
    const { isLoadingAssets } = useSelector((state) => state.productReducer);
    const [editModal, setEditModal] = useState(false);
    const [initialData, setInitialData]=useState(null);
    const [openAddGoalModal, setOpenAddGoalModal] = useState(false);
    const [salesGoalStats, setSalesGoalStats] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [openCollapseModal, setOpenCollapseModal] = useState(null);

    const {
        salesGoals,
        isLoading,
        fetchData,
        total,
        setRowsPerPage,
        setPage,
        page,
        rowsPerPage,
    } = useSalesGoalContext()
    const classes = useStyles();
    
    const handleEdit = (data) => {
        setInitialData(data);
        setEditModal(true);
    };
 
    const handleCloseModal = () => {
        setOpenAddGoalModal(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }; 
    const handleSalesGoalStats = async (id) => {
        try{
            setLoading(true);
            const resp = await userSaleGoalStats({ userSaleGoalId: id});
  
            if (resp && resp.success){
                setSalesGoalStats(resp.data);
            }else{
                setSalesGoalStats(null);
            }
            setLoading(false);
        }catch (err){
  
        }
      };

    return (
        <div>
             {(isLoading || isLoadingAssets ) ?
                <SalesGoalsTableSkeleton/> :
                !salesGoals[0] ? (
                <div style={{ display: "flex" ,marginTop:"40px",marginLeft:"40px"}}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "60%",
                        }}
                    >
                        <div>
                            <Typography align="center" variant="h5">
                                You haven't added any goal yet!
                            </Typography>
                            <Typography
                                variant="body1"
                                align="center"
                                style={{ color: "gray" }}
                            >
                                Click the button below to get started.
                            </Typography>
                            <Box display={"flex"} justifyContent={"center"} mt={2}>
                                <CustomButton
                                    variant="contained"
                                    onClick={() => setOpenAddGoalModal(true)}
                                    startIcon={<AddIcon />}
                                    disableElevation
                                >
                                    Add New Goal
                                </CustomButton>
                                {openAddGoalModal && (
                                    <AddOrEditSalesGoalModal
                                        open={openAddGoalModal}
                                        onClose={handleCloseModal}
                                    />
                                )}
                            </Box>
                        </div>
                    </Box>
                    <Box sx={{ width: "40%", display: "flex", alignItems: "center" }}>
                        <img
                            height={300}
                            src={EMPTY_DATA_IMG}
                            alt="Empty data"
                        />
                    </Box>
                </div>
            ) : (
                    <div className={classes.root}>
                        <TableContainer className={classes.container}>
                        <Table  stickyHeader aria-label="sticky table collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHead}>Team Member</TableCell>
                                <TableCell className={classes.tableHead} align="center">Sales Goal</TableCell>
                                <TableCell className={classes.tableHead} align="center">Product Count</TableCell>
                                <TableCell className={classes.tableHead} align="center">Frequency</TableCell>
                                <TableCell className={classes.tableHead} align="center">Pipeline</TableCell>
                                <TableCell className={classes.tableHead} align="center">Duration</TableCell>
                                <TableCell className={classes.tableHead} width={"15%"} align="center">
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                              salesGoals
                              .map((row) => (
                                    <EachGoalRow  key={row.id} row={row} isLoading={Loading} salesGoalStats={salesGoalStats} handleSalesGoalStats={handleSalesGoalStats} onEdit={handleEdit}
                                    openCollapseModal={openCollapseModal === row.id} setOpenCollapseModal={setOpenCollapseModal} />
                                ))}
                        </TableBody>
                    </Table>
                    </TableContainer>

                    <TablePagination
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                    />
                    </div>
            )}

            {
                editModal && (
                    <AddOrEditSalesGoalModal
                        open={editModal}
                        onClose={() => {
                            setEditModal(false);
                            setInitialData(null);
                        }}
                
                        edit={true}
                        initialData={initialData}
                        fetchData={fetchData}
                        handleSalesGoalStats={handleSalesGoalStats}
                        openCollapseModal={openCollapseModal}
                    />)}
        </div>
    );
};

export default SalesGoalTable;
