import * as ACTION from "./videoEmailActionTypes";

const initialState = {
    messageTemplates: null,
    videoLoader: true,
    videoFolders: null,
    videos: null,
    s3ServerVideoUrl: '',
    selectedVideo: null,
    folderId: null,
    isVideoPlaying: false,
    likeStatus: false,
    willSaveAsTemplate: false
};

const inboxReducer = (state = initialState, action) => {
    switch (action.type) {

        /* Video Email Start */
        case ACTION.FETCH_VIDEOS:
            return {...state, videoLoader: true}

        case ACTION.STORE_VIDEOS:
            return {...state, ...action.payload, videoLoader: false}

        case ACTION.STORE_VIDEO_FOLDERS:
            return {...state, videoFolders: action.payload, videoLoader: false}

        case ACTION.UPDATE_FOLDER_ID:
            return {...state, folderId: action.payload}

        case ACTION.CHANGE_IS_VIDEO_PLAYING:
            return {...state, isVideoPlaying: action.payload}

        case ACTION.CHANGE_LIKE_STATUS:
            return {...state, likeStatus: action.payload}

        case ACTION.CHANGE_SAVE_AS_TEMPLATE:
            return {...state, willSaveAsTemplate: !state.willSaveAsTemplate}
        /* Video Email End */

        /* Message Template Start */
        case ACTION.STORE_MESSAGE_TEMPLATE:
            return {...state, messageTemplates: action.payload}
        /* Message Template End */

        default:
            return state;
    }
}

export default inboxReducer;
