import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { connect } from 'react-redux';
import { getAllPipeline, getAllPipelineForUser } from '../../../../actions/DealAction';
import { addUserStageSubmit } from '../../../../actions/PipelineAction';
import { reloadPipeline, reloadStageList, storePipelineId } from '../../../../actions/PipelineReducerAction';
import { updateStageTextColor } from '../../../../api/pipelineApi';
import BasicInput from '../../../../components/custom/BasicInput';
import BasicSelect from '../../../../components/custom/BasicSelect';
import Utils from '../../../../helpers/Utils';
import GlobalModal from '../../../globals/Modal/GlobalModal';

const mapStateToProps = state => ({ 
    seletedPipelineId : state.PipelineReducer.seletedPipelineId,
});
const mapDispatchToProps = dispatch => ({
    getAllPipeline: (params, callback) => dispatch(getAllPipeline(params, callback)),
    getAllPipelineForUser: (params, callback) => dispatch(getAllPipelineForUser(params, callback)),
    addUserStageSubmit: (params, callback) => dispatch(addUserStageSubmit(params, callback)),
    reloadStageList: (params, callback) => dispatch(reloadStageList(params, callback)),
    storePipelineId: (params, callback) => dispatch(storePipelineId(params, callback)),
    reloadPipeline: (params) => dispatch(reloadPipeline(params)),
})

const winProbability = [
    {value : 1, label: '10%'},
    {value : 2, label: '20%'},
    {value : 3, label: '30%'},
    {value : 4, label: '40%'},
    {value : 5, label: '50%'},
    {value : 6, label: '60%'},
    {value : 7, label: '70%'},
    {value : 8, label: '80%'},
    {value : 9, label: '90%'},
    {value : 10, label: 'WON'},
    {value : 11, label: 'LOSE'},
]

const MainApp = props => {
    
    const titleRef = useRef(null)

    const [submit, setSubmit] = useState(false)
    const [title, setTitle] = useState('')
    const [requiredTitle, setRequiredTitle] = useState(false)
    const [validTitle, setValidTitle] = useState(false)
    const [lengthError, setLengthError] = useState(false)
    const [edit, setEdit] = useState(false)
    const [pipelineToMove,  setPipelineToMove] = useState(props.preSelectedPipelineId ?? '')
    const [validPipeline, setValidPipeline] = useState(false)
    const [pipelines, setPipelines] = useState([])
    const [probability,  setProbability] = useState(1)
    const [color,  setColor] = useState('#376B3F');

    useEffect(() => {
        if(typeof props.edit != 'undefined' && props.edit){
            setTitle(props.data.stage) 
            setPipelineToMove(props.data.pipeline_id)
            setColor(props.colorType === 'text' ? props.data.text_color : props.data.color_code)
            setProbability(props.data.percentage)
            setEdit(true)        
        }
        props.getAllPipelineForUser({}, (res) => {
            try{
                if(Utils.getAccountData('userIsAgent')){
                    const listData = [];
                    if(res.data !== undefined && res.data != null){
                        res.data.forEach((item, index) => {
                            if(item.user_id === Utils.getAccountData('userId')){
                                listData.push(item)
                            }
                        })
                        setPipelines(listData)
                    }
                }
                else if(Utils.getAccountData('userIsAdmin')){
                    const listData = [];
                    if(res.data !== undefined && res.data != null){
                        res.data.forEach((item, index) => {
                            if(item.user_id === Utils.getAccountData('userId')){
                                listData.push(item)
                            }
                            else if(item.user.role_user.role_id === 3){
                                listData.push(item)
                            }
                        })
                        setPipelines(listData)
                    }
                }
                else{
                    setPipelines(res.data)
                }
            }catch(error){
                setPipelines(res.data)
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => {
        if(props.colorType !== undefined && props.colorType === 'text'){
            updateColor()
            return;
        }

        if(title.trim() === ''){
            setRequiredTitle(true)
            titleRef.current.focus()
            return
        }
        if(title.length > 80){
            setLengthError(true)
            titleRef.current.focus()
            return
        }
        if(!(title.match(/^(?!\.)[-A-Za-z0-9.#%_ ]+$/))){
            setValidTitle(true)
            titleRef.current.focus()
            return
        }

        if(pipelineToMove === ''){
            setValidPipeline(true)
            return
        }

        setSubmit(true)
        if(edit){
            props.addUserStageSubmit({
                title: title,
                ID: props.data.id,
                background: color,
                percentage: probability,
                pipeline: pipelineToMove,
            }, (response) => {
                if(response['status'] === 'success') {
                    if(window.showNotification !== undefined){
                        window.showNotification("SUCCESS", response['html']);
                    }
                    // props.storePipelineId(pipelineToMove)
                    props.reloadStageList(true)
                    props.onClose()
                }
                else if(response.status === 'validation-error'){
                    if(window.showNotification !== undefined){
                        window.showNotification("ERROR", 'This title has already been taken! Please try another title');
                    }
                }
                else{
                    if(window.showNotification !== undefined){
                        window.showNotification("SUCCESS", response['html']);
                    }
                }
            })
        }
        else{
            props.addUserStageSubmit({
                title: title,
                ID: null,
                background: color,
                percentage: probability,
                pipeline: pipelineToMove,
            }, (response) => {
                if(response['status'] === 'success') {
                    if(pipelineToMove === props.seletedPipelineId){
                        props.reloadPipeline({addStageFlag: true})
                        props.reloadStageList(true)
                    }
                    props.storePipelineId({pipelineId: pipelineToMove, addStageFlag: true})
                    props.reloadPipeline({flag: true, editPipeline: true})
                    if(window.showNotification !== undefined){
                        window.showNotification("SUCCESS", response['html']);
                    }
                    props.onClose()
                }
                else if(response.status === 'validation-error'){
                    if(window.showNotification !== undefined){
                        window.showNotification("ERROR", 'This title has already been taken! Please try another title');
                    }
                }
                else{
                    if(window.showNotification !== undefined){
                        window.showNotification("SUCCESS", response['html']);
                    }
                }
            })
        }
    }

    const updateColor = () => {
        setSubmit(true)
        updateStageTextColor({
            id: props.data.id,
            text_color: color
        }).then(res => {
            let response = res.data;
            if(response.status === 'error'){
                if(window.showNotification !== undefined){
                    window.showNotification("ERROR", "Something went wrong");
                }
            }
            else{
                props.reloadStageList(true)
                props.onClose()
            }
            setSubmit(false)
        }).catch(error => {
            setSubmit(false)
            if(window.showNotification !== undefined){
                window.showNotification("ERROR", "Something went wrong");
            }
        })
    }

    const onChangeTitle = (event) => {
        setTitle(event.target.value)
        if(event.target.value.trim() === ''){
            setValidTitle(false)
        }
        if(validTitle){
            if(event.target.value.match(/^(?!\.)[-A-Za-z0-9.#%_ ]+$/)){
                setValidTitle(false)
            }
        }
    }

    const handlePipelineChange = (value) => {
        setPipelineToMove(value)
    }

    const select_props = {}
    select_props['value_field'] = 'id'
    select_props['titel_field'] = 'title'

    if(typeof props.onlyColorPicker != 'undefined'){
        return(
            <GlobalModal
                open={props.open} 
                title="Update Stage" 
                onClose={props.onClose}
                buttonText={`Update ${props.colorType === 'text' ? 'text color': 'color' }`}
                buttonIcon={<EditIcon fontSize="small" />}
                showLoader={submit}
                handleSubmit={handleSubmit}
                className="global-small-modal"
            >
                <h3 className="trigger__modal_title">Select {props.colorType === 'text' ? 'text color': 'color' }</h3>
                <SketchPicker
                    color={ color }
                    onChangeComplete={ (color) => setColor(color.hex) }
                />
            </GlobalModal>
        )
    }

    return(

        <GlobalModal
            open={props.open} 
            title="Stage" 
            onClose={props.onClose}
            buttonText={edit ? 'Edit Stage' :'Add Stage'}
            buttonIcon={edit ? <EditIcon fontSize="small" /> : <AddIcon fontSize="small" />}
            showLoader={submit}
            handleSubmit={handleSubmit}
            className="global-small-modal"
        >
            <p className="trigger__modal_title">Title <span style={{color: 'red'}}>*</span></p>
            
            <BasicInput 
                ref={titleRef}
                value={title} 
                onChange={onChangeTitle} 
                placeholder="Enter title" 
                fullWidth
            />

            {(requiredTitle && title === '') &&
            <p style={{color: 'red', fontSize: 12, width: '100%'}}>Title is required</p>      
            }  
            {(lengthError && title.length > 80) &&
            <p style={{color: 'red', fontSize: 12, width: '100%'}}>Pipeline title length should be max 80 </p>      
            } 
            {validTitle &&
            <p style={{color: 'red', fontSize: 12, width: '100%'}}>Invalid pipeline title [Accept: A-Z a-z 0-9 .-%#]</p>      
            } 


            <p>Select pipeline <span style={{color: 'red'}}>*</span></p>

            <BasicSelect
                options={pipelines}
                mapping={{
                    label: "title",
                    value: "id",
                }}
                defaultText="Select pipeline"
                value={pipelineToMove}
                onChange={(event) => handlePipelineChange(event.target.value)}
            />
            {validPipeline &&
            <p style={{color: 'red', fontSize: 12, width: '100%'}}>Pipeline is required</p>      
            } 

            <p style={{padding: '0px'}}    
            >Win Probability</p>

            <BasicSelect
                options={winProbability}
                defaultText="Select Probability"
                value={probability}
                onChange={(event) => {setProbability(event.target.value);
                    console.log(event.target)
                }}
                mapping={{
                    label: "label",
                    value: "value",
                }}
                
            />


            <p style={{padding: '10px'}}   
            >Select Color</p>
            <div style={{paddingLeft: '5px'}}>
            <SketchPicker
                color={ color }
                onChangeComplete={ (color) => setColor(color.hex) }
            />
            </div>
        </GlobalModal>
    )
}
const AddStage = connect(mapStateToProps, mapDispatchToProps)(MainApp);
export default AddStage;