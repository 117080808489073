import { useEffect } from "react";

function addDealUseDelayCallback(callback, array) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      callback();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, array); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
export default addDealUseDelayCallback;
