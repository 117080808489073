import { MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useNotification } from "../../../Notification/NotificationProvider";
import { dealMoveToStage, getAllPipeline, getAllStageByPipeline } from "../../../actions/DealAction";
import Utils from "../../../helpers/Utils";
import CustomSelect from "../../Common/Form-component/CustomSelect";
import GlobalModal from "../../globals/Modal/GlobalModal";

const App = props => {
    const dispatchNotification = useNotification();
    const [pipeLineList, setPipeLineList] = useState([])
	const [selectedPipeline, setselectedPipeline] = useState('')
    const [validPipeline, setValidPipeline] = useState(false)

    const [stageList, setStageList] = useState([])
	const [selectedStage, setSelectedStage] = useState('')
    const [validStage, setValidStage] = useState(false)

    const [submitProcessing, setSubmitProcessing] = useState(false)

    useEffect(() => {
        if(props.open){
            props.getAllPipeline({pipelineId : props.pipelineIdInDealList}, (response) => {
                setPipeLineList(response.data)
            })
        }
    }, [props.open]) // eslint-disable-line react-hooks/exhaustive-deps

    const handlePipelineChange = (event) => {
        setselectedPipeline(event.target.value)
        setSelectedStage('')
        props.getAllStageByPipeline({pipelineId : event.target.value}, (response) => {
            setStageList(response.dealStage)
        })
    }

    const handleSubmit = () => {
        if(submitProcessing){
            return false
        }
        if(selectedPipeline === ''){
            setValidPipeline(true)
            return false
        }
        if(selectedStage === ''){
            setValidStage(true)
            return false
        }
        setSubmitProcessing(true)
        props.dealMoveToStage({
            contact_stage_id: parseInt(props.selectedDealToMove),
            selectedPipeline: selectedPipeline,
            selectedStage: selectedStage
        }, (res) => {
            if(res){
                dispatchNotification({
                    type: "SUCCESS",
                    message: "Deal move to stage successfully",
                })
                props.refreshDataList()
                refreshData()
                props.onClose()
            }
            else{
                setSubmitProcessing(false)
                dispatchNotification({
                    type: "ERROR",
                    message: "Something went wrong",
                })
            }
        })

        /* api call to move stage */
    }

    const refreshData = () => {
        setPipeLineList([])
        setselectedPipeline('')
        setValidPipeline(false)
        setStageList([])
        setSelectedStage('')
        setValidStage(false)
        setSubmitProcessing(false)
    }

    const renderPipelineList = () => {
        if(Utils.getAccountData('userIsAgent')){
            const list = [];
            pipeLineList.forEach(item => {
                if(item.user.role_user.role_id === 3 && item.user_id === Utils.getAccountData('userId')){
                    list.push(item)
                }
                else if(item.user.role_user.role_id !== 3){
                    list.push(item)
                }
            })
            return list;
        }
        return pipeLineList
    }
  
    return <GlobalModal             
                open={props.open} 
                title="Move deal to pipeline" 
                onClose={props.onClose}
                buttonText={submitProcessing ? 'Assigning to Stage' : 'Assign to Stage'}
                buttonIcon={submitProcessing ? <MoreHorizIcon fontSize="small" /> : <MoveToInboxIcon fontSize="small" /> }
                handleSubmit={handleSubmit}
            >
                <h3 className="trigger__modal_title mt20pxSpaceTitle">Select Pipeline</h3>
                <CustomSelect
                    value={selectedPipeline}
                    handleChange={handlePipelineChange}
                    placeholder={(<MenuItem value="" disabled> Select a pipeline </MenuItem>)}
                    // item={pipeLineList}
                    item={renderPipelineList()}
                    // value_field={'value'}
                    value_field={'id'}
                    // titel_field={'label'}
                    titel_field={'title'}
                    modifyTitle={(text, item) => {
                        return (
                            <span
                                style={{
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                }}
                            >
                          {item.title}{" "}
                                <small>
                            <code>{item.user.full_name}</code>
                          </small>
                        </span>
                        );
                    }}
                />
                {validPipeline && selectedPipeline === '' &&
                <span style={{color: 'red'}}>Select a pipeline to select stage</span>
                }

                <h3 className="trigger__modal_title mt20pxSpaceTitle">Select a stage</h3>
                <CustomSelect
                    value={selectedStage}
                    handleChange={(event) => setSelectedStage(event.target.value)}
                    placeholder={(<MenuItem value="" disabled> Select a stage </MenuItem>)}
                    item={stageList}
                    value_field={'value'}
                    titel_field={'label'}
                />
                {validStage && selectedStage === '' &&
                <span style={{color: 'red'}}>Stage is required</span>
                }
         
            </GlobalModal>;
}

const mapStateToProps = state => ({
    pipelineIdInDealList: state.dealReducer.pipelineIdInDealList,
});
const mapDispatchToProps = dispatch => ({
	getAllPipeline: (params, callback) => dispatch(getAllPipeline(params, callback)),
	getAllStageByPipeline: (params, callback) => dispatch(getAllStageByPipeline(params, callback)),
	dealMoveToStage: (params, callback) => dispatch(dealMoveToStage(params, callback)),
    
})
const MoveDealToPipelineModal = connect(mapStateToProps, mapDispatchToProps)(App);
export default MoveDealToPipelineModal;