import React, {Fragment, useEffect, useState} from "react";
import { Button, LinearProgress, styled, Typography } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Utils from "../../../../helpers/Utils";
import {FREQUENCY} from "../../helper/coreConstant";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px !important",
  fontSize: "14px !important",
  textTransform: "capitalize !important",
  "&.MuiButton-containedPrimary": {
    backgroundColor: "#346fef !important",
  },
}));

const MonthlyGoalStatusWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: theme.spacing(1),

  "& .header": {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    "& .TabsWrapper": {
      backgroundColor: "#f8f8f8",
      display: "flex",
      borderRadius: 20,
      marginLeft: "auto",
    },
    "& .dateSmall": {
      fontSize: "12px"
    },
    "& .dateLabel": {
      margin: 0,
      padding: 0
    }
  },
  "& .trendingStats": {
    backgroundColor: "#f8f8f8",
    padding: 8,
    borderRadius: 4,
    boxShadow: "inset 0px -8px 0px -5px rgba(255,0,0,1)",
    textAlign: "center",
    "& h5": {
      marginBottom: "2px"
    }
  },
  "& .monthlyStats": {
    backgroundColor: "#f8f8f8",
    padding: 8,
    borderRadius: 4,
    marginTop: 8,

    display: "flex",
    justifyContent: "space-between",

    "& .leftSide": {
      "& .value": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        "& .icon": {
            backgroundColor: "white",
            borderRadius: 20,
            padding: 2,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          '& svg': {
              fontSize: 14,

          }
        },
      },
    },

    "& .rightSide": {
        textAlign: "right",
    }
  },
  "& .currentStats": {
    backgroundColor: "#f8f8f8",
    padding: 8,
    borderRadius: 4,
    marginTop: 8,
    "& .textWrapper": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 6,
        "& .rightSide": {
            textAlign: "right",
        }
    }
  },
  "& p.MuiTypography-root": {
    height: "unset"
  }
}));

const ProgressStyle = styled(LinearProgress)(({theme}) => ({
    borderRadius: 3,
    "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#346fef",
    }
  }));

const toZero = (val) => {
  try{
    if (val !== undefined && val !== null && typeof val === "number"){
      return val;
    }

    return 0;
  }catch (err){
    return 0;
  }
};

const numberArt = (val, nullReplacer="", suffix="",abs=false) => {
  if (val === null && nullReplacer){
    return nullReplacer;
  }

  try{
    if (val !== undefined && typeof val === "number"){
      if (abs){
        return Math.abs(val);
      }

      if (suffix){
        return val + suffix;
      }

      return val;
    }

    return 0;
  }catch (err){
    return 0;
  }
};

const MonthlyGoalStatus = ({ data, row }) => {
  const [activeTab, setActiveTab] = useState(1);

  const frequencyLabel = () => {
    switch (row.frequency){
      case FREQUENCY.WEEKLY:
        return "WEEK";
      case FREQUENCY.MONTHLY:
        return "MONTH";
      case FREQUENCY.QUARTERLY:
        return "QUARTER";
      case FREQUENCY.YEARLY:
        return "YEAR";
      default:
        return "N/A";
    }
  };

  const statsLabel = () => {
    switch (row.frequency){
      case FREQUENCY.WEEKLY:
        return <p className="dateLabel">This Week Stats<span className="dateSmall"> [{Utils.formatDate(data.currentFrequencyStartDate, "MMM D")} - {Utils.formatDate(data.currentFrequencyEndDate, "MMM D")}]</span></p>;
      case FREQUENCY.MONTHLY:
        return data.currentMonth + " Stats";
      case FREQUENCY.QUARTERLY:
        return <p className="dateLabel">This Quarter Stats<span className="dateSmall"> [{Utils.formatDate(data.currentFrequencyStartDate, "MMM D")} - {Utils.formatDate(data.currentFrequencyEndDate, "MMM D")}]</span></p>;
      case FREQUENCY.YEARLY:
        return data.currentYear + " Stats";
      default:
        return "N/A";
    }
  };

  return (
    <MonthlyGoalStatusWrapper>
      
      <div className="header">
        <Typography variant="body1">{statsLabel()}</Typography>
        <div className="TabsWrapper">
          <CustomButton
            size="small"
            variant={activeTab === 1 ? "contained" : "text"}
            color={activeTab === 1 ? "primary" : "inherit"}
            onClick={() => setActiveTab(1)}
            fullWidth
            disableElevation
          >
            Sales
          </CustomButton>

          {/*<CustomButton*/}
          {/*  size="small"*/}
          {/*  variant={activeTab === 2 ? "contained" : "text"}*/}
          {/*  color={activeTab === 2 ? "primary" : "inherit"}*/}
          {/*  onClick={() => setActiveTab(2)}*/}
          {/*  fullWidth*/}
          {/*  disableElevation*/}
          {/*>*/}
          {/*  Products*/}
          {/*</CustomButton>*/}
        </div>
      </div>

      <div className="trendingStats">
        <Typography variant="body1" style={{ textTransform: "uppercase" }}>
          Trending For
        </Typography>
        <Typography variant="h5">{Utils.formatCurrency(toZero(data.currentFrequencyPredictedSale), "$")}</Typography>
        <Typography variant="body1" color="textSecondary">
          {data.trendingForText}
        </Typography>
      </div>

      <div className="monthlyStats">
        <div className="leftSide">
          <Typography variant="body1">VS LAST {frequencyLabel()}</Typography>

          <div className="value">
            <Typography variant="h5">{numberArt(data.currentVsLastSalesGrowth, "N/A", "%", true)}</Typography>
            <span className="icon">
              {
                data.currentVsLastSalesGrowth !== null ?
                    (
                        toZero(data.currentVsLastSalesGrowth) > 0 ?
                            <ArrowUpwardIcon style={{color: 'green'}} /> :
                            <ArrowDownwardIcon style={{color: 'red'}} />
                    ) :
                    null
              }
            </span>
          </div>
        </div>

        <div className="rightSide">
          {
            data.currentVsLastSalesGrowth !== null &&
            <Fragment>
              <Typography variant="h5" color="textSecondary">
                {Utils.formatCurrency(toZero(data.previousGoalTotalSale), "$")}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                LAST {frequencyLabel()}
              </Typography>
            </Fragment>
          }
        </div>
      </div>

      <div className="currentStats">
        <div className="textWrapper">
        <div className="leftSide">
          <Typography variant="body1">CURRENTLY AT</Typography>
          <Typography variant="h5">{Utils.formatCurrency(toZero(data.currentFrequencyTotalSale), "$")}</Typography>
        </div>

        <div className="rightSide">
          <Typography variant="h5" color="textSecondary">
            {toZero(data.currentGoalPercentage)}%
          </Typography>
          <Typography variant="body1" color="textSecondary">
            OF GOAL
          </Typography>
        </div>
        </div>

        <ProgressStyle
            variant="determinate"
            value={toZero(data.currentGoalPercentage)}
        />
      </div>

      
    </MonthlyGoalStatusWrapper>
  );
};

export default MonthlyGoalStatus;
