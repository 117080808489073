import React, {useState, useEffect, useRef} from 'react'
import { connect } from 'react-redux'
import { getAllPipeline, getAllStageByPipeline } from '../../../../actions/DealAction';
import { deleteAndMoveToStage, deletePipelineAndMove } from '../../../../actions/PipelineAction';
import { reloadPipeline, reloadStageList, storePipelineId } from '../../../../actions/PipelineReducerAction';
import Utils from '../../../../helpers/Utils';
import CustomSelect from '../../../Common/Form-component/CustomSelect';
import GlobalModal from '../../../globals/Modal/GlobalModal';
import { MenuItem} from '@material-ui/core';  

const mapStateToProps = state => ({
    needToReloadPipeline: state.PipelineReducer.needToReloadPipeline,
    seletedPipelineId : state.PipelineReducer.seletedPipelineId,
    addPipeline : state.PipelineReducer.addPipeline,
    pipelineList : state.PipelineReducer.pipelineList,
 });
const mapDispatchToProps = dispatch => ({
    getAllPipeline: (params, callback) => dispatch(getAllPipeline(params, callback)),
    getAllStageByPipeline: (params, callback) => dispatch(getAllStageByPipeline(params, callback)),
    deleteAndMoveToStage: (params, callback) => dispatch(deleteAndMoveToStage(params, callback)),
    reloadStageList: (params, callback) => dispatch(reloadStageList(params, callback)),
})

const App = props => {
    const [pipelineToMove,  setPipelineToMove] = useState('')
    const [validPipeline, setValidPipeline] = useState(false)
    const [stageToMove, setStageToMove] = useState('')
    const [validStage, setValidStage] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [pipelines, setPipelines] = useState([])
    const [stages, setStages] = useState([])
    useEffect(() => {
        props.getAllPipeline({}, (res) => {
            setPipelines(res.data)
        })
    }, [])

    const handlePipelineChange = (value) => {
        setPipelineToMove(value)
        setStageToMove('')
        console.log(props.deletedStage)
        props.getAllStageByPipeline({pipelineId: value}, (response) => {
            let data = response.dealStage
            let filter_data = data.filter(item => item.value != props.deletedStage.id)
            setStages(filter_data)
        })
    }

    const handleSubmit = () => {
        if(pipelineToMove == ''){
            setValidPipeline(true)
            return;
        }
        if(stageToMove == ''){
            setValidStage(true)
            return;
        }
        setSubmit(true)
        props.deleteAndMoveToStage({
            selectedPipeline: pipelineToMove,
            selectedStage: stageToMove,
            deletedStage: props.deletedStage.id
        }, (res) => {
            setSubmit(false)
            props.reloadStageList(true)
            props.onClose(false)  
        })
    }

    const select_props = {}
    select_props['value_field'] = 'id'
    select_props['titel_field'] = 'title'

    return(
        <GlobalModal
            open={props.open} 
            title="Delete stage" 
            onClose={props.onClose}
            buttonText={'DELETE'}
            showLoader={submit}
            handleSubmit={handleSubmit}
            width={400}
        >
            {typeof props.deletedPipeline != 'undefined' &&
            <h4>Deleted pipeline : {props.deletedPipeline.title}</h4>
            }
            <h3 className="trigger__modal_title ds__mt20pxSpace">Select pipeline</h3>
            <CustomSelect
                value={pipelineToMove}
                handleChange={(event) => handlePipelineChange(event.target.value)}
                placeholder={(<MenuItem value="" disabled> Select a pipeline </MenuItem>)}
                item={pipelines}
                customClass={'pipeline-select'}
                {...select_props}
            />  
            {(validPipeline && pipelineToMove == '') &&
            <span>Select a pipeline</span>
            }

            <h3 className="trigger__modal_title ds__mt20pxSpace">Select stage</h3>
            <CustomSelect
                value={stageToMove}
                handleChange={(event) => setStageToMove(event.target.value)}
                placeholder={(<MenuItem value="" disabled> Select a stage </MenuItem>)}
                item={stages}
                value_field={'value'}
                titel_field={'label'}
            /> 
            {(validStage && stageToMove == '') &&
            <span>Select a stage</span>
            } 
        </GlobalModal>
    )
}
const MoveToStage = connect(mapStateToProps, mapDispatchToProps)(App);
export default MoveToStage;