// import { sendRequest } from "../../../../api/rootApi";
import Api from "./Api";
import axios from "axios";

export const saveFollowUp = async (payload) => {
    return sendRequest("post", Api.saveFollowup, payload);
}

export const updateEmailStepSettingApi = async (payload) => {
    return sendRequest("post", Api.updateCampaignEmailStepSetting, payload);
}

export const producePDF = async (payload)=>{
    return sendRequest("post", Api.producePDF, payload);
}

export const getThirdPartyService = async (payload)=>{
    return sendRequest("post", Api.getThirdPartyInfo, payload);
}
export const getMailCategory = async (payload)=>{
    return sendRequest("post", Api.getMailCategory, payload);
}

export const getMailTemplate = async (payload)=>{
    return sendRequest("post", Api.getMailTemplate, payload);
}

export const createMailTemplate = async (payload)=>{
    return sendRequest("post", Api.createMailTemplate, payload);
}

export const getCustomFields = async (payload) => {
    return sendRequest("post", Api.getCustomFields, payload);
}

export const sendRequest = (method, url, payload = [], config = []) => {
    let data = [];
    const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

    try{
        payload.append('api-key', apiKey)
        data = payload;
    } catch (error) {
        data = {...payload, ...{'api-key': apiKey}};
    }
    return axios.request({
        method: method,
        url: url,
        data: data,
        params: (method === 'get' || method === 'GET') ? data : null
    });
}


export const getCookie = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return atob(c.substring(name.length, c.length));
        }
    }
    return "";
}




