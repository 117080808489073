import { sendRequest } from "./rootApi";
import Api from "../constants/Api";
import HttpRequest from "./HttpRequest";

export const getProductFoldersApi = async () => {
  return sendRequest("get", Api.productFolders);
};
export const addProductFolderApi = async (payload) => {
  return sendRequest("post", Api.productFolders, payload);
};

export const deleteProductFolderApi = async (payload) => {
  return sendRequest("delete", `${Api.productFolders}/${payload}`);
};

export const updateProductFolderApi = async (payload) => {
  return sendRequest("put", `${Api.productFolders}/${payload.id}`, payload.payload);
};

export const getProductsApi = async ( payload ) => {
  return sendRequest("get", Api.userProducts, payload);
};

export const addProductApi = async (payload) => {
  return sendRequest("post", Api.userProducts, payload);
};

export const updateProductApi = async (payload) => {
  return sendRequest("put", `${Api.userProducts}/${payload.id}`, payload.payload);
};

export const deleteProductApi = async (payload) => {
  return sendRequest("put", `${Api.userProducts}/status/${payload.id}`, payload);
};

export const deleteProductForeverApi = async (payload) => {
  return sendRequest("delete", `${Api.userProducts}/${payload}`, payload);
};


export const toggleFavoriteApi = async (payload) => {
  return sendRequest("put", `${Api.userProducts}/is-favorite/${payload.id}`, payload);
}

export const getSalesGoalListApi = async (payload) => {
  return HttpRequest.get(Api.getAllSalesGoalList, payload);
};

export const addSalesGoalApi = async (payload) => {
  return HttpRequest.post(Api.addSalesGoal, payload);
};

export const updateSealsGoalApi = (payload) => {
  return HttpRequest.put(Api.updateSalesGoal, payload);
};

export const deleteSealsGoalApi = (payload) => {
  return HttpRequest.delete(Api.updateSalesGoal, payload);
};

export const userSaleGoalStats = (payload) => {
  return HttpRequest.get(Api.userSaleGoalStats, payload);
};

export const teamSalesReport = (payload) => {
  return HttpRequest.post(Api.teamSalesReport, payload);
};

export const productSalesReport = (payload) => {
  return HttpRequest.post(Api.productSalesReport, payload);
};

export const productSalesContact = (payload) => {
  return HttpRequest.post(Api.productSalesContact, payload);
};

export const salesVsGoalReport = (payload) => {
  return HttpRequest.get(Api.selesVsGoalReport, payload);
};

export const contactStageSearch = async (payload) => {
  return HttpRequest.post(Api.contactStageSearch, payload);
};

export const contactExport = async (payload) => {
  return HttpRequest.post(Api.contactExport, payload);
};

export const getTeamUsers = async (payload) => {
  return sendRequest("post", Api.getTeamUsers, payload.form_data);
};


  
export const productSalesExport = (payload) => {
  return HttpRequest.post(Api.productSalesExport, payload);
};

export const teamWiseSoldProducts = (payload) => {
  return HttpRequest.post(Api.teamWiseSoldProducts, payload);
};

export const teamWiseSoldProductExport = (payload) => {
  return HttpRequest.post(Api.teamWiseSoldProductExport, payload);
};
