import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const createPipelineApi = async (payload) => {
    return sendRequest("POST", Api.createPipeline, payload);
}

export const checkPipelineDeleteOrNotApi = async (payload) => {
    return sendRequest("POST", Api.checkPipelineDeleteOrNot, payload);
}

export const deletePipelineAndMoveApi = async (payload) => {
    return sendRequest("POST", Api.deletePipelineAndMove, payload);
}

export const updatePipelineApi = async (payload) => {
    return sendRequest("POST", Api.updatePipeline, payload);
}

export const getUserStageByPipelineApi = async (payload) => {
    return sendRequest("POST", Api.getUserStageByPipeline, payload);
}

export const sortUserStageApi = async (payload) => {
    return sendRequest("POST", Api.sortUserStage, payload);
}

export const deleteAndMoveToStageApi = async (payload) => {
    return sendRequest("POST", Api.deleteAndMoveToStage, payload);
}

export const userStageShowInChartApi = async (payload) => {
    return sendRequest("POST", Api.userStageToggleShowInChart, payload);
}

export const addUserStageSubmitApi = async (payload) => {
    return sendRequest("POST", Api.addUserStageSubmit, payload);
}

export const updatePipelineShowOptionApi = async (payload) => {
    return sendRequest("POST", Api.updatePipelineShowOption, payload);
}

export const updateStageTextColor = async (payload) => {
    return sendRequest("POST", Api.updateStageTextColor, payload);
}