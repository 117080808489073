import { MenuItem } from "@material-ui/core";
import GridOnIcon from "@material-ui/icons/GridOn";
import ListIcon from "@material-ui/icons/List";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAllPipeline,
  storeSelectedDealInfo,
  updateDealFilerOptions,
  updateDealStageReloadFlag,
} from "../../../actions/DealAction";
import {
  storeDateRange,
  storeDealStatus,
  storePipelineId,
  storePipelineList,
  storeSearchText,
  storeSelectAUser,
  storeTeamUserList,
} from "../../../actions/DealReducerAction";
import { IconList } from "../../../constants/IconList";
import Utils, { getAccountData } from "../../../helpers/Utils";
import CustomDateRangePicker from "../../Common/CustomDateRangePicker";
import CustomSelect from "../../Common/Form-component/CustomSelect";
import { Link, useLocation } from "react-router-dom";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import "../css/deal-pipeline-v2.css";
import Styles from "./grid-view-v2/eachi-item/style.module.css";
import { withStyles } from "@material-ui/core/styles";
import { getTeamUserListApi } from "../../../api/dealApi";
import GlobalUserListDropdown from "../../UserListDropdown/Index";
import { useIsMounted } from "../../../hooks/IsMounted";

const StyledTooltip = withStyles({
  tooltip: {
    color: "#fff",
  },
  arrow: {
    color: "#15008A",
  },
  tooltipPlacementTop: {
    background: "#15008A",
  },
  tooltipPlacementBottom: {
    background: "#15008A",
  },
  tooltipPlacementRight: {
    background: "#15008A",
  },
})(BootstrapTooltip);

const statusItem = [
  { value: 1, title: "Active" },
  { value: 2, title: "Won", icon: IconList.likeIcon },
  { value: 3, title: "Lost", icon: IconList.dislikeIcon },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let filterModified = false;

const DealTopbar = (props) => {
  const isMounted = useIsMounted();
  const [pipeLineList, setPipeLineList] = useState([]);
  // const [selectedPipeline, setselectedPipeline] = useState(props.pipelineIdInDealList)
  // const [seletedStatus, setSeletedStatus] = useState(props.statusInDealList)
  // const [selectedDateRange, setSelectedDateRange] = useState(props.calerdarSortingInDealList)
  const [selectedPipeline, setselectedPipeline] = useState(props.pipelineId);
  const [seletedStatus, setSeletedStatus] = useState(props.status);
  const [selectedDateRange, setSelectedDateRange] = useState(props.dateTime);
  const [, setSearchText] = useState(props.search);
  let queryParams = useQuery();
  const isTempUser = Utils.getAccountData("isTemplateUser");
  const starterPack = Utils.getAccountData("starterPack");

  /* filter by user */
  const [users, setUsers] = useState(props.users);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allPipeline, setAllPipeline] = useState([]);
  const [selectedPipelineOwner, setSelectedPipelineOwner] = useState(null);

  useEffect(() => {
    getTeamUserListApi()
      .then((res) => {
        let response = res.data;
        if (response.status) {
          let subUsersArray = [];
          response.data.forEach((subUser) => {
            subUsersArray.push({
              value: subUser.id,
              label: subUser.full_name,
            });
          });
          setUsers(subUsersArray);
          props.storeTeamUserList(subUsersArray);
        }
      })
      .catch((error) => {
        props.storeTeamUserList([]);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.getAllPipeline({}, (response) => {
      setPipeLineList(response.data);
      setAllPipeline(response.data);
      props.storePipelineList(response.data);
      if (props.pipelineIdInDealList === "") {
        //if you want to refresh remove this clouser
        let pipeLength = response.data.length;
        let selectedPipeId = null;
        if (pipeLength > 0) {
          if (
            queryParams.get("id") !== undefined &&
            queryParams.get("id") != null &&
            queryParams.get("id") !== ""
          ) {
            for (let i = 0; i < pipeLength; i++) {
              if (queryParams.get("id") == response.data[i].id) {
                selectedPipeId = queryParams.get("id");
                break;
              }
            }
            if (selectedPipeId != null) {

              setselectedPipeline(selectedPipeId);
              props.updateDealFilerOptions({
                pipelineId: selectedPipeId,
              });
            } else {

              setselectedPipeline(response.data[0].id);
              props.updateDealFilerOptions({
                pipelineId: response.data[0].id,
              });
            }
          } else {
            /* TODO: check in cookie */
            if (checkCookie()) {
              const cookie_data = getCookieData();
              if (
                cookie_data !== undefined &&
                cookie_data != null &&
                cookie_data !== ""
              ) {
                let selected_id = null;
                for (let i = 0; i < pipeLength; i++) {
                  if (response.data[i].id == cookie_data) {
                    selected_id = cookie_data;
                    break;
                  }
                }
                if (selected_id != null) {

                  setselectedPipeline(parseInt(cookie_data));
                  props.updateDealFilerOptions({
                    pipelineId: parseInt(cookie_data),
                  });
                } else {

                  setselectedPipeline(response.data[0].id);
                  props.updateDealFilerOptions({
                    pipelineId: response.data[0].id,
                  });
                }
              } else {

                setselectedPipeline(response.data[0].id);
                props.updateDealFilerOptions({
                  pipelineId: response.data[0].id,
                });
              }
            } else {
              if (response.data[0].user_id === Utils.getAccountData("userId")) {
                setCookieData(response.data[0].id);
              }

              setselectedPipeline(response.data[0].id);
              props.updateDealFilerOptions({
                pipelineId: response.data[0].id,
              });
            }
          }
        }
      } else {
        let pipeLength = response?.data.length;
        /* check pipeline id in cookie */
        if (checkCookie() && pipeLength !== undefined && pipeLength != null) {
          const cookie_data = getCookieData();
          if (
            cookie_data !== undefined &&
            cookie_data != null &&
            cookie_data !== ""
          ) {
            let selected_id = null;
            for (let i = 0; i < pipeLength; i++) {
              if (response.data[i].id == cookie_data) {
                selected_id = cookie_data;
                break;
              }
            }
            if (selected_id != null) {

              setselectedPipeline(parseInt(cookie_data));
              props.updateDealFilerOptions({
                pipelineId: parseInt(cookie_data),
              });
            }
          }
        }
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterModified) {
      return;
    }
    if (allPipeline.length > 0 && users.length > 0) {
      // if (getAccountData("userIsOwner")){
      let newData = [], newIds = [];
      for (let i = 0; i < users.length; i++) {
        if (users[i].value === getAccountData("userId") ||
          (users[i].value === getAccountData("parentId") && getAccountData("userId") !== getAccountData("parentId"))) {
          newData.push(users[i]);
          newIds.push(users[i].value);
        }
        filterModified = true;
      }


      if (!newData[0]) {
        setSelectedUsers([{ value: "__all__", label: "All users" }]);
        setSelectedIds(["__all__"]);
      } else {
        setSelectedUsers(newData);
        setSelectedIds(newIds);
      }
      // }else {

      //   setSelectedUsers([{ value: "__all__", label: "All users" }]);
      //   setSelectedIds(["__all__"]);
      // }
    }
  }, [allPipeline, users]);

  useEffect(() => {

    setselectedPipeline(props.pipelineId);
    // if (props.pipelineId != null && props.pipelineId !== "" && Utils.getAccountData("userId") === selectedPipelineOwner) {
    setCookieData(props.pipelineId);
    // }
  }, [props.pipelineId, selectedPipelineOwner]);

  useEffect(() => {
    if (isMounted) {
      try {
        if (selectedIds[0] === "__all__") {
          setPipeLineList(allPipeline);
          props.storePipelineList(allPipeline);

          if (selectedPipeline) {

            return;
          }
          if (allPipeline.length > 0 && selectedPipeline != null) {

            setselectedPipeline(allPipeline[0].id);
            props.updateDealFilerOptions({
              pipelineId: allPipeline[0].id,
            });
            setSelectedPipelineOwner(allPipeline[0].user_id);
          }
          else {
            setselectedPipeline("");
            props.updateDealFilerOptions({
              pipelineId: "",
            });
          }
          return;
        }
        if (selectedIds.length === 0) {
          setPipeLineList(allPipeline);
          props.storePipelineList(allPipeline);
          if (allPipeline.length > 0) {

            setselectedPipeline(allPipeline[0].id);
            props.updateDealFilerOptions({
              pipelineId: allPipeline[0].id,
            });
            setSelectedPipelineOwner(allPipeline[0].user_id);
          }
          else {
            setselectedPipeline("");
            props.updateDealFilerOptions({
              pipelineId: "",
            });
          }
          return;
        }
        let newData = [];
        let matchWithPrevious = false;
        allPipeline.forEach((item) => {
          if (selectedIds.includes(item.user_id)) {
            newData.push(item);
            if (item.id == props.pipelineId) {
              matchWithPrevious = true;
            }
          }
        });
        if (!matchWithPrevious) {
          if (newData.length > 0) {

            setselectedPipeline(newData[0].id);
            props.updateDealFilerOptions({
              pipelineId: newData[0].id,
            });
            setSelectedPipelineOwner(newData[0].user_id);
          }
        }
        setPipeLineList(newData);
        props.storePipelineList(newData);
        if (newData.length > 0) {
          // setselectedPipeline(newData[0].id);
          // props.updateDealFilerOptions({
          //   pipelineId: newData[0].id,
          // });
        }
        else {
          setselectedPipeline("");
          props.updateDealFilerOptions({
            pipelineId: "",
          });
        }
      } catch (error) { }
    }
  }, [selectedIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const setCookieData = (id) => {
    var d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = `selectd_pipeline_id_in_deal:${Utils.getAccountData("userId")}=` + id + ";" + expires + ";path=/";
  };
  const getCookieData = () => {
    let name = `selectd_pipeline_id_in_deal:${Utils.getAccountData("userId")}=`;
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  const checkCookie = (cname = `selectd_pipeline_id_in_deal:${Utils.getAccountData("userId")}`) => {
    const cookieInfo = getCookieData(cname);
    return !!cookieInfo;
  };

  const handlePipelineChange = (value, owner = null) => {
    setselectedPipeline(value);
    // props.storePipelineId(value)
    props.updateDealFilerOptions({
      pipelineId: value,
    });


    if (owner) {
      if (owner.user_id === Utils.getAccountData("userId")) {
        setCookieData(value);
      }
    }
  };
  const handleStatusChange = (value) => {
    setSeletedStatus(value);
    props.updateDealFilerOptions({
      status: value,
    });
  };
  const handleDateRangeChange = (value) => {
    // formate value
    let start_date, end_date;
    start_date = moment(value.startDate).format("MM/DD/YYYY");
    end_date = moment(value.endDate).format("MM/DD/YYYY");
    setSelectedDateRange({ startDate: start_date, endDate: end_date });
    props.updateDealFilerOptions({
      dateTime: { startDate: start_date, endDate: end_date },
    });
  };
  const handleSearch = (value) => {
    setSearchText(value);
    props.updateDealFilerOptions({
      search: value,
    });
  };
  const handleOnCancel = (value) => {
    setSelectedDateRange({ startDate: null, endDate: null });
    props.updateDealFilerOptions({
      dateTime: { startDate: null, endDate: null },
    });
  };

  const renderPipelineList = () => {
    if (Utils.getAccountData("userIsAgent")) {
      const list = [];
      pipeLineList.forEach((item) => {
        if (
          item.user.role_user.role_id === 3 &&
          item.user_id === Utils.getAccountData("userId")
        ) {
          list.push(item);
        } else if (item.user.role_user.role_id !== 3) {
          list.push(item);
        }
      });
      return list;
    }
    return pipeLineList;
  };


  const handleSelectUser = (item, remove) => {
    if (item.value === "__clear_all__") {
      setSelectedUsers([]);
      setSelectedIds([]);
      return;
    }
    if (item.value === "__all__") {
      if (remove) {
        setSelectedUsers([]);
        setSelectedIds([]);
      } else {
        setSelectedUsers([item]);
        setSelectedIds(["__all__"]);
      }
    } else {
      if (selectedIds.length > 0 && selectedIds[0] === "__all__") {
        setSelectedUsers([item]);
        setSelectedIds([item.value]);
      } else {
        let old_data_ids = [...selectedIds];
        let old_data_full = [...selectedUsers];
        if (remove) {
          let filter_old_data_ids = old_data_ids.filter(
            (list) => item.value !== list
          );
          setSelectedIds(filter_old_data_ids);

          let filter_old_data_full = old_data_full.filter(
            (list) => item.value !== list.value
          );
          setSelectedUsers(filter_old_data_full);
        } else {
          old_data_ids.push(item.value);
          old_data_full.push(item);
          setSelectedUsers(old_data_full);
          setSelectedIds(old_data_ids);
        }
      }
    }
  };

  return (
    <div className='deal-top-section deal__top__section__alt'>
      <div className='mb-0 row'>
        <div className='col s12 m6 deal-catagory-section'>
          <ul style={{ gridRowGap: '8px' }}>
            <li className='deal-title-wrapper'>
              <span className='deal-title'>Deal</span>
            </li>
            {props.showOptions && (
              <>
                <li className='action-btn-dropdown dm_tbps_alt'>
                  <CustomSelect
                    value={selectedPipeline}
                    handleChange={(event) => {
                      let value = pipeLineList.find((pipeLine) => (pipeLine.id === event.target.value));
                      handlePipelineChange(event.target.value, value);
                    }}
                    placeholder={
                      <MenuItem value='' disabled>
                        {" "}
                        Select a pipeline{" "}
                      </MenuItem>
                    }
                    // item={pipeLineList}
                    item={renderPipelineList()}
                    value_field={"id"}
                    titel_field={"title"}
                    customClass={"pipeline-select pipeline-select-alt-v2"}
                    menuItemClass={
                      "dropdownhelper-menuitem-class d-flex dark-menuitem-color"
                    }
                    modifyTitle={(text, item) => {
                      return (
                        <BootstrapTooltip arrow placement="top" title={item.title + " - " + item.user.full_name}>
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              maxWidth: "250px",
                              overflow: "hidden",
                            }}
                          >
                            {item.title}{" "}
                            <small>
                              <code>{item.user.full_name}</code>
                            </small>
                          </span>
                        </BootstrapTooltip>
                      );
                    }}
                  />
                </li>
                {props.dataViewType === 1 && (
                  <li className='action-btn-dropdown dm_tbps_alt'>
                    <GlobalUserListDropdown
                      userList={users}
                      handleSelect={(value, remove) =>
                        handleSelectUser(value, remove)
                      }
                      selected={selectedUsers}
                      selectedId={selectedIds}
                    />
                    {/* <CustomSelect
                      value={selectedUser}
                      handleChange={handleSelectUser}
                      placeholder={
                        <MenuItem value='' disabled>
                          Select a user
                        </MenuItem>
                      }
                      item={users}
                      value_field={"id"}
                      titel_field={"full_name"}
                      customClass={"pipeline-select pipeline-select-alt-v2"}
                      menuItemClass={
                        "dropdownhelper-menuitem-class d-flex dark-menuitem-color"
                      }
                    /> */}
                  </li>
                )}
                <li className='action-btn-dropdown statusDropdown' style={{ width: 'unset !important' }}>
                  <CustomSelect
                    value={seletedStatus}
                    handleChange={(event) =>
                      handleStatusChange(event.target.value)
                    }
                    item={statusItem}
                    value_field={"value"}
                    titel_field={"title"}
                    menuItemClass={"activeItemLi deal-list-status"}
                    customClass={"pipeline-select-alt-v2"}
                  />
                </li>
                {props.dataViewType === 1 && (
                  <li className='datepicker-dropdown'>
                    <CustomDateRangePicker
                      dateRange={selectedDateRange}
                      onApply={handleDateRangeChange}
                      onCancel={handleOnCancel}
                    // value={selectedDateRange.startDate+':'+selectedDateRange.startDate}
                    />
                  </li>
                )}
              </>
            )}
            {props.dataViewType === 1 ? (
              <li className='total-deals-value'>
                <span className='total-delas-number'>
                  TOTAL DEALS: {props.stats.totalDeals}{" "}
                </span>
                <span className='total-value-ammount'>
                  TOTAL VALUE:&nbsp;
                  <StyledTooltip
                    arrow
                    title={
                      <span className={Styles.dealValue_Tooltip}>
                        {Utils.calculateCashPlain(props.stats.totalDealValue)}
                      </span>
                    }
                  >
                    <span>
                      {Utils.calculateCash(props.stats.totalDealValue)}
                    </span>
                  </StyledTooltip>
                </span>
              </li>
            ) : null}
          </ul>
        </div>
        <div className='col s12 m6 deal-filtering-section'>
          <ul className='right'>
            {props.showOptions && (
              <li className='search-area'>
                <div className='input-field search-input-field-wrapper'>
                  <div className='search-input-field'>
                    <input
                      placeholder='Search'
                      onChange={(event) => handleSearch(event.target.value)}
                      id='search'
                      type='search'
                    />
                    <i className='material-icons'>search</i>
                  </div>
                </div>
              </li>
            )}
            <li className='deal-actions-buttons'>
              <a
                  href='javascript:void(0)'
                  className={
                    props.dataViewType == 1
                        ? "active active-icon accent--bg--text--color"
                        : ""
                  }
                  onClick={(e) => props.handleChangeDataView(true, 1, e)}
              >
                <GridOnIcon />
              </a>
              <a
                href='javascript:void(0)'
                className={
                  props.dataViewType == 2
                    ? "active active-icon accent--bg--text--color"
                    : ""
                }
                onClick={(e) => props.handleChangeDataView(true, 2, e)}
              >
                {" "}
                <ListIcon />{" "}
              </a>
              {/* <a href="#"> <i className="material-icons">dashboard</i> </a> */}
              <a
                href='javascript:void(0)'
                className={
                  props.dataViewType == 3
                    ? "active active-icon accent--bg--text--color"
                    : ""
                }
                onClick={(e) => props.handleChangeDataView(false, 3, e)}
              >
                <TrendingUpIcon />
              </a>
            </li>
            {!isTempUser && !starterPack && (
              <li className='mr10pxSpace'>
                <BootstrapTooltip title='New Deal' arrow placement='top'>
                  <a
                    className='rounded-icons'
                    href='#!'
                    onClick={(e) => {
                      e.preventDefault();
                      props.handleAddDealModal(true, false, selectedPipeline);
                    }}
                  >
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14 7C14 7.64517 13.944 8.16667 13.2988 8.16667H8.16667V13.2988C8.16667 13.9428 7.64517 14 7 14C6.35483 14 5.83333 13.9428 5.83333 13.2988V8.16667H0.701166C0.0571664 8.16667 0 7.64517 0 7C0 6.35483 0.0571664 5.83333 0.701166 5.83333H5.83333V0.701166C5.83333 0.0559998 6.35483 0 7 0C7.64517 0 8.16667 0.0559998 8.16667 0.701166V5.83333H13.2988C13.944 5.83333 14 6.35483 14 7Z'
                        fill='#546376'
                      ></path>
                    </svg>
                  </a>
                </BootstrapTooltip>
              </li>
            )}

            <li className='mr10pxSpace mr-0'>
              <BootstrapTooltip title='Pipeline Settings' arrow placement='top'>
                <Link to='pipelines' className='rounded-icons'>
                  <svg
                    className='settings-icon'
                    width='21'
                    height='20'
                    viewBox='0 0 21 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.82559 12.4883C8.56568 13.1768 9.45715 13.5211 10.5 13.5211C11.5429 13.5211 12.4343 13.1768 13.1744 12.4883C13.9145 11.7997 14.2845 10.9703 14.2845 10C14.2845 9.02973 13.9145 8.20031 13.1744 7.51174C12.4343 6.82316 11.5429 6.47887 10.5 6.47887C9.45715 6.47887 8.56568 6.82316 7.82559 7.51174C7.0855 8.20031 6.71546 9.02973 6.71546 10C6.71546 10.9703 7.0855 11.7997 7.82559 12.4883ZM18.5232 10.9859L20.794 12.6291C21.0294 12.7856 21.0631 13.0047 20.8949 13.2864L18.7251 16.7606C18.5905 16.9797 18.3718 17.0423 18.0691 16.9484L15.3947 15.9624C14.6882 16.4319 14.0827 16.7606 13.5781 16.9484L13.1744 19.5775C13.1071 19.8592 12.9389 20 12.6698 20H8.3302C8.06107 20 7.89287 19.8592 7.82559 19.5775L7.42191 16.9484C6.78274 16.698 6.17721 16.3693 5.60533 15.9624L2.93092 16.9484C2.62815 17.0423 2.40949 16.9797 2.27493 16.7606L0.105126 13.2864C-0.0630757 13.0047 -0.0294353 12.7856 0.206047 12.6291L2.47677 10.9859C2.44313 10.7668 2.42631 10.4382 2.42631 10C2.42631 9.56182 2.44313 9.23318 2.47677 9.01408L0.206047 7.37089C-0.0294353 7.2144 -0.0630757 6.99531 0.105126 6.71361L2.27493 3.23944C2.40949 3.02034 2.62815 2.95775 2.93092 3.05164L5.60533 4.03756C6.31177 3.56808 6.9173 3.23944 7.42191 3.05164L7.82559 0.422535C7.89287 0.140845 8.06107 0 8.3302 0H12.6698C12.9389 0 13.1071 0.140845 13.1744 0.422535L13.5781 3.05164C14.2173 3.30203 14.8228 3.63067 15.3947 4.03756L18.0691 3.05164C18.3718 2.95775 18.5905 3.02034 18.7251 3.23944L20.8949 6.71361C21.0631 6.99531 21.0294 7.2144 20.794 7.37089L18.5232 9.01408C18.5569 9.23318 18.5737 9.56182 18.5737 10C18.5737 10.4382 18.5569 10.7668 18.5232 10.9859Z'
                      fill='#546376'
                    ></path>
                  </svg>
                </Link>
              </BootstrapTooltip>
            </li>

            {/* <li className="mr10pxSpace d-none" onClick={()=> setShowFilter(true) }>
								<div className="rounded-icons waves-effect">
									<FilterListIcon />
									<TouchRipple> </TouchRipple>
								</div>
						</li>
						
						<li className="mr0pxSpace">
							<a href="#" className="rounded-icons">
								<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M13.8042 10.3065C15.1922 9.32627 16.0383 8.35391 16.0737 8.31299L16.3441 7.99998L16.0737 7.68698C16.0384 7.64606 15.1922 6.6737 13.8042 5.69348C11.9486 4.38309 10.0007 3.69044 8.17116 3.69044C7.18229 3.69044 6.15898 3.89321 5.1344 4.28605L0.848316 0L0.171143 0.677173L15.494 16L16.1711 15.3228L12.1485 11.3001C12.7048 11.0235 13.2585 10.6919 13.8042 10.3065ZM8.17116 5.60579C9.49131 5.60579 10.5653 6.67983 10.5653 7.99998C10.5653 8.48725 10.4187 8.94074 10.1677 9.31934L9.46728 8.61896C9.5572 8.43142 9.60767 8.22149 9.60767 7.99998C9.60767 7.20789 8.96325 6.56347 8.17116 6.56347C7.94965 6.56347 7.73976 6.61394 7.55221 6.70386L6.85183 6.00348C7.23037 5.75244 7.68389 5.60579 8.17116 5.60579Z" fill="#546376"></path>
									<path d="M8.17106 10.3925C6.8509 10.3925 5.77686 9.31851 5.77686 7.99835C5.77686 7.8835 5.78548 7.77065 5.80122 7.66004L3.32087 5.17969C3.05879 5.33837 2.79788 5.5089 2.5388 5.69188C1.15119 6.67213 0.305467 7.64452 0.270129 7.68545L0 7.99835L0.270129 8.31126C0.305467 8.35218 1.15119 9.32458 2.5388 10.3048C4.39385 11.6152 6.34148 12.3079 8.17106 12.3079C8.82959 12.3079 9.50347 12.2177 10.1828 12.0417L8.50937 10.3682C8.39876 10.3839 8.28591 10.3925 8.17106 10.3925Z" fill="#546376"></path>
								</svg>
							</a>
						</li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pipelineIdInDealList: state.dealReducer.pipelineIdInDealList,
  searchTextInDealList: state.dealReducer.searchTextInDealList,
  calerdarSortingInDealList: state.dealReducer.calerdarSortingInDealList,
  statusInDealList: state.dealReducer.statusInDealList,

  /* for new deal reducers */
  pipelineId: state.dealReducerV2.pipelineId,
  search: state.dealReducerV2.search,
  dateTime: state.dealReducerV2.dateTime,
  status: state.dealReducerV2.status,

  users: state.dealReducerV2.users,
  selectedUser: state.dealReducerV2.selectedUser,
});
const mapDispatchToProps = (dispatch) => ({
  getAllPipeline: (params, callback) =>
    dispatch(getAllPipeline(params, callback)),
  storePipelineId: (params, callback) =>
    dispatch(storePipelineId(params, callback)),
  storeDealStatus: (params, callback) =>
    dispatch(storeDealStatus(params, callback)),
  storeDateRange: (params, callback) =>
    dispatch(storeDateRange(params, callback)),
  storeSearchText: (params, callback) =>
    dispatch(storeSearchText(params, callback)),

  /* deal module new V2 */
  updateDealFilerOptions: (params) => dispatch(updateDealFilerOptions(params)),
  storeSelectedDealInfo: (params) => dispatch(storeSelectedDealInfo(params)),
  updateDealStageReloadFlag: (params) =>
    dispatch(updateDealStageReloadFlag(params)),
  storePipelineList: (params) => dispatch(storePipelineList(params)),
  storeTeamUserList: (params) => dispatch(storeTeamUserList(params)),
  storeSelectAUser: (params) => dispatch(storeSelectAUser(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DealTopbar);
