import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//! Must Use null in initial State except ""
//! Pass your selected state as value and setState or function as onChange in the Parent

const CustomDatePicker = (props) => {
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          format="MM/dd/yyyy"
          placeholder="MM/DD/YYYY"
          clearable
          InputProps={{
            error: true,
            helperText: "Invalid Date",
          }}
          validationError="Invalid Date"
          value={props.value || null}
          onChange={props.changeDate}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default CustomDatePicker;
