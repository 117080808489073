import { getCookie, getCookieLogin } from "./Cookie";
import moment from "moment-timezone";
/**
 * Handle error exception
 * 
 * @param Error error 
 * @param Function/null callback 
 */
const handleException = (error, callback = null) => {
    console.error(error);

    // to do
}

/**
 * Get contact's display name
 * 
 * @param string name 
 * @param string number 
 * @param string email 
 * @returns string
 */
const getContactDisplayName = (name, number, email) => {
    if (name === '' || name === ' ' || name === null || name === 'null null') {
        if (number === '' || number === ' ') {
            if (email === '' || email === ' ') {
                return '[No Name]';
            } else {
                return email.length > 15 ? email.substr(0, 13) + ' ...' : email;
            }
        } else {
            return number;
        }
    }

    return name.length > 15 ? name.substr(0, 13) + ' ...' : name;
}

/**
 * Display notification
 * 
 * @param string message 
 * @param string type 
 * @param string/null title 
 * @param boolean sticky 
 */
const showNotification = (message = 'Something went wrong', type = 'error') => {
    if (window.showNotification !== undefined) {
        window.showNotification(type, message);
    }
    // alert(message)
};

/**
 * Get initial of name
 * 
 * @param string name 
 * @returns string
 */
const getInitial = (name) => {
    try {
        if (typeof name != 'undefined') {
            let fullNameInitial = 'X'
            if (name == 'null null' || name == '' || name == ' ') {
                fullNameInitial = 'X';
            } else if (name !== 'undefined' || name !== null) {
                fullNameInitial = name.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("");
            }
            return fullNameInitial;
        } else {
            return 'X';
        }
    } catch (error) {
        return 'X';
    }
}

const checkEmptyJson = data => {
    if (JSON.stringify(data) == '{}') {
        return false
    }
    return true
}

const limitText = (text, limit = 40) => {
    if (text.length > limit) {
        return text.substring(0, limit - 3).trim() + "...";
    }
    return text;
}

const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const validateMobileNumber = (mobileNumber) => {
    if (mobileNumber != '' && mobileNumber.length < 10) {
        return false;
    }
    else if (mobileNumber != '' && mobileNumber.length > 11) {
        return false
    } else if (mobileNumber.length == 11 && mobileNumber.substring(0, 1) != 1) {
        return false;
    }
    return true
}

const checkLetterExistInText = (text) => {
    var regExp = /[a-zA-Z]/g;
    let result = false
    if (regExp.test(text)) {
        result = true
    }
    return result
}

const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return phoneNumberString
}

const calculateCash = (amount, fixedCount = 2) => {
    var flag = ''
    // 1000 = 1K
    // 1000000 = 1M = 1K * 1000
    // 1M * 1000 = 1B
    // 1B * 1000 = 1T
    if (amount >= 1000000000000000000000000) {
        amount = amount / 1000000000000000000000000
        flag = 'Y'
    }
    if (amount >= 1000000000000000000000) {
        amount = amount / 1000000000000000000000
        flag = 'Z'
    }
    if (amount >= 1000000000000000000) {
        amount = amount / 1000000000000000000
        flag = 'E'
    }
    if (amount >= 1000000000000000) {
        amount = amount / 1000000000000000
        flag = 'P'
    }
    if (amount >= 1000000000000) {
        amount = amount / 1000000000000
        flag = 'T'
    }
    else if (amount >= 1000000000) {
        amount = amount / 1000000000
        flag = 'B'
    }
    else if (amount >= 1000000) {
        amount = amount / 1000000
        flag = 'M'
    }
    else if (amount >= 1000) {
        amount = amount / 1000
        flag = 'K'
    }
    // return '$' + (Math.round(totalDeal * 100) / 100) + flag
    if (amount != null) {
        return '$' + amount.toFixed(fixedCount) + flag
    }
    return '$' + amount + flag
}

const calculateCashPlain = (amount, fixedCount = 2) => {
    if (amount != null) {
        return '$' + amount.toFixed(fixedCount);
    }
    return '$' + amount;
}

const isEmpty = (text) => {
    if (text) {
        return false
    } else {
        return true
    }
}

/**
 * Function name: getAccountData
 * This function will provide
 * AuthUserAgencyName
 * packageType
 * packageType
 * starterPack
 * packageType
 * starterProPack
 * packageType
 * standardPack
 * packageType
 * starterPack
 * starterProPack
 * standardPack
 * baseUrl
 * userIsAgent
 * userIsOwner
 * userIsAdmin
 * button_text_transform_class
 * userTimezone
 * userTimezoneRaw
 * userId
 * agencyId
 * canvaApiKey
 * agencyBusinessType
 */
export const getAccountData = (needle) => {
    // let accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if (check_new !== undefined) {
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    } else {
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    if (accountData) {
        accountData = JSON.parse(accountData);
        return accountData[needle];
    }
    return false;
}

function formatCurrency(n, currency = "") {
    try {
        if (n !== "") {
            n = parseFloat(n);
        }

        if (typeof n !== "number" || isNaN(n)) return currency + Number(0).toFixed(2);
        const hasDecimal = n % 1 !== 0;

        return (
            currency +
            n.toFixed(hasDecimal ? 2 : 0).replace(/./g, function (c, i, a) {
                return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
            })
        );
    } catch (err) {
        return n;
    }
}

const formatDate = (rawDate, format = "MM-DD-YYYY") => {
    try {
        return moment(rawDate).format(format);
    } catch (err) {
        console.log(err)
        return rawDate;
    }
};

export const activeStatusClass = (status) => {
    switch (status) {
        case "WIN":
            return "dealWon";
        case "LOST":
            return "dealLost";
        default:
            return "dealActive";
    }
};

export const activeStatus = (status) => {
    switch (status) {
        case "WIN":
            return "Won";
        case "LOST":
            return "Lost";
        default:
            return "Active";
    }
};

/**
 * Utility helper
 */
const Utils = {
    handleException,
    getContactDisplayName,
    getInitial,
    showNotification,
    checkEmptyJson,
    limitText,
    validateEmail,
    validateMobileNumber,
    checkLetterExistInText,
    formatPhoneNumber,
    calculateCash,
    calculateCashPlain,
    getAccountData,
    isEmpty,
    formatCurrency,
    formatDate,
    activeStatusClass,
    activeStatus

}

export default Utils;