import React from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
const RecycleInfoModalSkeleton = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rect" width="60%" height={'24px'} style={{borderRadius:'6px'}} />
          <div className="infoBox">
            <Skeleton variant="rect" width="100%" height={'36px'} style={{marginTop:'8px',borderRadius:'6px'}} />
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <Skeleton variant="rect" width="60%" height={'24px'} style={{borderRadius:'6px'}} />
          <div className="infoBox">
            <Skeleton variant="rect" width="100%" height={'36px'} style={{marginTop:'8px',borderRadius:'6px'}} />
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <Skeleton variant="rect" width="60%" height={'24px'} style={{borderRadius:'6px'}} />
          <div className="infoBox">
            <Skeleton variant="rect" width="100%" height={'36px'} style={{marginTop:'8px',borderRadius:'6px'}} />
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <Skeleton variant="rect" width="60%" height={'24px'} style={{borderRadius:'6px'}} />
          <div className="infoBox">
            <Skeleton variant="rect" width="100%" height={'36px'} style={{marginTop:'8px',borderRadius:'6px'}} />
          </div>
        </Grid>

        <Grid item xs={12} md={12}>
          <Skeleton variant="rect" width="60%" height={'24px'} style={{borderRadius:'6px'}}/>
          <div className="infoBox" style={{ height: "150px" }}>
            <Skeleton variant="rect" width="100%" height={'150px'} style={{marginTop:'8px',borderRadius:'6px'}}/>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default RecycleInfoModalSkeleton;
