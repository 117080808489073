import { useEffect, useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import { MoreIcon } from "./Icons";
import Styles from "./style.module.css";

const CustomMenuPype = ({
  wrapperClass = "",
  wrapperStyle = {},
  label,
  popup,
  onClose,
  isOpen,
  onClickCallback,
}) => {
  const [anchorActionMenu, setAnchorActionMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const dropDownLabel = useRef(null);

  useEffect(() => {
    if (isOpen) {
      dropDownLabel.current.click();
    }
  }, [isOpen]);

  const handleMenuLabel = (e) => {
    setAnchorActionMenu(e.currentTarget);
    setOpen(true);
    if (onClickCallback !== undefined) {
      onClickCallback();
    }
  };
  const handleClose = (e) => {
    setAnchorActionMenu(null);
    setOpen(false);
    if (onClose !== undefined) {
      onClose(false);
    }
  };

  const renderDefaultMenuLabel = () => {
    return (
      <span
        className={Styles.defaultMenuLabel}
        onClick={(e) => handleMenuLabel(e)}
      >
        <MoreIcon />
      </span>
    );
  };
  const renderMenuLabel = () => {
    const { leftIcon, rightIcon, text, className, renderCustom, labelStyle } =
      label;
    if (renderCustom !== undefined) {
      return (
        <div className={className} onClick={(e) => handleMenuLabel(e)}>
          {renderCustom}
        </div>
      );
    }
    return (
      <div
        className={`${className}`}
        style={labelStyle}
        onClick={(e) => handleMenuLabel(e)}
      >
        {leftIcon !== undefined && <span>{leftIcon}</span>}
        {text !== undefined && <span>{text}</span>}
        {rightIcon !== undefined && <span>{rightIcon}</span>}
      </div>
    );
  };

  const renderListItem = () => {
    const {
      list,
      listStyle = {},
      renderList,
      renderListClass = "",
      optionClass = "",
      labelField,
      appendUser = false,
      onClick,
    } = popup;

    let view = [];
    list.forEach((item, index) => {
      if (renderList !== undefined) {
        view.push(
          <div
            className={renderListClass}
            key={index}
            onClick={() => {
              onClick(item);
              handleClose();
            }}
          >
            {renderList(item, index)}
          </div>
        );
      } else {
        let label = labelField !== undefined ? item[labelField] : item.label;
        if(appendUser === true && item['user']['full_name'] !== undefined){
          label = label + ' - '+ item['user']['full_name'];
        }

        view.push(
          <div
            className={`${Styles.menuItem} ${optionClass}`}
            key={index}
            onClick={() => {
              onClick(item);
              handleClose();
            }}
            style={listStyle}
          >
            {item.icon !== undefined && <span>{item.icon}</span>}
            <span>
              {label}
            </span>
          </div>
        );
      }
    });
    if (view.length === 0) {
      return (
        <span
          className={Styles.noOption}
          // style={{ height: `calc(${height} - 50px)` }}
        >
          No option found !
        </span>
      );
    }
    return view;
  };
  const renderMenuList = () => {
    const {
      wrapperClass = "",
      popupStyle = {},
      isLoading,
      loadingText = "Loading...",
    } = popup;

    return (
      <div
        className={`${Styles.menuListWrp} scrollBar ${wrapperClass}`}
        style={popupStyle}
      >
        {isLoading !== undefined && isLoading ? (
          <span>{loadingText}</span>
        ) : (
          renderListItem()
        )}
      </div>
    );
  };
  const renderPopOver = () => {
    const {
      renderCustomContainer,
      id,
      height = "300px",
      width = "200px",
      placement = "right",
    } = popup;

    const popup_props = {};
    if (id !== undefined) {
      popup_props["id"] = id;
    }

    return (
      <Popover
        open={open}
        anchorEl={anchorActionMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: placement,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: placement,
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 2px 3px rgba(0,0,0,0.5)",
            width: width,
            height: height,
          },
        }}
        {...popup_props}
      >
        {renderCustomContainer !== undefined && renderCustomContainer}
        {renderCustomContainer === undefined && renderMenuList()}
      </Popover>
    );
  };

  return (
    <div
      className={`${Styles.mainWrp} ${wrapperClass}`}
      onClick={(e) => e.stopPropagation()}
      style={wrapperStyle}
    >
      {label === undefined && renderDefaultMenuLabel()}
      {label !== undefined && renderMenuLabel()}

      {popup !== undefined && renderPopOver()}
    </div>
  );
};
export default CustomMenuPype;

