import LinkFile from "../LinkFile";
import Api from "./Api";

export const fetchVideoFolders = async (payload) => {
    return LinkFile.sendRequest("post", Api.fetchVideoFolders, payload);
}

export const fetchVideos = async (payload) => {
    return LinkFile.sendRequest("post", Api.fetchVideos, payload);
}

export const deleteVideo = async (payload) => {
    return LinkFile.sendRequest("post", Api.deleteVideo, payload);
}

/* more api used from outside */
export const sendVideoEmail = async (payload) => {
    return LinkFile.sendRequest("post", Api.sendBulkMessage, payload);
}

export const getUserEmailList = async (payload) => {
    return LinkFile.sendRequest("post", Api.userEmailList, payload);
}

export const getMessageTemplate = async (payload) => {
    return LinkFile.sendRequest("post", Api.getMessageTemplate, payload);
}

export const getContactDetailsFromDeal = async (payload) => {
    return LinkFile.sendRequest("post", Api.geContactDetail, payload);
}