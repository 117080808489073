import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as DEAL_API from '../api/dealApi';
import * as ACTION from '../constants/dealActionTypes'
import * as PRODUCT_API from '../api/productsApi';
import * as PRODUCT_ACTION from '../constants/productActionTypes'
import Utils from "../helpers/Utils";
import { getProductFoldersFailure, getProductFoldersSuccess } from '../actions/ProductAction';

function* dealWatcher() {
    yield takeEvery(ACTION.FETCH_CUSTOM_FIELD, customFieldDataMiddleware);

    yield takeEvery(ACTION.FETCH_DEALS, fetchDealMiddleware);
    yield takeEvery(ACTION.FETCH_PIPELINES, fetchPipelineMiddleware);
    yield takeEvery(ACTION.FETCH_USER_PIPELINES, fetchPipelineForUserMiddleware);
    yield takeEvery(ACTION.REORDER_DEAL_STAGE, reorderDealStageMiddleware);
    yield takeEvery(ACTION.FETCH_DEAL_BY_SCROLL, fetchDealByScrollMiddleware);
    yield takeEvery(ACTION.CHANGED_DEAL_STATUS, changeDealStatusMiddleware);
    yield takeEvery(ACTION.GET_ALL_STAGE_BY_PIPELINE, getAllStageByPipelineMiddleware);
    yield takeEvery(ACTION.DEAL_MOVE_TO_STAGE, dealMoveToStageMiddleware);
    yield takeEvery(ACTION.GET_ALL_DEALS_TABLE_VIEW, getAllDealTableViewMiddleware);
    yield takeEvery(ACTION.GET_DEAL_STAGE_CHART_VIEW, getUserStageListReportMiddleware);
    yield takeEvery(ACTION.GET_DEAL_sTAGE_CHART_VIEW_BY_FILTER, getUserStageListReportByFilterMiddleware);
    yield takeEvery(ACTION.CONTACT_SEARCH, contactSearchMiddleware);
    yield takeEvery(ACTION.ADD_NEW_DEAL, addNewDealhMiddleware);
    yield takeEvery(ACTION.GET_LEAD_SOURCE, getLeadSourceMiddleware);
    yield takeEvery(ACTION.STORE_NOTE, storeNoteMiddleware);
    yield takeEvery(ACTION.UPDATE_NOTE, updateNoteMiddleware);
    yield takeEvery(ACTION.GET_CONVERSATION, getConversationMiddleware);
    yield takeEvery(ACTION.UPDATE_CONTACT_FIELD, updateContactFieldMiddleware);
    yield takeEvery(ACTION.FETCH_VIRTUAL_NUMBER, fetchVirtualNumberMiddleware);
    yield takeEvery(ACTION.SEND_CONVERSATION, sendConversationMiddleware);
    yield takeEvery(ACTION.GET_PROFILE_DATA, getProfileDataMiddleware);
    yield takeEvery(ACTION.GET_OTHER_EMAIL_BY_CONTACT, getOtherEmailByContactMiddleware);
    yield takeEvery(ACTION.SEND_BULK_EMAIL, sendBulkEmailMiddleware);
    yield takeEvery(ACTION.GET_ALL_TAGS, getAllTagsMiddleware);
    yield takeEvery(ACTION.REMOVE_CONTACT_TAG, removeContactTagMiddleware);
    yield takeEvery(ACTION.ADD_CONTACT_TAGS, addContactTagsMiddleware);
    yield takeEvery(ACTION.GET_CONTACT_DETAILS, getContactDetailsMiddleware);
    yield takeEvery(ACTION.UPDATE_CONTACT_CUSTOM_FIELD, updateContactCustomFieldMiddleware);
    yield takeEvery(ACTION.UPDATE_CONTACT_SOURCE, updateContactSourceMiddleware);
    yield takeEvery(ACTION.GET_ELIGIBLE_CAMPAIGN, getEligibleCampaignsMiddleware);
    yield takeEvery(ACTION.MOVE_CAMPAIGN_CONTACT, moveCampaignContactMiddleware);
    yield takeEvery(ACTION.GET_ALL_CAMPAIGN_BY_CONTACT, getAllCampaignByContactMiddleware);
    yield takeEvery(ACTION.CAMPAIGN_SINGLE_CONTACT_RUN, campaignSingleContactRunMiddleware);
    yield takeEvery(ACTION.CAMPAIGN_SINGLE_CONTACT_PAUSE, campaignSingleContactPauseMiddleware);
    yield takeEvery(ACTION.CAMPAIGN_SINGLE_CONTACT_UNSUBSCRIBED, campaignSingleContactUnsubscribedMiddleware);
    yield takeEvery(ACTION.GET_PEOPLE_ACTIVITY, getPeopleActivityMiddleware);
    yield takeEvery(ACTION.MARK_AS_DONE, markAsDoneMiddleware);
    yield takeEvery(ACTION.SUBMIT_USER_ACTIVITY, submitUserActivityMiddleware);
    yield takeEvery(ACTION.DELETE_CONTACT_FROM_STAGE, deleteContactFromStageMiddleware);
}

function* fetchVirtualNumberMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(DEAL_API.getVirtualNumberApi, action.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* customFieldDataMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(DEAL_API.fetchCustomFieldApi, action.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchDealMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.fetchDealApi, payload.payload);
        // yield put({type: ACTION.STORE_DEALS, payload: responseData});
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchPipelineMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.fetchPipelineApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchPipelineForUserMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.fetchPipelineV2Api, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* reorderDealStageMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.reorderDealStageApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchDealByScrollMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.fetchDealByScrollApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* changeDealStatusMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.changeDealStatusApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getAllStageByPipelineMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getAllStageByPipelineApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* dealMoveToStageMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.dealMoveToStageApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getAllDealTableViewMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getAllDealTableViewApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getUserStageListReportMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getUserStageListReportApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getUserStageListReportByFilterMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getUserStageListReportByFilterApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* contactSearchMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.contactSearchApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* addNewDealhMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.addNewDealApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getLeadSourceMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getLeadSourceApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* storeNoteMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.storeNoteApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateNoteMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.updateNoteApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getConversationMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getConversationApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateContactFieldMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.updateContactFieldApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* sendConversationMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.sendConversationApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getProfileDataMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getProfileDataApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getOtherEmailByContactMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getOtherEmailByContactApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* sendBulkEmailMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.sendBulkEmailApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getAllTagsMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getAllTagsApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* removeContactTagMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.removeContactTagApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* addContactTagsMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.addContactTagsApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getContactDetailsMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getContactDetailsApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateContactCustomFieldMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.updateContactCustomFieldApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateContactSourceMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.updateContactSourceApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getEligibleCampaignsMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getEligibleCampaignsApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* moveCampaignContactMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.moveCampaignContactApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getAllCampaignByContactMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getAllCampaignByContactApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* campaignSingleContactRunMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.campaignSingleContactRunApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* campaignSingleContactPauseMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.campaignSingleContactPauseApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* campaignSingleContactUnsubscribedMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.campaignSingleContactUnsubscribedApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getPeopleActivityMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.getPeopleActivityApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* markAsDoneMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.markAsDoneApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* submitUserActivityMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.submitUserActivityApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deleteContactFromStageMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(DEAL_API.deleteContactFromStageApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* dealMiddleware() {
    yield all([
        dealWatcher(),
    ])
}
