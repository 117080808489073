import "./globalSearchBox.css";

const GlobalSearchBox = (props) => {
  return (
    <div
      className={`global-search-box-wrapper ${
        props.wrapperClass === undefined ? "" : props.wrapperClass
      }`}
    >
      <input {...props} />
      <i className="material-icons">search</i>
    </div>
  );
};

export default GlobalSearchBox;
