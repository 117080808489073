import React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';

const CustomDateRangePicker = props => {
    const onApply = (event, picker) => {
        props.onApply(picker)
    }

    const onCancel = (event, picker) => {
        props.onCancel()
    }

    return (
        <DateRangePicker
            initialSettings={ (typeof props.dateRange != 'undefined' &&  (props.dateRange.startDate != null && props.dateRange.endDate != null)) ? props.dateRange : null }
            onApply={onApply}
            onCancel={onCancel}
        >
            <Button
                startIcon={ <DateRangeIcon /> }
                endIcon={ <ExpandMoreIcon /> }
            >{typeof props.value != 'undefined' ? props.value : ''}</Button>
        </DateRangePicker>
    )
}
export default CustomDateRangePicker