import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  addProductFailure,
  addProductFolderFailure,
  addProductFolderSuccess,
  addProductSuccess,
  deleteProductFailure,
  deleteProductFolderFailure,
  deleteProductFolderSuccess,
  deleteProductForeverFailure,
  deleteProductForeverSuccess,
  deleteProductSuccess,
  getProductFoldersFailure,
  getProductFoldersSuccess,
  getProductsFailure,
  getProductsSuccess,
  toggleFavoriteFailure,
  toggleFavoriteSuccess,
  updateProductFailure,
  updateProductFolderFailure,
  updateProductFolderSuccess,
  updateProductSuccess,
} from "../actions/ProductAction";
import * as PRODUCT_API from "../api/productsApi";
import * as PRODUCT_ACTION from "../constants/productActionTypes";
import Utils from "../helpers/Utils";

function* productWatcher() {
  yield takeEvery(
    PRODUCT_ACTION.GET_PRODUCT_FOLDERS,
    getProductFoldersMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.ADD_PRODUCT_FOLDER,
    addProductFolderMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.UPDATE_PRODUCT_FOLDER,
    updateProductFolderMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.DELETE_PRODUCT_FOLDER,
    deleteProductFolderMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.GET_PRODUCTS,
    getProductsMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.ADD_PRODUCT,
    addProductMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.UPDATE_PRODUCT,
    updateProductMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.DELETE_PRODUCT,
    deleteProductMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.DELETE_PRODUCT_FOREVER,
    deleteProductForeverMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.TOGGLE_FAVORITE,
    toggleFavoriteMiddleware
  );
}

function* getProductFoldersMiddleware(action) {
  try {
    const response = yield call(PRODUCT_API.getProductFoldersApi);
    if (response.data.success) {
      yield put(getProductFoldersSuccess(response.data.data));
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(getProductFoldersFailure(error.message));
    Utils.handleException(error);
  }
}

function* addProductFolderMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.addProductFolderApi,
      action.payload
    );

    if (response.data.success) {
      yield put(addProductFolderSuccess(response.data.data));
      window.showNotification("SUCCESS", response.data.message);
    }

    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(addProductFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* updateProductFolderMiddleware(action) {
  try {
    console.log(action.payload);
    const response = yield call(
      PRODUCT_API.updateProductFolderApi,
      action.payload
    );
    if (response.data.success) {
      yield put(updateProductFolderSuccess(action.payload));
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(updateProductFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* deleteProductFolderMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.deleteProductFolderApi,
      action.payload
    );
    if (response.data.success) {
      yield put(deleteProductFolderSuccess(action.payload));
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(deleteProductFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}


function* getProductsMiddleware(action) {
  const { payload } = action;
  const { callback } = payload || {};

  try {
    const response = yield call(PRODUCT_API.getProductsApi, payload);
    const responseData = response?.data;

    if (responseData?.success) {
      const { data, totalPage, totalCount } = responseData;
      const productData = {
        productList: data,
        ...(totalPage && { totalPage, totalCount }),
      };
      yield put(getProductsSuccess(productData));
    } else {
      throw new Error('API call was not successful');
    }
  } catch (error) {
    yield put(getProductsFailure(error.message));
    Utils.handleException(error);
    console.error('Error fetching products:', error);
  }
}

function* addProductMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.addProductApi,
      action.payload
    );

    console.log(response.data)
    if (response.data.success) {
      yield put(addProductSuccess(response.data.data));
      window.showNotification("SUCCESS", response.data.message);
    }

    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(addProductFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* updateProductMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.updateProductApi,
      action.payload
    );

    if (response.data.success) {
      const data = {
        id: action.payload.id,
        payload: {...action.payload, folders: response.data.data.folders}
      }

      yield put(updateProductSuccess(data));
      window.showNotification("SUCCESS", response.data.message);
    }

    if (action.payload && action.payload.payload && action.payload.payload.callback) {
      action.payload.payload.callback(response.data);
    }
  } catch (error) {
    yield put(updateProductFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* deleteProductMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.deleteProductApi,
      action.payload
    );

    if (response.data.success) {
      yield put(deleteProductSuccess());
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(deleteProductFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* deleteProductForeverMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.deleteProductForeverApi,
      action.payload
    );
    if (response.data.success) {
      yield put(deleteProductForeverSuccess());
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(deleteProductForeverFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* toggleFavoriteMiddleware(action){
  console.log(action.payload);
    try {
      const response = yield call(
        PRODUCT_API.toggleFavoriteApi,
        action.payload
      );
      if (response.data.success) {
        yield put(toggleFavoriteSuccess(action.payload));
        window.showNotification("SUCCESS", response.data.message);
      }
      if (action.payload && action.payload.callback) {
        action.payload.callback(response.data);
      }
    } catch (error) {
      yield put(toggleFavoriteFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  }

export default function* productMiddleware() {
  yield all([productWatcher()]);
}
