import { all } from "redux-saga/effects";
import dealMiddleware from "./dealMiddleware";
import pipelineMiddleware from "./pipelineMiddleware";
import videoEmailMiddleware from "../components/SendComponents/Video/redux/videoEmailMiddleware";
import cardBroadcastMiddleware from "../components/SendComponents/DirectCardSend/redux/cardBroadcastMiddleware";
import productMiddleware from "./productMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            dealMiddleware(),
            pipelineMiddleware(),
            videoEmailMiddleware(),
            cardBroadcastMiddleware(),
            productMiddleware()
        ]
    )
}
