import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  styled,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArchiveIcon from "@material-ui/icons/Archive";
import DeleteIcon from "@material-ui/icons/Delete";
import FolderIcon from "@material-ui/icons/Folder";
import StarIcon from "@material-ui/icons/Star";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductFolders,
  getProducts,
  onSelectProductFolder,
} from "../../../../actions/ProductAction";
import { SidebarLeftIcon, SidebarRightIcon } from "../../common/Icons";
import { productFolderState } from "../../common/productSelector";
import EachFolderItem from "./EachFolderItem";
import CreateNewFolderModal from "./CreateNewFolderModal";
import { CustomScrollbar } from "../../productsStyle";

const SidebarWrapper = styled("div")(({ theme, isSidebarCollapsed }) => ({
  width: isSidebarCollapsed ? 56 : 268,
  height: "calc(100vh - 180px)",
  transition: " 350ms ease",
  background: "white",
  overflow: "hidden",
  padding: "16px 10px",
  position: "relative",
  borderRadius: theme.spacing(1),

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },

  "& .sidebarHeader": {
    display: "flex",
    justifyContent: isSidebarCollapsed ? "center" : "space-between",
    alignItems: "center",
    padding: isSidebarCollapsed ? "0 !important" : "0 8px !important",
  },

  "& .sidebarTitle": {
    display: isSidebarCollapsed ? "none" : "block",
    fontWeight: 600,
    minWidth: 170,
    color: theme.palette.text.secondary,
  },

  "& .sidebarList": {
    "& .Mui-selected": {
      background: "rgba(52, 111, 239, 0.08)",
      borderRadius: 8,
      "& .MuiTypography-root": {
        fontWeight: 600,
      },
    },
    "& .MuiListItem-button:hover": {
      borderRadius: theme.spacing(1),
    },
    "& .MuiListItem-gutters": {
      padding: isSidebarCollapsed ? "8px 0" : "4px 8px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: isSidebarCollapsed ? 35 : 30,
      justifyContent: isSidebarCollapsed ? "center" : "flex-start",
    },
    "& .MuiListItemText-root": {
      minWidth: 170,
      display: isSidebarCollapsed ? "none" : "block",
    },
    "& .MuiListItemSecondaryAction-root": {
      display: isSidebarCollapsed ? "none" : "block",
      right: 8,
    },

    "& .folderList": {
      display: "flex",
      flexDirection: "column",
      gap: 4,

      "& svg": {
        fontSize: 20,
      },
    },
  },

  "& .sidebarDivider": {
    margin: "12px",
  },
}));

const SideBar = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [openNewFolderModal, setOpenNewFolderModal] = useState(false);

  useEffect(() => {
    fetchProductFolders();
  }, []);

  const dispatch = useDispatch();

  const { productFolders, selectedFolder, isLoading } =
    useSelector(productFolderState);

  const fetchProductFolders = () => {
    dispatch(getProductFolders());
  };

  const handleSelectedFolder = (id) => {
    dispatch(onSelectProductFolder(id));
  };

  const handleCollapsed = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  const handleShowAllProducts = () => {
    handleSelectedFolder("all");
    dispatch(getProducts({ page: 1, perPage: 10 }));
  };

  const handleFavorite = () => {
    handleSelectedFolder("favorite");
    dispatch(getProducts({ page: 1, perPage: 10, favorite: "YES" }));
  };

  const handleArchive = () => {
    handleSelectedFolder("archive");
    dispatch(getProducts({ page: 1, perPage: 10, status: "ARCHIVE" }));
  };

  const handleTrash = () => {
    handleSelectedFolder("trash");
    dispatch(getProducts({ page: 1, perPage: 10, status: "TRASH" }));
  };

  const showContent = isSidebarCollapsed ? "none" : "block";

  const renderFolderList = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
        </>
      );
    } else if (productFolders?.length > 0 && !isLoading) {
      return productFolders.map((folder) => (
        <EachFolderItem
          key={folder.id}
          folder={folder}
          selectedFolder={selectedFolder === folder.id}
        />
      ));
    }
  };

  return (
    <SidebarWrapper isSidebarCollapsed={isSidebarCollapsed}>
      <div className="sidebarHeader">
        <Typography className="sidebarTitle" variant="body2">
          My Products & Services
        </Typography>

        <IconButton color="primary" onClick={handleCollapsed} size="small">
          {isSidebarCollapsed ? <SidebarRightIcon /> : <SidebarLeftIcon />}
        </IconButton>
      </div>

      <List
        className="sidebarList"
        component="div"
        aria-label="main mailbox folders"
        dense
      >
        <ListItem
          button
          selected={selectedFolder === "all"}
          onClick={handleShowAllProducts}
          style={{ marginBottom: 4 }}
        >
          <ListItemIcon>
            <FolderIcon
              color="primary"
              fontSize="small"
              style={{ color: "#346fef" }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              color={"text.primary"}
              variant="body2"
              fontWeight={600}
              minWidth={170}
            >
              All Product & Service
            </Typography>
          </ListItemText>
        </ListItem>

        <CustomScrollbar style={{ maxHeight: 'calc(100vh - 445px)' }}>
          <div className="folderList">
            {renderFolderList()}
          </div>
        </CustomScrollbar>

        <ListItem button onClick={() => setOpenNewFolderModal(true)}>
          <ListItemIcon>
            <Tooltip title={showContent === "none" ? " Create Category" : ""}>
              <AddIcon style={{ color: "#346fef" }} fontSize="small" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant="body2"
              fontWeight={600}
              minWidth={170}
              style={{color: "#346fef" }}
            >
              Create Category
            </Typography>
          </ListItemText>
        </ListItem>

        <Divider className="sidebarDivider" light sx={{ my: "16px" }} />

        <ListItem
          onClick={handleFavorite}
          selected={selectedFolder === "favorite"}
          button
        >
          <ListItemIcon>
            <Tooltip title={showContent === "none" ? "Favorite" : ""}>
              <StarIcon fontSize="small" style={{ color: "#f79009" }} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>
            <Typography color={"text.primary"} variant="body2" fontWeight={500}>
              Favorite
            </Typography>
          </ListItemText>
        </ListItem>

        {/* <ListItem
          onClick={handleArchive}
          selected={selectedFolder === "archive"}
          button
        >
          <ListItemIcon>
            <Tooltip title={showContent === "none" ? "Archive" : ""}>
              <ArchiveIcon fontSize="small" style={{ color: "#346fef" }} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>
            <Typography color={"text.primary"} variant="body2" fontWeight={500}>
              Archive
            </Typography>
          </ListItemText>
        </ListItem> */}

        <ListItem
          button
          onClick={handleTrash}
          selected={selectedFolder === "trash"}
        >
          <ListItemIcon>
            <Tooltip title={showContent === "none" ? "Trash" : ""}>
              <DeleteIcon fontSize="small" style={{ color: "#ec131f" }} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>
            <Typography color={"text.primary"} variant="body2" fontWeight={500}>
              Trash
            </Typography>
          </ListItemText>
        </ListItem>
      </List>

      {openNewFolderModal && (
        <CreateNewFolderModal
          open={openNewFolderModal}
          onClose={() => setOpenNewFolderModal(false)}
        />
      )}
    </SidebarWrapper>
  );
};

export default SideBar;
