import React, {useEffect, useState} from 'react';
import './ProgressCircle.css';
import Utils from "../../../helpers/Utils";

const ProgressCircle = ({ data }) => {
    const [degree, setDegree] = useState(0);

    useEffect(() => {
        if (data) {
            const { goalForToday, achivedToday } = data;

            if (goalForToday > 0) {
                let progress = (achivedToday / goalForToday) * 100;
                let tempDegree = parseFloat((progress * 3.7).toFixed(2));

                setDegree(tempDegree);
            } else {
                setDegree(0);
            }
        }
    }, [data]);

    return (
        <div className="circular-container">
            <div
                className="progress-circle"
                style={{
                    background: `conic-gradient(#346fef 0deg, #346fef ${degree}deg, rgba(0, 0, 0, 0.1) ${degree}deg)`
                }}>
                <div className="progress-text">
                    <span className={`label ${ data.stillNeededForToday >= 0 ? "" : "complete" }`}>{data.stillNeededForToday >= 0 ? "Still Needed" : "Completed"}</span>
                    <span className="amount">{data.stillNeededForToday >= 0 ? Utils.formatCurrency(data.stillNeededForToday, "$") : Utils.formatCurrency(data.achivedToday, "$")}</span>
                    {
                        data.stillNeededForToday < 0 &&
                        <span className="label exceeded">{Utils.formatCurrency(Math.abs(data.stillNeededForToday), "$")} Exceeded</span>
                    }
                </div>
            </div>
        </div>
    );
};

export default ProgressCircle;