import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  resetAddProductSuccess,
  resetDeleteProductSuccess,
} from "../../../../../actions/ProductAction";
import {
  productFolderState,
  productState,
} from "../../../common/productSelector";
import { CustomScrollbar } from "../../../productsStyle";
import AddOrEditNewProductModal from "../AddOrEditNewProductModal";
import { CustomButton } from "./CustomButton";
import EachProductTableItem from "./EachProductTableItem";
import TableSkeleton from "./TableSkeleton";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#f3f3f3",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  tableBodyHeight: {
    height: `calc(100vh - 445px) !important`,
    overflowY: "auto",
    marginTop: "16px",
  },
  topbarNewProductButton: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#346fef",
    color: "white",
    "&:focus,&:hover": {
      background: "#346fef",
    },
  },
});

const ProductTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const {
    productList,
    isLoading,
    isAddProductSuccessful,
    isDeleteProductSuccessful,
  } = useSelector(productState);

  const { selectedFolder } = useSelector(productFolderState);

  let campaignStatus = null;

  useEffect(() => {
    if (isAddProductSuccessful) {
      dispatch(resetAddProductSuccess());
    }
    if (isDeleteProductSuccessful) {
      dispatch(resetDeleteProductSuccess());
    }
  }, [isAddProductSuccessful, isDeleteProductSuccessful]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getProducts({ page: 1, perPage: 10, ...campaignStatus }));
  }, [isAddProductSuccessful, isDeleteProductSuccessful]); // eslint-disable-line react-hooks/exhaustive-deps

  switch (selectedFolder) {
    case "all":
      campaignStatus = null;
      break;
    case "favorite":
      campaignStatus = { isFavorite: "YES" };
      break;
    case "archive":
      campaignStatus = { status: "ARCHIVE" };
      break;
    case "trash":
      campaignStatus = { status: "TRASH" };
      break;
    default:
      campaignStatus = { userProductFolderId: selectedFolder };
  }
  const handleCloseModal = () => {
    setOpenAddProductModal(false);
  };
  if (productList?.length === 0 && !isLoading) {
    return (
      <div className={`${classes.tableBodyHeight}`} style={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "60%",
          }}
        >
          <div>
            <Typography align="center" variant="h5">
              You haven't added any products or services yet!
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ color: "gray" }}
            >
              Click the button below to get started.
            </Typography>
            <Box display={"flex"} justifyContent={"center"} mt={2}>
              <CustomButton
                variant="contained"
                onClick={() => setOpenAddProductModal(true)}
                startIcon={<AddIcon />}
                disableElevation
              >
                New Product/Service
              </CustomButton>
            </Box>

            {openAddProductModal && (
              <AddOrEditNewProductModal
                open={openAddProductModal}
                onClose={handleCloseModal}
              />
            )}
          </div>
        </Box>
        <Box sx={{ width: "40%", display: "flex", alignItems: "center" }}>
          <img
            height={300}
            src={
              "https://d24gujj67p1uc1.cloudfront.net/assets/bulk-emails/images/releases/1.0.0/Empty%20Campaign.png"
            }
            alt="Empty data"
          />
        </Box>
      </div>
    );
  } else if (isLoading) {
    return <TableSkeleton />;
  } else {
    return (
      <CustomScrollbar className={`${classes.tableBodyHeight}`}>
        <TableContainer component={"span"}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableHeader} width={"25%"}>
                  Product Name
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  Product Description
                </TableCell>
                <TableCell align={"center"} className={classes.tableHeader}>
                  Unit Cost
                </TableCell>
                <TableCell align={"center"} className={classes.tableHeader}>
                  Unit Price
                </TableCell>
                <TableCell align={"center"} className={classes.tableHeader}>
                  Billing Frequency
                </TableCell>
                <TableCell align={"center"} className={classes.tableHeader}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {productList.map((product) => (
                <EachProductTableItem key={product.id} product={product} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomScrollbar>
    );
  }
};

export default ProductTable;
