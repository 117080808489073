import * as ACTION from "../constants/dealActionTypes";

export const storePipelineId = (payload) => ({
  type: ACTION.STORE_PIPELINE_ID,
  payload: payload,
});

export const storeDealStatus = (payload) => ({
  type: ACTION.STORE_DEAL_STATUS,
  payload: payload,
});

export const storeDateRange = (payload) => ({
  type: ACTION.STORE_DEATE_RANGER,
  payload: payload,
});

export const storeSearchText = (payload) => ({
  type: ACTION.STORE_SEACRCH_TEXT,
  payload: payload,
});

export const storeDealContact = (payload) => ({
  type: ACTION.STORE_DEAL_CONTACT,
  payload: payload,
});

export const reloadDealStage = (payload) => ({
  type: ACTION.RELOAD_DEAL_STAGE_FLAG,
  payload: payload,
});

export const storeLoggedOnUser = (payload) => ({
  type: ACTION.STORE_LOGGED_ON_USER,
  payload: payload,
});

export const storePipelineList = (payload) => ({
  type: ACTION.STORE_PIPELINE_LIST,
  payload: payload,
});

export const storeTeamUserList = (payload) => ({
  type: ACTION.STORE_USER_LIST,
  payload: payload,
});
export const storeSelectAUser = (payload) => ({
  type: ACTION.SELECT_A_USER,
  payload: payload,
});
