import * as ACTION from "../constants/dealActionTypes";
import moment from "moment";
import { getContactDetailsApi } from "../api/dealApi";

var date = new Date();

const initialState = {
  pipelineIdInDealList: "",
  searchTextInDealList: "",
  // calerdarSortingInDealList: { startDate: moment(new Date()).format('MM/DD/YYYY'), endDate: moment(date.setDate(date.getDate() + 29)).format('MM/DD/YYYY') },
  calerdarSortingInDealList: { startDate: null, endDate: null },
  statusInDealList: "1",
  dealContact: null,
  dealIndex: null,
  stageIndexForDeal: null,
  reloadDealStage: false,
  loggedOnUser: null,
  fullContactDetails: null,

  pipelineList: [],
};

const dealReducer = (state = initialState, action) => {
  let { payload } = action;
  switch (action.type) {
    case ACTION.STORE_PIPELINE_ID:
      return { ...state, pipelineIdInDealList: action.payload };

    case ACTION.STORE_DEAL_STATUS:
      return { ...state, statusInDealList: action.payload };

    case ACTION.STORE_DEATE_RANGER:
      return { ...state, calerdarSortingInDealList: action.payload };

    case ACTION.STORE_SEACRCH_TEXT:
      return { ...state, searchTextInDealList: action.payload };

    case ACTION.STORE_DEAL_CONTACT:
      if (payload.deal == null)
        return {
          ...state,
          dealContact: payload.deal,
          dealIndex: payload.dealIndex,
          stageIndexForDeal: payload.stageIndex,
          fullContactDetails: null,
        };
      else
        return {
          ...state,
          dealContact: payload.deal,
          dealIndex: payload.dealIndex,
          stageIndexForDeal: payload.stageIndex,
        };

    case ACTION.RELOAD_DEAL_STAGE_FLAG:
      return { ...state, reloadDealStage: action.payload };

    case ACTION.STORE_LOGGED_ON_USER:
      return {
        ...state,
        loggedOnUser: payload.loggedOnUser,
        fullContactDetails: payload.fullContactDetails,
      };

    case ACTION.STORE_PIPELINE_LIST:
      return { ...state, pipelineList: payload };

    default:
      return state;
  }
};
export default dealReducer;
