import {createContext, useContext, useEffect, useState} from "react";
import {getSalesGoalListApi} from "../../../../api/productsApi";
import {fetchPipelineApi, getTeamUserListApi} from "../../../../api/dealApi";

const SalesGoalsContext = createContext(undefined);

const SalesGoalsProvider = ({ children }) => {
    const [salesGoals, setSalesGoals] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [allUser, setAllUser] = useState([]);
    const [allPipeline, setAllPipeline] = useState([]);
    const [loadingTeam, setLoadingTeam] = useState(false);
    const [loadingPipeline, setLoadingPipeline] = useState(false);

    useEffect(()=>{
        fetchData().then();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page,rowsPerPage]);

    const fetchData = async () => {
        const payload = {
            page: page + 1,
            limit: rowsPerPage,
        };
        try {
            const response = await getSalesGoalListApi(payload);
            if (response && response.success) {
                setSalesGoals(response.data);
                setTotal(response.total_count);
            }
        } catch (error) {
            console.error("Failed to fetch sales goals:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SalesGoalsContext.Provider
            value={{
                salesGoals,
                isLoading,
                total,
                setRowsPerPage,
                setPage,
                fetchData,
                page,
                rowsPerPage,
                allUser,
                allPipeline,
                loadingTeam,
                loadingPipeline,
        }}>
            {children}
        </SalesGoalsContext.Provider>
    );
};

const useSalesGoalContext = () => {
    const context = useContext(SalesGoalsContext);
    if (!context) { 
        throw new Error("useSalesGoalContext must be used within a SalesGoalsProvider");
    }
    return context;
}

const withSalesGoalContext = (Component) => {
    return (props) => (
        <SalesGoalsProvider>
            <Component {...props} />
        </SalesGoalsProvider>
    );
};

export { withSalesGoalContext, useSalesGoalContext };
