import {
  Box,
  Button,
  Dialog,
  IconButton,
  InputBase,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { renderFolderIcon } from "../../helper/functions.js";
import {
  addProductFolder,
  updateProductFolder,
} from "../../../../actions/ProductAction.js";
import { productStyle } from "../content/ProductStyle.js";
export const BasicInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "&.MuiInputBase-multiline": {
      padding: "0 !important",
    },
  },
  input: {
    position: "relative",
    color: "var(--dark_blue)",
    backgroundColor: "white !important",
    fontSize: "14px !important",
    margin: "0 !important",
    height: "2.5rem !important",
    padding: "10px 12px 10px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      boxShadow: "unset !important",
    },

    "&.MuiSelect-select": {
      height: "1.5rem !important",
    },
  },
}))(InputBase);

const useStyles = makeStyles({
  titleHeader: {
    fontSize: "20px",
  },
  iconsButton: {
    borderRadius: "12px",
    padding: "8px",
    "&:hover,&:focus": {
      backgroundColor: "white",
    },
  },
  selectedFolderIcon: {
    backgroundColor: "white",
  },
  checkIconColor: {
    color: "white",
    height: "30px",
    width: "30px",
  },
  outerIconColor: {
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",
  },
  innerIconColor: {
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "2px solid white",
  },
  errorStyle: {
    border: "1px solid red !important",
    borderRadius: "4px",
  },
  errorText: {
    color: "red",
  },
});

const folderIconList = [
  "FolderFilledIcon",
  "WebsiteIcon",
  "MonitorIcon",
  "MailIcon",
  "FilterIcon",
  "DollarIcon",
  "StarIcon",
  "LockIcon",
  "RadioIcon",
  "CopyIcon",
  "BrushOutlinedIcon",
];

const CreateNewFolderModal = ({ open, onClose, edit }) => {
  const {
    titleHeader,
    iconsButton,
    checkIconColor,
    outerIconColor,
    innerIconColor,
    errorText,
    selectedFolderIcon,
    errorStyle,
  } = useStyles();
  const { topbarNewProductButton, newFolderModalCancelButton } = productStyle();
  const [selectedColor, setSelectedColor] = useState(
    edit?.folder_color || "#346fef"
  );
  const [selectedIcon, setSelectedIcon] = useState(
    edit?.folder_icon || folderIconList[0]
  );
  const [folderName, setFolderName] = useState(edit?.title || null);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (folderName === null || folderName === "") {
      setError(true);
      return;
    }

    if (edit) {
      setError(false);
      dispatch(
        updateProductFolder({
          id: edit.id,
          payload: {
            title: folderName,
            folder_icon: selectedIcon,
            folder_color: selectedColor,
          },
        })
      );
      onClose();
    } else {
      setError(false);
      dispatch(
        addProductFolder({
          title: folderName,
          folder_icon: selectedIcon,
          folder_color: selectedColor,
        })
      );

      onClose();
    }
  };
  const handleFolderName = (e) => {
    setError(false);
    setFolderName(e.target.value);
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="newfolder-dialog-title"
      aria-describedby="newfolder-dialog-description"
    >
      <Box minWidth={600} minHeight={300}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={2}
          borderBottom={"1px solid #d3d3d3"}
        >
          <Typography className={titleHeader}>{edit ? 'Update Category' : 'New Category'}</Typography>
          <Box
            onClick={onClose}
            border={"1px solid #d3d3d3"}
            display={"flex"}
            padding={"3px"}
            borderRadius={"25%"}
            sx={{ cursor: "pointer" }}
          >
            <Close />
          </Box>
        </Box>
        <Box padding={2}>
          <Box>
            <Typography className={`${error === true ? errorText : ""}`}>
              Category Name
            </Typography>
            <Box mt={1}>
              <BasicInput
                fullWidth
                className={`${error ? errorStyle : ""}`}
                value={folderName}
                onChange={handleFolderName}
              />
              {error && (
                <Typography variant="body2" className={errorText}>
                  Folder Name is required
                </Typography>
              )}
            </Box>
          </Box>
          <Box paddingTop={2}>
            <Typography>Category Icon</Typography>
            <Box
              display={"flex"}
              mt={1}
              justifyContent={"space-around"}
              sx={{ background: "rgba(148, 157, 178, 0.12)" }}
              borderRadius={"8px"}
              padding={"8px"}
            >
              {folderIconList.map((icon, i) => (
                <IconButton
                  key={i}
                  className={`${iconsButton} ${
                    selectedIcon === icon ? selectedFolderIcon : ""
                  }`}
                  onClick={() => setSelectedIcon(icon)}
                >
                  {renderFolderIcon(icon, "gray")}
                </IconButton>
              ))}
            </Box>
          </Box>
          <Box pt={2}>
            <Typography>Icon Color</Typography>
            <Box mt={1} display={"flex"} gridGap={8}>
              <Box
                className={outerIconColor}
                sx={{ background: " #346fef" }}
                onClick={() => setSelectedColor("#346fef")}
              >
                {selectedColor === "#346fef" && (
                  <Box
                    sx={{ background: " #346fef" }}
                    className={innerIconColor}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: " #924cff" }}
                onClick={() => setSelectedColor("#924cff")}
              >
                {selectedColor === "#924cff" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: " #924cff" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: " #008951" }}
                onClick={() => setSelectedColor("#008951")}
              >
                {selectedColor === "#008951" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: " #008951" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: " #f79009" }}
                onClick={() => setSelectedColor("#f79009")}
              >
                {selectedColor === "#f79009" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: " #f79009" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: "#00bec5" }}
                onClick={() => setSelectedColor("#00bec5")}
              >
                {selectedColor === "#00bec5" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: "#00bec5" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: "#1d2939" }}
                onClick={() => setSelectedColor("#1d2939")}
              >
                {selectedColor === "#1d2939" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: "#1d2939" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: "#475467" }}
                onClick={() => setSelectedColor("#475467")}
              >
                {selectedColor === "#475467" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: "#475467" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          padding={2}
          borderTop={"1px solid #d3d3d3"}
          display={"flex"}
          justifyContent={"end"}
          fullWidth
        >
          <Box display={"flex"} gridGap={8}>
            <Button
              onClick={onClose}
              className={newFolderModalCancelButton}
              variant="contained"
              disableElevation
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className={topbarNewProductButton}
              variant="contained"
              disableElevation
            >
              Save Folder
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateNewFolderModal;
