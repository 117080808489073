import {
  Chip,
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useRef, useState } from "react";
import DateRangePicker from "../dateRangePicker";
import { CustomScrollbar } from "../productsStyle";
import ProductSalesDrawer from "./ProductSalesDrawer";
import ProductBarChart from "./ProductBarChart";
import { useSelector } from "react-redux";
import GlobalUserListDropdown from "../common/UserListDropdown/Index";
import Utils from "../../../helpers/Utils";
import ProductSalesReportSkeleton from "./ProductSalesReportSkeleton";
import EmptyProductSales from "./EmptyProductSales";
import { onSelectUser, userTimeSelectConverter } from "../helper/functions";
import { USER_SELECTOR } from "../helper/coreConstant";
import { productSalesReport } from "../../../api/productsApi";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 290,
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "#2c3e50",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#346fef",
      borderRadius: "10px",
    },
  },
  tableHead: {
    fontWeight: 600,
    fontSize: "16px",
    color: "white",
    borderRadius: "0px !important",
    backgroundColor: "#346fef",
  },
});

const Wrapper = styled("div")(({ theme }) => ({
  padding: 16,
  background: "white",
  borderRadius: "8px",
  minHeight: "calc(100vh - 180px)",

  "& .teamSalesHeader": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    "& .filter": {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
  },

  "& .teamChart": {
    width: "100%",
    display: "flex",
    gap: 40,
    
    "& .teamList": {
      flex: 1,
      maxHeight: 420,
      overflowY: "auto",
      marginBottom: 16,

      "& .teamItem": {
        borderRadius: 8,
        padding: "12px 24px",
        background: "#e9e9e9",
        cursor: "pointer",
        color:"#133159",

        "&:not(:last-child)": {
          marginBottom: 8,
        },

        "&.active": {
          background: "#346fef",
          color: "white",
        },
      },
    },
    "& .teamChartList": {
      flex: 2,
    },
  },

  "& .tableWrapper": {
    "& .MuiTableHead-root": {
      color: "white",
      backgroundColor: "#e9e9e9",
      fontWeight: 600,

      "& .MuiTableCell-head": {
        fontWeight: 600,
      },
    },
  },
}));

const ProductSalesReport = ({ assetsLoaded }) => {
  const { teamUsers } = useSelector((state) => state.productReducer);
  const classes = useStyles();
  const [activeProduct, setActiveProduct] = useState(null);
  const [resetClosedRange, setResetClosedRange] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([USER_SELECTOR.ALL]);
  const [selectedUserIds, setSelectedUserIds] = useState([USER_SELECTOR.ALL]);
  const [drawer, setDrawer] = useState(null);
  const activeItemRef = useRef(null);
  const [reportData, setReportData] = useState(null);
  const [formData, setFormData] = useState({
    dealClosedByIds: [],
    dealCloseStartDate: null,
    dealCloseEndDate: null
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [activeProduct]);

  useEffect(() => {
    if (assetsLoaded) {
      handleProductSalesReport().then();

    }
  }, [formData, assetsLoaded]);

  const handleProductSalesReport = async () => {
    try{
      let submitData = {
        dealCloseStartDate: formData.dealCloseStartDate ? userTimeSelectConverter(formData.dealCloseStartDate) : null,
        dealCloseEndDate: formData.dealCloseEndDate ? userTimeSelectConverter(formData.dealCloseEndDate) : null,
        dealClosedByIds: formData.dealClosedByIds,
      }

      if (selectedUserIds[0] === USER_SELECTOR.ALL){
        submitData.dealClosedByIds = teamUsers.map((team)=>(team.id));
      }

      // if (!submitData.dealClosedByIds[0]){
      //   return;
      // }

      setIsLoading(true);
      const resp = await productSalesReport(submitData);

      if (resp && resp.success && resp.data[0]){
         setReportData(resp.data);
         setActiveProduct(resp.data[0].userProductId)
      } else {
        setReportData([]);
      }
    }catch (err){
      console.log(err);
      setReportData([]);
    }finally {
      setIsLoading(false);
    }
  };

  const formattedUsers = teamUsers.map((user) => ({ value: user.id, label: user.full_name }))
  
  const handleActiveProduct = (id) => {
    if (activeProduct === id) {
      setActiveProduct(null);
      return;
    }
    setActiveProduct(id);
  };

  const handleSelectUser = (item, remove) => {
    onSelectUser(item, remove, selectedUsers, selectedUserIds, (tempUsers, tempUserIds) => {
      setSelectedUsers(tempUsers);
      setSelectedUserIds(tempUserIds);

      if (tempUserIds[0] === USER_SELECTOR.ALL) {
        tempUserIds = teamUsers.map((user) => (user.id));
      }

      setFormData((prevState) => ({ ...prevState, dealClosedByIds: tempUserIds, page: 1 }));
    });
  };

  return (
    <Wrapper>
      <div className="teamSalesHeader">
        <Typography variant="body1" style={{ color: "#346fef" }}>
          Products
        </Typography>

        <div className="filter">
          <GlobalUserListDropdown
            userList={formattedUsers}
            handleSelect={(value, remove) => {
              handleSelectUser(value, remove);
            }}
            selected={selectedUsers}
            selectedId={selectedUserIds}
          />

          <DateRangePicker
            style={{ right: 0 }}
            reset={resetClosedRange}
            updateReset={() => setResetClosedRange(false)}
            callback={(data) => {
              setFormData((prevData) => ({
                ...prevData,
                dealCloseStartDate: data.startDate,
                dealCloseEndDate: data.endDate
              }));
            }}
          />
        </div>
      </div>

      {
        isLoading ?
          <ProductSalesReportSkeleton/> :
          (!reportData || !reportData[0]) ? <EmptyProductSales/> :
            <Fragment>
              <div className="teamChart">
                <div className="teamList" style={{ color: "#346fef" }}>
                  <CustomScrollbar style={{maxHeight: 375}}>
                    {reportData.map((item, i) => (
                      <div
                            className={`teamItem ${
                                activeProduct === item.userProductId ? "active" : ""
                          }`}
                        key={i}
                        onClick={() => handleActiveProduct(item.userProductId)}
                        ref={activeProduct === item.userProductId ? activeItemRef : null}
                      >
                        {item.userProductTitle}
                      </div>
                    ))}
                  </CustomScrollbar>
                </div>

                <div className="teamChartList">
                  <ProductBarChart
                    data={reportData}
                    activeProduct={activeProduct}
                    setActiveProduct={setActiveProduct}
                  />
                </div>
              </div>

              <div className={classes.root}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{width:'550px'}} className={classes.tableHead}>Product Name</TableCell>
                        <TableCell style={{textAlign:'center'}} className={classes.tableHead}>Value of Deals Closed</TableCell>
                        <TableCell  style={{textAlign:'center'}} className={classes.tableHead}>Total Products/Services Sold</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {reportData.map((product, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography
                              variant="body2"
                              style={{cursor: "pointer"}}
                              onClick={() => setDrawer(product)}
                            >
                              {product.userProductTitle}
                            </Typography>
                          </TableCell>
                          <TableCell style={{textAlign:'center'}}>
                          {product.totalSoldProductValue ? (
                            <Chip
                                style={{color: '#346fef', backgroundColor: 'rgba(52, 111, 239, 0.05)', fontWeight: 600}}
                                size="small"
                                label={Utils.formatCurrency(product.totalSoldProductValue, "$")}
                                clickable
                                color="primary"
                                className="valueChip"
                                onClick={() => setDrawer(product)}
                              />
                            ) : ''}
                            </TableCell>

                          <TableCell
                            variant="body2"
                            style={{ cursor: "pointer",textAlign:'center' }}
                            onClick={() => setDrawer(product)}
                          >
                            {product.totalSoldProductCount}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              {drawer &&
                <ProductSalesDrawer
                  teamUsers={teamUsers}
                  open={drawer}
                  onClose={() => setDrawer(null)}
                  item={drawer}
                  formData={formData}
                  selectedUserIds={selectedUserIds}
                />}
            </Fragment>
      }
    </Wrapper>
  );
};

export default ProductSalesReport;