import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Styles from './style.module.css'
import Confetti from 'react-confetti'
import {Button, withStyles} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const StyledNextButton = withStyles((theme) => ({
  root: {
    backgroundColor: "rgb(0, 255, 145) !important",
    color: "#000  !important",
    fontWeight: "600  !important",
    width: "275px  !important"
  }
}))(Button);

const TrophyAlert = (props) => {
  let default_params = {
    title: '',
    closeOnClickOutside: true,
    width: '520px'
  }
  let data_params = {...default_params, ...props}
  let {
    title, closeOnClickOutside, width
  } = data_params

  const wHeight  = window.innerHeight;
  const wWidth  = window.innerWidth;

  const handleNext = () => {
    props.setConfirmDealModal(true);
  }
  return (
      confirmAlert({
        customUI: ({onClose}) => {
          return (
              <>
                <Confetti width={wWidth}  height={wHeight}  />
                <div className={Styles.mainWrp} style={{ width: width }}>
                  <div className={`${Styles.bodyWrp} `}>
                    <span className={Styles.bodyTitle}>{title}</span>

                    <span className={Styles.bodyImg}>
                  <img src="https://s3.us-east-1.amazonaws.com/pypepro/user/1/FQtY1ZIl1IIyeetAsNcLUg2psU3U2xLGVeHrNjFT.gif" alt="" />
                </span>

                  </div>

                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <StyledNextButton
                        variant="contained"
                        color='primary'
                        endIcon={<NavigateNextIcon />}
                        onClick={() => {handleNext(); onClose()}}>
                      Next
                    </StyledNextButton>
                  </div>

                </div>
              </>
          );
        },
        closeOnClickOutside: closeOnClickOutside,
        overlayClassName: Styles.overlay
      })
  )
}
export default TrophyAlert;