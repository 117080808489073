import { useEffect, useState } from "react";
import moment from "moment";
import { DropdownHelper } from "./assets/components/DropdownHelper";
import ApiListAddDeal from "./assets/ApiListAddDeal";
import CustomDatePicker from "./assets/components/CustomDatePicker";
import Utils from "../../helpers/Utils";
import './css/global-edit-deal-v2.css'
import CustomCircleLoader from "../../components/Common/loaders/CustomCircleLoader";

const EditDealApp = (props) => {
    const initial_states = {
        id: null,
        contact_id: "",
        title: '',
        pipelines: [],
        pipeline_id: "",
        stage: "",
        deal: 0,
        closing_date: new Date(),
        submitting: false
    }

    const [states, setStates] = useState(initial_states);
    const [stages, setStages] = useState([])
    const [error,setError] = useState({})
    const [prevInfo,setPreviousInfo] = useState({previous_deal_value:'',previous_stage_id:''});

    const [selectedPipeline, setSelectedPipeline] = useState(null)

    useEffect(() => {
        if(props.editDealData != null){
            let data = props.editDealData;
            var myEstimateClosingDate = moment(data.estimate_closing_date);
            if (myEstimateClosingDate.isValid()) {
                myEstimateClosingDate = myEstimateClosingDate.format('YYYY-MM-DD');
            } else {
                myEstimateClosingDate = new Date();
            }
            setStates({
                ...states,
                id: data.contact_stages_id,
                contact_id: data.contact_id,
                title: data.title,
                stage: data.user_stage_id,
                deal: data.deal_value,
                closing_date: myEstimateClosingDate,
                pipeline_id: data.pipelineId,
                pipelines: data.pipelineList
            })
            setPreviousInfo({
                ...prevInfo,
                previous_deal_value : data.deal_value,
                previous_stage_id : data.user_stage_id
            })
            ApiListAddDeal.getAllStageByPipeline({ pipelineId: data.pipelineId }).then((res) => {
                setStages(res.data.dealStage)
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editDealData])

    useEffect(() => {
        if(selectedPipeline){
            setSelectedPipeline(null);
            ApiListAddDeal.getAllStageByPipeline({ pipelineId: selectedPipeline }).then((res) => {
                setStages([])
                setTimeout(()=>{
                    setStages(pre => (res.data.dealStage))
                }, 0)

                try{
                    if(res.data.dealStage.length > 0){
                        setStates({...states, stage: res.data.dealStage[0].value})
                        // setStates({...states, stage: ""})
                    }
                    else{
                        setStates({...states, stage: ""})
                    }
                }
                catch (e){
                    setStates({...states, stage: ""})
                };

            });
        }
    }, [selectedPipeline])// eslint-disable-line react-hooks/exhaustive-deps

    const handlePipelineChange = (value) => {
        setStates({...states, pipeline_id: value})
        setSelectedPipeline(value);
    }

    const handleChangeDealValue = (event) => {
        if(event.target.value.match(/^\d{0,9}(\.\d{0,2}){0,1}$/g)){
            setStates({...states, deal: event.target.value})
        }else{
            if(event.target.value === "" || event.target.value.trim() === ""){
                setStates({...states, deal: ""})
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(states.submitting){
            return
        }
        if(checkError()){
            setStages({...states, submitting: true})
            let formData = {...states,...prevInfo}
            ApiListAddDeal.editDealSubmit(formData)
                .then((res)=>{
                    setStages({...states, submitting: false})
                    props.onClose(false, true);
                }).catch((err) => {
                    setStages({...states, submitting: false})
                    // props.onClose();
                    console.log(err);
                  });
        }
    }

    const checkError = () => {
        let dealData = {...states}
        let errors = {};
        for(let key in dealData){
            if(key === "title"){
                continue;
            }
            else if(dealData[key] === "" || dealData[key] === '' || dealData[key] == null){
                errors[key] = "required"
            }
        }
        
        setError(errors)
        let checkError = Object.keys(errors).length > 0 ? false : true;
        return checkError
    }

    const renderPipelineList = () => {
        if(Utils.getAccountData('userIsAgent')){
            const list = [];
            states.pipelines.forEach(item => {
                if(item.user.role_user.role_id === 3 && item.user_id === Utils.getAccountData('userId')){
                    list.push(item)
                }
                else if(item.user.role_user.role_id !== 3){
                    list.push(item)
                }
            })
            return list;
        }
        return states.pipelines
    }

    return (
        <div className="global__edit__deal__container__v2">
            <div className="deal__title__container">
                <span>Title: </span>
                <div>
                    <input 
                        placeholder="Enter deal title"
                        defaultValue={states.title}
                        value={states.title}
                        onChange={(event) => {
                            setStates({...states, title: event.target.value})
                        }}
                        className="edit__deal__title__input__field"
                    />
                    {/* {
                        error.hasOwnProperty('title') && <p style={{color:"red"}}>required this field</p>
                    } */}
                </div>
            </div>

            <div className="global__edit__deal__container__select__v2">
                <div className="global__edit__deal__container__margintop">
                    <span>Pipeline: </span>
                    <div className="global__edit__deal__container__select__left__v2">
                        <DropdownHelper
                            // datalist={states.pipelines}
                            datalist={renderPipelineList()}
                            noneFieldRequired={true}
                            noneFieldTitle="Select Pipeline"
                            className=""
                            mapping={{ title: "title", id: "id" }}
                            menuItemClassName=""
                            updateSelectedData={handlePipelineChange}
                            selectedValue={states.pipeline_id}
                            updateDatatable={() => console.log("update")}
                            dropdownHeader={"ownerChange"}
                            parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"
                            from="edit-deal-deal-module"
                        />
                    </div>
                    {
                        error.hasOwnProperty('pipeline_id') && <p style={{color:"red"}}>required this field</p>
                    }
                </div>

                <div className="global__edit__deal__container__margintop">
                    <span>Stage: </span>
                    <div>
                        <DropdownHelper
                            datalist={stages}
                            noneFieldRequired={true}
                            noneFieldTitle="Select stage"
                            className=""
                            mapping={{ title: "label", id: "value" }}
                            menuItemClassName=""
                            updateSelectedData={(value) => {
                                setStates({...states, stage: value})
                            }}
                            selectedValue={states.stage}
                            updateDatatable={() => console.log("update")}
                            dropdownHeader={"ownerChange"}
                            parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"
                            from="edit-deal-deal-module"
                        />
                    </div>
                    {
                        error.hasOwnProperty('stage') && <p style={{color:"red"}}>required this field</p>
                    }
                </div>
            </div>

            <div className="global__edit__deal__container__date__v2 global__edit__deal__container__margintop">
                <div className="global__edit__deal__container__date__flex">
                    <span>Deal Value</span>
                    <div>
                        <input 
                            className="global__edit__deal__container__dateinput__v2 "
                            placeholder="Enter deal value"
                            defaultValue={states.deal}
                            value={states.deal}
                            onChange={handleChangeDealValue}
                        />
                        {
                        error.hasOwnProperty('deal') && <p style={{color:"red"}}>required this field</p>
                        }
                    </div>
                </div>
                <div className="global__edit__deal__container__date__flex">
                    <span>Estimate Close Date</span>
                    <div>
                        <CustomDatePicker
                        
                            value={states.closing_date}
                            changeDate={(date) =>
                               setStates({...states, closing_date: date})
                            }
                        />
                    </div>
                    {
                        error.hasOwnProperty('closing_date') && <p style={{color:"red"}}>required this field</p>
                    }
                </div>
            </div>
            {states.submitting ?
                <button className="global__edit__deal__container__btn">Submitting <span><CustomCircleLoader size="small" block={false} className="edit__deal__button"/></span></button>
            :
                <button className="global__edit__deal__container__btn" onClick={handleSubmit}>Submit</button>
            }
            
        </div>
    )
}
export default EditDealApp;