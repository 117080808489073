import React, { useContext, useState } from 'react';
import { Menu, MenuItem } from "@material-ui/core";
import BootstrapTooltip from '../../../../../globals/BootstrapTooltip';
import Styles from './style.module.css';
import { ActivityIcon, CallIcon, DirectMailIcon, EmailIcon, SmsIcon, VideoIcon, VoiceIcon } from '../../helpers/IconList';
import Utils from '../../../../../../helpers/Utils';
import { calculateCash, formatPhoneNumber, isEmpty } from '../../helpers/Utils';
import { HistoryProvider } from '../../../../../../App';
import moment from 'moment';
import {RECYCLED_STATUS} from "../../../../../../constants/CoreConstants";
import {RecycledIcon} from "../../../grid-view-v2/Icons";
import RecycledInformationModal from "../../../grid-view-v2/eachi-item/RecycledInformationModal";
import {TooltipStyles} from "../../../grid-view-v2/eachi-item/EachItem";
const EachContactRow = ({item, index}) => {
  const history = useContext(HistoryProvider);
  const [mailOptionVisible, setMailOptionVisible] = useState(false);
  const thanksIoActive = (Utils.getAccountData('customMenu')['63'] && Utils.getAccountData('customMenu')['63'] !== null && Utils.getAccountData('customMenu')['63'] !== "") ? true :false
  const zenDirectActive = (Utils.getAccountData('customMenu')['61'] && Utils.getAccountData('customMenu')['61'] !== null && Utils.getAccountData('customMenu')['61'] !== "") ? true :false

  const formateDateTime = (minutes = 0) => {
    if(minutes === 0){
      return 'a few seconds'
    }
    else if(minutes > 0 & minutes < 60){
      if(minutes === 1){
        return `${minutes} minute`
      }
      return `${minutes} minutes`
    }
    else if(minutes > 60){
      let hour  = Math.floor(minutes / 60);
      if(hour < 24){
        if(hour === 1){
          return `${hour} hour`
        }
        return `${hour} hours`
      }
      else{
        let day = Math.floor(hour / 24)
        if(day < 31){
          if(day === 1){
            return `${day} day`
          }
          return `${day} days`
        }
        else{
          let month = Math.floor(day / 30)
          if(month === 1){
            return `${month} month`
          }
          if(month < 12){
            return `${month} months`
          }
          else{
            let year  = Math.floor(month / 12)
            if(year === 1){
              return `${year} year`
            }
            return `${year} years`
          }
        }
      }
    }
    else if(minutes === 60){
      return `in 1 hour`
    }
    return ''
  }
  const goToLink = (link) => {
    history.push({
      pathname: link
    });
  }
  /* handle actions */
  const makeContactInfo = () => {
    return {
      first_name: item.first_name,
      last_name: item.last_name,
      number: item.number,
      email: item.email,
      address: item.address,
      id: item.contact_id,
      contact_id: item.contact_id,
      contactId: item.contact_id
    }
  }
  const getContactEmailInfo = () => {
    if (!isEmpty(item.email)) {
      return item.email
    }
    return ""
  }
  const handleCallOption = () => {
    if (window.initiateGlobalDialer !== undefined) {
      window.initiateGlobalDialer(
        makeContactInfo()
      )
    }
  }
  const handleSmsSendOption = () => {
    if (window.sendCommunication !== undefined) {
      window.sendCommunication({
        open: true,
        onClose: () => {},
        id: item.contact_id,
        from: "deal",
        fetchContactDetails: true,
      },'sms')
    }
  }
  const handleEmailSendOption = () => {
    if (window.sendCommunication !== undefined) {
      window.sendCommunication({
        open: true,
        onClose: () => {},
        id: item.contact_id,
        from: "deal",
        primaryEmail: getContactEmailInfo(),
        fetchContactDetails: true
      },'email')
    }
  }
  const handleVideoEmailSendOption = (item, index) => {
    if (window.sendCommunication !== undefined) {
      window.sendCommunication({
      open: true,
      contactId: item.contact_id,
      contactDetails: makeContactInfo(),
      from: 'deal',
      responseCallback: () => {}
      },'videoEmail')
    }
  }
  const handleVoiceMailSendOption = (item, index) => {
    if (window.sendCommunication !== undefined) {
      window.sendCommunication({
        open: true,
        onClose: () => {},
        id: item.contact_id,
        from: "deal",
        fetchContactDetails: true
      },'voice')
    }
  }
  const handleNewPostcard = (type = 'postcard') => {
    setMailOptionVisible(false)
    window.sendGlobalDirectMailV2({
      contactId: item.contact_id,
      from: '/deal-stages',
      card_type: type,
      for: 'deal-table-view'
    })
  }
  const renderActions = () => {
    return (
      <div className={Styles.actionWrp}>
        {!isEmpty(item.number) &&
        <BootstrapTooltip arrow placement="top" title="Call">
            <span className={Styles.actionIcon} onClick={handleCallOption}><CallIcon height='24' width='24' /></span>
        </BootstrapTooltip>
        }

        {!isEmpty(item.number) &&
        <BootstrapTooltip arrow placement="top" title="Text">
            <span className={Styles.actionIcon} onClick={handleSmsSendOption}><SmsIcon width='24' height='24' /></span>
        </BootstrapTooltip>
        }

        {!isEmpty(item.email) &&
        <BootstrapTooltip arrow placement="top" title="Email">
            <span className={Styles.actionIcon} onClick={handleEmailSendOption}><EmailIcon height='24' width='24' /></span>
        </BootstrapTooltip>
        }

        {!isEmpty(item.email) &&
        <BootstrapTooltip arrow placement="top" title="Video Email">
            <span className={Styles.actionIcon} onClick={handleVideoEmailSendOption}><VideoIcon height='24' width='24' /></span>
        </BootstrapTooltip>
        }

        {!isEmpty(item.number) &&
        <BootstrapTooltip arrow placement="top" title="Voicemail">
            <span className={Styles.actionIcon} onClick={handleVoiceMailSendOption}><VoiceIcon height='24' width='24' /></span>
        </BootstrapTooltip>
        }

        {(!isEmpty(item.address) && (thanksIoActive || zenDirectActive)) &&
        <BootstrapTooltip arrow placement="top" title="Direct Mail">
            <div className={Styles.actionIcon} onClick={(e) => {setMailOptionVisible(e.currentTarget) }}>
                <span className="icon___inbox_action" ><DirectMailIcon height='24' width='24' /></span>
            </div>
        </BootstrapTooltip>
        }

        <BootstrapTooltip arrow placement="top" title="Activity">
            <span className={Styles.actionIcon} onClick={() => window.openGlobalActivityModal({ contactDetails: makeContactInfo() })}><ActivityIcon height='24px' width='24px' /></span>
        </BootstrapTooltip>
      </div>
    )
  }
  const renderName = () => {
    let name = ""
    if(!isEmpty(item.first_name) && !isEmpty(item.last_name)){
      name = item.first_name + " " + item.last_name
    }
    else if(!isEmpty(item.first_name)){
      name = item.first_name
    }
    else if(!isEmpty(item.last_name)){
      name = item.last_name
    }
    return (<span className={`${name === "" ? Styles.emptyText : Styles.nameText}`}>{name === "" ? 'No name provided !' : name}</span>);
  }
  const renderNumber = () => {
    let number = ""
    if(!isEmpty(item.number)){
      number = item.number
    }
    return (<span className={`${number === "" ? Styles.emptyText : Styles.nameText}`}><code>Number:</code> {number === "" ? "No number found !" : formatPhoneNumber(number)}</span>);
  }
  const renderEmail = () => {
    let email = ""
    if(!isEmpty(item.email)){
      email = item.email
    }
    return (<span className={`${email === "" ? Styles.emptyText : Styles.nameText}`}><code>Email:</code> {email === "" ? "No email found !" : email}</span>);
  }
  const renderAddress = () => {
    let address = ""
    if(!isEmpty(item.address)){
      address = item.address
    }
    return (<span className={`${Styles.addressText}`}>Address: {address === "" ? "No address found !" : address}</span>);
  }
  const renderClosingDate = () => {
    if(!isEmpty(item.estimate_closing_date)){
      return moment(item.estimate_closing_date).format('MMM DD, YYYY')
      // return window.globalTimezoneConversionToDifferentTimezone(item.estimate_closing_date,'UTC','','ll h:mm A')
      return window.globalTimezoneConversionToDifferentTimezone(item.estimate_closing_date,'UTC','','ll')
    }
    return "No closing date !"
  }

  const renderTimeInStage = () => {
    if(!isEmpty(item.moved_at_actual)){
      let moveAt = window.globalTimezoneConversionToDifferentTimezone(item.moved_at_actual,'UTC','','ll h:mm A')
      let currentTime = window.globalTimezoneConversionToDifferentTimezone(new Date(),'UTC','','ll h:mm A')
      let date1 = new Date(currentTime);
      let date2 = new Date(moveAt);


      const diffTime = Math.abs(date2.getTime() - date1.getTime());

      let diffInMin = Math.ceil(diffTime / (1000 * 60));
      return formateDateTime(diffInMin)
    }
    return null
  }
  return(
    <div className={Styles.rowWrp}>
      {/* contact info clolumn */}
      <div className={Styles.contactWrp}>
        {renderName()}
        {renderNumber()}
        {renderEmail()}
        {renderAddress()}
        <span className={Styles.linkText} onClick={()=> goToLink(`/contacts/${item.contact_id}`)}>Go To Profile</span>
      </div>

      {/* deal info column */}
      <div className={Styles.stageWrp}>
        <span className={`${Styles.text}`}><code>Stage:</code> {item.stage_title}</span>
        <span className={`${Styles.text}`}><code>Closing date:</code> {renderClosingDate()}</span>
        {!isEmpty(item.deal_title) &&
        <span className={`${Styles.addressText} ${Styles.stageTitle}`}><code>Title:</code> {item.deal_title}</span>
        }
      </div>

      {/* deal value */}
      <div className={Styles.dealValueWrp}>
        <BootstrapTooltip arrow title={<span className={Styles.dealValue_Tooltip}>{Utils.calculateCashPlain(item.deal_value)}</span>}>
          <span className={Styles.nameText}>{calculateCash(item.deal_value)}</span>
        </BootstrapTooltip>
      </div>

      {/* time in this stage */}
      <div className={Styles.timeWrp}>
        <span className={Styles.text}>{renderTimeInStage()}</span>
      </div>

      {/* actions */}
      {renderActions()}

      <Menu
        id="more-menu"
        anchorEl={mailOptionVisible}
        keepMounted
        open={Boolean(mailOptionVisible)}
        onClose={() => setMailOptionVisible(false)}
      >
        {thanksIoActive &&
        <>
        <MenuItem
          style={{
              paddingTop: 6,
              paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
          }}
          onClick={() => handleNewPostcard('postcard')}
        >
          Postcard
        </MenuItem>
        <MenuItem
          style={{
              paddingTop: 6,
              paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
          }}
          onClick={() => handleNewPostcard('letter')}
        >
          Letter
        </MenuItem>
        <MenuItem
          style={{
              paddingTop: 6,
              paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
          }}
          onClick={() => handleNewPostcard('notecard')}
        >
          Notecard
        </MenuItem>
        </>
        }
        {zenDirectActive &&
        <MenuItem
          style={{
              paddingTop: 6,
              paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
          }}
          onClick={() => handleNewPostcard('zendirect_gift')}
        >
          Gift
        </MenuItem>
        }
      </Menu>
    </div>
  );
}
export default EachContactRow;