export const DateTimeFilters = [
  {label: 'Daily', value: 'daily'},
  {label: 'Weekly', value: 'weekly'},
  {label: 'Monthly', value: 'monthly'},
  {label: 'Quarterly', value: 'quarterly'},
  {label: 'Yearly', value: 'yearly'}
];
export const UserFilters = [
  {label: 'All Data', value: 'all'},
  {label: 'My Data', value: 'own'}
];
export const TimeIntervals = [
  {label: 'Today', value: 'today'},
  {label: 'Yesterday', value: 'yesterday'},
  {label: 'This week', value: 'this_week'},
  {label: 'Last week', value: 'last_week'},
  {label: 'Last 7 days', value: 'last_7_days'},
  {label: 'Last 30 days', value: 'last_30_days'},
  {label: 'This month', value: 'this_month'},
  {label: 'Last month', value: 'last_month'},
  {label: 'This year', value: 'this_year'},
  {label: 'Last year', value: 'last_year'},
  {label: 'All Time', value: 'all_time'}
];