import { useEffect, useRef } from 'react';
import { useIsMounted } from '../../../../../../hooks/IsMounted';
import Styles from './style.module.css'
const Pagination = ({page, limit, total, onChangePage, hanldeReload}) => {
  const inputRef = useRef(null)
  const isMount = useIsMounted();
  useEffect(()=>{
    if(isMount){
      inputRef.current.value = (page+1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])
  const getTotalPage = () => {
    return Math.ceil(total / limit);
  }
  const onChange = (e) => {
    let pageValue = e.target.value.trim();
    if(pageValue !== ""){
      if(/^[0-9]+$/.test(pageValue)){
        pageValue = parseInt(pageValue);
        if(pageValue > 0 && pageValue <= getTotalPage()){
          if(pageValue-1 !== page){
            onChangePage((pageValue-1))
          }
        }
      }
    }
  }
  const onChangeByClick = (disabled=false, type='forward') => {
    if(!disabled){
      let updatePage = null
      if(type === 'back'){
        updatePage = page-1        
      }else{
        updatePage = page+1
      }
      onChangePage(updatePage)
    }
  }
  const renderLeftClasses = page <= 0 ? true : false
  const renderRightClasses = (page+1) >= getTotalPage() ? true : false
  return (
    <div className={Styles.paginationWrp}>
      <span className={`${Styles.refresh}`} onClick={hanldeReload}>Refresh</span>
      <span className={`${Styles.left} ${renderLeftClasses && Styles.disabled}`} onClick={() => onChangeByClick(renderLeftClasses, 'back')}>{'<'}</span>
      <input ref={inputRef} className={Styles.input} placeholder='Page' defaultValue={(page+1)} onChange={onChange} />
      <span className={Styles.seprator}>{'/'}</span>
      <span className={Styles.totalPages}>{getTotalPage()}</span>
      <span className={`${Styles.right} ${renderRightClasses && Styles.disabled}`} onClick={() => onChangeByClick(renderRightClasses, 'forward')}>{'>'}</span>
    </div>
  );
}
export default Pagination;