
const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`;
const profileUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/profile`;
const containerUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`;
const calendarUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar`;
const backendeUrlV1 = `${process.env.REACT_APP_JAVAEND_URL}/v1`;
const tenantBackendV1 = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1`;
const java17CoreApiV1 = `${process.env.REACT_APP_JAVA17_CORE_URL}/v1`;

const Api = {
  customFields: `${rootUrl}/get-custom-fields`,

  getDeals: `${rootUrl}/all-deal-contact-list`,
  getAllPipeLine: `${rootUrl}/select-all-pipeline-except`,
  getAllPipeLineV2: `${rootUrl}/select-all-pipeline-except-v2`,
  reorderDealStage: `${rootUrl}/reorder-deals`,
  getDealByScroll: `${rootUrl}/all-deal-contact-list-by-scroll`,
  changedDealStatus: `${rootUrl}/status-change-deals`,
  getAllStageByPipeline: `${rootUrl}/select-all-stage-by-pipeline`,
  moveToStage: `${rootUrl}/move-to-stage`,
  getAllDealsTableView: `${rootUrl}/all-deal-contact-list-view`,
  getUserStageListReport: `${rootUrl}/user-stage-list-report`,
  getUserStageListReportByFilter: `${rootUrl}/user-stage-filter-chart-change`,
  contactSearch: `${rootUrl}/search/contact`,
  addNewDeal: `${rootUrl}/add-new-deal`,
  leadSource: `${rootUrl}/lead-sources`,
  addNote: `${rootUrl}/note-add-submit`,
  updateNote: `${rootUrl}/update-timeline-note`,
  getConversation: `${rootUrl}/get/people/conversation`,
  updateContactField: `${rootUrl}/contact/update/field`,
  getVirtualNumber: `${rootUrl}/get-virtual-number-by-user`,
  sendConversation: `${rootUrl}/campaign/contacts/conversation`,
  getProfileData: `${rootUrl}/single/profile`,
  getOtherEmailByContact: `${rootUrl}/get-other-email-by-contact`,
  sendBulkEmail: `${rootUrl}/send/bulk-email`,
  getAllTags: `${rootUrl}/get/tag/list`,
  removeContactTag: `${rootUrl}/contact/remove-tags`,
  addContactTags: `${rootUrl}/contact/add-tags`,
  getContactDetails: `${rootUrl}/get/people`,
  contactSingleCustomFieldUpdate: `${rootUrl}/contact/single/custom-field/update`,
  changeContactSourceType: `${rootUrl}/contact-source-type/change/source`,
  getAllCampaignByContact: `${rootUrl}/campaign/list/contact`,
  getEligibleCampaignForContact: `${rootUrl}/campaign/not-in-current-contact/list`,
  moveCampaignContact: `${rootUrl}/campaign/contact/move`,

  campaignSingleContactRun: `${rootUrl}/campaign/single-contact/run`,
  campaignSingleContactPause: `${rootUrl}/campaign/single-contact/pause`,
  campaignSingleContactUnsubscribed: `${rootUrl}/campaign/contacts/unsubscribe`,

  getPeopleActivity: `${rootUrl}/get/people/activity`,
  markAsDone: `${rootUrl}/contact-activity-mark-as-done`,
  submitUserActivity: `${rootUrl}/user-activity-submit-task`,
  contactDeleteFromStage: `${rootUrl}/contact/delete/from/stage`,

  // pipeline module
  createPipeline: `${rootUrl}/user-pipeline-create`,
  checkPipelineDeleteOrNot: `${rootUrl}/check-pipeline-deletable-or-not`,
  deletePipelineAndMove: `${rootUrl}/delete-pipeline-and-move-deal`,
  updatePipeline: `${rootUrl}/user-pipeline-update`,
  getUserStageByPipeline: `${rootUrl}/user-stage-list`,
  sortUserStage: `${rootUrl}/sort-stage-list`,
  deleteAndMoveToStage: `${rootUrl}/delete-move-to-stage`,
  userStageToggleShowInChart: `${rootUrl}/user-stage-toogle-show-in-chart`,
  addUserStageSubmit: `${rootUrl}/user-stage-submit`,
  updatePipelineShowOption: `${rootUrl}/pipeline-toogle-show-chart`,
  updateStageTextColor: `${rootUrl}/update-stage-text-color`,

  /* for deal module new v2 */
  getAllUserStages: `${rootUrl}/getAllContactStage`,
  getPipelineStats: `${rootUrl}/getPipelineStats`,
  getUserStageContactsV2: `${rootUrl}/getUserStageContactsV2`,
  getUserStageStats: `${rootUrl}/getUserStageStats`,

  // For add new deal with elastic search
  searchContactsNew: `${containerUrl}/search-contacts-new`,
  searchContactFromElastic: `${backendeUrlV1}/contacts/get-elastic-contacts`,

  getAllStageContactsV2: `${rootUrl}/deals-table-view-v2`,
  getAllStageContactsGraphV2: `${rootUrl}/deals-graph-view-v2`,
  getSubUserList: `${rootUrl}/get-sub-user-list-v2`,

  updateDealBgColor: `${rootUrl}/update-deal-bg-color`,

  getTealUserList: `${calendarUrl}/get-team-users`,

  // Products
  productFolders: `${rootUrl}/user-product-folders`,
  userProducts: `${rootUrl}/user-products`,
  contactStageProducts: `${rootUrl}/user-contact-stage-products/contact-stage-products`,
  updateSuccessDeal: `${rootUrl}/user-contact-stage-products/update-success-deal`,

  // Sales Goals
  addSalesGoal: `${tenantBackendV1}/user-sale-goals`,
  updateSalesGoal: `${tenantBackendV1}/user-sale-goals`,
  deleteSalesGoal: `${tenantBackendV1}/user-sale-goals`,
  getAllSalesGoalList: `${tenantBackendV1}/user-sale-goals`,
  userSaleGoalStats: `${tenantBackendV1}/user-sale-goals/stats`,
  selesVsGoalReport : `${tenantBackendV1}/user-sale-goals/report`,

  teamSalesReport: `${java17CoreApiV1}/reports/team-sales`,
  productSalesReport: `${java17CoreApiV1}/reports/product-sales`,
  productSalesContact: `${java17CoreApiV1}/reports/product-sold-contacts`,


  contactStageSearch: `${java17CoreApiV1}/contact-stage/search`,
  contactExport: `${java17CoreApiV1}/contact-stage/export`,
  getTeamUsers: `${profileUrl}/get-team-users`,

  //product sales export with email
  productSalesExport: `${java17CoreApiV1}/reports/product-report/export`,
  teamWiseSoldProducts: `${java17CoreApiV1}/reports/user-wise-sold-products`,
  teamWiseSoldProductExport: `${java17CoreApiV1}/reports/user-wise-sold-products/export`,
  recycleInfoApi: `${tenantBackendV1}/deal/lost-deal-recycle/success-data`,
};
export default Api;
