import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import {useSelector} from "react-redux";
import Utils from "../../../helpers/Utils";

const ProductBarChart = ({activeProduct,data,setActiveProduct}) => {

  const handleActiveProduct = (id) => {
    if (activeProduct === id) {
      setActiveProduct(null);
      return;
    }
    setActiveProduct(id);
  };

  const DataFormater = (number) => {
    if(number > 1000000000){
      return '$' + (number/1000000000).toString() + 'B';
    }else if(number > 1000000){
      return '$' + (number/1000000).toString() + 'M';
    }else if(number > 1000){
      return '$' + (number/1000).toString() + 'K';
    }else{
      return '$' + number.toString();
    }
  }


  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload[0]) {
      const { product, sold, count } = payload[0].payload;
      return (
        <div style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '16px',
          borderRadius: '4px',
          textAlign: 'center',
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        }}>
          <p style={{ margin: 0, fontWeight: '600', color: '#133159',marginBottom:'6px' }}>{product}</p> 
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',alignItems:'center',gap:'20px'}}>
          <div style={{paddingRight:'20px', borderRight:'1px solid #E5E8EF'}}>
            <p style={{fontWeight: '500', marginBottom:'6px',color: '#133159'  }}>SALES</p>
            <p style={{ margin: 0, fontWeight: '600', color: '#346FEF',textAlign:'center' }}>
              {`${Utils.formatCurrency(sold, "$")}`}
            </p>
          </div>
          <div>
            <p style={{  fontWeight: '500', marginBottom:'6px',color: '#133159' }}>PRODUCTS/SERVICES</p>
            <p style={{ margin: 0, fontWeight: '600', color: '#346FEF',textAlign:'center' }}>{count}</p>
          </div>
          </div>
        </div>
      );
    }
    return null;
  };
  

   const formatData = data.map((item)=>({ sold: item.totalSoldProductValue, product: item.userProductTitle, user_product_id: item.userProductId ,count: item.totalSoldProductCount,}));

  return (
    <ResponsiveContainer width="100%" height={370}>
    <BarChart
      data={formatData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid 
      strokeDasharray="none"
      stroke="#fff" 
       />
     <XAxis dataKey="product" tick={{fontSize:`${data.length < 20 ? 8 : 6}`}}/>
      <YAxis tickFormatter={DataFormater}/>
      <Tooltip content={CustomTooltip}/>
      <Bar
        maxBarSize={24}
        dataKey="sold"
        fill="#8884d8"
        isAnimationActive={false}
      >
        {
          formatData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={activeProduct === entry.user_product_id ? "#346fef" : "#e9e9e9"}
              onClick={()=>handleActiveProduct(entry.user_product_id)}
            />
          ))
        }
      </Bar>
    </BarChart>
  </ResponsiveContainer>
  );
};

export default ProductBarChart;
