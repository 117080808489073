import React from "react";
import { connect } from "react-redux";
import {
  addNewDeal,
  contactSearch,
  getAllPipeline,
  getAllStageByPipeline,
  getLeadSource,
} from "../../../../actions/DealAction";
import "react-datepicker/dist/react-datepicker.css";
import GlobalAddDeal from "../../../../GlobalComponents/GlobalAddDeal";
import GlobalModal from "../../../globals/Modal/GlobalModal";
import EditDealApp from "../../../../GlobalComponents/GlobalAddDeal/EditDeal";

const App = (props) => {
  const closeModal = (status = false, reload = false, pipeline = null) => {
    props.onClose(status, reload, pipeline);
  };

  return (
    <GlobalModal
      className="global__add__deal__wrapper"
      open={props.open}
      title={props.editDealData == null ? "Add Deal" : "Edit deal"}
      onClose={closeModal}
      buttonText={"Add Deal"}
      hideFooter={true}
    >
      {props.editDealData !== undefined && props.editDealData !== null ? 
        <EditDealApp callBack={props.updateCallback} onClose={closeModal} editDealData={props.editDealData} />
      :
        <GlobalAddDeal callBack={props.updateCallback} onClose={closeModal} />
      }
    </GlobalModal>
  );
};

const mapStateToProps = (state) => ({
  pipelineIdInDealList: state.dealReducer.pipelineIdInDealList,
});
const mapDispatchToProps = (dispatch) => ({
  getAllPipeline: (params, callback) =>
    dispatch(getAllPipeline(params, callback)),
  getAllStageByPipeline: (params, callback) =>
    dispatch(getAllStageByPipeline(params, callback)),
  contactSearch: (params, callback) =>
    dispatch(contactSearch(params, callback)),
  getLeadSource: (params, callback) =>
    dispatch(getLeadSource(params, callback)),
  addNewDeal: (params, callback) => dispatch(addNewDeal(params, callback)),
});
const ModalAddDeal = connect(mapStateToProps, mapDispatchToProps)(App);
export default ModalAddDeal;
