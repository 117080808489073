import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { changeDealStatus } from "../../../../../actions/DealAction";
import { updateDealBgColorApi } from "../../../../../api/dealApi";
import { RECYCLED_STATUS } from "../../../../../constants/CoreConstants";
import Helper from "../../../../../helpers/Helper";
import Utils from "../../../../../helpers/Utils";
import BootstrapTooltip from '../../../../globals/BootstrapTooltip';
import { ColorIcon, DeleteIcon, DislikeIcon, LikeIcon, PipelineIcon, RightArrowIcon } from '../IconList';
import { RecycledIcon } from "../Icons";
import CustomConfirmAlert from "./confirm-alert/CustomConfirmAlert";
import ConfirmDealModal from "./ConfirmDealModal";
import CustomMenuPype from "./custom-menu/CustomMenuPype";
import RecycledInformationModal from "./RecycledInformationModal";
import Styles from './style.module.css';

export const TooltipStyles = makeStyles((theme) => ({
  tooltip: {
    color: '#fff',
  },
  arrow: {
    color: '#15008A',
  },
  tooltipPlacementTop: {
    background: '#15008A'
  },
  tooltipPlacementBottom: {
    background: '#15008A'
  },
  tooltipPlacementRight: {
    background: '#15008A'
  }
}));



const EachItemApp = ({
  data,
  index,
  handleMoreOptionModal,
  handleAddDealModal,
  changeDealStatus,

  handlActions,
  stageIndex,
  stageId,

  handleDealProperties
}) => {
  const [color, setColor] = useState('#ff0088');
  const [confirmDealModal, setConfirmDealModal] = useState(false);
  const [recycleInformationModalOpen,setRecycleInformationModalOpen] = useState(false);
  useEffect(() => {
    if (data.bgColor !== undefined) {
      setColor(data.bgColor)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /* handle actions */
  const handleDeleteConfirm = () => {
    CustomConfirmAlert({
      onSubmit: onDeleteDeal,
      title: 'Are you sure you want to delete this deal?',
      description: 'You cannot undo this action'
    })
  }
  const onDeleteDeal = () => {
    changeDealStatus({
      stage_id: data.contact_stages_id,
      status: 4
    }, (res) => {
      if (res.status === 'success') {
        window.showNotification("SUCCESS", 'Deal removed successfully');
        handlActions.delete(stageIndex, index, data.id); /* stageIndex, dealIndex, dealId */
      }
      else {
        window.showNotification("ERROR", "Something went wrong!");
      }
    })
  }
  const onDealMarkAsWon = () => {
    if (Utils.getAccountData("agencyBusinessType") === "INSURANCE"){
      window.globalInsuranceDealStatus(true, {
        callback: (data) => {
          if (data){
            handlActions.delete(stageIndex, index, data.stageId)
          }
        },
        contact_stages_id: data.contact_stages_id,
        status: 2
      });
    }else {
      window.globalDealStatus(true, {
        callback: (data) => {
          if (data){
            handlActions.delete(stageIndex, index, data.stageId)
          }
        },
        contact_stages_id: data.contact_stages_id,
        status: 2
      });
    }
  }
  const onDealMarkAsLost = () => {
    changeDealStatus({
      stage_id: data.contact_stages_id,
      status: 3
    }, (res) => {
      if (res.status === 'success') {
        window.showNotification("SUCCESS", 'Deal mark as lost successfully !');
        handlActions.delete(stageIndex, index, data.id); /* stageIndex, dealIndex, dealId */
        if (Utils.getAccountData("agencyBusinessType") === "INSURANCE"){
          window.globalInsuranceDealStatusLost(true, {
            callback: (res) => {

            },
            contact_stages_id: data.contact_stages_id,
            contact_id: data.contact_id
          });
        }
      }
      else {
        window.showNotification("ERROR", "Something went wrong!");
      }
    })
  }
  const onDealMove = () => {
    handlActions.move(data.contact_stages_id);
  }
  const onBgColorChange = (colorValue) => {
    updateDealBgColorApi({
      id: data.contact_stages_id,
      color: colorValue
    }).then(res => {
      let response = res.data;
      if (response.status === 'success') {
        setColor(colorValue)
        window.showNotification("SUCCESS", 'Color changed');
        data['bgColor'] = colorValue
        handleDealProperties(stageIndex, index, data)
      }
      else {
        window.showNotification("ERROR", 'Something went wrong !')
      }
    }).catch(error => { window.showNotification("ERROR", 'Something went wrong !'); })
  }
  const renderContactInfo = () => {
    let name = ''
    if (Helper.isValidText(data.first_name) && Helper.isValidText(data.last_name)) {
      name = data.first_name + ' ' + data.last_name
    }
    else if (Helper.isValidText(data.first_name)) {
      name = data.first_name
    }
    else if (Helper.isValidText(data.last_name)) {
      name = data.last_name
    }
    if (name.trim() === '') {
      if (Helper.isValidText(data.email)) {
        name = data.email
      }
      else {
        name = Utils.formatPhoneNumber(data.number)
      }
    }
    return name;
  }

  const renderEstimateClosingDate = () => {
    if (data.estimate_closing_date !== undefined && data.estimate_closing_date != null) {
      return (
        <p><b>{'Estimated closing date: '}</b> {moment(data.estimate_closing_date).format('MMM DD, YYYY')}</p>
      )
    }
    return null;
  }
  const renderAddedDate = () => {
    if (data.moved_at !== undefined && data.moved_at != null) {
      let moveAt = window.globalTimezoneConversionToDifferentTimezone(data.moved_at, 'UTC', '', 'll h:mm A')
      let currentTime = window.globalTimezoneConversionToDifferentTimezone(new Date(), 'UTC', '', 'll h:mm A')
      let date1 = new Date(moveAt);
      let date2 = new Date(currentTime);

      const diffTime = Math.abs(date2.getTime() - date1.getTime());

      let diffInMin = Math.ceil(diffTime / (1000 * 60));
      let time = formateDateTime(diffInMin)
      return (
        <div className={Styles.dateWrp}>
          <BootstrapTooltip classes={TooltipStyles()} placement="right" arrow title={moment(data.moved_at).format('MMM DD, YYYY')}>
            <span className={Styles.dateText} style={{ backgroundColor: '#0759F3' }}>{time} ago</span>
          </BootstrapTooltip>
          {
              data.recycle_status === RECYCLED_STATUS.TRUE &&
              <span onClick={()=> setRecycleInformationModalOpen(true)} className={Styles.recycledChip}>
                    <RecycledIcon/>
                    Recycled
              </span>
          }
          {
            recycleInformationModalOpen && (
              <RecycledInformationModal
              open={recycleInformationModalOpen}
              onClose={() => setRecycleInformationModalOpen(false)}
              data={data}
              
              />
            )
          }
        </div>
      );
    }
    return null;
  }
  const renderView = () => {
    return (
      <>
        <div className={`${Styles.dealWrp}`}>
          <div className={Styles.top}>
            <div className={Styles.leftSide}>
              <BootstrapTooltip
                title={
                  <>
                    <p>{renderContactInfo()}</p>
                    <p><b>{'Title: '}</b> {data.title !== null ? data.title : 'No title provided!'}</p>
                    {renderEstimateClosingDate()}
                  </>
                }
                arrow
                placement="top"
                classes={TooltipStyles()}
              >
                <span
                  onClick={() => handleAddDealModal({ editData: data, status: true, reload: false })}
                  className={Styles.contactInfo}>
                  <span style={{ fontSize: '15px' }}>{renderContactInfo()}</span>
                  <br />
                  <span>{data.title !== null ? data.title : null}</span>
                </span>
              </BootstrapTooltip>
              {/* {renderCompany()} */}
              {renderAddedDate()}
              <span onClick={() => handleAddDealModal({editData: data, status: true, reload: false})} className={Styles.editOption}>Edit</span>
            </div>
            <span className={Styles.rightSide}>
              <span onClick={handleMoreOptionModal}><RightArrowIcon /></span>
            </span>
          </div>
          <div className={Styles.bottom} style={{ backgroundColor: color }}>
            <BootstrapTooltip classes={TooltipStyles()} arrow title={<span className={Styles.dealValue_Tooltip}>{Utils.calculateCashPlain(data.deal_value)}</span>}>
              <span className={Styles.dealValue}>{Utils.calculateCash(data.deal_value)}</span>
            </BootstrapTooltip>
            <div className={Styles.actionWrp}>
              <CustomMenuPype
                wrapperClass="mlvc_row_per_page_dropdown"
                popup={{
                  placement: 'center',
                  list: COLORS_CODE,
                  renderList: (item, index, callback) => {
                    return (
                      <div onClick={() => {
                        onBgColorChange(item.value)
                        callback()
                      }} key={index} className={Styles.colorItem} style={{ backgroundColor: item.value }} >
                        {color === item.value && <ColorIcon height="14px" width="14px" color="#fff" />}
                      </div>
                    );
                  },
                  // onClick: (item) => {},
                  height: 'auto',
                  width: '180px',
                  popupStyle: {
                    height: 'auto',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '5px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '5px',
                    paddingBottom: '0px'
                  }
                }}
                label={{
                  renderCustom: (
                    <BootstrapTooltip classes={TooltipStyles()} arrow title="Change color"><span className={Styles.icon}><ColorIcon /></span></BootstrapTooltip>
                  )
                }}
              />
              <BootstrapTooltip classes={TooltipStyles()} arrow title="Delete deal" onClick={handleDeleteConfirm}><span className={Styles.icon}><DeleteIcon /></span></BootstrapTooltip>
              <BootstrapTooltip classes={TooltipStyles()} arrow title="Move to another pipeline" onClick={onDealMove}><span className={Styles.icon}><PipelineIcon /></span></BootstrapTooltip>
              {data.contact_stage_status !== 2 && <BootstrapTooltip classes={TooltipStyles()} arrow title="Mark as won" onClick={onDealMarkAsWon}><span className={Styles.icon}><LikeIcon /></span></BootstrapTooltip>}
              {data.contact_stage_status !== 3 && <BootstrapTooltip classes={TooltipStyles()} arrow title="Mark as lost" onClick={onDealMarkAsLost}><span className={Styles.icon}><DislikeIcon /></span></BootstrapTooltip>}
            </div>
          </div>
        </div>

        {confirmDealModal && (<ConfirmDealModal contact_stages_id={data.contact_stages_id} open={confirmDealModal} onClose={() => { handlActions.delete(stageIndex, index, data.id); setConfirmDealModal(false) }} />)}
      </>

    );
  }
  return (
    <Draggable
      key={data.contact_stages_id}
      draggableId={`draggable-${data.contact_stages_id}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className={`update__dragbale__v2`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            backgroundColor: snapshot.isDragging ? "lightgreen" : "",
            ...provided.draggableProps.style
          }}
        >{renderView()}</div>
      )}


    </Draggable>
  );
}
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  changeDealStatus: (params, callback) => dispatch(changeDealStatus(params, callback)),
})
const EachItem = connect(mapStateToProps, mapDispatchToProps)(EachItemApp);
export default EachItem;

const COLORS_CODE = [
  { value: '#EC1111', lable: '#EC1111' },
  { value: '#d4d226', lable: '#d4d226' },
  { value: '#8e4b10', lable: '#8e4b10' },
  { value: '#2DE80C', lable: '#2DE80C' },
  { value: '#0759F3', lable: '#0759F3' },
  { value: '#9E00DA', lable: '#9E00DA' },
  { value: '#6701F9', lable: '#6701F9' },
  { value: '#ff0088', lable: '#ff0088' },
  { value: '#0A2C56', lable: '#0A2C56' },
  { value: '#0F9B87', lable: '#0F9B87' },
  { value: '#5D6D7E', lable: '#5D6D7E' },
  { value: '#C70039', lable: '#C70039' },
  { value: '#CACFD2', lable: '#CACFD2' },
  { value: '#0B5345', lable: '#0B5345' },
  { value: '#4A235A', lable: '#4A235A' },
  { value: '#27AE60', lable: '#27AE60' },
  { value: '#000000', lable: '#000000' },
];
const formateDateTime = (minutes = 0) => {
  if (minutes === 0) {
    return 'a few seconds'
  }
  else if (minutes > 0 & minutes < 60) {
    if (minutes === 1) {
      return `${minutes} minute`
    }
    return `${minutes} minutes`
  }
  else if (minutes > 60) {
    let hour = Math.floor(minutes / 60);
    if (hour < 24) {
      if (hour === 1) {
        return `${hour} hour`
      }
      return `${hour} hours`
    }
    else {
      let day = Math.floor(hour / 24)
      if (day < 31) {
        if (day === 1) {
          return `${day} day`
        }
        return `${day} days`
      }
      else {
        let month = Math.floor(day / 30)
        if (month === 1) {
          return `${month} month`
        }
        if (month < 12) {
          return `${month} months`
        }
        else {
          let year = Math.floor(month / 12)
          if (year === 1) {
            return `${year} year`
          }
          return `${year} years`
        }
      }
    }
  }
  else if (minutes === 60) {
    return `in 1 hour`
  }
  return ''
}