export const RightArrowDouble = ({
  height = "24px",
  width = "24px",
  fill = "#000000",
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      enableBackground='new 0 0 24 24'
      height={height}
      viewBox='0 0 24 24'
      width={width}
      fill={fill}
    >
      <g>
        <rect fill='none' height='24' width='24' />
      </g>
      <g>
        <g>
          <path
            d='M4,12c0-4.41,3.59-8,8-8s8,3.59,8,8s-3.59,8-8,8S4,16.41,4,12 M12,11l-4,0v2l4,0v3l4-4l-4-4V11z'
            opacity='.3'
          />
          <path d='M4,12c0-4.41,3.59-8,8-8s8,3.59,8,8s-3.59,8-8,8S4,16.41,4,12 M2,12c0,5.52,4.48,10,10,10c5.52,0,10-4.48,10-10 c0-5.52-4.48-10-10-10C6.48,2,2,6.48,2,12L2,12z M12,11l-4,0v2l4,0v3l4-4l-4-4V11z' />
        </g>
      </g>
    </svg>
  );
};
export const LeftArrowDouble = ({
  height = "24px",
  width = "24px",
  fill = "#000000",
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      enableBackground='new 0 0 24 24'
      height={height}
      viewBox='0 0 24 24'
      width={width}
      fill={fill}
    >
      <g>
        <rect fill='none' height='24' width='24' />
      </g>
      <g>
        <g>
          <path
            d='M20,12c0,4.41-3.59,8-8,8s-8-3.59-8-8s3.59-8,8-8S20,7.59,20,12 M12,13l4,0v-2l-4,0V8l-4,4l4,4V13z'
            opacity='.3'
          />
          <path d='M20,12c0,4.41-3.59,8-8,8s-8-3.59-8-8s3.59-8,8-8S20,7.59,20,12 M22,12c0-5.52-4.48-10-10-10C6.48,2,2,6.48,2,12 c0,5.52,4.48,10,10,10C17.52,22,22,17.52,22,12L22,12z M12,13l4,0v-2l-4,0V8l-4,4l4,4V13z' />
        </g>
      </g>
    </svg>
  );
};
export const DownArrowIcon = ({
  width = 24,
  height = 24,
  fill = "#949DB2",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M7 10L12 15L17 10H7Z' fill={fill} />
    </svg>
  );
};
