import ContactDealApp from './components/contacts-deals/ContactDealApp';
import StageContactDealApp from './components/stage-contacts-deals/StageContactDealApp';
import Styles from './style.module.css'
import './style.css'
import StatusContactDealApp from './components/status-contacts-deals/StatusContactDealApp';

const GraphViewV2App = () => {

  return (
    <div className={Styles.appMainWrp}>
      <ContactDealApp />
      <div className={Styles.width50PercentWrp}>
        <StageContactDealApp />
        <StatusContactDealApp />
      </div>
    </div>
  );
}
export default GraphViewV2App;