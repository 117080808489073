import { useEffect, useRef, useState } from "react";
import Popover from '@material-ui/core/Popover';

const more_icon = (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 6C9.825 6 10.5 5.325 10.5 4.5C10.5 3.675 9.825 3 9 3C8.175 3 7.5 3.675 7.5 4.5C7.5 5.325 8.175 6 9 6ZM9 7.5C8.175 7.5 7.5 8.175 7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5ZM9 12C8.175 12 7.5 12.675 7.5 13.5C7.5 14.325 8.175 15 9 15C9.825 15 10.5 14.325 10.5 13.5C10.5 12.675 9.825 12 9 12Z" fill="#949DB2"/>
</svg>
)

const CustomMenuPype = ({
  wrapperClass = '', wrapperStyle = {}, label, popup, onClose, isOpen, onClickCallback
}) => {
  const [anchorActionMenu, setAnchorActionMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const dropDownLabel = useRef(null);

  useEffect(() => {
    if(isOpen){
      dropDownLabel.current.click()
    }
  }, [isOpen])

  const handleMenuLabel = (e) => {
    setAnchorActionMenu(e.currentTarget);
    setOpen(true)
    if(onClickCallback !== undefined){
      onClickCallback()
    }
  }
  const handleClose = (e) => {
    setAnchorActionMenu(null);
    setOpen(false)
    if(onClose !== undefined){
      onClose(false)
    }
  }

  const renderDefaultMenuLabel = () => {
    return (
      <span className="new_f_c_over_menu_item_label_default" onClick={(e) => handleMenuLabel(e)}>
        {more_icon}
      </span>
    )
  }
  const renderMenuLabel = () => {
    const {leftIcon, rightIcon, text, className, renderCustom, labelStyle} = label;
    if(renderCustom !== undefined){
      return <div onClick={(e) => handleMenuLabel(e)} >{renderCustom}</div>;
    }
    return (
      <div className={`new_f_c_over_menu_item_label_customized ${className}`} style={labelStyle} onClick={(e) => handleMenuLabel(e)}>
        {leftIcon !== undefined && <span>{leftIcon}</span>}
        {text !== undefined && <span>{text}</span>}
        {rightIcon !== undefined && <span>{rightIcon}</span>}
      </div>
    );
  }

  const renderListItem = () => {
    const {
      list, listStyle = {}, renderList, optionClass='', labelField, onClick
    } = popup;

    let view = [];
    list.forEach((item, index) => {
      if(renderList !== undefined){
        view.push(renderList(item, index, handleClose))
      }
      else{
        view.push(
        <div 
          className={`new_f_c_over_menu_item ${optionClass}`} 
          key={index} 
          onClick={() => {
              onClick(item)
              handleClose()
          }}
          style={listStyle}
        >
          {item.icon !== undefined &&
            <span>{item.icon}</span>
          }
          <span>{labelField !== undefined ? item[labelField] : item.label}</span>
        </div>
        )
      }
    })
    return view;
  }
  const renderMenuList = () => {
    const {
      wrapperClass = '', popupStyle={},
      isLoading, loadingText='Loading...'
    } = popup

    return (
      <div className={`new_folder_c_over_container  awesome__scroll_bar ${wrapperClass}`} style={popupStyle}>
        {isLoading !== undefined && isLoading ?
          <span>{loadingText}</span>
          :
          renderListItem()
        }
      </div>
    );
  }
  const renderPopOver = () => {
    const {
      renderCustomContainer, id,
      height="300px", width="200px", placement='right'
    } = popup

    const popup_props = {}
    if(id !== undefined){
      popup_props['id'] = id;
    }

    return (
      <Popover
        open={open}
        anchorEl={anchorActionMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: placement
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: placement
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 2px 3px rgba(0,0,0,0.5)",
            width: width,
            height: height
          }
        }}
        {...popup_props}
      >
        {renderCustomContainer !== undefined && renderCustomContainer}
        {renderCustomContainer === undefined && renderMenuList()}
      </Popover>
    );
  }

  return (
    <div className={`new_folder_c_menu_list ${wrapperClass}`} onClick={(e) => e.stopPropagation()} style={wrapperStyle}>
      {label === undefined && renderDefaultMenuLabel()}
      {label !== undefined && renderMenuLabel()}

      {popup !== undefined && renderPopOver()}
    </div>
  );
}
export default CustomMenuPype;
/* 
props: 
    wrapperClass
    wrapperStyle
    isOpen: false/true
    onClose: func
    label: {
        leftIcon: svg icon
        rightIcon: svg icon
        text: string
        className: string
        renderCustom: component
        labelStyle: object
    }
    popup: {
        wrapperClass: string
        id: string
        popupStyle: object
        list: array -> {label, icon}
        listStyle: object
        renderList: component
        optionClass: string
        labelField: string
        onClick: func
        renderCustomContainer: component
        height: px
        width: px
        placement: left/right/center
    },
    isLoading: true/false
    loadingText: string,
    onClickCallback: () => {}, //when click on menu icon
*/