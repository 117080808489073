export const isEmpty = (text) => {
  if(text !== undefined && text != null && text !== "null" && text.trim() !== ""){
    return false;
  }
  return true;
}
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
      var intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
}
export const calculateCash = (amount, fixedCount = 2) => {
  var flag = ''
  // 1000 = 1K
  // 1000000 = 1M = 1K * 1000
  // 1M * 1000 = 1B
  // 1B * 1000 = 1T
  if (amount >= 1000000000000000000000000) {
    amount = amount / 1000000000000000000000000
    flag = 'Y'
  }
  if (amount >= 1000000000000000000000) {
    amount = amount / 1000000000000000000000
    flag = 'Z'
  }
  if (amount >= 1000000000000000000) {
    amount = amount / 1000000000000000000
    flag = 'E'
  }
  if (amount >= 1000000000000000) {
    amount = amount / 1000000000000000
    flag = 'P'
  }
  if (amount >= 1000000000000) {
    amount = amount / 1000000000000
    flag = 'T'
  }
  else if (amount >= 1000000000) {
    amount = amount / 1000000000
    flag = 'B'
  }
  else if (amount >= 1000000) {
    amount = amount / 1000000
    flag = 'M'
  }
  else if (amount >= 1000) {
    amount = amount / 1000
    flag = 'K'
  }
  // return '$' + (Math.round(totalDeal * 100) / 100) + flag
  if(amount != null){
      return '$' + amount.toFixed(fixedCount) + flag
  }
  return '$' + amount + flag
}