import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Chip,
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import DateRangePicker from "../dateRangePicker";
import { CustomScrollbar } from "../productsStyle";
import TeamBarChart from "./TeamBarChart";
import { teamSalesReport } from "../../../api/productsApi";
import { useSelector } from "react-redux";
import Utils from "../../../helpers/Utils";
import GlobalUserListDropdown from "../common/UserListDropdown/Index";
import TeamSalesReportSkeleton from "./TeamSalesReportSkeleton";
import EmptyTeamSales from "./EmptyTeamSales";
import { onSelectUser, userTimeSelectConverter } from "../helper/functions";
import { USER_SELECTOR } from "../helper/coreConstant";
import TeamSalesDrawer from "./TeamSalesDrawer";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 300,
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "#2c3e50",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#346fef",
      borderRadius: "10px",
    },
  },
  tableHead: {
    fontWeight: 600,
    fontSize: "16px",
    color: "white",
    borderRadius: "0px !important",
    backgroundColor: "#346fef",
  },
});

const Wrapper = styled("div")(({ theme }) => ({
  padding: 16,
  background: "white",
  borderRadius: "8px",
  minHeight: "calc(100vh - 180px)",

  "& .teamSalesHeader": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    "& .filter": {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
  },

  "& .teamChart": {
    width: "100%",
    display: "flex",
    gap: 40,

    "& .teamList": {
      flex: 1,
      maxHeight: 420,
      overflowY: "auto",
      marginBottom: 16,

      "& .teamItem": {
        borderRadius: 8,
        padding: "12px 24px",
        background: "#e9e9e9",
        cursor: "pointer",
        color:"#133159",

        "&:not(:last-child)": {
          marginBottom: 8,
        },

        "&.active": {
          background: "#346fef",
          color: "white",
        },
      },
    },
    "& .teamChartList": {
      flex: 2,
    },
  },

  "& .tableWrapper": {
    "& .MuiTableHead-root": {
      color: "white",
      backgroundColor: "#e9e9e9",
      fontWeight: 600,

      "& .MuiTableCell-head": {
        fontWeight: 600,
      },
      root: {
        width: '100%',
      },
      container: {
        maxHeight: 440,
      },
    },
  },
}));

const TeamSalesReport = ({ assetsLoaded }) => {
  const { teamUsers } = useSelector((state) => state.productReducer);
  const [activeMember, setActiveMember] = useState(null) ;
  const [resetClosedRange, setResetClosedRange] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([USER_SELECTOR.ALL]);
  const [selectedUserIds, setSelectedUserIds] = useState([USER_SELECTOR.ALL]);
  const classes = useStyles();
  const activeItemRef = useRef(null);
  const [reportData, setReportData] = useState(null);
  const [formData, setFormData] = useState({
    dealClosedByIds: [],
    dealCloseStartDate: null,
    dealCloseEndDate: null
  });
  const [isLoading, setIsLoading] = useState(true);
  const [drawer, setDrawer] = useState(null);

  useEffect(()=>{
    if(teamUsers[0]){
      setActiveMember(teamUsers[0].id);
    }
  },[teamUsers]);

  useEffect(() => {
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [activeMember]);

  useEffect(() => {
    if (assetsLoaded) {
      handleTeamSalesReport().then();

    }
  }, [formData, assetsLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const formattedUsers = teamUsers.map((user) => ({ value: user.id, label: user.full_name }))

  const handleTeamSalesReport = async () => {
    try {
      let submitData = {
        dealCloseStartDate: formData.dealCloseStartDate ? userTimeSelectConverter(formData.dealCloseStartDate) : null,
        dealCloseEndDate: formData.dealCloseEndDate ? userTimeSelectConverter(formData.dealCloseEndDate) : null,
        dealClosedByIds: formData.dealClosedByIds,
      }

      if (selectedUserIds[0] === USER_SELECTOR.ALL) {
        submitData.dealClosedByIds = teamUsers.map((team) => (team.id));
      }
      setIsLoading(true);
      const resp = await teamSalesReport(submitData);

      if (resp && resp.success && resp.data[0]) {
        setReportData(resp.data);
      } else {
        setReportData([]);
      }
    } catch (err) {
      console.log(err);
      setReportData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleActiveMember = (id) => {
    if (activeMember === id) {
      setActiveMember(null);
      return;
    }
    setActiveMember(id);
  };

  const handleSelectUser = (item, remove) => {
    onSelectUser(item, remove, selectedUsers, selectedUserIds, (tempUsers, tempUserIds) => {
      setSelectedUsers(tempUsers);
      setSelectedUserIds(tempUserIds);

      if (tempUserIds[0] === USER_SELECTOR.ALL) {
        tempUserIds = teamUsers.map((user) => (user.id));
      }

      setFormData((prevState) => ({ ...prevState, dealClosedByIds: tempUserIds, page: 1 }));
    });
  };

  const member = (id) => {
    try {
      const fMember = teamUsers.find((team) => (team.id === id));

      if (fMember) {
        return fMember.full_name;
      }

      return "Unknown";
    } catch (err) {
      return "Unknown";
    }
  };

  return (
    <Wrapper>
      <div className="teamSalesHeader">
        <Typography variant="body1" style={{ color: "#346fef" }}>
          Team Members
        </Typography>

        <div className="filter">
          <GlobalUserListDropdown
            userList={formattedUsers}
            handleSelect={(value, remove) => {
              handleSelectUser(value, remove);
            }}
            selected={selectedUsers}
            selectedId={selectedUserIds}
          />

          <DateRangePicker
            style={{ right: 0 }}
            reset={resetClosedRange}
            updateReset={() => setResetClosedRange(false)}
            callback={(data) => {
              setFormData((prevData) => ({
                ...prevData,
                dealCloseStartDate: data.startDate,
                dealCloseEndDate: data.endDate
              }));
            }}
          />
        </div>
      </div>

      {
        (isLoading) ?
          <TeamSalesReportSkeleton /> :
          (!reportData || !reportData[0]) ? <EmptyTeamSales /> :
            <Fragment>
              <div className="teamChart">
                <div className="teamList" style={{ color: "#346fef" }}>
                  <CustomScrollbar style={{ maxHeight: 350 }}>
                    {
                      reportData.map((item, i) => (
                        <div
                          className={`teamItem ${activeMember === item.dealClosedBy ? "active" : ""
                            }`}
                          key={i}
                          onClick={() => handleActiveMember(item.dealClosedBy)}
                          ref={activeMember === item.dealClosedBy ? activeItemRef : null}
                        >
                          {member(item.dealClosedBy)}
                        </div>
                      ))
                    }
                  </CustomScrollbar>
                </div>

                <div className="teamChartList">
                  <TeamBarChart
                    data={reportData}
                    activeMember={activeMember}
                    setActiveMember={setActiveMember}
                  />
                </div>
              </div>

              <div className={classes.root}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHead}>Team Member</TableCell>
                        <TableCell style={{textAlign:'center'}} className={classes.tableHead}>Value of Deals Closed</TableCell>
                        <TableCell style={{textAlign:'center'}} className={classes.tableHead}>Total Products/Services Sold</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        reportData.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography
                                variant="body2"
                                style={{ cursor: "pointer" }}
                                 onClick={() => setDrawer(row)}
                              >
                                {member(row.dealClosedBy)}
                              </Typography>
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                              {row.totalDealValue ? (
                                <Chip
                                  style={{ color: '#346fef', backgroundColor: 'rgba(52, 111, 239, 0.05)', fontWeight: 600,}}
                                  size="small"
                                  label={Utils.formatCurrency(row.totalDealValue, "$")}
                                  clickable
                                  color="primary"
                                  className="valueChip"
                                   onClick={() => setDrawer(row)}
                                />
                              ) : ''}
                            </TableCell>

                            <TableCell style={{textAlign:'center'}} 
                             onClick={() => setDrawer(row)}
                            >{row.totalDealCount}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              { 
               drawer && 
                <TeamSalesDrawer
                  teamUsers={teamUsers}
                  open={drawer}
                  onClose={() => setDrawer(null)}
                  item={drawer}
                  formData={formData}
                  selectedUserIds={selectedUserIds}
                />
              }
            </Fragment>
      }
    </Wrapper>
  );
};

export default TeamSalesReport;
