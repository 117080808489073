// custom field
export const FETCH_CUSTOM_FIELD = "FETCH_CUSTOM_FIELD";
export const FETCH_VIRTUAL_NUMBER = "FETCH_VIRTUAL_NUMBER";
export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const GET_OTHER_EMAIL_BY_CONTACT = "GET_OTHER_EMAIL_BY_CONTACT";

// deals
export const FETCH_DEALS = "FETCH_DEALS";

// pipeline
export const FETCH_PIPELINES = "FETCH_PIPELINES";
export const FETCH_USER_PIPELINES = "FETCH_USER_PIPELINES";
export const REORDER_DEAL_STAGE = "REORDER_DEAL_STAGE";
export const FETCH_DEAL_BY_SCROLL = "FETCH_DEAL_BY_SCROLL";
export const CHANGED_DEAL_STATUS = "CHANGED_DEAL_STATUS";
export const GET_ALL_STAGE_BY_PIPELINE = "GET_ALL_STAGE_BY_PIPELINE";
export const DEAL_MOVE_TO_STAGE = "DEAL_MOVE_TO_STAGE";
export const GET_ALL_DEALS_TABLE_VIEW = "GET_ALL_DEALS_TABLE_VIEW";
export const GET_DEAL_STAGE_CHART_VIEW = "GET_DEAL_sTAGE_CHART_VIEW";
export const GET_DEAL_sTAGE_CHART_VIEW_BY_FILTER =
  "GET_DEAL_sTAGE_CHART_VIEW_BY_FILTER";

export const CONTACT_SEARCH = "CONTACT_SEARCH";
export const ADD_NEW_DEAL = "ADD_NEW_DEAL";

export const GET_LEAD_SOURCE = "GET_LEAD_SOURCE";
export const STORE_NOTE = "STORE_NOTE";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const GET_CONVERSATION = "GET_CONVERSATION";
export const UPDATE_CONTACT_FIELD = "UPDATE_CONTACT_FIELD";
export const SEND_CONVERSATION = "SEND_CONVERSATION";
export const SEND_BULK_EMAIL = "SEND_BULK_EMAIL";
export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const REMOVE_CONTACT_TAG = "REMOVE_CONTACT_TAG";
export const ADD_CONTACT_TAGS = "ADD_CONTACT_TAGS";
export const GET_CONTACT_DETAILS = "GET_CONTACT_DETAILS";
export const UPDATE_CONTACT_CUSTOM_FIELD = "UPDATE_CONTACT_CUSTOM_FIELD";
export const UPDATE_CONTACT_SOURCE = "UPDATE_CONTACT_SOURCE";
export const GET_ALL_CAMPAIGN_BY_CONTACT = "GET_ALL_CAMPAIGN_BY_CONTACT";
export const GET_ELIGIBLE_CAMPAIGN = "GET_ELIGIBLE_CAMPAIGN";
export const MOVE_CAMPAIGN_CONTACT = "MOVE_CAMPAIGN_CONTACT";

export const CAMPAIGN_SINGLE_CONTACT_RUN = "CAMPAIGN_SINGLE_CONTACT_RUN";
export const CAMPAIGN_SINGLE_CONTACT_PAUSE = "CAMPAIGN_SINGLE_CONTACT_PAUSE";
export const CAMPAIGN_SINGLE_CONTACT_UNSUBSCRIBED =
  "CAMPAIGN_SINGLE_CONTACT_UNSUBSCRIBED";

export const GET_PEOPLE_ACTIVITY = "GET_PEOPLE_ACTIVITY";
export const MARK_AS_DONE = "MARK_AS_DONE";
export const SUBMIT_USER_ACTIVITY = "SUBMIT_USER_ACTIVITY";
export const DELETE_CONTACT_FROM_STAGE = "DELETE_CONTACT_FROM_STAGE";

// store reduce data
export const STORE_PIPELINE_ID = "STORE_PIPELINE_ID";
export const STORE_DEAL_STATUS = "STORE_DEAL_STATUS";
export const STORE_DEATE_RANGER = "STORE_DEATE_RANGER";
export const STORE_SEACRCH_TEXT = "STORE_SEACRCH_TEXT";
export const STORE_DEAL_CONTACT = "STORE_DEAL_CONTACT";
export const RELOAD_DEAL_STAGE_FLAG = "RELOAD_DEAL_STAGE_FLAG";
export const STORE_LOGGED_ON_USER = "STORE_LOGGED_ON_USER";
export const STORE_PIPELINE_LIST = "STORE_PIPELINE_LIST";

/* deal module v2 */
export const UPDATE_DEAL_FILTER_OPTIONS = "UPDATE_DEAL_FILTER_OPTIONS";
export const STORE_SELECTED_DEAL = "STORE_SELECTED_DEAL";
export const NEED_TO_RELOAD_DEAL_STAGE = "NEED_TO_RELOAD_DEAL_STAGE";
export const RELOAD_SPECIFIC_STAGE = "RELOAD_SPECIFIC_STAGE";

/* for user wise filter */
export const STORE_USER_LIST = "STORE_USER_LIST";
export const SELECT_A_USER = "SELECT_A_USER";
