import React, { createContext } from "react";
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import {BrowserRouter as Router} from 'react-router-dom';
import { createGenerateClassName, StylesProvider } from "@material-ui/styles";

const defaultHistory = createBrowserHistory();
export const HistoryProvider = createContext({});

const generateClassName = createGenerateClassName({
    productionPrefix: 'deal',
});

function App({ history = defaultHistory }) {
    return (
      <HistoryProvider.Provider value={history}>
        <Router history={history}>
            <StylesProvider generateClassName={generateClassName}>
                <Routes />
            </StylesProvider>
        </Router>
      </HistoryProvider.Provider>
    );
}
export default App;


