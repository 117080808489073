import React from 'react';
import Helper from '../../../../helpers/Helper';
import BootstrapTooltip from '../../../globals/BootstrapTooltip';
import moment from 'moment'
import Utils from '../../../../helpers/Utils';
import { Draggable } from 'react-beautiful-dnd';

const EachDeal = (props) => {
    let name = ''
    if(Helper.isValidText(props.data.first_name) &&  Helper.isValidText(props.data.last_name)){
        name = props.data.first_name + ' ' + props.data.last_name 
    }
    else if(Helper.isValidText(props.data.first_name)){
        name = props.data.first_name
    }
    else if(Helper.isValidText(props.data.last_name)){
        name = props.data.last_name
    }
    if(name.trim() == ''){
        if(props.data.email != ''){
            name = props.data.email
        }
        else{
            name = Utils.formatPhoneNumber(props.data.number)
        }
    }

    return(
        <Draggable
            key={props.data.contact_stages_id}
            draggableId={`draggable-${props.data.contact_stages_id}`}
            index={props.index}
        >
            {(provided, snapshot) => (
                <div 
                    className={`update__dragbale__v2`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                        backgroundColor: snapshot.isDragging ? "lightgreen" : "",
                        ...provided.draggableProps.style
                    }}
                >
                    <div className="each__deal__container__v2">
                        <div className="each__deal__container__v2__left">
                            <BootstrapTooltip
                                title={
                                    <React.Fragment>
                                        <p>{name}</p>
                                        <p><b>{'Title: '}</b>{props.data.title !== null ? props.data.title : 'No title provided!'}</p>
                                    </React.Fragment>
                                }
                                arrow
                            >
                                <span 
                                    className="" 
                                    onClick={() => props.handleAddDealModal({editData: props.data, status: true, reload: false})}
                                >{name}</span>
                            </BootstrapTooltip>
                            <p>Added {moment(props.data.moved_at).format('MMM DD, YYYY')}</p>
                            <a className="" href="#">{Utils.calculateCash(props.data.deal_value)}</a>
                        </div>
                        <div className="each__deal__container__v2__right">
                            <a className="accent--bg--text--color" href="javascript:void(0)" onClick={props.handleMoreOptionModal}><i className="material-icons">keyboard_arrow_right</i></a>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    )
}
export default EachDeal;