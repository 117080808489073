import * as ACTION from "../constants/pipelineActionTypes";

export const storePipelineId = (payload) => ({
    type: ACTION.SELECTED_PIPELINE_ID,
    payload: payload
});

export const storePipelines = (payload) => ({
    type: ACTION.STORE_PIPELINES,
    payload: payload
});

export const reloadPipeline = (payload) => ({
    type: ACTION.RELOAD_PIPELINE_FLAG,
    payload: payload
});

export const reloadStageList = (payload) => ({
    type: ACTION.RELOAD_STAGE_LIST,
    payload: payload
});

export const handleSroteData = (payload) => ({
    type: ACTION.HANDLE_STORE_DATA,
    payload: payload
});