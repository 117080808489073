import React, {createContext, useContext, useReducer, useState} from "react";
import Notification from "./Notification";

const NotificationContext = createContext();

const NotificationProvider = (props) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "ADD_NOTIFICATION":
        return [...state, {...action.payload}];
      case "REMOVE_NOTIFICATION":
        return state.filter(el => el.id !== action.id);
      default:
        return state
    }
  }, []);
  
  /* notification-wrapper: topCenter, center, topRight, bottomRight, centerRight, bottomLeft, topLeft, centerLeft */
  const position = ''
  return(
    <NotificationContext.Provider value={dispatch}>
      <div className={`notification-wrapper`}>
        {state.map((note) => {
          return <Notification dispatch={dispatch} key={note.id} {...note} />
        })}
      </div>
      {props.children}
    </NotificationContext.Provider>
  )
};

export const useNotification = () => {
  const dispatch = useContext(NotificationContext);
  let _id = Math.floor(Math.random() * (999999999 - 9 + 1)) + 9;

  return (props) => {
    dispatch({
      type: "ADD_NOTIFICATION",
      payload: {
        // id: v4(),
        id: _id,
        ...props
      }
    })
  }
};

export default NotificationProvider;
