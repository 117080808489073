import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Brush, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { fetchPipelineApi, getAllStageContactsGraphV2Api, getSubUserListV2Api } from '../../../../../../api/dealApi';
import Utils from '../../../../../../helpers/Utils';
import { DateTimeFilters, TimeIntervals, UserFilters } from '../../helpers/Constants';
import { ArrowDownIcon } from '../../helpers/IconList';
import CustomMenuPype from '../custom-menu/CustomMenuPype';
import Styles from './style.module.css'
import { useIsMounted } from '../../../../../../hooks/IsMounted';

const customTooltip = ({ payload, label, active }) => {
  if (active) {
    return (
      <div className={Styles.tooltipWrp}>
        <div className={Styles.tooltipTimeWrp}>
          <small className={Styles.tooltipTimeLabel}>Stage: </small>
          <code className={Styles.tooltipTimeText}>{label}</code>
        </div>
        <div className={Styles.tooltipDesWrp}>
          <code className={`${Styles.tooltipTimeText}`}>{payload[0].value}</code>
          <small className={Styles.tooltipTimeLabel}> contact(s) with</small>
          <small className={Styles.tooltipTimeLabel}> deal value </small>
          <code className={`${Styles.tooltipTimeText}`}>{Utils.calculateCash(payload[1].value)}</code>
        </div>
      </div>
    );
  }
  return null;
}

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};
TriangleBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};
const customValueYAxis = ({ x, y, payload }) => {
  return (
    <text x={x} y={y} fill={"#82ca9d"} >
      {Utils.calculateCash(payload.value)}
    </text>
  );
}

const StageContactDealApp = () => {
  const isMount = useIsMounted()
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pipeline, setPipeline] = useState(null);
  const [date, setDate] = useState('last_7_days');
  const [pipelineList, setPipelineList] = useState([]);
  useEffect(() => {
    getPipelineList();
  }, [])
  useEffect(() => {
    if(isMount){
      getData()
    }
  }, [date, pipeline])  
  const getData = () => {
    setIsLoading(true)
    let form_data = {
      day_filter: date,
      type: 'stage_total_contact_with_deal_value',
    }
    if(pipeline != null){
      form_data['pipeline_id'] = pipeline.id
    }
    getAllStageContactsGraphV2Api(form_data).then(res => {
      let response = res.data;
      if(response.status === "success"){
        setData(response.data)
      }
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
    })
  }
  const getPipelineList = () => {
    fetchPipelineApi().then(res => {
      let response = res.data;
      setPipelineList(response.data)
      if(response.data.length > 0){
        setPipeline(response.data[0])
      }
    }).catch(error => {})
  }
  const renderLoading = () => {
    return (
      <div className={Styles.loadingWrp}>
        <span>Loading...</span>
        <span>Please wait</span>
      </div>
    );
  }
  const renderGraph = () => {
    return (
      <div className={Styles.graphWrp}>
        <ResponsiveContainer className={Styles.graphWrpResponsive}>
          <BarChart
            width={500}
            height={300}
            data={data.length > 0 ? data : dummy_data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            id="stage_wise_contact_with_deal_value"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis allowDecimals={false} mirror={false} yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis mirror={false} yAxisId="right" orientation="right" tick={customValueYAxis} stroke="#82ca9d" />
            <XAxis allowDataOverflow={false} dataKey="stage" />
            <Tooltip content={customTooltip} />
            <Bar yAxisId="left"  dataKey="total_contacts" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top', display: '', fontSize: '10px', fill:'#8884d8' }} />
            <Bar yAxisId="right" dataKey="total_deal_value" fill="#82ca9d" shape={<TriangleBar />} label={{ position: 'top', display: '', fontSize: '10px', fill:'#82ca9d' }} />
            {/* <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '20px' }} /> */}
            <Brush className={'brush'} alwaysShowText={true} dataKey="stage" height={20} stroke="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
        {data.length === 0 && <div className={Styles.emptyScreen}>No report data found !</div>}
      </div>
    );
  }
  return (
    <div className={Styles.mainWrp}>
      <div className={Styles.headerWrp}>
        <div className={Styles.headerLeft}>
          <span className={Styles.headerText}>Stage-wise report</span>
          <div className={Styles.headerHelpWrp}>
            <div className={Styles.headerHelp}><span></span><span>Contact</span></div>
            <div className={Styles.headerHelp}><span></span><span>Value</span></div>
          </div>
        </div>
        <div className={Styles.filterWrp}>
          {/* pipeline filter */}
          <CustomMenuPype
            popup= {{
              placement: 'center',
              list: pipelineList,
              onClick: (item) => {
                if(item === pipeline){
                  return;
                }
                setPipeline(item)
              },
              height: 'auto',
              width: 'auto',
              optionClass: Styles.timeFilterOption,
              wrapperClass: Styles.timeFilterWrp,
              labelField: 'title',
              renderList:(item, index)=>{
                return (
                  <div key={index}>
                    <span>{item.title}</span>
                    <small><code> ({item.user.full_name})</code></small>
                  </div>
                );
              },
              renderListWithDefaultClick:true
            }}
            label= {{
              renderCustom: (
              <div className={Styles.timeFilterLabelWrp}>
                <span>{pipeline?.title.toUpperCase()}</span>
                <span style={{display: 'inline-flex'}}><ArrowDownIcon /></span>
              </div>
              )
            }}
          />
          {/* time interval filter */}
          <CustomMenuPype
            popup= {{
              placement: 'center',
              list: TimeIntervals,
              onClick: (item) => {
                if(item.value === date){
                  return;
                }
                setDate(item.value)
              },
              height: 'auto',
              width: 'auto',
              listStyle: {
                justifyContent: 'center'
              },
              optionClass: Styles.timeFilterOption,
              wrapperClass: Styles.timeFilterWrp
            }}
            label= {{
              renderCustom: (
              <div className={Styles.timeFilterLabelWrp}>
                <span>{date.replace(/_/g, ' ').toUpperCase()}</span>
                <span style={{display: 'inline-flex'}}><ArrowDownIcon /></span>
              </div>
              )
            }}
          />
        </div>
      </div>
      {isLoading ? renderLoading() : renderGraph()}
    </div>
  );
}
export default StageContactDealApp;

const dummy_data = [
  {
    stage: 'Stage 1',
    uv: 4000,
    total_contacts: 2400,
    total_deal_value: 2400,
  },
  {
    stage: 'Stage 2',
    uv: 3000,
    total_contacts: 1398,
    total_deal_value: 2210,
  },
  {
    stage: 'Stage 3',
    uv: 2000,
    total_contacts: 9800,
    total_deal_value: 2290,
  },
  {
    stage: 'Stage 4',
    uv: 2780,
    total_contacts: 3908,
    total_deal_value: 2000,
  },
  {
    stage: 'Stage 5',
    uv: 1890,
    total_contacts: 4800,
    total_deal_value: 2181,
  },
  {
    stage: 'Stage 6',
    uv: 2390,
    total_contacts: 3800,
    total_deal_value: 2500,
  },
  {
    stage: 'Stage 7',
    uv: 3490,
    total_contacts: 4300,
    total_deal_value: 2100,
  },
  {
    stage: 'Stage 8',
    uv: 3490,
    total_contacts: 4300,
    total_deal_value: 2100,
  },
  {
    stage: 'Stage 9',
    uv: 3490,
    total_contacts: 4300,
    total_deal_value: 2100,
  },
  {
    stage: 'Stage 10',
    uv: 3490,
    total_contacts: 4300,
    total_deal_value: 2100,
  },
];