/* VIDEO EMAIL START */
export const FETCH_VIDEO_FOLDERS = 'FETCH_VIDEO_FOLDERS';
export const STORE_VIDEO_FOLDERS = 'STORE_VIDEO_FOLDERS';
export const FETCH_VIDEOS = 'FETCH_VIDEOS';
export const STORE_VIDEOS = 'STORE_VIDEOS';
export const DELETE_VIDEOS = 'DELETE_VIDEOS';
export const UPDATE_FOLDER_ID = 'UPDATE_FOLDER_ID';
export const FETCH_CUSTOM_FIELD = 'FETCH_CUSTOM_FIELD';
export const STORE_CUSTOM_FIELD = 'STORE_CUSTOM_FIELD';
export const CHANGE_IS_VIDEO_PLAYING = 'CHANGE_IS_VIDEO_PLAYING';
export const CHANGE_LIKE_STATUS = 'CHANGE_LIKE_STATUS';
export const CHANGE_SAVE_AS_TEMPLATE = 'CHANGE_SAVE_AS_TEMPLATE';
/* VIDEO EMAIL END */

/* MESSAGE TEMPLATE START */
export const FETCH_MESSAGE_TEMPLATES = 'FETCH_MESSAGE_TEMPLATES';
export const STORE_MESSAGE_TEMPLATE = 'STORE_MESSAGE_TEMPLATE';
/* MESSAGE TEMPLATE END */

export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL';
