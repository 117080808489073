import React from "react";
import { MenuItem, Select, makeStyles } from "@material-ui/core";
import { BasicInputSelect } from "./GlobalAddDealModalStyles";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        "&:hover": {
            backgroundColor: "#cbd4ff",
            color: "#133159",
        },
        "&.Mui-selected": {
            backgroundColor: "#cbd4ff",
            color: "#133159",
        },
    },
    menuItemLineThrough: {
        "&.MuiListItem-root": {
            textDecoration: "line-through",
            color: "#ee1b54",
        },
    },
    deleted: {
        fontSize: "9px"
    }
}));

const BasicSelect = ({ options, mapping = { label: "title", value: "id" }, defaultText = "Select", name = "default", onChange, value = "", disabled = false, selectedOption, deletedMapping }) => {
    const styles = useStyles();
    const handleSelectChange = (event) => {
        onChange(event);

        if (selectedOption) {
            const selected = options.find((option) => (option[mapping.value] === event.target.value));
            if (selected) {
                selectedOption(selected);
            }
        }
    };

    const menuItems = () => {

        return options.map((option, index) => {

            if (deletedMapping && option[deletedMapping.field] === deletedMapping.value) {

                if (value === option[mapping.value]) {
                    return (
                        <MenuItem disabled={(deletedMapping && option[deletedMapping.field] === deletedMapping.value)} className={`${styles.menuItem} dropdownhelper-menuitem-class ${(deletedMapping && option[deletedMapping.field] === deletedMapping.value) ? styles.menuItemLineThrough : ""}`} key={index} value={option[mapping.value]}>
                            {option[mapping.label]}
                            {(deletedMapping && option[deletedMapping.field] === deletedMapping.value) && (<span className={styles.deleted}>(deleted)</span>)}
                        </MenuItem>
                    )
                } else {
                    return null;
                }

            } else {
                return (
                    <MenuItem className={`${styles.menuItem} dropdownhelper-menuitem-class`} key={index} value={option[mapping.value]}>
                        {option[mapping.label]}
                    </MenuItem>
                )
            }

        }
        )


    }


    return (
        <Select
            fullWidth
            defaultValue={value}
            value={value}
            onChange={handleSelectChange}
            name={name}
            displayEmpty
            disabled={disabled}
            input={<BasicInputSelect />}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                PaperProps: {
                    style: {
                        maxHeight: "280px"
                    },
                },
                getContentAnchorEl: null,
            }}
        >
            <MenuItem className={`${styles.menuItem} dropdownhelper-menuitem-class`} value="" disabled>
                {defaultText}
            </MenuItem>
            {menuItems()}
        </Select>
    );
}

export default BasicSelect;
