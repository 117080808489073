import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAllPipeline } from "../../../actions/DealAction";
import {
  handleSroteData,
  reloadPipeline,
  storePipelineId,
  storePipelines,
} from "../../../actions/PipelineReducerAction";
import { storePipelineId as storePipelineIdForDeal } from "../../../actions/DealReducerAction";
import Loading from "../../Common/Loading";
import AddPipeline from "./Modals/AddPipeline";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { confirmAlert } from "react-confirm-alert";
import {
  checkPipelineDeleteOrNot,
  deletePipelineAndMove,
  updatePipelineShowOption,
} from "../../../actions/PipelineAction";
import Utils from "../../../helpers/Utils";
import MovePipeline from "./Modals/MovePipeline";
import AddStage from "./Modals/AddStage";
import CustomSwitch from "../../Common/Switch";
import { Link, useLocation } from "react-router-dom";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import Add from "@material-ui/icons/Add";
import CustomMenuPype from "../../custom-menu/CustomMenuPype";
import { DownArrowIcon } from "../icons/DealIconList";
import "./header-style.css";
import CustomConfirmAlert from "../../confirm-alert/CustomConfirmAlert";

const mapStateToProps = (state) => ({
  needToReloadPipeline: state.PipelineReducer.needToReloadPipeline,
  seletedPipelineId: state.PipelineReducer.seletedPipelineId,
  addPipeline: state.PipelineReducer.addPipeline,
  editPipeline: state.PipelineReducer.editPipeline,
  selectedPipelineIdFromDeal: state.dealReducer.pipelineIdInDealList,
});
const mapDispatchToProps = (dispatch) => ({
  getAllPipeline: (params, callback) =>
    dispatch(getAllPipeline(params, callback)),
  storePipelineId: (params, callback) =>
    dispatch(storePipelineId(params, callback)),
  storePipelineIdForDeal: (params, callback) =>
    dispatch(storePipelineIdForDeal(params, callback)),
  storePipelines: (params, callback) =>
    dispatch(storePipelines(params, callback)),
  checkPipelineDeleteOrNot: (params, callback) =>
    dispatch(checkPipelineDeleteOrNot(params, callback)),
  deletePipelineAndMove: (params, callback) =>
    dispatch(deletePipelineAndMove(params, callback)),
  reloadPipeline: (params) => dispatch(reloadPipeline(params)),
  updatePipelineShowOption: (params, callback) =>
    dispatch(updatePipelineShowOption(params, callback)),
  handleSroteData: (params) => dispatch(handleSroteData(params)),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const HeaderApp = (props) => {
  let queryParams = useQuery();

  const [addModal, setAddModal] = useState(false);
  const [pipelineList, setPipelineList] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState("");
  const [selectedPipelineTitle, setSelectedPipelineTitle] = useState("");
  const [visibleFunnel, setVisibleFunnel] = useState(1);
  const [visibleChart, setVisibleChart] = useState(1);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDefaultPipeline, setIsDefaultPipeline] = useState(null);
  const [pipelineSelectModal, setPipelineSelectModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [addStageModal, setAddStageModal] = useState(false);
  const [preSelectedPipelineId, setPreSelectedPipelineId] = useState(null);


  const getCookieData = () => {
    let name = `selectd_pipeline_id_in_deal:${Utils.getAccountData("userId")}=`;
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const checkCookie = (cname = `selectd_pipeline_id_in_deal:${Utils.getAccountData("userId")}`) => {
    return getCookieData(cname);
  };

  const generatePipelineDetails = (pipeline) =>{
    setSelectedPipeline(pipeline);
    setSelectedPipelineTitle(pipeline.title);
    setIsDefaultPipeline(pipeline.order);
    setVisibleChart(pipeline.visible_in_pie_chart);
    setVisibleFunnel(pipeline.visible_in_funnel_chart);
    setPreSelectedPipelineId(pipeline.id);
    props.storePipelineId(pipeline.id);
    props.storePipelineIdForDeal(pipeline.id);
    props.handleSroteData({ selectedPipeline: pipeline });
  }

  useEffect(() => {
    // fetch pipeline
    if (loading) return;
    setLoading(true);
    const preSelectedPipeline = checkCookie();
    props.getAllPipeline({}, (response) => {
      if (Utils.getAccountData("userIsAgent")) {
        handleResponse(response);
      } else {
        setPipelineList(response.data);
        props.storePipelines(response.data);
        if (response.data.length > 0) {
          if (props.selectedPipelineIdFromDeal != "") {
            // let filter_data = response.data.filter(
            //   (item) => item.id == props.selectedPipelineIdFromDeal
            // );
            if (response.data.length > 0) {
              let pipeLength = response.data.length;
              for (let i = 0; i < pipeLength; i++) {
                if (response.data[i].id == preSelectedPipeline) {
                  generatePipelineDetails(response.data[i]);
                  break;
                }
              }

            }
          } else if (
            queryParams.get("id") !== undefined &&
            queryParams.get("id") != null &&
            queryParams.get("id") !== ""
          ) {
            let pipeLength = response.data.length;
            for (let i = 0; i < pipeLength; i++) {
              if (response.data[i].id == queryParams.get("id")) {
                generatePipelineDetails(response.data[i]);
                break;
              }
            }
          }else if (preSelectedPipeline !== null || preSelectedPipeline !== '') {
            let pipeLength = response.data.length;
            for (let i = 0; i < pipeLength; i++) {
              if (response.data[i].id == preSelectedPipeline) {
                generatePipelineDetails(response.data[i]);
                break;
              }
            }
          } else {
            generatePipelineDetails(response.data[0]);
          }
        }
        setLoading(false);
      }
    });
  }, []);

  const handleResponse = (response) => {
    const listData = [];
    response.data.forEach((item) => {
      if (item.user.role_user.role_id === 3) {
        if (item.user_id === Utils.getAccountData("userId")) {
          listData.push(item);
        }
      } else {
        listData.push(item);
      }
    });
    setPipelineList(listData);
    props.storePipelines(listData);
    if (listData.length > 0) {
      if (props.selectedPipelineIdFromDeal != "") {
        let filter_data = listData.filter(
          (item) => item.id == props.selectedPipelineIdFromDeal
        );
        if (filter_data.length > 0) {
          setSelectedPipeline(filter_data[0]);
          setSelectedPipelineTitle(filter_data[0].title);
          setIsDefaultPipeline(filter_data[0].order);
          setVisibleChart(filter_data[0].visible_in_pie_chart);
          setVisibleFunnel(filter_data[0].visible_in_funnel_chart);
          props.storePipelineId(filter_data[0].id);
          props.storePipelineIdForDeal(filter_data[0].id);
          props.handleSroteData({ selectedPipeline: filter_data[0] });
        } else {
          setSelectedPipeline(listData[0]);
          setSelectedPipelineTitle(listData[0].title);
          setIsDefaultPipeline(listData[0].order);
          setVisibleChart(listData[0].visible_in_pie_chart);
          setVisibleFunnel(listData[0].visible_in_funnel_chart);
          props.storePipelineId(listData[0].id);
          props.storePipelineIdForDeal(listData[0].id);
          props.handleSroteData({ selectedPipeline: listData[0] });
        }
      } else if (
        queryParams.get("id") !== undefined &&
        queryParams.get("id") != null &&
        queryParams.get("id") !== ""
      ) {
        let pipeLength = listData.length;
        for (let i = 0; i < pipeLength; i++) {
          if (listData[i].id == queryParams.get("id")) {
            setSelectedPipeline(listData[i]);
            setSelectedPipelineTitle(listData[i].title);
            setIsDefaultPipeline(listData[i].order);
            setVisibleChart(listData[i].visible_in_pie_chart);
            setVisibleFunnel(listData[i].visible_in_funnel_chart);
            props.storePipelineId(queryParams.get("id"));
            props.storePipelineIdForDeal(queryParams.get("id"));
            props.handleSroteData({ selectedPipeline: listData[i] });
            break;
          }
        }
      } else {
        setSelectedPipeline(listData[0]);
        setSelectedPipelineTitle(listData[0].title);
        setIsDefaultPipeline(listData[0].order);
        setVisibleChart(listData[0].visible_in_pie_chart);
        setVisibleFunnel(listData[0].visible_in_funnel_chart);
        props.storePipelineId(listData[0].id);
        props.storePipelineIdForDeal(listData[0].id);
        props.handleSroteData({ selectedPipeline: listData[0] });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (props.needToReloadPipeline) {
      props.reloadPipeline({ flag: false });
      props.getAllPipeline({}, (response) => {
        setPipelineList(response.data);
        props.storePipelines(response.data);
        if (response.data.length > 0) {
          if (props.editPipeline) {
            let filter_data = response.data.filter(
              (item) => item.id == props.seletedPipelineId
            );
            setSelectedPipeline(filter_data[0]);
            setSelectedPipelineTitle(filter_data[0].title);
            setIsDefaultPipeline(filter_data[0].order);
            setVisibleChart(filter_data[0].visible_in_pie_chart);
            setVisibleFunnel(filter_data[0].visible_in_funnel_chart);
            props.reloadPipeline({ flag: false, editPipeline: false });
          } else {
            if (!props.addPipeline) {
              setSelectedPipeline(response.data[0]);
              setSelectedPipelineTitle(response.data[0].title);
              setIsDefaultPipeline(response.data[0].order);
              setVisibleChart(response.data[0].visible_in_pie_chart);
              setVisibleFunnel(response.data[0].visible_in_funnel_chart);
              props.reloadPipeline({ flag: false, addPipeline: false });
            } else {
              setSelectedPipeline(response.data[response.data.length - 1]);
              setSelectedPipelineTitle(
                response.data[response.data.length - 1].title
              );
              setIsDefaultPipeline(
                response.data[response.data.length - 1].order
              );
              setVisibleChart(
                response.data[response.data.length - 1].visible_in_pie_chart
              );
              setVisibleFunnel(
                response.data[response.data.length - 1].visible_in_funnel_chart
              );
            }
          }
        } else {
        }
      });
    }
  }, [props.needToReloadPipeline]);

  const handleAddPipelineModal = (show = true) => {
    setAddModal(show);
    setEdit(false);
  };

  const handlePipelineChange = (value) => {
    setSelectedPipeline(value);
    setPreSelectedPipelineId(value.id);
    props.storePipelineId(value.id);
    props.storePipelineIdForDeal(value.id);
    setSelectedPipelineTitle(value.title);
    setIsDefaultPipeline(value.order);
    setVisibleChart(value.visible_in_pie_chart);
    setVisibleFunnel(value.visible_in_funnel_chart);
    props.handleSroteData({ selectedPipeline: value });
  };

  const deletePipeline = () => {
    CustomConfirmAlert({
      onCancelSubmit: () => {
        props.checkPipelineDeleteOrNot(
          { pipelineId: selectedPipeline.id },
          (res) => {
            if (res.status === "error") {
              Utils.showNotification("res.data.html", "error");
              return false;
            }
            if (res.status === "success") {
              CustomConfirmAlert({
                onCancelSubmit: () => setPipelineSelectModal(true),
                onSubmit: () => deleteAndMove(),
                title:
                  "Do you want to move your existing deals to another pipeline?",
                cancelText: "Yes",
                submitText: "No",
                customCancelClass: "custom-confirm-button",
                customSubmitClass: "custom-cancel-button",
                titleCustomClass: "titleCustomClass",
              });
            }
          }
        );
      },
      title: "Are you sure you want to delete this pipeline?",
      description: "(Deleted pipelines cannot be restored)",
      cancelText: "Yes",
      submitText: "No",
      customCancelClass: "custom-confirm-button",
      customSubmitClass: "custom-cancel-button",
      titleCustomClass: "titleCustomClass",
    });
  };

  const deleteAndMove = () => {
    props.deletePipelineAndMove(
      {
        deletedPipelineId: selectedPipeline.id,
        moveDeal: false,
      },
      (res) => {
        if (res.status == "success") {
            actionAfterDelete(selectedPipeline.id);
        }
      }
    );
  };

  const actionAfterDelete = (pipeline) => {
    props.storePipelineId(pipeline.id);
    props.storePipelineIdForDeal(pipeline.id);
    setSelectedPipeline(pipeline);
    setSelectedPipelineTitle(pipeline.title);
    setIsDefaultPipeline(pipeline.order);
    props.reloadPipeline({ flag: true, addPipeline: false });
  };

  const handleEditModal = () => {
    setEdit(true);
    setAddModal(true);
  };

  const handlePipelineShowOption = (field = "", visibleFlag) => {
    if (submit) return;
    if (!hasAccessFlag()) {
      return;
    }
    if (field == "visible_in_pie_chart") {
      setVisibleChart(visibleFlag);
    } else if (field == "visible_in_funnel_chart") {
      setVisibleFunnel(visibleFlag);
    }
    setSubmit(true);
    props.updatePipelineShowOption(
      {
        field: field,
        pipeline_id: selectedPipeline.id.toString(),
        visible_in_chart: visibleFlag,
      },
      () => {
        setSubmit(false);
        props.reloadPipeline({ flag: true, editPipeline: true });
      }
    );
  };

  const renderDeleteButton = () => {
    try {
      if (pipelineList.length > 0) {
        if (Utils.getAccountData("userIsOwner")) {
          return (
            <BootstrapTooltip
              title='Delete This Pipeline'
              arrow
              placement='top'
            >
              <li
                className='ds__pipeline_action ds__pipeline_action_delete'
                style={{ cursor: "pointer" }}
                onClick={deletePipeline}
              >
                <DeleteIcon color='error' />
              </li>
            </BootstrapTooltip>
          );
        } else if (Utils.getAccountData("userIsAdmin")) {
          if (selectedPipeline.user.role_user.role_id !== 1) {
            return (
              <BootstrapTooltip
                title='Delete This Pipeline'
                arrow
                placement='top'
              >
                <li
                  className='ds__pipeline_action ds__pipeline_action_delete'
                  style={{ cursor: "pointer" }}
                  onClick={deletePipeline}
                >
                  <DeleteIcon color='error' />
                </li>
              </BootstrapTooltip>
            );
          }
        } else if (Utils.getAccountData("userIsAgent")) {
          if (selectedPipeline.user_id === Utils.getAccountData("userId")) {
            return (
              <BootstrapTooltip
                title='Delete This Pipeline'
                arrow
                placement='top'
              >
                <li
                  className='ds__pipeline_action ds__pipeline_action_delete'
                  style={{ cursor: "pointer" }}
                  onClick={deletePipeline}
                >
                  <DeleteIcon color='error' />
                </li>
              </BootstrapTooltip>
            );
          }
        }
      }
    } catch (error) {
      return null;
    }
    return null;
  };

  const renderEditOption = () => {
    if (selectedPipeline !== null && selectedPipeline !== "") {
      if (selectedPipeline.user_id === Utils.getAccountData("userId")) {
        return (
          <BootstrapTooltip title='Update Pipeline Title' arrow placement='top'>
            <li
              className='ds__pipeline_action accent--bg--text--color'
              style={{ cursor: "pointer" }}
              onClick={handleEditModal}
            >
              <EditIcon />
            </li>
          </BootstrapTooltip>
        );
      } else if (Utils.getAccountData("userIsOwner")) {
        return (
          <BootstrapTooltip title='Update Pipeline Title' arrow placement='top'>
            <li
              className='ds__pipeline_action accent--bg--text--color'
              style={{ cursor: "pointer" }}
              onClick={handleEditModal}
            >
              <EditIcon />
            </li>
          </BootstrapTooltip>
        );
      } else if (Utils.getAccountData("userIsAdmin")) {
        if (selectedPipeline.user.role_user.role_id !== 1) {
          return (
            <BootstrapTooltip
              title='Update Pipeline Title'
              arrow
              placement='top'
            >
              <li
                className='ds__pipeline_action accent--bg--text--color'
                style={{ cursor: "pointer" }}
                onClick={handleEditModal}
              >
                <EditIcon />
              </li>
            </BootstrapTooltip>
          );
        }
      }
    }
  };

  const renderAddStageOption = () => {
    if (hasAccessFlag()) {
      return (
        <li
          className='ds__alternative_select_pipeline alt common__round_circle accent--bg--text--color'
          onClick={() => setAddStageModal(true)}
        >
          <Add /> Add Stage
        </li>
      );
    }
  };

  const getPipelineList = () => {
    if (Utils.getAccountData("userIsAgent")) {
      const list = [];
      pipelineList.forEach((item) => {
        if (
          item.user.role_user.role_id === 3 &&
          item.user_id === Utils.getAccountData("userId")
        ) {
          list.push(item);
        } else if (item.user.role_user.role_id !== 3) {
          list.push(item);
        }
      });
      return list;
    }
    return pipelineList;
  };

  const renderNote = () => {
    if (
      selectedPipeline !== null &&
      selectedPipeline !== "" &&
      selectedPipeline.user_id !== Utils.getAccountData("userId")
    ) {
      return (
        <small style={{ color: "red" }}>
          <code>
            {" "}
            You can not update or delete anything for this pipeline !
          </code>
        </small>
      );
    }
    return null;
  };

  const hasAccessFlag = () => {
    if (selectedPipeline !== null && selectedPipeline !== "") {
      if (Utils.getAccountData("userIsOwner")) {
        return true;
      } else if (Utils.getAccountData("userIsAdmin")) {
        if (selectedPipeline.user.role_user.role_id !== 1) {
          return true;
        }
      } else if (Utils.getAccountData("userIsAgent")) {
        if (selectedPipeline.user_id === Utils.getAccountData("userId")) {
          return true;
        }
      }
    }
    return false; //"disalbe_option_view"; //disalbe_option_view
  };

  const renderSelectedPipeline = () => {
    if (selectedPipeline.user !== undefined && selectedPipeline.user != null) {
      if (selectedPipeline.user.full_name !== undefined) {
        return (
          <div className='drop-down'>
            <div className='label-wrp'>
              <span>
                {selectedPipeline.title}{" "}
                <small>{selectedPipeline.user.full_name}</small>
              </span>
            </div>
            <span className=''>
              <DownArrowIcon />
            </span>
          </div>
        );
      }
    }
    if (selectedPipeline.title !== undefined) {
      return (
        <div className='drop-down'>
          <div className='label-wrp'>
            <span>{selectedPipeline.title} </span>
          </div>
          <span className=''>
            <DownArrowIcon />
          </span>
        </div>
      );
    }
    return (
      <div className='drop-down'>
        <div className='label-wrp'>
          <span>{"Select a pipeline"}</span>
        </div>
        <span className=''>
          <DownArrowIcon />
        </span>
      </div>
    );
  };

  const renderDisableClass = hasAccessFlag() ? "" : "disalbe_option_view";

  return (
    <div className='deal-top-section alt'>
      <div className='mb-0 row'>
        <div className='col s12 m7 deal-catagory-section'>
          <ul>
            <li className='deal-title-wrapper ds__deal_title'>
              <span className='deal-title'>Pipeline</span>
            </li>
            <li
              className={` ${
                !Utils.getAccountData("userIsOwner") ? "mb-2" : ""
              }`}
            >
              {selectedPipelineTitle}
            </li>
            {renderEditOption()}
            {renderDeleteButton()}
            <li className='d-flex align-items-center ds__flex1'>
              <div className='ds__alternative'>
                <span
                  className={`common__round_circle mr-2 ${renderDisableClass}`}
                >
                  Visible in Funnel Chart
                  <CustomSwitch
                    status={visibleFunnel}
                    brandColor={"#f94f72"}
                    onChange={() =>
                      handlePipelineShowOption(
                        "visible_in_funnel_chart",
                        !visibleFunnel
                      )
                    }
                  />
                </span>
              </div>
              <div className='ds__alternative'>
                <span
                  className={`common__round_circle mr-2x ${renderDisableClass}`}
                >
                  Visible in Stage Distribution
                  <CustomSwitch
                    status={visibleChart}
                    brandColor={"#f94f72"}
                    onChange={() =>
                      handlePipelineShowOption(
                        "visible_in_pie_chart",
                        !visibleChart
                      )
                    }
                  />
                </span>
              </div>
            </li>
          </ul>
        </div>

        {!loading && (
          <div className='col s12 m5 deal-filtering-section'>
            <ul className='alt'>
              <li className='ds__deal_add_icon'>
                <Link className='pr-3' to={`/deal-stages`}>
                  {"<<"} Back To Deal
                </Link>
                <BootstrapTooltip title='New Pipeline' arrow placement='top'>
                  <a
                    className='rounded-icons'
                    href='#!'
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddPipelineModal();
                    }}
                  >
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14 7C14 7.64517 13.944 8.16667 13.2988 8.16667H8.16667V13.2988C8.16667 13.9428 7.64517 14 7 14C6.35483 14 5.83333 13.9428 5.83333 13.2988V8.16667H0.701166C0.0571664 8.16667 0 7.64517 0 7C0 6.35483 0.0571664 5.83333 0.701166 5.83333H5.83333V0.701166C5.83333 0.0559998 6.35483 0 7 0C7.64517 0 8.16667 0.0559998 8.16667 0.701166V5.83333H13.2988C13.944 5.83333 14 6.35483 14 7Z'
                        fill='#546376'
                      ></path>
                    </svg>
                  </a>
                </BootstrapTooltip>
              </li>
            </ul>
            <ul className='alter'>
              <li className='action-btn-dropdown dp_asp_alt'>
                <CustomMenuPype
                  wrapperClass='pipeline-drop-down'
                  popup={{
                    placement: "center",
                    list: getPipelineList(),
                    labelField: "title",
                    appendUser: true,
                    onClick: (item) => {
                      handlePipelineChange(item);
                    },
                    height: "auto",
                    width: "300px",
                    listStyle: {
                      //   justifyContent: "center",
                    },
                    popupStyle: {
                      maxHeight: "250px",
                    },
                  }}
                  label={{
                    renderCustom: renderSelectedPipeline(),
                    className: "drop-down-wrp",
                  }}
                />
              </li>
              {renderAddStageOption()}
            </ul>
          </div>
        )}
        <div className='col s12'>
          <div className='divider bottom-divider'></div>
        </div>
      </div>

      {addModal && (
        <AddPipeline
          open={addModal}
          onClose={handleAddPipelineModal}
          edit={edit}
          data={selectedPipeline}
        />
      )}

      {pipelineSelectModal && (
        <MovePipeline
          open={pipelineSelectModal}
          onClose={setPipelineSelectModal}
          deletedPipeline={selectedPipeline}
        />
      )}

      {addStageModal && (
        <AddStage open={addStageModal} onClose={setAddStageModal} preSelectedPipelineId={preSelectedPipelineId}/>
      )}
    </div>
  );
};
const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderApp);
export default Header;
