import { Box, styled, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import { CustomButton } from "../productService/content/productBody/CustomButton";
import AddOrEditSalesGoalModal from "./AddOrEditSalesGoalModal";

const HeaderWrapper = styled('div')(({theme}) => ({
  borderBottom: "1px solid #d3d3d3",

  '& .headerTitle': {
    fontSize:'20px',
    fontWeight:600,
  },
  '& .headerSubtitle': {
    color: "#546376"
  }
}));

const SalesGoalHeader = () => {
  const [openAddGoalModal, setOpenAddGoalModal] = useState(false);

  const handleCloseModal = () => {
    setOpenAddGoalModal(false);
  };

  return (
    <HeaderWrapper>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 16px"}
      >
        <div>
          <Typography variant="h5" className={'headerTitle'}>
            Sales Goals
          </Typography>
          <Typography variant="subtitle2" className="headerSubtitle" >
            Set sales goals for your team and track their progress
          </Typography>
        </div>

        <CustomButton
          variant="contained"
          onClick={() => setOpenAddGoalModal(true)}
          startIcon={<AddIcon />}
          disableElevation
          size="small"
        >
          Add Goal
        </CustomButton>
      </Box>

      <AddOrEditSalesGoalModal
          open={openAddGoalModal}
          onClose={handleCloseModal}
      />
    </HeaderWrapper>
  );
};

export default SalesGoalHeader;
