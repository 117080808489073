const PipelineLogic = () => {
    return (
        <div className="" style={{padding: '10px'}}>
            <h5>Pipeline logic</h5>
            <table>
                <tr>
                    <th>created by</th>
                    <th>view</th>
                    <th>edit</th>
                    <th>pipeline functionality</th>
                </tr>
                <tr>
                    <td>owner</td>
                    <td>all</td>
                    <td>owner</td>
                    <td>owner</td>
                </tr>
                <tr>
                    <td>admin</td>
                    <td>all</td>
                    <td>owner, admin</td>
                    <td>owner, admin</td>
                </tr>
                <tr>
                    <td>agent</td>
                    <td>owner, admin, self</td>
                    <td>owner, admin, self</td>
                    <td>owner, admin, self</td>
                </tr>
            </table>
            <br/>
            <h5>Stage logic</h5>
            <table>
                <tr>
                    <th>Pileline created by</th>
                    <th>add</th>
                    <th>view</th>
                    <th>edit</th>
                    <th>stage functionality</th>
                    <th>Drag & drop</th>
                </tr>
                <tr>
                    <td>owner</td>
                    <td>owner</td>
                    <td>all</td>
                    <td>owner</td>
                    <td>owner</td>
                    <td>owner</td>
                </tr>
                <tr>
                    <td>admin</td>
                    <td>owner, admin</td>
                    <td>all</td>
                    <td>owner, admin</td>
                    <td>owner, admin</td>
                    <td>owner, admin</td>
                </tr>
                <tr>
                    <td>agent</td>
                    <td>owner, admin, self</td>
                    <td>owner, admin, self</td>
                    <td>owner, admin, self</td>
                    <td>owner, admin, self</td>
                    <td>owner, admin, self</td>
                </tr>
            </table>
            <br/>
            <h5>Deal table view logic</h5>
            <table>
                <tr>
                    <th>Deal created by</th>
                    <th>view</th>
                </tr>
                <tr>
                    <td>Team owner</td>
                    <td>Self, Admin, Collaborator</td>
                </tr>
                <tr>
                  <td>Admin user</td>
                  <td>Admin, Team owner, Collaborator</td>
                </tr>
                <tr>
                  <td>Agent user</td>
                  <td>Admin, Team owner, Collaborator, Self</td>
                </tr>
            </table>
        </div>
    );
}
export default PipelineLogic;