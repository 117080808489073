import { styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { HOME_PAGE_TABS } from "./helper/coreConstant";
import HomePageTab from "./HomePageTab";
import ProductService from "./productService";
import SalesGoal from "./salesGoal";
import TeamSalesReport from "./teamSalesReport";
import ProductSalesReport from "./productSalesReport";
import SalesVsGoalReport from "./salesVsGoalReport";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchPipelineApi, getTeamUserListApi } from "../../api/dealApi";
import { handleAssetLoadingState, setPipelines, setTeamUsers } from "../../actions/ProductAction";
import DealReport from "./dealReport/index"
const ProductsWrapper = styled("div")(({ theme }) => ({
  height: ` calc(100vh - 100px)`,
}));
const Products = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState(HOME_PAGE_TABS.PRODUCTS);
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  useEffect(() => {

    if (history &&
      history.location &&
      history.location.state &&
      history.location.state.from === "settingPage"
    ) {
      setValue(history.location.state.tab);

      history.replace({});
    }
    window.setActiveSidebar('setting')
    loadAssets().then();
  }, []);

  const loadAssets = async () => {
    try {
      const [response1, response2] = await Promise.all([
        fetchPipelineApi(),
        getTeamUserListApi()
      ]);

      if (response1 && response1.data && response1.data.data) {
        dispatch(setPipelines(response1.data.data));
      }

      if (response2 && response2.data && response2.data.status && response2.data.data) {
        dispatch(setTeamUsers(response2.data.data));
      }

      setAssetsLoaded(true)
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(handleAssetLoadingState(false));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const RenderTabContent = () => {
    switch (value) {
      case HOME_PAGE_TABS.DASHBOARD:
        // return <DashboardContent />;
        break;

      case HOME_PAGE_TABS.PRODUCTS:
        return <ProductService />;

      case HOME_PAGE_TABS.GOALS:
        return <SalesGoal />;

      case HOME_PAGE_TABS.TEAM_SALES_REPORT:
        return <TeamSalesReport assetsLoaded={assetsLoaded} />;

      case HOME_PAGE_TABS.PRODUCT_SALES_REPORT:
        return <ProductSalesReport assetsLoaded={assetsLoaded} />;

        case HOME_PAGE_TABS.SALES_VS_GOAL_REPORT:
          return <SalesVsGoalReport assetsLoaded={assetsLoaded} />;
  
          case  HOME_PAGE_TABS.DEAL_REPORT: 
            return  <DealReport/>  

      default:
        return <ProductService />;
    }
  };

  return (
    <ProductsWrapper>
      <HomePageTab
        value={value}
        handleChange={handleChange}
      />
      <RenderTabContent />
    </ProductsWrapper>
  );
};

export default Products;
