import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  Fade,
  Grid,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { fetchRecycleInfo } from "../../../../../api/dealApi";
import RecycleInfoModalSkeleton from "./RecycleInfoModalSkeleton";
import { EMPTY_DATA_IMG } from "../../../../../constants/CoreConstants";
import Utils from "../../../../../helpers/Utils";

const modalStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 930,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    "&:focus-visible": {
      outline: "none",
    },
  },
  modalHeader: {
    padding: "16px 24px",
    background: "#FFF",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    display: "flex",
    alignItems: "center",
    borderBottom:'1px solid #d3d3d3',

  },
  modalBody: {
    padding: "16px 24px",
    "& .productValueSignDisabled": {
      color: "rgba(0,0,0,0.42)",
    },
  },
  modalBodyInput: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },

  modalFooter: {
    borderEndEndRadius: "8px",
    borderEndStartRadius: "8px",
    padding: "16px 24px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "& .title": {
      color: "#fff",
      fontWeight: "600",
    },

    "& .btnSubmit": {
      background: "#346fef !important",
      fontWeight: "600",
    },
    "& .skipSubmit": {
      color: "white",
      borderColor: "white",
      fontWeight: "600",
      marginRight: ".5rem",
    },
  },
  errorText: {
    color: "red",
    marginTop: "4px",
    fontSize: "0.875rem",
  },
  infoBox: {
    border: "1px solid rgb(141, 148, 165)",
    padding: "6px 12px",
    borderRadius: "4px",
    marginTop: "8px",
  },
  infoNote: {
    height: "150px",
    border: "1px solid rgb(141, 148, 165)",
    padding: "6px 12px",
    borderRadius: "4px",
    marginTop: "8px",
  },
  infoHeading: {
    height: "unset !important",
    fontWeight: 600,
    color: "#133159",
  },

  infoText: {
    height: "unset !important",
    fontWeight: 500,
    color: "#133159",
  },
  notFound: {
    height:'350px', 
    display:'flex', 
    flexDirection:'column', 
    justifyContent:'center', 
    alignItems:'center'
  }
}));

const RecycledInformationModal = ({ open, onClose, data }) => {
  const classes = modalStyles();
  const [recycleInfo, setRecycleInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleRecycleInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRecycleInfo = async () => {
    setLoading(true);
    try {
      const submitData = {
        userStageId: data.user_stage_id,
        contactId: data.contact_id,
      } 
      const resp = await fetchRecycleInfo(submitData);
        if (resp.success && resp.data) {
          setRecycleInfo(resp.data);
        } else {
          setRecycleInfo(null);
        }
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
          <div style={{width:'60px',height:'60px'}}>
          <img
          src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/recycled-lead.png`}
            alt="logo"
            style={{width:'100%',height:'100%',objectFit:'cover'}}
          />
          </div>
            <div style={{ marginLeft: "24px" }}>
              <Typography
                style={{
                  height: "unset !important",
                  color: "#133159",
                  fontWeight: 600,
                }}
                variant="h5"
              >
                Recycle Event
              </Typography>
              <Typography
                style={{
                  height: "unset !important",
                  color: "#133159",
                  fontWeight: 500,
                  fontSize: "15px",
                }}
                variant="h5"
              >
                This lead was recycled back into your pipeline for the following
                reasons...
              </Typography>
            </div>
          </div>

          <div className={classes.modalBody}>
            {loading ? (
              <RecycleInfoModalSkeleton />
            ) : recycleInfo ? (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.infoHeading}>
                      Why did you lose the deal?
                    </Typography>
                    <div>
                      <div className={classes.infoBox}>
                        <Typography className={classes.infoText}>
                          {recycleInfo.lostDealReason || ""}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.infoHeading}>
                      Carrier prospect went with?
                    </Typography>
                    <div>
                      <div className={classes.infoBox}>
                        <Typography className={classes.infoText}>
                          {recycleInfo.companyName || ""}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.infoHeading}>
                      Recycle event
                    </Typography>
                    <div>
                      <div className={classes.infoBox}>
                        <Typography className={classes.infoText}>
                          {recycleInfo.recycleEvent || ""}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.infoHeading}>
                      Date to recycle lead?
                    </Typography>
                    <div>
                      <div className={classes.infoBox}>
                        <Typography className={classes.infoText}>
                        {Utils.formatDate(recycleInfo.date, "MM-DD-YYYY") || ""}
                        </Typography>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography className={classes.infoHeading}>
                      Notes
                    </Typography>
                    <div className={classes.infoNote}>
                      <Typography className={classes.infoText}>
                        {recycleInfo.note || ""}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div className={classes.notFound}>
                 <div>
                <img
                  height={200}
                  src={EMPTY_DATA_IMG}
                  alt="Empty data"
                />
                </div>
                <Typography variant="h5" className={classes.infoHeading}>
                  No Recycle Information Found
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.modalFooter}>
            <Button
              onClick={handleClose}
              className="btnSubmit #346fef"
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default RecycledInformationModal;
