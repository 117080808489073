import React, { useState, useEffect, useRef } from "react";
import "react-date-range/dist/styles.css";
import "./datepicker-theme.css";
import { DateRangePicker,createStaticRanges, defaultStaticRanges  } from "react-date-range";
import { addDays, startOfYear } from "date-fns";
import { CloseIcon, DatePickerIcon } from "./Icons";
import { formatDateWithoutZone } from "../../common/TimezoneConversion";


const DatePicker = (props) => {
    const pickerRef = useRef(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [datePreview, setDatePreview] = useState("Time frame: All dates");
    const [isSelected, setIsSelected] = useState(false);
    const defaultRange = [{
        startDate: addDays(new Date(), -7),
        endDate: new Date(),
        key: "selection"
    }]
    const [state, setState] = useState([
        {
            startDate: "",
            endDate: "",
            key: "selection"
        }
    ]);

    useEffect(() => {
        if (props.reset === true) {

            setIsSelected(false);
            setShowDatePicker(false);
            setState([
                {
                    startDate: "",
                    endDate: "",
                    key: "selection"
                }
            ]);
            setDatePreview("Time frame: All dates");
            props.updateReset()
        }
    }, [props.reset]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.callback && state[0].startDate && state[0].endDate) {
            const startDate = formatDateWithoutZone(state[0].startDate, "MM-DD-YYYY");
            const endDate = formatDateWithoutZone(state[0].endDate, "MM-DD-YYYY");

            const startDateView = formatDateWithoutZone(state[0].startDate, "MM/DD/YYYY");
            const endDateView = formatDateWithoutZone(state[0].endDate, "MM/DD/YYYY");

            setDatePreview(`${startDateView} - ${endDateView}`);
            setIsSelected(true);
            props.callback({ startDate: startDate + " 00:00:01", endDate: endDate + " 23:59:59" });

        }
    }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (showDatePicker) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showDatePicker]);

    const resetSelector = () => {
        setIsSelected(false);
        setShowDatePicker(false);
        setState([
            {
                startDate: "",
                endDate: "",
                key: "selection"
            }
        ]);
        setDatePreview("Time frame: All dates");
        props.callback({ startDate: null, endDate: null });
    };

    const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            setShowDatePicker(false);
        }
    };

    const handleRangeChange = (ranges) => {
        const {startDate,endDate} = ranges.selection;
        if(!startDate && !endDate){
            setDatePreview("All Time")
            setIsSelected(true);
            props.callback({ startDate: null, endDate: null });
        };

        setState([ranges.selection]);
    };
    
     const customStaticRanges = [
        {
            label: 'All Time',
            range: () => ({ startDate: null, endDate: null }),
        },
        {
            label: 'Year to Date',
            range: () => ({ startDate: startOfYear(new Date()), endDate: new Date()}),
        }
    ];

    const combinedStaticRanges = [
        ...createStaticRanges([customStaticRanges[0]]),  
        ...defaultStaticRanges,  
        ...createStaticRanges([customStaticRanges[1]]),  
    ];


    return (
        <div className="datePickerWrapper">
            <div className="datePickerPreviewInputWrapper">
                <input className="datePickerPreviewInput" type="text" value={datePreview} onChange={() => { }} onClick={() => { setShowDatePicker(true); }} readOnly />
                {
                    isSelected ?
                        <CloseIcon className="datePickerClear" onClick={resetSelector} /> :
                        <DatePickerIcon onClick={() => { setShowDatePicker(true); }} />
                }
            </div>
            {
                showDatePicker &&
                <div ref={pickerRef} className="datePickerSelectorWrapper" style={props.style ? { ...props.style } : {}}>
                    <DateRangePicker
                        onChange={handleRangeChange}
                        showSelectionPreview={true}
                        months={2}
                        ranges={isSelected ? state : defaultRange}
                        direction="horizontal"
                        editableDateInputs={true}
                        // minDate={addDays(new Date(), -600)}
                        // maxDate={new Date()}
                        dateDisplayFormat="MM/dd/yyyy"
                        retainEndDateOnFirstSelection={true}
                        moveRangeOnFirstSelection={false}
                        startDatePlaceholder={state[0].startDate === null ? "All Time" : "Start Date"}
                        endDatePlaceholder={state[0].endDate === null ? "All Time" : "End Date"}
                        staticRanges={combinedStaticRanges}
                    />
                    <div className="datePickerClose" onClick={() => { setShowDatePicker(false); }}>
                        <CloseIcon />
                    </div>
                </div>
            }
        </div>
    );
}

export default DatePicker;