import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAllPipeline,
  getAllStageByPipeline,
} from "../../../../actions/DealAction";
import { deletePipelineAndMove } from "../../../../actions/PipelineAction";
import {
  reloadPipeline,
  storePipelineId,
} from "../../../../actions/PipelineReducerAction";
import CustomMenuPype from "../../../custom-menu/CustomMenuPype";
import GlobalModal from "../../../globals/Modal/GlobalModal";
import { DownArrowIcon } from "../../icons/DealIconList";
import "../header-style.css";

const mapStateToProps = (state) => ({
  needToReloadPipeline: state.PipelineReducer.needToReloadPipeline,
  seletedPipelineId: state.PipelineReducer.seletedPipelineId,
  addPipeline: state.PipelineReducer.addPipeline,
});
const mapDispatchToProps = (dispatch) => ({
  getAllPipeline: (params, callback) =>
    dispatch(getAllPipeline(params, callback)),
  getAllStageByPipeline: (params, callback) =>
    dispatch(getAllStageByPipeline(params, callback)),
  deletePipelineAndMove: (params, callback) =>
    dispatch(deletePipelineAndMove(params, callback)),
  reloadPipeline: (params) => dispatch(reloadPipeline(params)),
  storePipelineId: (params, callback) =>
    dispatch(storePipelineId(params, callback)),
});

const App = (props) => {
  const [pipelineToMove, setPipelineToMove] = useState("");
  const [validPipeline, setValidPipeline] = useState(false);
  const [stageToMove, setStageToMove] = useState("");
  const [validStage, setValidStage] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [pipelines, setPipelines] = useState([]);
  const [stages, setStages] = useState([]);

  useEffect(() => {
    let formData = {};
    if (typeof props.deletedPipeline !== "undefined") {
      formData["pipelineId"] = props.deletedPipeline.id;
    }
    props.getAllPipeline(formData, (res) => {
      let data = res.data;
      if (typeof props.deletedPipeline !== "undefined") {
        data = data.filter((item) => item.value != props.deletedPipeline.id);
      }
      setPipelines(data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePipelineChange = (value) => {
    setPipelineToMove(value);
    setStageToMove("");
    // props.getAllStageByPipeline({ pipelineId: value }, (response) => {
    props.getAllStageByPipeline({ pipelineId: value.id }, (response) => {
      setStages(response.dealStage);
    });
  };

  const handleSubmit = () => {
    if (pipelineToMove === "") {
      setValidPipeline(true);
      return;
    }
    if (stageToMove === "") {
      setValidStage(true);
      return;
    }
    setSubmit(true);
    props.deletePipelineAndMove(
      {
        selectedPipeline: pipelineToMove,
        // selectedStage: stageToMove,
        selectedStage: stageToMove.value,
        deletedPipelineId: props.deletedPipeline.id,
        moveDeal: true,
      },
      (res) => {
        setSubmit(false);
        if (res.status === "success") {
          if (typeof res.pipeline.id !== "undefined") {
            actionAfterDelete(res.pipeline);
          }
        }
      }
    );
  };

  const actionAfterDelete = (pipeline) => {
    props.storePipelineId(pipeline.id);
    props.reloadPipeline({ flag: true, addPipeline: false });
    props.onClose(false);
  };

  const select_props = {};
  if (typeof props.deletedPipeline !== "undefined") {
    select_props["value_field"] = "value";
    select_props["titel_field"] = "label";
    // select_props['modifyTitle'] = (title) => {
    //                                 title = title.substring(0, title.lastIndexOf("-") + 1);
    //                                 title = title.replace(/-([^-]*)$/, '$1')
    //                                 return title
    //                             }
  } else {
    select_props["value_field"] = "id";
    select_props["titel_field"] = "title";
  }

  const renderSelectedPipeline = () => {
    if (pipelineToMove.user !== undefined && pipelineToMove.user != null) {
      if (pipelineToMove.user.full_name !== undefined) {
        return (
          <div className='drop-down'>
            <div className='label-wrp'>
              <span>
                {pipelineToMove.title}{" "}
                <small>{pipelineToMove.user.full_name}</small>
              </span>
            </div>
            <span className=''>
              <DownArrowIcon />
            </span>
          </div>
        );
      }
    }
    if (pipelineToMove.title !== undefined) {
      return (
        <div className='drop-down'>
          <div className='label-wrp'>
            <span>{pipelineToMove.title} </span>
          </div>
          <span className=''>
            <DownArrowIcon />
          </span>
        </div>
      );
    }
    return (
      <div className='drop-down'>
        <div className='label-wrp'>
          <span>{"Select a pipeline"}</span>
        </div>
        <span className=''>
          <DownArrowIcon />
        </span>
      </div>
    );
  };
  const renderSelectedStage = () => {
    if (stageToMove !== "" && stageToMove.label !== undefined) {
      return (
        <div className='drop-down'>
          <div className='label-wrp'>
            <span>{stageToMove.label} </span>
          </div>
          <span className=''>
            <DownArrowIcon />
          </span>
        </div>
      );
    }
    return (
      <div className='drop-down'>
        <div className='label-wrp'>
          <span>{"Select a stage"}</span>
        </div>
        <span className=''>
          <DownArrowIcon />
        </span>
      </div>
    );
  };

  return (
    <GlobalModal
      open={props.open}
      title='Move deal to another Pipeline'
      onClose={props.onClose}
      buttonIcon={""}
      buttonText={"Add Pipeline"}
      showLoader={submit}
      handleSubmit={handleSubmit}
      width={400}
    >
      {typeof props.deletedPipeline != "undefined" && (
        <p>
          Deleted pipeline :{" "}
          <span style={{ fontWeight: "bold" }}>
            {props.deletedPipeline.title.toUpperCase()}
          </span>
        </p>
      )}
      <h3 className='trigger__modal_title ds__mt20pxSpace'>Select pipeline</h3>
      <CustomMenuPype
        wrapperClass='pipeline-drop-down pipeline-drop-down-alt'
        popup={{
          placement: "center",
          list: pipelines,
          labelField: "title",
          onClick: (item) => {
            handlePipelineChange(item);
          },
          height: "auto",
          width: "350px",
          popupStyle: {
            maxHeight: "250px",
          },
        }}
        label={{
          renderCustom: renderSelectedPipeline(),
          className: "drop-down-wrp",
        }}
      />
      {validPipeline && pipelineToMove === "" && <span>Select a pipeline</span>}

      <h3 className='trigger__modal_title ds__mt20pxSpace'>Select stage</h3>
      <CustomMenuPype
        wrapperClass='pipeline-drop-down pipeline-drop-down-alt'
        popup={{
          placement: "center",
          list: stages,
          labelField: "label",
          onClick: (item) => {
            setStageToMove(item);
          },
          height: "auto",
          width: "350px",
          popupStyle: {
            maxHeight: "250px",
          },
        }}
        label={{
          renderCustom: renderSelectedStage(),
          className: "drop-down-wrp",
        }}
      />
      {validStage && stageToMove === "" && <span>Select a stage</span>}
    </GlobalModal>
  );
};
const MovePipeline = connect(mapStateToProps, mapDispatchToProps)(App);
export default MovePipeline;
