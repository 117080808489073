// custom field
export const GET_PRODUCT_FOLDERS = "GET_PRODUCT_FOLDERS";
export const GET_PRODUCT_FOLDERS_SUCCESS = "GET_PRODUCT_FOLDERS_SUCCESS";
export const GET_PRODUCT_FOLDERS_FAILURE = "GET_PRODUCT_FOLDERS_FAILURE";

export const ADD_PRODUCT_FOLDER = "ADD_PRODUCT_FOLDER";
export const ADD_PRODUCT_FOLDER_SUCCESS = "ADD_PRODUCT_FOLDER_SUCCESS";
export const ADD_PRODUCT_FOLDER_FAILURE = "ADD_PRODUCT_FOLDER_FAILURE";


export const UPDATE_PRODUCT_FOLDER = "UPDATE_PRODUCT_FOLDER";
export const UPDATE_PRODUCT_FOLDER_SUCCESS = "UPDATE_PRODUCT_FOLDER_SUCCESS";
export const UPDATE_PRODUCT_FOLDER_FAILURE = "UPDATE_PRODUCT_FOLDER_FAILURE";

export const DELETE_PRODUCT_FOLDER = "DELETE_PRODUCT_FOLDER";
export const DELETE_PRODUCT_FOLDER_SUCCESS = "DELETE_PRODUCT_FOLDER_SUCCESS";
export const DELETE_PRODUCT_FOLDER_FAILURE = "DELETE_PRODUCT_FOLDER_FAILURE";

export const SELECT_PRODUCT_FOLDER = "SELECT_PRODUCT_FOLDER";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";
export const RESET_ADD_PRODUCT_SUCCESS = "RESET_ADD_PRODUCT_SUCCESS";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";
export const RESET_DELETE_PRODUCT_SUCCESS = "RESET_DELETE_PRODUCT_SUCCESS";

export const DELETE_PRODUCT_FOREVER = "DELETE_PRODUCT_FOREVER";
export const DELETE_PRODUCT_FOREVER_SUCCESS = "DELETE_PRODUCT_FOREVER_SUCCESS";
export const DELETE_PRODUCT_FOREVER_FAILURE = "DELETE_PRODUCT_FOREVER_FAILURE";

export const TOGGLE_FAVORITE = "TOGGLE_FAVORITE";
export const TOGGLE_FAVORITE_SUCCESS = "TOGGLE_FAVORITE_SUCCESS";
export const TOGGLE_FAVORITE_FAILURE = "TOGGLE_FAVORITE_FAILURE";

export const DESELECT_ALL_PRODUCTS = "DESELECT_ALL_PRODUCTS";

export const SET_TEAM_USERS = "SET_TEAM_USERS";
export const SET_PIPELINES = "SET_PIPELINES";
export const HANDLE_ASSET_LOADING_STATE = "HANDLE_ASSET_LOADING_STATE";
