const marketingUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`
const contactUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const dealUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`

const Api = {
    fetchVideoFolders: `${marketingUrl}/video/folders`,
    fetchVideos: `${marketingUrl}/videos`,
    deleteVideo: `${marketingUrl}/video/delete`,
    uploadVideo: `${marketingUrl}/video/upload`,
    sendBulkMessage: `${contactUrl}/send-message`,
    userEmailList: `${contactUrl}/user-email-list`,
    getMessageTemplate: `${marketingUrl}/message-templates`,
    geContactDetail: `${dealUrl}/get/people`
}

export default Api;