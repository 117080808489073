import * as ACTION from "../constants/pipelineActionTypes";

export const createPipeline = (payload, callback) => ({
    type: ACTION.CREATE_PIPELINE,
    payload: {payload, callback}
});

export const checkPipelineDeleteOrNot = (payload, callback) => ({
    type: ACTION.CEHCK_PIPELINE_DELETE_OR_NOT,
    payload: {payload, callback}
});

export const deletePipelineAndMove = (payload, callback) => ({
    type: ACTION.DELETE_PIPELINE_AND_MOVE,
    payload: {payload, callback}
});

export const updatePipeline = (payload, callback) => ({
    type: ACTION.UPDATE_PIPELINE,
    payload: {payload, callback}
});

export const getUserStageByPipeline = (payload, callback) => ({
    type: ACTION.GET_USER_STAGE_BY_PIPELINE,
    payload: {payload, callback}
});

export const sortUserStage = (payload, callback) => ({
    type: ACTION.SORT_USER_STAGE,
    payload: {payload, callback}
});

export const deleteAndMoveToStage = (payload, callback) => ({
    type: ACTION.DELETE_AND_MOVE_TO_STAGE,
    payload: {payload, callback}
});

export const userStageShowInChart = (payload, callback) => ({
    type: ACTION.USER_STAGE_SHOW_IN_CHART,
    payload: {payload, callback}
});

export const addUserStageSubmit = (payload, callback) => ({
    type: ACTION.ADD_USER_STAGE_SUBMIT,
    payload: {payload, callback}
});

export const updatePipelineShowOption = (payload, callback) => ({
    type: ACTION.UPDATE_PIPELINE_SHOW_OPTION,
    payload: {payload, callback}
});

