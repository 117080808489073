import React, {useState} from 'react'
import Utils from "../../../../helpers/Utils";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit';
import { IconList } from '../../../../constants/IconList';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux'
import { deleteAndMoveToStage } from '../../../../actions/PipelineAction';
import { reloadStageList } from '../../../../actions/PipelineReducerAction';
import MoveToStage from '../../Pipelines/Modals/MoveToStage';
import AddStage from '../../Pipelines/Modals/AddStage';

const EachPipelineApp = ({
    item, index, list, pipelineContainerRef, 
    deleteAndMoveToStage, reloadStageList, selectedPipeline, hasAccessFlag
}) => {

    const [stageMoveModal, setStageMoveModal] = useState(false)
    const [editStageModal, setEditStageModal] = useState(false)
    const [updateColor, setUpdateColor] = useState(false)
    const [colorType, setColorType] = useState('bg') //text

    const confirmDeleteStage = () => {
        if(!hasAccessFlag){
            return
        }
        confirmAlert({
            title: 'Confirm to delete?',
            message: 'Are you sure to want to delete this stage?',
            buttons: [
                {
                    label: 'Yes, delete it!',
                    onClick: () => {
                        setTimeout(() => {
                            confirmAlert({
                                title: 'Do you want to move deals?',
                                message: 'Are you sure to want to move deal to another pipeline stage?',
                                buttons: [
                                    {
                                        label: 'Yes',
                                        onClick: () => setStageMoveModal(true)
                                    },
                                    {
                                        label: 'No',
                                        onClick: () => deleteAndMove()
                                    }
                                ]
                            });
                        }, 100);
                    }
                },
                {
                    label: 'No, keep it',
                    onClick: () => {
                        return false;
                    }
                }
            ]
        });
    }

    const deleteAndMove = () => {
        deleteAndMoveToStage({
            selectedPipeline: null,
            selectedStage: null,
            deletedStage: item.id,
        }, (res) => {
            reloadStageList(true)
        })
    }

    const handleColorChangeModal = (type) => {
        if(!hasAccessFlag){
            return
        }
        setColorType(type)
        setUpdateColor(true)
    }

    let LiItem =  styled.li`
        &:after {
            border-left-color: ${item.color_code} !important
        }
        background-color: ${item.color_code} !important
    `

    if(!item['system']){
        if(!item.color_code.includes("#") || item.color_code == '#FFF' || item.color_code == '#fff'){
            LiItem =  styled.li`
                &:after {
                    border-left-color: #e3ebfa !important
                }
                background-color: #e3ebfa !important
            `
        }
    }

    const colorPickerIcon = (<svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.572 1.76c-.8-.8-2.08-.8-2.88 0l-1.44 1.44-.56-.56a.773.773 0 0 0-1.12 0l-.64.56c-.32.32-.32.8 0 1.12l4 4c.32.32.8.32 1.12 0l.56-.56c.32-.32.32-.8 0-1.12l-.48-.56 1.44-1.44c.8-.8.8-2.08 0-2.88zM3.852 9.6c-1.76 1.76-.72 2.56-2.32 4.64l.56.56c2.08-1.6 2.88-.56 4.64-2.32l4.08-4.08-2.88-2.88-4.08 4.08z" fill={item.color_code}/></svg>)
    const textColorPicker = (
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="24px" fill={item.text_color}>
            <g><rect fill="none" height="24" width="24"/></g>
            <g><path d="M2,20h20v4H2V20z M5.49,17h2.42l1.27-3.58h5.65L16.09,17h2.42L13.25,3h-2.5L5.49,17z M9.91,11.39l2.03-5.79h0.12l2.03,5.79 H9.91z"/></g>
        </svg>
    )

    const renderDisableClass = hasAccessFlag ? '' : 'disalbe_option_view'

    return(
        <div className="each__pipeline__container__v2" ref={index === (list.length - 1) ? pipelineContainerRef : null}>
            <LiItem>
                <div className="each__pipeline__header__container">
                    <div className="each__pipeline__title truncate">
                        {item.stage}
                    </div>
                    <div className="each__pipeline__count">
                        <span className="each__pipeline__count__top">
                            <span className="each__pipeline__count__top__contant">{item.total_contact}</span>
                            <span className="dot__separator" style={{background: item.text_color}}></span>
                        </span>
                        
                        <span className='each__pipeline__count__bottom__total'>{Utils.calculateCash(item.total_deals)}</span>
                    </div>
                </div>
            </LiItem>

            <div className="each__pipeline__description">
                <div className="each__pipeline__action__row">
                    <div className='each__pipeline__action__row__card'>
                    <div className="each__pipeline__action__container">
                        <div className="each__pipeline__action__single__i">
                            <div className={`each__pipeline__action__single__div ${renderDisableClass}`}>
                                    <BootstrapTooltip title={`Delete stage: ${item.stage}`} arrow>
                                    <span className="each__pipeline__action__button each__pipeline__action__border__red" onClick={confirmDeleteStage}>{ IconList.redDeleteIcon }</span>
                                </BootstrapTooltip>

                                <BootstrapTooltip title={`Stage color`} arrow>
                                    <span className="each__pipeline__action__button" onClick={ () => handleColorChangeModal('bg') }>{colorPickerIcon}</span>
                                </BootstrapTooltip>

                                <BootstrapTooltip title={`Stage title text color`} arrow>
                                    <span className="each__pipeline__action__button each__pipeline__action__button__bg" onClick={ () => handleColorChangeModal('text') }>{textColorPicker}</span>
                                </BootstrapTooltip>

                                <BootstrapTooltip title={`Edit stage`} arrow>
                                    <span className="each__pipeline__action__button" onClick={ () => {
                                        if(renderDisableClass === ""){
                                            setEditStageModal(true)
                                        }
                                    } }><EditIcon fontSize="small" /></span>
                                </BootstrapTooltip>
                            </div>
                            <span className="each__pipeline__action__single__percent">{item['percentage'] < 10 ? item['percentage'] * 10+"%": item['percentage'] === 10 ? 'Won' : 'LOSS'}</span>
                        </div>

                       
                    </div>

                    <div className="each__title"  onClick={() => {
                        if(renderDisableClass === ""){
                            setEditStageModal(true)
                        }
                        }} 
                    >
                        <span>{item.stage}</span>                    
                    </div>
                    </div>
                </div>
            </div>

            {stageMoveModal && 
            <MoveToStage open={stageMoveModal} onClose={setStageMoveModal} deletedStage={item}/>
            }
            
            {editStageModal &&
            <AddStage open={editStageModal} onClose={setEditStageModal} edit={true} data={item}/> 
            }

            {updateColor &&
            <AddStage open={updateColor} onClose={setUpdateColor} edit={true} data={item} onlyColorPicker={true} colorType={colorType}/> 
            }
        </div>
    )


}
const mapStateToProps = state => ({
    needToReloadPipeline: state.PipelineReducer.needToReloadPipeline,
    seletedPipelineId : state.PipelineReducer.seletedPipelineId,
    addPipeline : state.PipelineReducer.addPipeline,
    editPipeline : state.PipelineReducer.editPipeline, 
    needToReloadStageList: state.PipelineReducer.needToReloadStageList,
    addStage : state.PipelineReducer.addStage, 
    selectedPipeline : state.PipelineReducer.selectedPipeline, 
 });
const mapDispatchToProps = dispatch => ({
    deleteAndMoveToStage: (params, callback) => dispatch(deleteAndMoveToStage(params, callback)),
    reloadStageList: (params, callback) => dispatch(reloadStageList(params, callback)),
})
const EachPipeline = connect(mapStateToProps, mapDispatchToProps)(EachPipelineApp);
export default EachPipeline;