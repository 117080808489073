import { sendRequest } from "../../../api/rootApi";
import { getCookie } from "../../../helpers/Cookie";
import Utils from "../../../helpers/Utils";
import CustomSelectBox from "../../Common/CustomSelectBox";
import Loading from "../../Common/Loading";
import ListSkeleton from "../../Common/Skeletons/ListSkeleton";
import GlobalModal from "../../globals/Modal/GlobalModal";
import QucikReplyCard from "../../QuickReplyCard";

const LinkFile = {
    'getCookie' : getCookie,
    'sendRequest' : sendRequest,
    'CustomSelectBox' : CustomSelectBox,
    'Utils' : Utils,
    'Loading': Loading,
    'skeleton': ListSkeleton,
    'globalModal': GlobalModal,
    'quickReplyCard': QucikReplyCard
}
export default LinkFile;