import axios from "axios";
import UtilsAddDeal from "./UtilsAddDeal";

// const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`
const dealRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`;

// const apiKey = UtilsAddDeal.getCookie(process.env.REACT_APP_ACCESS_TOKEN); //for production
const apiKey = UtilsAddDeal.getCookie(process.env.REACT_APP_ACCESS_TOKEN);

// const apiKey = "W6GSG6PQ6K5ZCQ1UQT5E" //for development

const getAllPipeline = (params) => {
  let data = {
    ...params,
    ...{ "api-key": apiKey },
  };
  return axios.request({
    method: "POST",
    url: dealRootUrl + "/select-all-pipeline-except-v2",
    data: data,
  });
};

const getLeadSource = () => {
  return axios.request({
    method: "POST",
    url: dealRootUrl + "/lead-sources",
    data: {
      "api-key": apiKey,
    },
  });
};

const getAllStageByPipeline = (params) => {
  let data = {
    ...params,
    ...{ "api-key": apiKey },
  };
  return axios.request({
    method: "POST",
    url: dealRootUrl + "/select-all-stage-by-pipeline",
    data: data,
  });
};

const contactSearch = (params) => {
  let data = {
    ...params,
    ...{ "api-key": apiKey },
  };
  return axios.request({
    method: "POST",
    url: dealRootUrl + "/search/contact",
    data: data,
  });
};

const addDealSubmit = (params) => {
  let data = {
    ...params,
    ...{ "api-key": apiKey },
  };
  return axios.request({
    method: "POST",
    url: dealRootUrl + "/add-new-deal",
    data: data,
  });
};

const editDealSubmit = (params) => {
  let data = {
      ...params,
      ...{ 'api-key': apiKey }
  }
  return axios.request({
      method: 'POST',
      url: dealRootUrl+'/edit-deal',
      data: data
  });
}

const ApiListAddDeal = {
  addDealSubmit,
  getAllPipeline,
  getLeadSource,
  getAllStageByPipeline,
  contactSearch,
  editDealSubmit
};
export default ApiListAddDeal;
