import * as ACTION from "../constants/pipelineActionTypes";

const initialState = {
    pipelineList : [],
    seletedPipelineId: '',
    selectedPipeline: null,

    needToReloadPipeline: false,
    addPipeline: false,
    editPipeline: false,
    addStage: false,

    needToReloadStageList: false
};

const PipelineReducer = (state = initialState, action) => {
    let {payload} = action;
    switch (action.type) {

        case ACTION.STORE_PIPELINES:
            return {...state, pipelineList: payload}

        case ACTION.SELECTED_PIPELINE_ID:
            if(typeof payload == 'object'){
                return {
                    ...state, 
                    seletedPipelineId: payload.pipelineId,
                    addStage: typeof payload.addStageFlag != 'undefined' ? payload.addStageFlag : state.addStage
                }
            }
            else{
                return {...state, seletedPipelineId: payload}
            }
        case ACTION.RELOAD_PIPELINE_FLAG:
            return {...state, 
                    needToReloadPipeline: typeof payload.flag != 'undefined' ? payload.flag : state.needToReloadPipeline, 
                    addPipeline: typeof payload.addPipeline != 'undefined' ? payload.addPipeline : state.addPipeline,
                    editPipeline: typeof payload.editPipeline != 'undefined' ? payload.editPipeline : state.editPipeline,
                    addStage: typeof payload.addStageFlag != 'undefined' ? payload.addStageFlag : state.addStage
                }

        case ACTION.RELOAD_STAGE_LIST:
            return {...state, needToReloadStageList: payload}

        case ACTION.HANDLE_STORE_DATA:
            return {...state, ...payload}

        default:
            return state;
    }
}
export default PipelineReducer;