import axios from "axios";
import { getCookie } from "../helpers/Cookie";

export const sendRequest = (method, url, payload = [], config = []) => {
    let data = [];
    const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

    try{
        payload.append('api-key', apiKey)
        // payload.append('api-key', "W6GSG6PQ6K5ZCQ1UQT5E")
        data = payload;
    } catch (error) {
        data = {...payload, ...{'api-key': apiKey}};
        // data = {...payload, ...{'api-key': "W6GSG6PQ6K5ZCQ1UQT5E"}};
    }
    return axios.request({
        method: method,
        url: url,
        data: data,
        params: (method === 'get' || method === 'GET') ? data : null
    });
}

export const sendRequestBackEnd = (method, url, postData, queryparams=null) => {

  const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

  if(queryparams != null){
    url = url+queryparams
  }
  if(method === 'GET'){
    return fetch(url, {
      method: method,
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + apiKey,
          // 'Access-Control-Allow-Headers': '*',
  
          // Authorization: 'Bearer DGM2RNXDL8KS85JD066E' //stage api
  
      },
      // referrer: 'origin-when-cross-origin',
      referrerPolicy: 'origin',
      // mode: 'cors',
  })
      .then((response) => response.json())
      .then((responseData) => {
        return responseData
      })
      .catch(error=>{
        return false;
      })
  }
    return fetch(url, {
      method: method,
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + apiKey,
          // 'Access-Control-Allow-Headers': '*',
  
          // Authorization: 'Bearer DGM2RNXDL8KS85JD066E' //stage api
  
      },
      // referrer: 'origin-when-cross-origin',
      referrerPolicy: 'origin',
      // mode: 'cors',
      body: JSON.stringify(postData)
  })
      .then((response) => response.json())
      .then((responseData) => {
        return responseData
      })
      .catch(error=>{
        return false;
      })
  }