import React, { useEffect, useState } from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getAllStageContactsGraphV2Api, getSubUserListV2Api } from '../../../../../../api/dealApi';
import Utils from '../../../../../../helpers/Utils';
import { DateTimeFilters, UserFilters } from '../../helpers/Constants';
import { ArrowDownIcon } from '../../helpers/IconList';
import CustomMenuPype from '../custom-menu/CustomMenuPype';
import Styles from './style.module.css'

const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;
  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
}
const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate($this{x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
        {payload.value}
      </text>
    </g>
  );
}
const customTooltip = ({ payload, label, active }) => {
  if (active) {
      return (
        <div className={Styles.tooltipWrp}>
          <div className={Styles.tooltipTimeWrp}>
            <small className={Styles.tooltipTimeLabel}>Time frame: </small>
            <code className={Styles.tooltipTimeText}>{label}</code>
          </div>
          <div className={Styles.tooltipDesWrp}>
            <code className={`${Styles.tooltipTimeText} ${Styles.tooltipContact}`}>{payload[0].value}</code>
            <small className={Styles.tooltipTimeLabel}> contact(s) with</small>
            <small className={Styles.tooltipTimeLabel}> deal value </small>
            <code className={`${Styles.tooltipTimeText} ${Styles.tooltipValue}`}>{Utils.calculateCash(payload[1].value)}</code>
          </div>
        </div>
      );
  }
  return null;
}
const customValueYAxis = ({ x, y, payload }) => {
  return (
    <text x={x} y={y} fill={"#82ca9d"} >
      {Utils.calculateCash(payload.value)}
    </text>
  );
}
const ContactDealApp = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(UserFilters[1]);
  const [date, setDate] = useState('daily');
  const [subUserList, setSubUserList] = useState([]);
  const [getUserData, setGetUserData] = useState(false);

  const [opacity, setOpacity] = useState({
    total_deal_value: 1,
    total_contacts: 1,
  });
  
  useEffect(() => {
    getData()
    if(!Utils.getAccountData('userIsAgent') && !getUserData){
      getSubUserList();
    }
  }, [date, user])  
  const getData = () => {
    setIsLoading(true)
    let form_data = {
      day_filter: date,
      type: 'total_contact_with_deal_value',
    }
    if(user.value === "own" || user.value === "all"){
      form_data['user_filter'] = user.value
    }else{
      form_data['user_id'] = user.value
    }
    getAllStageContactsGraphV2Api(form_data).then(res => {
      let response = res.data;
      if(response.status === "success"){
        setData(response.data)
      }
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
    })
  }
  const getSubUserList = () => {
    getSubUserListV2Api().then(res => {
      let response = res.data;
      if(response.status === "success"){
        let list = [];
        response.data.forEach(item => {
          list.push({
            label: item.full_name.charAt(0).toUpperCase() + item.full_name.slice(1),
            value: item.id
          })
        })
        setSubUserList([...UserFilters, ...list])
      }
      setGetUserData(true)
    }).catch(error => {})
  }
  const  handleLegendClick = (e) => {
    const { dataKey } = e;
    let flag = 0;
    if(opacity[dataKey] === 0){
      flag = 1
    }
    setOpacity({...opacity, [dataKey]: flag})
  };
  const renderLoading = () => {
    return (
      <div className={Styles.loadingWrp}>
        <span>Loading...</span>
        <span>Please wait</span>
      </div>
    );
  }
  const renderGraph = () => {
    return (
      <div className={Styles.graphWrp}>
        <ResponsiveContainer className={Styles.graphWrpResponsive}>
          <LineChart
            width={500}
            height={300}
            data={data.length > 0 ? data : dummy_data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time_frame" /* height={60} tick={<CustomizedAxisTick />} */ />
            <YAxis yAxisId="left" mirror={false} stroke="#8884d8" allowDecimals={false} />
            <YAxis yAxisId="right" orientation="right" tick={customValueYAxis} />
            <Tooltip content={customTooltip} />
            <Legend  onClick={handleLegendClick} wrapperStyle={{cursor: 'pointer'}} />
            <Line strokeOpacity={opacity.total_contacts} name='Contacts' yAxisId="left" type="monotone" dataKey="total_contacts" stroke="#8884d8" label={<CustomizedLabel />} /* activeDot={{ r: 8 }} */ dot={{r:6}} activeDot={{ r: 5 }} strokeWidth={2} />
            <Line strokeOpacity={opacity.total_deal_value} name="Value" yAxisId="right" type="monotone" dataKey="total_deal_value" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
        {data.length === 0 && <div className={Styles.emptyScreen}>No report data found !</div>}
      </div>
    );
  }
  return (
    <div className={Styles.mainWrp}>
      <div className={Styles.headerWrp}>
        <span className={Styles.headerText}>Total contact with deal value</span>
        <div className={Styles.filterWrp}>
          {/* user filter */}
          {subUserList.length > 0 &&
          <CustomMenuPype
            popup= {{
              placement: 'center',
              list: subUserList,
              onClick: (item) => {
                if(item === user){
                  return;
                }
                setUser(item)
              },
              height: 'auto',
              width: 'auto',
              // listStyle: {
              //   justifyContent: 'center'
              // },
              optionClass: Styles.timeFilterOption,
              wrapperClass: Styles.timeFilterWrp
            }}
            label= {{
              renderCustom: (
              <div className={Styles.timeFilterLabelWrp}>
                <span>{user.label.toUpperCase()}</span>
                <span style={{display: 'inline-flex'}}><ArrowDownIcon /></span>
              </div>
              )
            }}
          />
          }
          {/* time filter */}
          <CustomMenuPype
            popup= {{
              placement: 'center',
              list: DateTimeFilters,
              onClick: (item) => {
                if(item.value === date){
                  return;
                }
                setDate(item.value)
              },
              height: 'auto',
              width: 'auto',
              listStyle: {
                justifyContent: 'center'
              },
              optionClass: Styles.timeFilterOption,
              wrapperClass: Styles.timeFilterWrp
            }}
            label= {{
              renderCustom: (
              <div className={Styles.timeFilterLabelWrp}>
                <span>{date.toUpperCase()}</span>
                <span style={{display: 'inline-flex'}}><ArrowDownIcon /></span>
              </div>
              )
            }}
          />
        </div>
      </div>
      {isLoading ? renderLoading() : renderGraph()}
    </div>
  );
}
export default ContactDealApp;
const dummy_data = [
  {
    time_frame: '2022-10-12',
    total_deal_value: 4000,
    total_contacts: 2400,
  },
  {
    time_frame: '2022-10-13',
    total_deal_value: 3000,
    total_contacts: 1398,
  },
  {
    time_frame: '2022-10-14',
    total_deal_value: 2000,
    total_contacts: 9800,
  },
  {
    time_frame: '2022-10-15',
    total_deal_value: 2780,
    total_contacts: 3908,
  },
  {
    time_frame: '2022-10-16',
    total_deal_value: 1890,
    total_contacts: 4800,
  },
  {
    time_frame: '2022-10-17',
    total_deal_value: 2390,
    total_contacts: 3800,
  },
  {
    time_frame: '2022-10-18',
    total_deal_value: 3490,
    total_contacts: 4300,
  }
];