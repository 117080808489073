

const Helper={

    getIconTextFromContact:(contact)=>{
        
        let firstName = (contact.first_name !== undefined && contact.first_name != null) ? contact.first_name.trim() : ''
        let lastName = (contact.last_name !== undefined && contact.last_name != null) ? contact.last_name.trim() : ''
        
        if(firstName !== '' && lastName !== ''){
            return (firstName.substring(0,1)+lastName.substring(0,1)).toUpperCase()
        }else if(firstName !== ''){
            return firstName.substring(0,2).toUpperCase()
        }else if(lastName !== ''){
            return lastName.substring(0,2).toUpperCase()
        }else {
            return 'N/A'
        }
    },

    isEmpty:(text)=>{
        if(text !== undefined && text!= null && text !== '' && text.trim() !== ''){
            return false
        }else{
            return true
        }
    },
    isValidText:(text)=>{
        if(text !== undefined && text!= null){
            return true
        }
        return false
    },
    getAvaterText: function (firstName, lastName) {
        var FN = Helper.isEmpty(firstName);
        var LN = Helper.isEmpty(lastName);
        if (FN && LN) {
          return false;
        }
        else if (!FN && !LN) {
          return firstName.substring(0, 1).toUpperCase() + "" + lastName.substring(0, 1).toUpperCase()
        }
        else if (FN && !LN) {
          return lastName.substring(0, 2).toUpperCase()
        } else if (!FN && LN) {
          return firstName.substring(0, 2).toUpperCase()
        } else {
          return false;
        }
    },
    checkLetterExistInText: function (text) {
        var regExp = /[^0-9]+/g;
        let result = false
        if(regExp.test(text)){
            
            result = true
        }
        return result
    }
}

export default Helper;