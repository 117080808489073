import * as ACTION from "../constants/productActionTypes";

export const getProductFolders = (callback) => ({
  type: ACTION.GET_PRODUCT_FOLDERS,
  payload: { callback },
});

export const getProductFoldersSuccess = (data) => ({
  type: ACTION.GET_PRODUCT_FOLDERS_SUCCESS,
  payload: { data },
});

export const getProductFoldersFailure = (error) => ({
  type: ACTION.GET_PRODUCT_FOLDERS_FAILURE,
  payload: { error },
});

export const addProductFolder = (payload) => ({
  type: ACTION.ADD_PRODUCT_FOLDER,
  payload: payload,
});

export const addProductFolderSuccess = (payload) => ({
  type: ACTION.ADD_PRODUCT_FOLDER_SUCCESS,
  payload: payload,
});

export const addProductFolderFailure = (error) => ({
  type: ACTION.ADD_PRODUCT_FOLDER_FAILURE,
  payload: error,
});

export const updateProductFolder = ({id, payload}) => ({
  type: ACTION.UPDATE_PRODUCT_FOLDER,
  payload: { id, payload },
});

export const updateProductFolderSuccess = (payload) => ({
  type: ACTION.UPDATE_PRODUCT_FOLDER_SUCCESS,
  payload: payload,
});

export const updateProductFolderFailure = (error) => ({
  type: ACTION.UPDATE_PRODUCT_FOLDER_FAILURE,
  payload: error,
});

export const deleteProductFolder = (id) => ({
  type: ACTION.DELETE_PRODUCT_FOLDER,
  payload: id,
});

export const deleteProductFolderSuccess = (id) => ({
  type: ACTION.DELETE_PRODUCT_FOLDER_SUCCESS,
  payload: id,
});

export const deleteProductFolderFailure = (error) => ({
  type: ACTION.DELETE_PRODUCT_FOLDER_FAILURE,
  payload: error,
});

export const onSelectProductFolder = (id) => ({
  type: ACTION.SELECT_PRODUCT_FOLDER,
  payload: id,
});

export const getProducts = (payload) => ({
  type: ACTION.GET_PRODUCTS,
  payload: payload,
});

export const getProductsSuccess = (data) => ({
  type: ACTION.GET_PRODUCTS_SUCCESS,
  payload: data,
});

export const getProductsFailure = (error) => ({
  type: ACTION.GET_PRODUCTS_FAILURE,
  payload: error,
});

export const addProduct = (payload) => ({
  type: ACTION.ADD_PRODUCT,
  payload: payload,
});

export const addProductSuccess = (data) => ({
  type: ACTION.ADD_PRODUCT_SUCCESS,
  payload: data,
});

export const addProductFailure = (error) => ({
  type: ACTION.ADD_PRODUCT_FAILURE,
  payload: error,
});

export const resetAddProductSuccess = () => ({
  type: ACTION.RESET_ADD_PRODUCT_SUCCESS,
});

export const updateProduct = (payload) => ({
  type: ACTION.UPDATE_PRODUCT,
  payload: payload,
});

export const updateProductSuccess = (data) => ({
  type: ACTION.UPDATE_PRODUCT_SUCCESS,
  payload: data,
});

export const updateProductFailure = (error) => ({
  type: ACTION.UPDATE_PRODUCT_FAILURE,
  payload: error,
});

export const deleteProduct = (payload) => ({
  type: ACTION.DELETE_PRODUCT,
  payload: payload,
});

export const deleteProductSuccess = (data) => ({
  type: ACTION.DELETE_PRODUCT_SUCCESS,
  payload: data,
});

export const deleteProductFailure = (error) => ({
  type: ACTION.DELETE_PRODUCT_FAILURE,
  payload: error,
});

export const deleteProductForever = (id) => ({
  type: ACTION.DELETE_PRODUCT_FOREVER,
  payload: id,
});

export const deleteProductForeverSuccess = () => ({
  type: ACTION.DELETE_PRODUCT_FOREVER_SUCCESS,
});

export const deleteProductForeverFailure = (error) => ({
  type: ACTION.DELETE_PRODUCT_FOREVER_FAILURE,
  payload: error,
});

export const resetDeleteProductSuccess = () => ({
  type: ACTION.RESET_DELETE_PRODUCT_SUCCESS,
})

export const toggleFavorite = (payload) => ({
  type: ACTION.TOGGLE_FAVORITE,
  payload: payload,
});

export const toggleFavoriteSuccess = (payload) => ({
  type: ACTION.TOGGLE_FAVORITE_SUCCESS,
  payload: payload,
});

export const toggleFavoriteFailure = (error) => ({
  type: ACTION.TOGGLE_FAVORITE_FAILURE,
  payload: error,
});

export const deSelectAllProducts = () => ({
  type: ACTION.DESELECT_ALL_PRODUCTS,
})

export const setPipelines = (payload) => ({
  type: ACTION.SET_PIPELINES,
  payload: payload,
});

export const setTeamUsers = (payload) => ({
  type: ACTION.SET_TEAM_USERS,
  payload: payload,
});

export const handleAssetLoadingState = (payload) => ({
  type: ACTION.HANDLE_ASSET_LOADING_STATE,
  payload: payload,
});