import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import App from './App';
import store from "./store/Store"
import * as serviceWorker from './serviceWorker';
import NotificationProvider from './Notification/NotificationProvider';

window.renderDealModule = (containerId, history) => {
    ReactDOM.render(
        <React.Fragment>
            <NotificationProvider>
                <Provider store={store}>
                    <App history={history} />
                </Provider>
            </NotificationProvider>
        </React.Fragment>,
        document.getElementById(containerId)
    );
    serviceWorker.unregister();
};

window.unmountDealModule = (containerId) => {
    if (document.getElementById(containerId)) {
        ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
    }
};

// if (!document.getElementById('DealModule-container')) {
//     document.getElementById('root').innerHTML = 'Permission denied';
// }
if (process.env.REACT_APP_PRODUCTION_MODE !== true && document.getElementById('DealModule-root')) {
    ReactDOM.render(
        <React.Fragment>
            <NotificationProvider>
                <Provider store={store}>
                    <App />
                </Provider>
            </NotificationProvider>
        </React.Fragment>,
        document.getElementById('DealModule-root'));
    serviceWorker.unregister();
}