import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useState } from "react";
const Notification = props => {
  const [exit, setExit] = useState(false);
  const [width, setWidth] = useState(0);
  const [intervalID, setIntervalID] = useState(null);

  const handleStartTimer = () => {
    const id = setInterval(() => {
      setWidth(prev => {
        if (prev < 100) {
          return prev + 0.5;
        }

        clearInterval(id);
        return prev;
      });
    }, 20);

    setIntervalID(id);
  };

  const handlePauseTimer = () => {
    clearInterval(intervalID);
  };

  const handleCloseNotification = () => {
    handlePauseTimer();
    setExit(true);
    setTimeout(() => {
      props.dispatch({
        type: "REMOVE_NOTIFICATION",
        id: props.id
      })
    }, 400)
  };

  React.useEffect(() => {
    if (width === 100) {
      // Close notification
      handleCloseNotification()
    }
  }, [width]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    handleStartTimer();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      onMouseEnter={handlePauseTimer}
      onMouseLeave={handleStartTimer}
      className={`notification-item ${
        props.type === "SUCCESS" ? "success" : (props.type === "ERROR" ? "error" : props.type === "WARNING" ? 'warning' : 'info') //success, warning, error
      } ${exit ? "exit" : ""}`}
    >
        <InfoIcon className="globalIcon notificationIcon infoIcon" /> 
        <ErrorOutlineIcon className="globalIcon notificationIcon errorIcon" />
        <CheckCircleOutlineIcon className="globalIcon notificationIcon successIcon" />
        <WarningIcon className="globalIcon notificationIcon warningIcon" />
        <div className="notification__message_body">
          <p>{props.message}</p>
        </div>
       <CloseIcon className="timesIcon" onClick={handleCloseNotification} />
        <div className={"bar"} style={{ width: `${width}%` }} />
      </div>
  );
};

export default Notification;