import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import DealStages from "../components/Deal";
import TableViewV2App from "../components/Deal/Deals/table-view-v2/TableViewV2App";
// import Pipeline from '../components/Deal/Pipelines';
import Pipeline from "../components/Deal/Pipelines-new/Pipeline";
import HealthCheck from "../components/HealthCheck";
import PipelineLogic from "../components/PipelineLogic";
import Products from "../components/products";

class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* <Route exact path="/"><Redirect to="/deal-stages" /></Route> */}
          {/* <Route path="/pipelines" component={Pipeline}/> */}
          <Route path="/deal-stages" component={DealStages} />
          {/* <Route path="/pipelines" component={Pipeline}/> */}
          <Route path="/pipelines" component={Pipeline} />
          <Route path={"/deal/health-check"} component={HealthCheck} />
          <Route path={"/deal/pipeline-logic"} component={PipelineLogic} />
          <Route path={"/deal/table-view"} component={TableViewV2App} />
          <Route path={"/user/products"} component={Products} />
        </Switch>
      </Router>
    );
  }
}
export default Routes;
