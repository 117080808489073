import { sendRequest, sendRequestBackEnd } from './rootApi';
import Api from "../constants/Api";

export const getContactsFromTopbarNew = async (payload) => {
    return sendRequest("post", Api.searchContactsNew, payload);
}

export const searchContactsFromElastic = async (method, payload, queryparams = null) => {
    return sendRequestBackEnd(method, Api.searchContactFromElastic, payload, queryparams);
}
