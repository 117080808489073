import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as PIPELINE_API from '../api/pipelineApi';
import * as ACTION from '../constants/pipelineActionTypes'
import Utils from "../helpers/Utils";

function* pipelineWatcher() {
    yield takeEvery(ACTION.CREATE_PIPELINE, createPipelineMiddleware);
    yield takeEvery(ACTION.CEHCK_PIPELINE_DELETE_OR_NOT, checkPipelineDeleteOrNotMiddleware);
    yield takeEvery(ACTION.DELETE_PIPELINE_AND_MOVE, deletePipelineAndMoveMiddleware);
    yield takeEvery(ACTION.UPDATE_PIPELINE, updatePipelineMiddleware);
    yield takeEvery(ACTION.GET_USER_STAGE_BY_PIPELINE, getUserStageByPipelineMiddleware);
    yield takeEvery(ACTION.SORT_USER_STAGE, sortUserStageMiddleware);
    yield takeEvery(ACTION.DELETE_AND_MOVE_TO_STAGE, deleteAndMoveToStageMiddleware);
    yield takeEvery(ACTION.USER_STAGE_SHOW_IN_CHART, userStageShowInChartMiddleware);
    yield takeEvery(ACTION.ADD_USER_STAGE_SUBMIT, addUserStageSubmitMiddleware);
    yield takeEvery(ACTION.UPDATE_PIPELINE_SHOW_OPTION, updatePipelineShowOptionMiddleware);
}

function* createPipelineMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(PIPELINE_API.createPipelineApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* checkPipelineDeleteOrNotMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(PIPELINE_API.checkPipelineDeleteOrNotApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deletePipelineAndMoveMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(PIPELINE_API.deletePipelineAndMoveApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updatePipelineMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(PIPELINE_API.updatePipelineApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getUserStageByPipelineMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(PIPELINE_API.getUserStageByPipelineApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* sortUserStageMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(PIPELINE_API.sortUserStageApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deleteAndMoveToStageMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(PIPELINE_API.deleteAndMoveToStageApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* userStageShowInChartMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(PIPELINE_API.userStageShowInChartApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* addUserStageSubmitMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(PIPELINE_API.addUserStageSubmitApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updatePipelineShowOptionMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(PIPELINE_API.updatePipelineShowOptionApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* pipelineMiddleware() {
    yield all([
        pipelineWatcher(),
    ])
}
