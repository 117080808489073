import React, { useEffect, useState } from "react";
import Utils from "../../helpers/Utils";
import ApiListAddDeal from "./assets/ApiListAddDeal";
import CustomDatePicker from "./assets/components/CustomDatePicker";
import { DropdownHelper } from "./assets/components/DropdownHelper";

const PipelineInformaiton = (props) => {
  const [stageList, setStageList] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState("");

  useEffect(() => {
    setSelectedPipeline(checkCookie);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedPipeline !== "") {
      ApiListAddDeal.getAllStageByPipeline({ pipelineId: selectedPipeline }).then((res) => {
        let response = res.data;
        setStageList(response.dealStage);
      });
    }
  }, [selectedPipeline])

  const getCookieData = () => {
    let name = `selectd_pipeline_id_in_deal:${Utils.getAccountData("userId")}=`;
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const checkCookie = (cname = `selectd_pipeline_id_in_deal:${Utils.getAccountData("userId")}`) => {
    let res = getCookieData(cname);
    return res;
  };





  const handlePipelineChange = (value) => {
    setStageList([])
    props.handleUpdateState({ stage: "" });
    props.getSelectedPipelineId(value);
    setSelectedPipeline(value);


  };

  const handleStage = (value) => {
    props.handleUpdateState({ stage: value });
  };

  const renderPipelineList = () => {
    if (Utils.getAccountData('userIsAgent')) {
      const list = [];
      props.pipelines.forEach(item => {
        if (item.user.role_user.role_id === 3 && item.user_id === Utils.getAccountData('userId')) {
          list.push(item)
        }
        else if (item.user.role_user.role_id !== 3) {
          list.push(item)
        }
      })
      return list;
    }
    return props.pipelines
  }

  return (
    <div className="deal__single__section__wr">
      <div className="deal__single__section__wr__row_1">
        <h3 className="add__deal__label__title">Deal Title</h3>
        <input
          className="add__deal__text__field"
          min="0"
          value={props.prevStates.title}
          onChange={(event) =>
            props.handleUpdateState({ title: event.target.value })
          }
          placeholder="Enter title"
        />
      </div>

      <div className="deal__single__section__wr__row_2">
        <div className="deal__single__section__wr__row_3">
          <h3 className="add__deal__label__title">Select Pipeline</h3>

          <DropdownHelper
            // datalist={props.pipelines}
            datalist={renderPipelineList()}
            noneFieldRequired={true}
            noneFieldTitle="Select Pipeline"
            className=""
            // mapping={{ title: "label", id: "value" }}
            mapping={{ title: "title", id: "id" }}
            menuItemClassName=""
            updateSelectedData={handlePipelineChange}
            selectedValue={selectedPipeline}
            updateDatatable={() => console.log("update")}
            dropdownHeader={"ownerChange"}
            parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"
            from="add-deal"
          />
        </div>

        <div className="">
          <h3 className="add__deal__label__title">Select a stage</h3>

          <DropdownHelper
            datalist={stageList}
            noneFieldRequired={true}
            noneFieldTitle="Select stage"
            className=""
            mapping={{ title: "label", id: "value" }}
            menuItemClassName=""
            updateSelectedData={handleStage}
            selectedValue={props.prevStates.stage}
            updateDatatable={() => console.log("update")}
            dropdownHeader={"ownerChange"}
            parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"
            from="add-deal"
          />
        </div>
      </div>

      <div className="deal__value__with__estimate__date">
        <div className="deal__value__with__estimate__1">
          <h3 className="add__deal__label__title">Deal Value</h3>
          <input
            className="add__deal__text__field"
            min="0"
            value={props.prevStates.dealValue}
            onChange={(event) =>
              props.handleUpdateState({ dealValue: event.target.value })
            }
            type="number"
            placeholder="Enter deal value"
          />
        </div>

        <div className="deal__value__with__estimate__2">
          <h3 className="add__deal__label__title">Estimate Close Date</h3>
          <div className="add__deal__text__field add__deal__text__field__datePicker">
            <CustomDatePicker
              value={props.prevStates.estimatedClosingDate}
              changeDate={(date) =>
                props.handleUpdateState({ estimatedClosingDate: date })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelineInformaiton;
