import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { BasicInput } from "../../../../products/productService/sidebar/CreateNewFolderModal";
import {
  getContactStageProductsApi,
  getUserProductsApi,
  updateSuccessDealApi,
} from "../../../../../api/dealApi";
import CustomTitleAddDeal from "./CustomTitleAddDeal";
import BasicSelect from "./BasicSelect";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {
  BasicAddButton,
  BasicFormLabel,
  ContentWrapper,
} from "./GlobalAddDealModalStyles";

const modalStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 700,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
  },
  modalHeader: {
    padding: "16px",
    background: "#346fef",
    color: "#fff",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .closeIcon": {
      display: "flex",
      cursor: "pointer",
      "& svg": {
        color: "#fff",
      },
    },
  },
  modalBody: {
    padding: "16px",
  },
  modalBodyInput: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },

  modalFooter: {
    background: "rgb(24, 31, 72)",
    borderEndEndRadius: "8px",
    borderEndStartRadius: "8px",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .title": {
      color: "#fff",
      fontWeight: "600",
    },

    "& .btnSubmit": {
      background: "#346fef !important",
    },
  },
  errorText: {
    color: "red",
    marginTop: "4px",
    fontSize: "0.875rem",
  },
}));

const formatDateForInput = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const ConfirmDealModal = ({ open, onClose, contact_stages_id }) => {
  const today = new Date();
  const formattedDate = formatDateForInput(today);
  const [title, setTitle] = useState("");
  const [closeDate, setCloseDate] = useState(formattedDate);
  const [dealValue, setDealValue] = useState(0);
  const [userProducts, setUserProducts] = useState([]);
  const [contactStageProduct, setContactStageProduct] = useState([]);
  const [errors, setErrors] = useState({
    title: "",
    dealValue: "",
    userProducts: [],
  });

  useEffect(() => {
    getContactStageProducts();
    getUserProducts();
  }, []);

  const getContactStageProducts = async () => {
    const resp = await getContactStageProductsApi(contact_stages_id);

    if (resp.data.success) {
      setTitle(resp.data.data.contactStage?.title);
      const closeDate =
        resp.data.data.contactStage?.estimate_closing_date?.split(" ")[0];
      setCloseDate(closeDate || formattedDate);
      setDealValue(resp.data.data.contactStage?.deal_value);

      setContactStageProduct(resp.data.data.products);
    }
  };

  const getUserProducts = async () => {
    const resp = await getUserProductsApi();

    if (resp.data.success) {
      setUserProducts(resp.data.data);
    } else {
      setUserProducts([]);
    }
  };

  const handleAddProduct = () => {
    let old = [...contactStageProduct];

    old.push({
      user_product_id: "",
      value: 0,
    });

    setContactStageProduct(old);
    setErrors((prevErrors) => ({
      ...prevErrors,
      userProducts: [...prevErrors.userProducts, { user_product_id: "", value: "" }],
    }));
  };

  const handleProductChange = (index, key, value) => {
    // console.log('ads')
    let old = [...contactStageProduct];
    let errorCopy = [...errors.userProducts];

    if (key === "value" && typeof value === "string" && value.trim() !== "") {
      if (value.length > 10) {
        return;
      }
      value = parseFloat(value)
      let parsedDealValue = parseFloat(dealValue)
      const selectedValue = parseInt(value);
      if (isNaN(selectedValue)) {
        return;
      }
      if (old[index][key] === 0) {
        if (parsedDealValue + value >= 0) {
          setDealValue(parsedDealValue + value)
        } else {
          setDealValue(0)

        }
      }
      else if (old[index][key] >= value) {
        if (parsedDealValue - parseFloat(old[index][key]) + value >= 0) {
          setDealValue(parsedDealValue - parseFloat(old[index][key]) + value)
        } else {
          setDealValue(0)
        }
      } else {

        if (!old[index][key]) {
          if (parsedDealValue + value >= 0) {
            setDealValue(parsedDealValue + value)
          } else {
            setDealValue(0)
          }

        } else {
          if (parsedDealValue + value - parseFloat(old[index][key]) >= 0) {
            setDealValue(parsedDealValue + value - parseFloat(old[index][key]))
          } else {
            setDealValue(0)
          }
        }

      }

    }
    old[index][key] = value;
    if (key === "user_product_id" && value === "") {
      if (errorCopy[index]) {
        errorCopy[index].user_product_id = "Product cannot be empty";
      }

    } else {
      if (errorCopy[index]) {
        errorCopy[index].user_product_id = "";
      }
    }

    setContactStageProduct(old);
    setErrors((prevErrors) => ({ ...prevErrors, userProducts: errorCopy }));
  };

  const handleDeleteProduct = (index) => {
    let old = [...contactStageProduct];
    let errorCopy = [...errors.userProducts];

    old.splice(index, 1);
    errorCopy.splice(index, 1);

    setContactStageProduct(old);
    setErrors((prevErrors) => ({ ...prevErrors, userProducts: errorCopy }));
  };

  const sumProductValue = () => {
    let totalValue = 0;

    contactStageProduct?.forEach((selectedProduct) => {
      const selectedValue = parseFloat(selectedProduct.value);
      if (!isNaN(selectedValue)) {
        totalValue += selectedValue;
      }
    });

    return totalValue;
  };

  const validateForm = () => {
    let formValid = true;
    let errors = { title: "", dealValue: "", userProducts: [] };

    contactStageProduct.forEach((product, index) => {
      if (product?.user_product_id === "") {
        errors.userProducts[index] = { user_product_id: "Product cannot be empty" };
        formValid = false;
      }
    });

    setErrors(errors);
    return formValid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const payload = {
      title,
      estimate_closing_date: closeDate,
      deal_value: dealValue,
      user_contact_stage_products: contactStageProduct,
    };

    const response = await updateSuccessDealApi({ id: contact_stages_id, payload });

    if (response.data.success) {
      window.showNotification("SUCCESS", response.data.message);
      onClose();
    } else {
      window.showNotification("ERROR", response.data.message);
      onClose();
    }

  };

  const classes = modalStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <Typography style={{ height: 'unset !important' }} variant="h5">Confirm Your Deal Information</Typography>

            <div className="closeIcon" onClick={onClose}>
              <Close />
            </div>
          </div>

          <div className={classes.modalBody}>
            <ContentWrapper>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography style={{ height: 'unset !important' }} className={errors.title ? classes.errorText : ""}>
                    Deal Title
                  </Typography>
                  <div>
                    <BasicInput
                      fullWidth
                      value={title}
                      onChange={(e) => { setTitle(e.target.value); validateForm() }}
                      required
                      className={errors.title ? classes.errorStyle : ""}
                    />
                    {errors.title && (
                      <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                        {errors.title}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ height: 'unset !important' }}>Close Date</Typography>
                  <div>
                    <BasicInput
                      type="date"
                      fullWidth
                      value={closeDate}
                      onChange={(e) => setCloseDate(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ height: 'unset !important' }}>Deal Value</Typography>
                  <div className="productValueInputWrapper">
                    <span className="productValueSign">$</span>
                    <BasicInput
                      name="value"
                      value={dealValue}
                      fullWidth
                      type="number"
                      onChange={(e) => setDealValue(e.target.value)}
                    />
                    {errors.dealValue && (
                      <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                        {errors.dealValue}
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                style={{ maxHeight: "calc(100vh - 400px)", overflowY: "auto", marginTop: 16 }}
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  <CustomTitleAddDeal
                    title={"Products associated with the deal:"}
                  />
                </Grid>

                <Grid
                  container
                  style={{ marginTop: "4px", padding: "0px 8px" }}
                >
                  {contactStageProduct.length > 0 && (
                    <>
                      <Grid item xs={9} style={{ textAlign: "center" }}>
                        <BasicFormLabel variant="body1">Product</BasicFormLabel>
                      </Grid>

                      <Grid item xs={3} style={{ textAlign: "center" }}>
                        <BasicFormLabel variant="body1">Value</BasicFormLabel>
                      </Grid>
                    </>
                  )}

                  {contactStageProduct?.map((product, index) => (
                    <Grid
                      container
                      spacing={2}
                      className="productItem"
                      key={index}
                    >
                      <Grid item xs={9}>
                        <BasicSelect
                          name="title"
                          options={userProducts}
                          mapping={{
                            label: "title",
                            value: "id",
                          }}
                          deletedMapping={{
                            field: "status",
                            value: "TRASH",
                          }}
                          defaultText="Select product"
                          value={product.user_product_id}
                          onChange={(e) => {
                            handleProductChange(
                              index,
                              "user_product_id",
                              e.target.value
                            );
                          }}
                          selectedOption={(option) => {
                            handleProductChange(
                              index,
                              "value",
                              option.unit_price
                            );
                          }}
                        />
                        {errors.userProducts[index]?.user_product_id && (
                          <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                            {errors.userProducts[index]?.user_product_id}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={3}>
                        <div className="productValueWrapper">
                          <div className="productValueInputWrapper">
                            <span className="productValueSign">$</span>
                            <BasicInput
                              name="value"
                              value={product.value}
                              fullWidth
                              disabled={product.user_product_id === '' ? true : false}
                              onChange={(e) => {
                                handleProductChange(
                                  index,
                                  "value",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <IconButton
                            size="small"
                            onClick={() => {
                              handleDeleteProduct(index);
                            }}
                          >
                            <DeleteForeverIcon color={"error"} />
                          </IconButton>
                        </div>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <BasicAddButton
                    startIcon={<AddIcon />}
                    onClick={handleAddProduct}
                  >
                    Add New Item
                  </BasicAddButton>
                </Grid>
              </Grid>
            </ContentWrapper>
          </div>

          <div className={classes.modalFooter}>
            <Typography style={{ height: 'unset !important' }} className="title" variant="h5" color="inherit">
              Total Deal Value: ${dealValue || 0}
            </Typography>
            <Button
              className="btnSubmit #346fef"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ConfirmDealModal;
