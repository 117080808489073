import {InputBase, Button, styled, withStyles, Typography, Modal} from "@material-ui/core";

export const ContentWrapper = styled("div")(({ theme }) => ({
    position: "relative",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    "& .textCenter": {
        textAlign: "center",
    },

    "& .productItem:not(:last-child)": {
        marginBottom: "8px",
    },

    "& .productValueWrapper": {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
    "& .productValueInputWrapper": {
        position: "relative"
    },
    "& .productValueSign": {
        position: "absolute",
        top: "8px",
        left: "10px",
        zIndex: 1
    },
    "& .productValueInputWrapper input": {
        paddingLeft: "20px !important"
    }
}));

export const BasicAddButton = withStyles((theme) => ({
    root: {
        color: "#3C7EF3 !important",
        fontWeight: "600 !important",
        "&:focus": { backgroundColor: "transparent !important"}
    }
}))(Button);

export const BasicFormLabel = withStyles((theme) => ({
    root: {
        color: "var(--dark_blue) !important",
        fontSize: "14px !important",
        fontWeight: "500 !important",
        marginBottom: "4px !important",
        height: "unset !important"
    }
}))(Typography);

export const BasicInputSelect = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        backgroundColor: "white !important",
        position: "relative",
        color: "var(--dark_blue)",
        fontSize: "13px",
        margin: "0 !important",

        padding: " 9px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),

        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
            backgroundColor: "#fff !important"
        },
    },
}))(InputBase);