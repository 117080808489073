import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { confirmAlert } from "react-confirm-alert";
import { connect } from 'react-redux';
// import useDelayCallback from "../../../hooks/useDelayCallback";
import { changeDealStatus, reorderDealStage, storeSelectedDealInfo, updateDealFilerOptions, updateDealStageReloadFlag } from "../../../../actions/DealAction";
import { getAllUserStagesApi } from "../../../../api/dealApi";
import Utils from "../../../../helpers/Utils";
import { LeftArrowDouble, RightArrowDouble } from "../../icons/DealIconList";
import MoveDealToPipelineModal from "../MoveDealToPipelineModal";
import './css/grid_view_alt.css';
import EachStageContainer from "./EachStageContainer";

var deal_left_arrow_interval = null
var deal_right_arrow_interval = null

var deal_grid_view_arrow_key_interval = null

const GridViewApp = props => {
  const [userStates, setUserStages] = useState([]);
  const [startDrag, setStartDrag] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [perPage, setPerPage] = useState(10)
  const [dragAndDropProcess, setDragAndDropProcess] = useState(null)

  /* for moving deal */
  const [showHideMoveDealToPipelineModal, setShowHideMoveDealToPipelineModal] = useState(false);
  const [selectedDealToMove, setSelectedDealToMove] = useState('')

  const [reRender, setReRender] = useState(false)
  const [status, setStatus] = useState(props.status);

  const container_scroll_ref = useRef(null)

  useEffect(() => {
    let calculatePerPage = Math.floor(window.innerHeight / 80);
    if (calculatePerPage > 10) {
      let perPage = calculatePerPage;
      setPerPage(perPage)
    }

    addKeyPressEventListnerMethod(true)
    return () => {
      addKeyPressEventListnerMethod(false)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.pipelineId !== '') {
      // eslint-disable-next-line eqeqeq
      if (status != props.status) {
        getUserStage(true)
        setStatus(props.status)
      } else {
        setIsLoading(true)
        getUserStage()
      }
    } else {
      setIsLoading(false);
      /* new changes */
      setStatus(props.status);
      props.handleStatsValue({
        totalDeals: 0,
        totalDealValue: 0
      });
      setUserStages([])
    }
  }, [props.pipelineId, props.status]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.needToReloadDeal) {
      setIsLoading(true)
      getUserStage()
      props.handleReload()
    }
  }, [props.needToReloadDeal]) // eslint-disable-line react-hooks/exhaustive-deps

  const isSidebarCollapsed = window?.isMenubarCollapsed();


  const addKeyPressEventListnerMethod = (add = true) => {
    if (add) {
      document.addEventListener('keydown', addKeyDownEventListner, false);
      document.addEventListener('keyup', addKeyUpEventListner, false);
    }
    else {
      document.removeEventListener('keydown', addKeyDownEventListner, false);
      document.removeEventListener('keyup', addKeyUpEventListner, false);
    }
  }
  const addKeyDownEventListner = (event) => {
    const code = event.code;
    if (code === 'ArrowRight') {
      try {
        if (deal_grid_view_arrow_key_interval == null) {
          deal_grid_view_arrow_key_interval = setInterval(() => {
            container_scroll_ref.current.style.scrollBehavior = "smooth";
            container_scroll_ref.current.scrollLeft += 250
          }, 100);
        }
      }
      catch (error) { }
    } else if (code === 'ArrowLeft') {
      try {
        if (deal_grid_view_arrow_key_interval == null) {
          deal_grid_view_arrow_key_interval = setInterval(() => {
            container_scroll_ref.current.style.scrollBehavior = "smooth";
            container_scroll_ref.current.scrollLeft -= 250
          }, 100);
        }
      }
      catch (error) { }
    }
  }
  const addKeyUpEventListner = (event) => {
    const code = event.code;
    if (code === 'ArrowRight' || code === 'ArrowLeft') {
      if (deal_grid_view_arrow_key_interval != null) {
        clearInterval(deal_grid_view_arrow_key_interval)
        deal_grid_view_arrow_key_interval = null
      }
    }
  }

  const getUserStage = (only_value = false) => {
    getAllUserStagesApi({
      'pipelineId': props.pipelineId,
      'dealStatus': props.status,
      'startDate': props.dateTime.startDate != null ? moment(props.dateTime.startDate).format('YYYY-MM-DD') : null,
      'endDate': props.dateTime.endDate != null ? moment(props.dateTime.endDate).format('YYYY-MM-DD') : null,
      'search': ''
    }).then(res => {
      let response = res.data;
      if (response.status === 'success') {
        let totalDeal = 0;
        if (props.status == 1) {
          totalDeal = response.total_deal_stats.active === null ? 0 : response.total_deal_stats.active
        } else if (props.status == 2) {
          totalDeal = response.total_deal_stats.win === null ? 0 : response.total_deal_stats.win
        } else if (props.status == 3) {
          totalDeal = response.total_deal_stats.lost === null ? 0 : response.total_deal_stats.lost
        }
        props.handleStatsValue({
          totalDeals: totalDeal,
          totalDealValue: response.total_deal_stats.total_deals === null ? 0 : response.total_deal_stats.total_deals
        });
        if (!only_value) {
          setUserStages(response.dealStage)
        }
      }
      else {
        props.handleStatsValue({
          totalDeals: 0,
          totalDealValue: 0
        });
        if (!only_value) {
          setUserStages([])
        }
        window.showNotification("ERROR", response.message);
      }
      setIsLoading(false)
    }).catch(err => {
      setIsLoading(false)
      window.showNotification("ERROR", "Something went wrong!");
    })
  }

  /* handle drag and drop */
  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      setStartDrag(false)
      return;
    }

    // total deal, total contact for bath stage and pipeline for delete

    let destinationId = destination.droppableId.replace('dropable-context-', ''),
      destinationIndex = destination.index,
      sourceId = parseInt(source.droppableId.replace('dropable-context-', '')),
      sourceIndex = source.index;

    setStartDrag(false)

    if (destinationId === 'delete' || destinationId === 'win' || destinationId === 'lost' || destinationId === 'pipeline') {
      let changeStatusValue = 4;
      let message = ''
      if (destinationId === 'delete') {
        changeStatusValue = 4
        message = 'Deal delete successfully'
      }
      else if (destinationId === 'win') {
        changeStatusValue = 2
        message = 'Deal status changed to WON successfully'
      }
      else if (destinationId === 'lost') {
        changeStatusValue = 3
        message = 'Deal status changed to LOST successfully'
      }
      else if (destinationId === 'pipeline') {
        handleShowHideDealMoveToPipelineModal(true)
        setSelectedDealToMove(draggableId.replace('draggable-', ''))
        return;
      }
      // get confirmation alert before action
      if (destinationId === 'delete') {
        confirmAlert({
          title: 'Confirm to delete?',
          message: 'Are you sure to want to delete this deal.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                setStartDrag(false)
                changeDealStatus(changeStatusValue, draggableId.replace('draggable-', ''), sourceId, sourceIndex, message)
              }
            },
            {
              label: 'No',
              onClick: () => {
                setStartDrag(false)
                return;
              }
            }
          ]
        });
      }
      else {
        changeDealStatus(changeStatusValue, draggableId.replace('draggable-', ''), sourceId, sourceIndex, message)
      }
    }
    else {
      let soruce_id = userStates[sourceId].id,
        destination_id = userStates[destinationId].id

      const [removed] = userStates[sourceId].all_deals.splice(sourceIndex, 1);
      removed['user_stage_id'] = destination_id;
      userStates[destinationId].all_deals.splice(destinationIndex, 0, removed);
      setDragAndDropProcess({
        destinationIndex: destinationIndex,
        destinationStageIndex: destinationId,
        sourceIndex: sourceIndex,
        sourceStageIndex: sourceId,
        selectedDealIndex: sourceIndex,
        selectedDealData: removed
      })

      props.reorderDealStage({
        source_stage: 'stage-' + soruce_id,
        destination_stage: 'stage-' + destination_id,
        stage_id: draggableId.replace('draggable-', ''),
        order: destination.index,
        old_order: sourceIndex
      }, (response) => {
        if (response.deal_info !== undefined) {
          // const [removed] = userStates[sourceId].all_deals.splice(sourceIndex, 1);
          // removed['contact_stages_id'] = response.deal_info?.id;
          // userStates[destinationId].all_deals.splice(destinationIndex, 0, removed);
          // setDragAndDropProcess({
          //     destinationIndex: destinationIndex,
          //     destinationStageIndex: destinationId,
          //     sourceIndex: sourceIndex,
          //     sourceStageIndex: sourceId,
          //     selectedDealIndex: sourceIndex,
          //     selectedDealData: removed
          // })
          userStates[destinationId].all_deals[destinationIndex]['contact_stages_id'] = response.deal_info?.id;
          setReRender(!reRender)
        }
      })
    }
  };

  const onDragStart = (result) => {
    setStartDrag(true)
  }
  /* end */

  const handleDragAndDropProcess = () => {
    setDragAndDropProcess(null)
  }

  /* handle full data start */
  const handleDealFullDataState = (stageIndex, data, removeOldData = false) => {

    let oldData = [...userStates];
    /* index:  all_deals:[] */
    if (oldData[stageIndex] !== undefined) {
      if (oldData[stageIndex]['all_deals'] !== undefined) {
        if (removeOldData) {
          oldData[stageIndex]['all_deals'] = data;
        } else {
          oldData[stageIndex]['all_deals'] = [...oldData[stageIndex]['all_deals'], ...data]
        }
      }
      else {
        oldData[stageIndex]['all_deals'] = data;
      }
    }
    setUserStages(oldData)
  }
  /* handle full data end */
  const handleDealProperties = (stageIndex, dealIndex, dealData) => {
    let oldData = [...userStates];
    if (oldData[stageIndex] !== undefined) {
      if (oldData[stageIndex]['all_deals'] !== undefined) {
        if (oldData[stageIndex]['all_deals'][dealIndex] !== undefined) {
          oldData[stageIndex]['all_deals'][dealIndex] = dealData
        }
      }
    }
    setUserStages(oldData)
  }

  const renderStage = () => {
    let stages = [];
    userStates.map((eachStageItem, eachStageIndex) => {
      stages.push(
        <EachStageContainer
          item={eachStageItem}
          index={eachStageIndex}
          key={eachStageIndex}
          itemPerPage={perPage}
          startDrag={startDrag}
          dragAndDropProcess={dragAndDropProcess}
          handleDragAndDropProcess={handleDragAndDropProcess}

          /* new state to handle full state */
          stageDealData={userStates[eachStageIndex]}
          handleDealFullDataState={handleDealFullDataState}
          /* for more action */
          handleAddDealModal={props.handleAddDealModal}
          handleMoreOptionModal={props.handleMoreOptionModal}

          handlActions={{
            delete: hanldeDeleteActionFromChild,
            move: (contact_stage_id) => {
              handleShowHideDealMoveToPipelineModal(true)
              setSelectedDealToMove(contact_stage_id)
            }
          }}

          handleDealProperties={handleDealProperties}
        />
      )
    })
    return stages;
  }

  const renderActionButton = () => {
    return (
      <div className={`deal-handle-buttons-v2`}>
        {/* <Droppable droppableId={`dropable-context-delete`} key={'dropable-context-delete'}>
                {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    id={'dropable-context-delete'}
                    style={{
                        background: snapshot.isDraggingOver ? "lightblue" : ""
                    }}
                    className={'deal__handle__buttons__each__v2 deal__handle__buttons__delete__v2'}
                    key={'dropable-context-delete'}
                >
                    <div className={`deal__actions__buttons__each__v2 deal__actions__buttons__delete__v2`}>
                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.2356 2.42308H10.9038V1.81731C10.9038 0.813624 10.0902 0 9.08654 0H6.66346C5.65978 0 4.84615 0.813624 4.84615 1.81731V2.42308H1.51442C0.678045 2.42308 0 3.10112 0 3.9375V5.14904C0 5.48361 0.271195 5.75481 0.605769 5.75481H15.1442C15.4788 5.75481 15.75 5.48361 15.75 5.14904V3.9375C15.75 3.10112 15.072 2.42308 14.2356 2.42308ZM6.05769 1.81731C6.05769 1.48338 6.32953 1.21154 6.66346 1.21154H9.08654C9.42047 1.21154 9.69231 1.48338 9.69231 1.81731V2.42308H6.05769V1.81731Z" fill="#FF5858"></path>
                            <path d="M1.14974 6.96484C1.04165 6.96484 0.95552 7.05518 0.960669 7.16316L1.46043 17.6521C1.50662 18.6229 2.30396 19.3831 3.27546 19.3831H12.4748C13.4463 19.3831 14.2437 18.6229 14.2899 17.6521L14.7896 7.16316C14.7948 7.05518 14.7086 6.96484 14.6005 6.96484H1.14974ZM10.2982 8.47927C10.2982 8.14458 10.5693 7.8735 10.904 7.8735C11.2387 7.8735 11.5098 8.14458 11.5098 8.47927V16.3543C11.5098 16.689 11.2387 16.96 10.904 16.96C10.5693 16.96 10.2982 16.689 10.2982 16.3543V8.47927ZM7.26938 8.47927C7.26938 8.14458 7.54046 7.8735 7.87515 7.8735C8.20983 7.8735 8.48092 8.14458 8.48092 8.47927V16.3543C8.48092 16.689 8.20983 16.96 7.87515 16.96C7.54046 16.96 7.26938 16.689 7.26938 16.3543V8.47927ZM4.24053 8.47927C4.24053 8.14458 4.51161 7.8735 4.8463 7.8735C5.18099 7.8735 5.45207 8.14458 5.45207 8.47927V16.3543C5.45207 16.689 5.18099 16.96 4.8463 16.96C4.51161 16.96 4.24053 16.689 4.24053 16.3543V8.47927Z" fill="#FF5858"></path>
                        </svg>
                        <span>{'DELETE'}</span>
                    </div>
                    {provided.placeholder}
                </div>
                )}
            </Droppable> */}

        {parseInt(props.status) !== 2 &&
          <Droppable droppableId={`dropable-context-win`} key={'dropable-context-win'}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                id={'dropable-context-win'}
                className={'deal__handle__buttons__each__v2 deal__handle__buttons__won__v2'}
                // style={{
                //   background: snapshot.isDraggingOver ? "#6fcf97" : ""
                // }}
                key={'dropable-context-win'}
              >
                <div
                  className={`deal__actions__buttons__each__v2 deal__actions__buttons__won__v2`}
                  style={{
                    background: snapshot.isDraggingOver ? "#6fcf97" : ""
                  }}
                >
                  {/* {IconList.likeIconAlt} */}
                  <svg width="24" height="22" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.45867 18.5127H3.54029C3.92689 18.5131 4.29784 18.36 4.57163 18.0871C4.84543 17.8141 4.99968 17.4437 5.00049 17.0571V7.4862C4.99968 7.09986 4.84564 6.72963 4.57218 6.45674C4.29871 6.18385 3.92816 6.03059 3.54182 6.03059H1.45867C1.07233 6.03059 0.70178 6.18385 0.428315 6.45674C0.154849 6.72963 0.000810772 7.09986 0 7.4862L0 17.0555C0 17.8576 0.653569 18.5112 1.45867 18.5112V18.5127ZM15.7346 18.7208C16.52 18.7221 17.2804 18.4454 17.8813 17.9397C18.4822 17.4341 18.8848 16.7321 19.0178 15.9581L19.9515 10.5505C20.0341 10.0722 20.011 9.58156 19.884 9.11309C19.7569 8.64462 19.5289 8.20962 19.2159 7.8386C18.9029 7.46758 18.5126 7.16952 18.0722 6.96534C17.6319 6.76115 17.1522 6.65578 16.6668 6.65661H12.7346C12.7346 6.65661 13.3607 5.40763 13.3607 3.32754C13.3607 0.83265 11.4857 0 10.6515 0C9.81884 0 9.40252 0.416325 9.40252 2.49489C9.40252 4.47243 7.48467 6.06426 6.251 6.88314V17.1902C7.58569 17.8071 10.2566 18.7208 14.4015 18.7208H15.7362H15.7346Z" fill="#000000" />
                  </svg>
                  <span>{'WON'}</span>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        }

        {parseInt(props.status) !== 3 &&
          <Droppable droppableId={`dropable-context-lost`} key={'dropable-context-lost'}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                id={'dropable-context-lost'}
                className={'deal__handle__buttons__each__v2 deal__handle__buttons__lost__v2'}
                // style={{
                //   background: snapshot.isDraggingOver ? "#ff0001" : ""
                // }}
                key={'dropable-context-lost'}
              >
                <div
                  className={`deal__actions__buttons__each__v2 deal__actions__buttons__lost__v2`}
                  style={{
                    background: snapshot.isDraggingOver ? "#ff0001" : ""
                  }}
                >
                  {/* {IconList.dislikeIcon} */}
                  <svg width="24" height="22" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.5413 0.208166H16.4582C16.0718 0.208166 15.7013 0.361426 15.4278 0.634318C15.1544 0.907209 15.0003 1.27744 14.9995 1.66377V11.2347C14.9995 12.0367 15.6531 12.6903 16.4582 12.6903H18.5398C18.9264 12.6907 19.2973 12.5376 19.5711 12.2647C19.8449 11.9917 19.9992 11.6213 20 11.2347V1.66377C19.9992 1.27744 19.8451 0.907209 19.5717 0.634318C19.2982 0.361426 18.9277 0.208166 18.5413 0.208166ZM4.26537 4.04351e-06C3.48003 -0.00121998 2.71959 0.275489 2.1187 0.781138C1.5178 1.28679 1.11521 1.98875 0.982217 2.76275L0.048547 8.17038C-0.0340424 8.64857 -0.0110436 9.13903 0.115931 9.60739C0.242906 10.0757 0.470784 10.5107 0.78359 10.8817C1.0964 11.2527 1.48656 11.5507 1.92673 11.755C2.3669 11.9593 2.84643 12.0648 3.3317 12.0642H7.26536C7.26536 12.0642 6.64087 13.3132 6.64087 15.3933C6.64087 17.8882 8.51586 18.7208 9.35004 18.7208C10.1812 18.7208 10.599 18.3045 10.599 16.226C10.599 14.2484 12.5153 12.6566 13.749 11.8377V1.53061C12.4158 0.913776 9.74494 4.04351e-06 5.59853 4.04351e-06H4.26537Z" fill="#000" />
                  </svg>
                  <span>{'LOST'}</span>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        }

        {/* <Droppable droppableId={`dropable-context-pipeline`} key={'dropable-context-pipeline'}>
                {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    id={'dropable-context-pipeline'}
                    className={'deal__handle__buttons__each__v2 deal__handle__buttons__pipeline__v2'}
                    style={{
                        background: snapshot.isDraggingOver ? "lightblue" : ""
                    }}
                    key={'dropable-context-pipeline'}
                >
                    <div className={`deal__actions__buttons__each__v2 deal__actions__buttons__pipeline__v2`}>
                        <span>{IconList.pipelineIcon}</span>
                        <span>{'PIPELINE'}</span>
                    </div>
                    {provided.placeholder}
                </div>
                )}
            </Droppable> */}
      </div>
    )
  }

  const handleShowHideDealMoveToPipelineModal = (status = false) => {
    setShowHideMoveDealToPipelineModal(status)
  }

  const refreshDataList = () => {
    setIsLoading(true);
    getUserStage();
  }

  const changeDealStatus = (status, stage_id, sourceId, sourceIndex, showAlertMessgae = '') => {
    if (status === 2){
      if (Utils.getAccountData("agencyBusinessType") === "INSURANCE"){
        window.globalInsuranceDealStatus(true, {
          callback: (data) => {
            if (data){
              const [removed] = userStates[sourceId].all_deals.splice(sourceIndex, 1);
              setDragAndDropProcess({
                destinationIndex: null,
                destinationStageIndex: null,
                sourceIndex: sourceIndex,
                sourceStageIndex: sourceId,
                selectedDealIndex: sourceIndex,
                selectedDealData: removed
              })
              setReRender(!reRender);
            }
          },
          contact_stages_id: stage_id,
          status: 2
        });
      }else {
        window.globalDealStatus(true, {
          callback: (data) => {
            if (data){
              const [removed] = userStates[sourceId].all_deals.splice(sourceIndex, 1);
              setDragAndDropProcess({
                destinationIndex: null,
                destinationStageIndex: null,
                sourceIndex: sourceIndex,
                sourceStageIndex: sourceId,
                selectedDealIndex: sourceIndex,
                selectedDealData: removed
              })
              setReRender(!reRender);
            }
          },
          contact_stages_id: stage_id,
          status: 2
        });
      }
    }else {
      props.changeDealStatus({
        stage_id: stage_id,
        status: status
      }, (res) => {
        if (res.status === 'success') {
          window.showNotification("SUCCESS", 'Deal mark as lost successfully !');
          const [removed] = userStates[sourceId].all_deals.splice(sourceIndex, 1);
          setDragAndDropProcess({
            destinationIndex: null,
            destinationStageIndex: null,
            sourceIndex: sourceIndex,
            sourceStageIndex: sourceId,
            selectedDealIndex: sourceIndex,
            selectedDealData: removed
          })
          setReRender(!reRender);
          // props.reloadDealStageMethod(true)
        }
        else {
          window.showNotification("ERROR", "Something went wrong!");
        }
      })
    }
    setStartDrag(false)
  }

  const hanldeDeleteActionFromChild = (stageIndex, dealIndex, dealId) => {
    const [removed] = userStates[stageIndex].all_deals.splice(dealIndex, 1);
    setDragAndDropProcess({
      destinationIndex: null,
      destinationStageIndex: null,
      sourceIndex: dealIndex,
      sourceStageIndex: stageIndex,
      selectedDealIndex: dealIndex,
      selectedDealData: removed
    })
    setReRender(!reRender);
  }

  return (
    <div className={`deal__update__middle__wrapper__alt__v2 ${userStates.length === 0 ? 'empty__container__v2' : ''} ${isLoading ? 'empty__container__loading__v2' : ''} ${startDrag ? 'deal__update__card__wrapper__alt__active' : ''}`}>
      {isLoading &&
        <div style={{ position: 'absolute' }}><p>Loading data...</p></div>
      }

      {(!isLoading && userStates.length === 0) &&
        <div className=""><p>No stage created</p></div>
      }

      {(!isLoading && userStates.length > 0) &&
        <DragDropContext
          onDragEnd={result => onDragEnd(result)}
          // onDragEnd={onDragEnd} 
          onDragStart={(result) => onDragStart(result)}
        >
          <div ref={container_scroll_ref} id="cutomScrollbar1" className={`deal__update__card__wrapper__alt deal__custom__wrapper__scroll__bar__v2`}>
            {renderStage()}
          </div>

          <div className="deal__update__card__buttons__alt">
            {renderActionButton()}
          </div>
        </DragDropContext>
      }

      {showHideMoveDealToPipelineModal &&
        <MoveDealToPipelineModal
          open={showHideMoveDealToPipelineModal}
          onClose={handleShowHideDealMoveToPipelineModal}
          selectedDealToMove={selectedDealToMove}
          refreshDataList={refreshDataList}
        />
      }

      {/* scroll icon */}
      <div
        className={`left_arrow_sign ${startDrag ? 'show_arrow_sign' : ''}`}
        onMouseEnter={() => {
          if (deal_left_arrow_interval == null) {
            deal_left_arrow_interval = setInterval(() => {
              container_scroll_ref.current.style.scrollBehavior = "smooth";
              container_scroll_ref.current.scrollLeft -= 250
            }, 100);
          }
        }}
        onMouseLeave={() => {
          clearInterval(deal_left_arrow_interval)
          deal_left_arrow_interval = null
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill="#fff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M14 8.83L17.17 12 14 15.17V14H6v-4h8V8.83M12 4v4H4v8h8v4l8-8-8-8z" /></svg>
      </div>
      <div
        className={`right_arrow_sign ${startDrag ? 'show_arrow_sign' : ''}`}
        onMouseEnter={() => {
          if (deal_right_arrow_interval == null) {
            deal_right_arrow_interval = setInterval(() => {
              container_scroll_ref.current.style.scrollBehavior = "smooth";
              container_scroll_ref.current.scrollLeft += 250
            }, 100);
          }
        }}
        onMouseLeave={() => {
          clearInterval(deal_right_arrow_interval)
          deal_right_arrow_interval = null
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill="#fff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M14 8.83L17.17 12 14 15.17V14H6v-4h8V8.83M12 4v4H4v8h8v4l8-8-8-8z" /></svg>
      </div>

      <div
        style={{
          position: 'fixed',
          right: isSidebarCollapsed ? 0 : '12px',
          top: '50%',
          zIndex: 9,
          display: 'flex',
          flexDirection: 'column',
          padding: '0px 8px',
          gap: '2px'
        }}
      >
        <span
          style={{ cursor: 'pointer', padding: '1px' }}
          onClick={() => {
            container_scroll_ref.current.style.scrollBehavior = "smooth";
            container_scroll_ref.current.scrollLeft += 250
          }}
        >
          <RightArrowDouble height="30px" width="30px" />
        </span>
        <span
          style={{ cursor: 'pointer', padding: '1px' }}
          onClick={() => {
            container_scroll_ref.current.style.scrollBehavior = "smooth";
            container_scroll_ref.current.scrollLeft -= 250
          }}
        >
          <LeftArrowDouble height="30px" width="30px" />
        </span>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  /* from new deal reducer */
  pipelineId: state.dealReducerV2.pipelineId,
  search: state.dealReducerV2.search,
  dateTime: state.dealReducerV2.dateTime,
  status: state.dealReducerV2.status,
  needReload: state.dealReducerV2.needReload,
});
const mapDispatchToProps = dispatch => ({
  changeDealStatus: (params, callback) => dispatch(changeDealStatus(params, callback)),
  reorderDealStage: (params, callback) => dispatch(reorderDealStage(params, callback)),
  /* deal module new V2 */
  updateDealFilerOptions: (params) => dispatch(updateDealFilerOptions(params)),
  storeSelectedDealInfo: (params) => dispatch(storeSelectedDealInfo(params)),
  updateDealStageReloadFlag: (params) => dispatch(updateDealStageReloadFlag(params))
})
const GridView = connect(mapStateToProps, mapDispatchToProps)(GridViewApp);
export default GridView;