import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createPipeline, updatePipeline } from '../../../../actions/PipelineAction';
import { reloadPipeline, storePipelineId } from '../../../../actions/PipelineReducerAction';
import BasicInput from '../../../../components/custom/BasicInput';
import GlobalModal from '../../../globals/Modal/GlobalModal';

const mapStateToProps = state => ({ });
const mapDispatchToProps = dispatch => ({
    createPipeline: (params, callback) => dispatch(createPipeline(params, callback)),
    storePipelineId: (params, callback) => dispatch(storePipelineId(params, callback)),
    reloadPipeline: (params) => dispatch(reloadPipeline(params)),
    updatePipeline: (params, callback) => dispatch(updatePipeline(params, callback)),
})

const AddPipelineApp = props => {
    
    const titleRef = useRef(null)

    const [submit, setSubmit] = useState(false)
    const [title, setTitle] = useState('')
    const [requiredTitle, setRequiredTitle] = useState(false)
    const [validTitle, setValidTitle] = useState(false)
    const [lengthError, setLengthError] = useState(false)
    const [edit, setEdit] = useState(false)


    useEffect(() => {
        if(typeof props.edit !== 'undefined' && props.edit){
            setTitle(props.data.title) 
            setEdit(true)           
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => {
        if(title.trim() === ''){
            setRequiredTitle(true)
            titleRef.current.focus()
            return
        }
        if(title.length > 80){
            setLengthError(true)
            titleRef.current.focus()
            return
        }
        if(!(title.match(/^(?!\.)[-A-Za-z0-9.#%_ ]+$/))){
            setValidTitle(true)
            titleRef.current.focus()
            return
        }
        setSubmit(true)
        if(edit){
            props.updatePipeline({
                pipeline_id: props.data.id,
                title : title
            }, (res) => {
                if(res.status === 'success'){
                    props.onClose(false)
                    props.reloadPipeline({flag: true, editPipeline: true})//editPipeline
                }
                else if(res.status === 'validation-error'){
                    if(window.showNotification !== undefined){
                        window.showNotification("ERROR", res.html.title);
                    }
                }
                setSubmit(false)
            })
        }
        else{
            props.createPipeline({title : title}, (res) => {
                if(res.status === 'success'){
                    props.storePipelineId(res.pipelineid)
                    props.onClose(false)
                    props.reloadPipeline({flag: true, addPipeline: true})//editPipeline
                }
                else if(res.status === 'validation-error'){
                    if(window.showNotification !== undefined){
                        window.showNotification("ERROR", res.html.title);
                    }
                }
                setSubmit(false)
            })
        }
    }

    const onChangeTitle = (event) => {
        setTitle(event.target.value)
        if(event.target.value.trim() === ''){
            setValidTitle(false)
        }
        if(validTitle){
            if(event.target.value.match(/^(?!\.)[-A-Za-z0-9.#%_ ]+$/)){
                setValidTitle(false)
            }
        }
    }

    return(
        <GlobalModal            
            open={props.open} 
            title="Pipeline" 
            onClose={props.onClose}
            buttonText={edit ? 'Edit Pipeline' :'Add Pipeline'}
            buttonIcon={submit ? <MoreHorizIcon /> : (edit ? <EditIcon fontSize="small"/> : <AddIcon />)}
            showLoader={submit}
            handleSubmit={handleSubmit}
            className="global-small-modal"
        >
            <h3 className="trigger__modal_title">Title <span style={{color: 'red'}}>*</span></h3>
            {/* <input 
            ref={titleRef} 
            className={`mb-0 setFullWidth trigger__modal_textField dealValue`} 
            type="text" value={title} 
            placeholder="Enter title" 
            onChange={onChangeTitle} 
            />  */}
            <BasicInput 
                ref={titleRef}
                value={title} 
                onChange={onChangeTitle} 
                placeholder="Enter title" 
                fullWidth
            />

            {(requiredTitle && title === '') &&
            <p style={{color: 'red', fontSize: 12, width: '100%'}}>Title is required</p>      
            }  
            {(lengthError && title.length > 80) &&
            <p style={{color: 'red', fontSize: 12, width: '100%'}}>Pipeline title length should be max 80 </p>      
            } 
            {validTitle &&
            <p style={{color: 'red', fontSize: 12, width: '100%'}}>Invalid pipeline title [Accept: A-Z a-z 0-9 .-%#]</p>      
            }    
        </GlobalModal>
    )
}
const AddPipeline = connect(mapStateToProps, mapDispatchToProps)(AddPipelineApp);
export default AddPipeline;