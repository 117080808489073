import { combineReducers } from 'redux'
import dealReducer from './dealReducer'
import PipelineReducer from './PipelineReducer'
import videoEmailReducer from "../components/SendComponents/Video/redux/videoEmailReducer";
import cardBroadcastReducer from '../components/SendComponents/DirectCardSend/redux/cardBroadcastReducer';
import dealReducerV2 from './dealReducerV2';
import productReducer from './productReducer';

export default combineReducers({
   dealReducer: dealReducer,
   PipelineReducer: PipelineReducer,
   videoEmailReducer: videoEmailReducer,
   cardBroadcastReducer: cardBroadcastReducer,
   dealReducerV2: dealReducerV2,
   productReducer: productReducer
})