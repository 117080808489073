import React from "react";
import {Skeleton} from "@material-ui/lab";
import TableCell from "@material-ui/core/TableCell";
import {TableContainer, Table, TableBody, TableRow, makeStyles} from "@material-ui/core";

const commonUseStyles = makeStyles((theme) => ({
  table: {
      border: `1px solid ${theme.palette.divider}`,
      borderBottom: "none",
      "& .MuiTableRow-root": {
          "& .MuiTableCell-root": { borderBottom: `1px solid ${theme.palette.divider}` },
      },
  }
}))

const TableSkeletonColumn = ({ ...rest }) => {
  return (
    <TableCell {...rest}>
      <Skeleton variant='text' style={{ width: "100px", height: "20px" }} />
    </TableCell>
  );
};

const TableLoaderSkeleton = ({ columns = 6, numberOfRows = 10, minWidth=750 }) => {
  const classes = commonUseStyles();

  return (
    <TableContainer>
        <Table className={classes.table} sx={{
            "& table": {
                minWidth: minWidth
            }
        }}
        >
        <TableBody>
          {Array.from({ length: numberOfRows }).map((rowNum, i) => (
            <TableRow key={i}>
              {Array.from({ length: columns }).map((column, index) => (
                <TableSkeletonColumn key={index} />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableLoaderSkeleton;
