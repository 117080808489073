import * as ACTION from "../constants/dealActionTypes";

export const getCustomFieldData = (callback) => ({
  type: ACTION.FETCH_CUSTOM_FIELD,
  payload: { callback },
});

export const getVirtualNumber = (callback) => ({
  type: ACTION.FETCH_VIRTUAL_NUMBER,
  payload: { callback },
});

export const fetchDeals = (payload, callback) => ({
  type: ACTION.FETCH_DEALS,
  payload: { payload, callback },
});

export const getAllPipeline = (payload, callback) => ({
  type: ACTION.FETCH_PIPELINES,
  payload: { payload, callback },
});

export const getAllPipelineForUser = (payload, callback) => ({
  type: ACTION.FETCH_USER_PIPELINES,
  payload: { payload, callback },
});

export const reorderDealStage = (payload, callback) => ({
  type: ACTION.REORDER_DEAL_STAGE,
  payload: { payload, callback },
});

export const fetchDealByScroll = (payload, callback) => ({
  type: ACTION.FETCH_DEAL_BY_SCROLL,
  payload: { payload, callback },
});

export const changeDealStatus = (payload, callback) => ({
  type: ACTION.CHANGED_DEAL_STATUS,
  payload: { payload, callback },
});

export const getAllStageByPipeline = (payload, callback) => ({
  type: ACTION.GET_ALL_STAGE_BY_PIPELINE,
  payload: { payload, callback },
});

export const dealMoveToStage = (payload, callback) => ({
  type: ACTION.DEAL_MOVE_TO_STAGE,
  payload: { payload, callback },
});

export const getAllDealTableView = (payload, callback) => ({
  type: ACTION.GET_ALL_DEALS_TABLE_VIEW,
  payload: { payload, callback },
});

export const getUserStageListReport = (payload, callback) => ({
  type: ACTION.GET_DEAL_STAGE_CHART_VIEW,
  payload: { payload, callback },
});

export const getUserStageListReportByFilter = (payload, callback) => ({
  type: ACTION.GET_DEAL_sTAGE_CHART_VIEW_BY_FILTER,
  payload: { payload, callback },
});

export const contactSearch = (payload, callback) => ({
  type: ACTION.CONTACT_SEARCH,
  payload: { payload, callback },
});

export const addNewDeal = (payload, callback) => ({
  type: ACTION.ADD_NEW_DEAL,
  payload: { payload, callback },
});

export const getLeadSource = (payload, callback) => ({
  type: ACTION.GET_LEAD_SOURCE,
  payload: { payload, callback },
});

export const storeNote = (payload, callback) => ({
  type: ACTION.STORE_NOTE,
  payload: { payload, callback },
});

export const updateNote = (payload, callback) => ({
  type: ACTION.UPDATE_NOTE,
  payload: { payload, callback },
});

export const getConversation = (payload, callback) => ({
  type: ACTION.GET_CONVERSATION,
  payload: { payload, callback },
});

export const updateContactField = (payload, callback) => ({
  type: ACTION.UPDATE_CONTACT_FIELD,
  payload: { payload, callback },
});

export const sendConversation = (payload, callback) => ({
  type: ACTION.SEND_CONVERSATION,
  payload: { payload, callback },
});

export const getProfileData = (payload, callback) => ({
  type: ACTION.GET_PROFILE_DATA,
  payload: { payload, callback },
});

export const getOtherEmailByContact = (payload, callback) => ({
  type: ACTION.GET_OTHER_EMAIL_BY_CONTACT,
  payload: { payload, callback },
});

export const sendBulkEmail = (payload, callback) => ({
  type: ACTION.SEND_BULK_EMAIL,
  payload: { payload, callback },
});

export const getAllTags = (payload, callback) => ({
  type: ACTION.GET_ALL_TAGS,
  payload: { payload, callback },
});

export const removeContactTag = (payload, callback) => ({
  type: ACTION.REMOVE_CONTACT_TAG,
  payload: { payload, callback },
});

export const addContactTags = (payload, callback) => ({
  type: ACTION.ADD_CONTACT_TAGS,
  payload: { payload, callback },
});

export const getContactDetails = (payload, callback) => ({
  type: ACTION.GET_CONTACT_DETAILS,
  payload: { payload, callback },
});

export const updateContactCustomField = (payload, callback) => ({
  type: ACTION.UPDATE_CONTACT_CUSTOM_FIELD,
  payload: { payload, callback },
});

export const updateContactSource = (payload, callback) => ({
  type: ACTION.UPDATE_CONTACT_SOURCE,
  payload: { payload, callback },
});

export const getEligibleCampaigns = (payload, callback) => ({
  type: ACTION.GET_ELIGIBLE_CAMPAIGN,
  payload: { payload, callback },
});

export const getAllCampaignByContact = (payload, callback) => ({
  type: ACTION.GET_ALL_CAMPAIGN_BY_CONTACT,
  payload: { payload, callback },
});

export const moveCampaignContact = (payload, callback) => ({
  type: ACTION.MOVE_CAMPAIGN_CONTACT,
  payload: { payload, callback },
});

export const campaignSingleContactRun = (payload, callback) => ({
  type: ACTION.CAMPAIGN_SINGLE_CONTACT_RUN,
  payload: { payload, callback },
});

export const campaignSingleContactPause = (payload, callback) => ({
  type: ACTION.CAMPAIGN_SINGLE_CONTACT_PAUSE,
  payload: { payload, callback },
});

export const campaignSingleContactUnsubscribed = (payload, callback) => ({
  type: ACTION.CAMPAIGN_SINGLE_CONTACT_UNSUBSCRIBED,
  payload: { payload, callback },
});

export const getPeopleActivity = (payload, callback) => ({
  type: ACTION.GET_PEOPLE_ACTIVITY,
  payload: { payload, callback },
});

export const markAsDone = (payload, callback) => ({
  type: ACTION.MARK_AS_DONE,
  payload: { payload, callback },
});

export const submitUserActivity = (payload, callback) => ({
  type: ACTION.SUBMIT_USER_ACTIVITY,
  payload: { payload, callback },
});

export const deleteContactFromStage = (payload, callback) => ({
  type: ACTION.DELETE_CONTACT_FROM_STAGE,
  payload: { payload, callback },
});

/* deal module v2 */
export const updateDealFilerOptions = (payload) => ({
  type: ACTION.UPDATE_DEAL_FILTER_OPTIONS,
  payload: payload,
});

export const storeSelectedDealInfo = (payload) => ({
  type: ACTION.STORE_SELECTED_DEAL,
  payload: payload,
});

export const updateDealStageReloadFlag = (payload) => ({
  type: ACTION.NEED_TO_RELOAD_DEAL_STAGE,
  payload: payload,
});

export const updateStageReloadFlag = (payload) => ({
  type: ACTION.RELOAD_SPECIFIC_STAGE,
  payload: payload,
});
