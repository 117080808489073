import * as ACTION from "../constants/dealActionTypes";

const initialState = {
  pipelineId: "",
  search: "",
  dateTime: { startDate: null, endDate: null },
  status: 1,
  /* for selected deal detail */
  deal: null,
  dealIndex: null,
  stageIndex: null,
  needReload: false,
  reloadSpecificStage: false,
  stageIdToReload: null,

  /* for user wise filter */
  users: [],
  selectedUser: "",
};

const dealReducerV2 = (state = initialState, action) => {
  let { payload } = action;
  switch (action.type) {
    case ACTION.UPDATE_DEAL_FILTER_OPTIONS:
      return {
        ...state,
        pipelineId:
          payload.pipelineId === undefined
            ? state.pipelineId
            : payload.pipelineId,
        search: payload.search === undefined ? state.search : payload.search,
        dateTime:
          payload.dateTime === undefined ? state.dateTime : payload.dateTime,
        status: payload.status === undefined ? state.status : payload.status,
      };

    case ACTION.STORE_SELECTED_DEAL:
      return {
        ...state,
        deal: payload.deal === undefined ? state.deal : payload.deal,
        dealIndex:
          payload.dealIndex === undefined ? state.dealIndex : payload.dealIndex,
        stageIndex:
          payload.stageIndex === undefined
            ? state.stageIndex
            : payload.stageIndex,
      };

    case ACTION.NEED_TO_RELOAD_DEAL_STAGE:
      return { ...state, needReload: action.payload };

    case ACTION.RELOAD_SPECIFIC_STAGE:
      return {
        ...state,
        reloadSpecificStage:
          payload.reloadFlag === undefined
            ? state.reloadSpecificStage
            : payload.reloadFlag,
        stageIdToReload:
          payload.stageId === undefined
            ? state.stageIdToReload
            : payload.stageId,
      };

    case ACTION.STORE_USER_LIST:
      return { ...state, users: payload };

    case ACTION.SELECT_A_USER:
      return { ...state, selectedUser: payload };

    default:
      return state;
  }
};
export default dealReducerV2;
