
import {useState } from 'react';
import { connect } from 'react-redux'
import { getAllStageContactsV2Api } from '../../../../api/dealApi';
import { useIsMounted } from '../../../../hooks/IsMounted';
import useDelayCallback from '../../../../hooks/useDelayCallback';
import EachContactRow from './components/each-contact/EachContactRow';
import Header from './components/header/Header';
import Pagination from './components/pagination/Pagination';
import { validateEmail } from './helpers/Utils';
import Styles from './style.module.css'
const TableViewV2App = (props) => {
  const isMount = useIsMounted();
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(1);
  
  useDelayCallback(()=>{
    getAllContacts()
  }, [
    props.pipelineIdInDealList, 
    props.statusInDealList,
    page
  ])

  useDelayCallback(()=>{
    if(isMount){
      if(page === 0){
        getAllContacts()
      }
      else{
        setPage(0)
      }
    }
  }, [
    props.searchTextInDealList 
  ])

  const getAllContacts = (search = "") => {
    setLoading(true)
    let form_data = {
      pipeline_id: props.pipelineIdInDealList,
      deal_status: props.statusInDealList,
      limit: limit,
      page: page,
      search_text: props.searchTextInDealList
    }
    if(/^[0-9]+$/.test(props.searchTextInDealList)){
      form_data['onlyNumberSearch'] = true
    }
    else if(validateEmail(props.searchTextInDealList)){
      form_data['onlyEmailSearch'] = true
    }
    else if(props.searchTextInDealList.trim() !== ""){
      form_data['onlyTextSearch'] = true
    }
    getAllStageContactsV2Api(form_data).then(res => {
      let response = res.data;
      if(response.status === "success"){
        if(response.data !== undefined && response.data.data !== undefined){
          setList(response.data.data)
          if(response.data.count !== undefined && response.data.count != null){
            setTotal(response.data.count)
          }
        }
        else{
          setList([])
          setTotal(1)
        }
      }
      else{
        setList([])
        setTotal(1)
      }
      setLoading(false)
    }).catch(error => {
      setLoading(false)
    })
  }

  const renderList = () => {
    if(list.length === 0){
      return renderEmptyView();
    }
    const view = [];
    list.forEach((item, index) => {
      view.push(
        <EachContactRow 
          index={index}
          key={index}
          item={item}
        />
      );
    })
    return view;
  }
  const renderLoadingView = () => {
    return(
      <div className={Styles.loadingWrp}>
        <span className={Styles.loadingText}>Loading...</span>
        <span className={Styles.loadingText}>Please wait</span>
      </div>
    );
  }
  const renderEmptyView = () => {
    return(
      <div className={Styles.loadingWrp}>
        <span className={Styles.loadingText}>No data found !</span>
      </div>
    );
  }

  return (
    <div className={Styles.mainWrp}>
      <div className={Styles.tableWrp}>
        {/* header */}
        <Header />
        {/* body */}
        <div className={`${Styles.tableBody} ${Styles.pdad_scroll_bar}`}>
          {loading ? renderLoadingView() : renderList()}
        </div>
        {/* pagination */}
        {list.length > 0 &&
        <Pagination hanldeReload={getAllContacts} page={page} limit={limit} total={total} onChangePage={(value) => setPage(value)} />
        }
      </div>
    </div>
  );
}
const mapStateToProps = state => ({
  pipelineIdInDealList: state.dealReducerV2.pipelineId,
  searchTextInDealList: state.dealReducerV2.search,
  calerdarSortingInDealList: state.dealReducerV2.dateTime,
  statusInDealList: state.dealReducerV2.status
});
const TableViewV2 = connect(mapStateToProps, null)(TableViewV2App);
export default TableViewV2;