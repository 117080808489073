import React, { useState } from "react";
import { Button, styled, Typography } from "@material-ui/core";
import AlbumIcon from "@material-ui/icons/Album";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import ProgressCircle from "../../common/Progress";
import Utils from "../../../../helpers/Utils";

const DailyGoalsStatsWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: theme.spacing(1),
  width: "100%",

  display: "flex",
  gap: 18,

  "& .leftSide": {
    flex: 2,
  },
  "& .statsWrapper": {
    display: "flex",
    flexDirection: "column",
    gap: 8,

    "& .statItem": {
      position: "relative",
      backgroundColor: "#f8f8f8",
      padding: 15,
      borderRadius: 8,
      overflow: "hidden",

      "& .icon": {
        color: "rgb(229 229 229)",
        position: "absolute",
        right: -20,
        top: 0,
        fontSize: 80,
      },
    },
  },
  "& .rightSide": {
    flex: 3,

    "& .TabsWrapper": {
      width: 68, //135 add this value when product button visible
      backgroundColor: "#f8f8f8",
      display: "flex",
      borderRadius: 20,
      marginLeft: "auto",
    },

    "& .bottomStats": {
      display: "flex",
      justifyContent: "flex-end",

      "& .MuiTypography-body1": {
        fontSize: "12px"
      },
      "& .MuiTypography-h5": {
        fontSize: "16px",
        fontWeight: 600
      },
      "& .statsItem": {
        marginRight: "10px"
      }
    }
  },
  "& p.MuiTypography-root": {
    height: "unset"
  }
}));

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px !important",
  fontSize: "14px !important",
  textTransform: "capitalize !important",
  "&.MuiButton-containedPrimary": {
    backgroundColor: "#346fef !important",
  },
}));

const DailyGoalsStats = ({ data, renderTeamMember }) => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <DailyGoalsStatsWrapper>
      <div className="leftSide">
        <Typography variant="body1" style={{ marginBottom: 12 }}>
          Hi {renderTeamMember() ? renderTeamMember().split(" ")[0] : ""}, here's your agency's goal for today
        </Typography>

        <div className="statsWrapper">
          <div className="statItem">
            <Typography variant="body1">STARTED TODAY AT</Typography>
            <Typography variant="h5">{Utils.formatCurrency(data.startedTodayAt, "$")}</Typography>
            <StarBorderIcon className="icon" />
          </div>

          <div className="statItem">
            <Typography variant="body1">SHOULD BE AT</Typography>
            <Typography variant="h5">{Utils.formatCurrency(data.shouldBeAt, "$")}</Typography>
            <TrackChangesIcon className="icon" />
          </div>

          <div className="statItem">
            <Typography variant="h5">{Utils.formatCurrency(data.goalForToday, "$")}</Typography>
            <Typography variant="body1">GOAL FOR TODAY</Typography>
            <AlbumIcon className="icon" />
          </div>
        </div>
      </div>

      <div className="rightSide">
        <div className="TabsWrapper">
          <CustomButton
            size="small"
            variant={activeTab === 1 ? "contained" : "text"}
            color={activeTab === 1 ? "primary" : "inherit"}
            onClick={() => setActiveTab(1)}
            disableElevation
          >
            Sales
          </CustomButton>

          {/*<CustomButton*/}
          {/*  size="small"*/}
          {/*  variant={activeTab === 2 ? "contained" : "text"}*/}
          {/*  color={activeTab === 2 ? "primary" : "inherit"}*/}
          {/*  onClick={() => setActiveTab(2)}*/}
          {/*  disableElevation*/}
          {/*>*/}
          {/*  Products*/}
          {/*</CustomButton>*/}
        </div>
        <ProgressCircle
            data={data}
        />
        <div className="bottomStats">
          <div className="statsItem">
            <Typography variant="body1">ACHIEVED TODAY</Typography>
            <Typography variant="h5">{Utils.formatCurrency(data.achivedToday, "$")}</Typography>
          </div>
        </div>
      </div>
    </DailyGoalsStatsWrapper>
  );
};

export default DailyGoalsStats;
