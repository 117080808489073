import { Box, Typography } from "@material-ui/core";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  getContactsFromTopbarNew,
  searchContactsFromElastic,
} from "../../api/globalApi";
import { useIsMounted } from "./assets/hooks/useIsMounted";
import addDealUseDelayCallback from "./assets/hooks/useDelayCallbackAddDeal";
import GlobalSearchBox from "../../components/globals/GlobalSearchBox/GlobalSearchBox";

let typingTimer;
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const SearchLead = (props) => {
  const isMounted = useIsMounted();
  const [menuVisible, setMenuVisible] = useState(false);
  const [searchTabValue, setSearchTabValue] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [contacts, setContacts] = useState([]);
  const [scrolling, setScrolling] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [isMoreData, setIsMoreData] = useState(false);
  const [loadingNewData, setLoadingNewData] = useState(false);

  const [loadingData, setLoadingData] = useState(false);

  const global_search_text_ref = useRef("");
  const eventListener = useRef();

  function clickListener() {
    let test = document.getElementById("top-search-result");
    let search_div = document.getElementById("global_search_text_ref_id");
    if (test) {
      if (
        global_search_text_ref.current.value === "" ||
        search_div !== document.activeElement
      ) {
        if (test.classList.contains("global___search___panel")) {
          setMenuVisible(false);
          test.classList.remove("global___search___panel");
        }
      }
    }
  }

  useEffect(() => {
    if (!menuVisible) {
      window.removeEventListener("click", eventListener.current);
    } else {
      eventListener.current = clickListener;
      window.addEventListener("click", clickListener);
    }
  }, [menuVisible]);

  useEffect(() => {
    setMenuVisible(searchText !== "");
    setLoadingData(true);
  }, [searchText]);

  addDealUseDelayCallback(() => {
    if (
      isMounted &&
      searchText !== "" &&
      (props.from === undefined || props.from !== "deal-modal")
    ) {
      setScrolling(true);

      if (process.env.REACT_APP_DIALER_CONTACT_SEARCH === "elastic") {
        searchUsingElastic(1);
      } else {
        getSearchContactsForScroll(1);
      }
    } else if (
      isMounted &&
      searchText !== "" &&
      props.from !== undefined &&
      props.from === "deal-modal"
    ) {
      if(process.env.REACT_APP_DIALER_CONTACT_SEARCH === 'elastic'){
        searchUsingElastic(1)

      }else{
        getSearchContactsForScroll(1)
      }
    }
  }, [searchText]);

  const getSearchContactsForScroll = (page, onScroll = false) => {
    if (!onScroll) {
      setLoadingNewData(true);
    }
    setPageNo(page);
    getContactsFromTopbarNew({ q: searchText, page: page, perPage: 10 }).then(
      (res) => {
        if (onScroll) {
          setContacts((prevData) => [
            ...prevData,
            ...res.data.data.pypepro_contacts,
          ]);
        } else {
          setContacts(res.data.data.pypepro_contacts);
          if (!onScroll) {
            setLoadingNewData(false);
          }
        }
        if (res.data.data.pypepro_contacts.length < 10) {
          setIsMoreData(false);
        } else {
          setIsMoreData(true);
        }
        setScrolling(false);
        setLoadingData(false);
      }
    );
  };

  const searchUsingElastic = (page, onScroll = false) => {
    if (!onScroll) {
      setLoadingNewData(true);
    }
    setPageNo(page);

    searchContactsFromElastic("GET", {}, "?page=1&size=10&search=" + searchText)
      .then((res) => {
        if (!res) {
          window.showNotification("ERROR", "Something went wrong");
        }
        if (onScroll) {
          setContacts((prevData) => [...prevData, ...res.data.contactInfos]);
        } else {
          setContacts(res.data?.contactInfos);
          if (!onScroll) {
            setLoadingNewData(false);
          }
        }
        if (res.data.contactInfos.length < 10) {
          setIsMoreData(false);
        } else {
          setIsMoreData(true);
        }

        setScrolling(false);
        setLoadingData(false);
      })
      .catch((e) => {
        console.log("error : " + e);
      });
  };

  //! For laravel search, dont remove
  const renderContacts = () => {
    if (loadingData) {
      return (
        <div className="empty__loading__container">
          {loadingData ? "Please wait..." : "No contact available !"}
        </div>
      );
    }
    if(typeof contacts === "undefined" || contacts == null || contacts.length === 0){
      return (
        <div className="empty__loading__container">
          No contact available !
        </div>
      );
    }
    if (typeof contacts != "undefined") {
      let allContacts = contacts;
      return allContacts.map((contact, index) => {
        let firstName = contact["first_name"] ? contact["first_name"] : "";
        let lastName = contact["last_name"] ? contact["last_name"] : "";
        let avatar = firstName?.substring(0, 1) + lastName?.substring(0, 1);
        return (
          <div className="search-list-items" key={index}>
            <div
              onClick={() => props.onSubmit(contacts[index])}
              className="d-flex justify-content-start align-items-center search_content"
            >
              <span className="d-flex align-items-center justify-content-center search_avatar">
                {avatar}
              </span>
              <div className="search_infos">
                <p className="search_name">
                  {(firstName + " " + lastName).trim()}
                </p>
                <p className="search_email">{contact["email"]}</p>
                <p className="search_number">{contact["number"]}</p>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  //! For laravel search, dont remove
  const renderElasticContacts = () => {
    if (loadingData) {
      return (
        <div className="empty__loading__container">
          {loadingData ? "Please wait..." : "No contact available !"}
        </div>
      );
    }
    if(typeof contacts === "undefined" || contacts == null || contacts.length === 0){
      return (
        <div className="empty__loading__container">
          No contact available !
        </div>
      );
    }

    if (typeof contacts != "undefined") {
      let allContacts = contacts;
      return allContacts.map((contact, index) => {
        let firstName = contact["firstName"] ? contact["firstName"] : "";
        let lastName = contact["lastName"] ? contact["lastName"] : "";
        let avatar = firstName?.substring(0, 1) + lastName?.substring(0, 1);
        return (
          <div className="search-list-items" key={index}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                props.onSubmit(contacts[index])
                setMenuVisible(false);
              }}
              className="d-flex justify-content-start align-items-center search_content"
            >
              <span className="d-flex align-items-center justify-content-center search_avatar ml-2">
                {avatar}
              </span>
              <div className="search_infos">
                <p className="search_name">
                  {(firstName + " " + lastName).trim()}
                </p>
                <p className="search_email">{contact["email"]}</p>
                <p className="search_number">{contact["number"]}</p>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  const loadMoreContact = (e) => {
    setScrolling(true);
    if (
      (props.from === undefined || props.from !== "deal-modal") &&
      !scrolling &&
      isMoreData &&
      Math.round(e.target.scrollTop + e.target.clientHeight + 50, 20) >=
        Math.round(e.target.scrollHeight, 10)
    ) {
      // getMoreData(pageNo+1)
      clearTimeout(typingTimer);

      typingTimer = setTimeout(() => {
        if (process.env.REACT_APP_DIALER_CONTACT_SEARCH === "elastic") {
          searchUsingElastic(pageNo + 1, true);
        } else {
          getSearchContactsForScroll(pageNo + 1, true);
        }
      }, 500);
    } else {
      setScrolling(false);
    }
  };

  const onFocusGlobalSearch = (e) => {
    e.stopPropagation();
    let test = document.getElementById("top-search-result");
    if (searchText !== "") {
      if (test) {
        setMenuVisible(true);
      }
    } else {
      if (test) {
        if (test.classList.contains("global___search___panel")) {
          setMenuVisible(false);
          test.classList.remove("global___search___panel");
        }
      }
    }
  };

  return (
    <>
      <div className="header-search-wrapper hide-on-med-and-down global__search__box__wr">
        <GlobalSearchBox
          dataTarget="top-search-result"
          className=""
          type="search"
          autocomplete="off"
          name="Search"
          placeholder="Search"
          id="searchInput global_search_text_ref_id"
          onChange={(e) => setSearchText(e.target.value.replace(/[#,%,$,!]/g, ''))}
          onFocus={(e) => onFocusGlobalSearch(e)}
          value={searchText}
          ref={global_search_text_ref}
        />
      </div>
      <div
        id="top-search-result"
        tabIndex="0"
        onClick={(e) => {
          e.stopPropagation();
          setMenuVisible(true);
        }}
        onBlur={() => setMenuVisible(false)}
        className={`top-bar-search-result top-bar-search-wrapper dropdown-content dropdown-tabbed ${
          menuVisible ? "global___search___panel" : ""
        }`}
        style={{
          opacity: menuVisible ? 1 : 0,
          visibility: menuVisible ? "visible" : "hidden",
        }}
      >
        <div id="top-search-result-off">
          <TabPanel
            className="search-result-tab p-0"
            id="5peoples"
            value={searchTabValue}
            index={0}
          >
            <div
              className="search-list-content"
              onScroll={(e) => loadMoreContact(e)}
            >
              {(!loadingNewData && process.env.REACT_APP_DIALER_CONTACT_SEARCH === "elastic") && renderElasticContacts()}
              {(!loadingNewData && process.env.REACT_APP_DIALER_CONTACT_SEARCH !== "elastic") && renderContacts()}
            </div>
          </TabPanel>
        </div>
      </div>
    </>
  );
};
export default SearchLead;