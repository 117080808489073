import { sendRequest } from "./rootApi";
import Api from "../constants/Api";
import HttpRequest from "./HttpRequest";

export const fetchCustomFieldApi = async () => {
  return sendRequest("post", Api.customFields);
};

export const getVirtualNumberApi = async () => {
  return sendRequest("post", Api.getVirtualNumber);
};

export const fetchDealApi = async (payload) => {
  return sendRequest("POST", Api.getDeals, payload);
};

export const fetchPipelineV2Api = async (payload) => {
  return sendRequest("POST", Api.getAllPipeLineV2, payload);
};
export const fetchPipelineApi = async (payload) => {
  return sendRequest("POST", Api.getAllPipeLine, payload);
};

export const reorderDealStageApi = async (payload) => {
  return sendRequest("POST", Api.reorderDealStage, payload);
};

export const fetchDealByScrollApi = async (payload) => {
  return sendRequest("POST", Api.getDealByScroll, payload);
};

export const changeDealStatusApi = async (payload) => {
  return sendRequest("POST", Api.changedDealStatus, payload);
};

export const getAllStageByPipelineApi = async (payload) => {
  return sendRequest("POST", Api.getAllStageByPipeline, payload);
};

export const dealMoveToStageApi = async (payload) => {
  return sendRequest("POST", Api.moveToStage, payload);
};

export const getAllDealTableViewApi = async (payload) => {
  return sendRequest("POST", Api.getAllDealsTableView, payload);
};

export const getUserStageListReportApi = async (payload) => {
  return sendRequest("POST", Api.getUserStageListReport, payload);
};

export const getUserStageListReportByFilterApi = async (payload) => {
  return sendRequest("POST", Api.getUserStageListReportByFilter, payload);
};

export const contactSearchApi = async (payload) => {
  return sendRequest("POST", Api.contactSearch, payload);
};

export const addNewDealApi = async (payload) => {
  return sendRequest("POST", Api.addNewDeal, payload);
};

export const getLeadSourceApi = async (payload) => {
  return sendRequest("POST", Api.leadSource, payload);
};

export const storeNoteApi = async (payload) => {
  return sendRequest("POST", Api.addNote, payload);
};

export const updateNoteApi = async (payload) => {
  return sendRequest("POST", Api.updateNote, payload);
};

export const getConversationApi = async (payload) => {
  return sendRequest("POST", Api.getConversation, payload);
};

export const updateContactFieldApi = async (payload) => {
  return sendRequest("POST", Api.updateContactField, payload);
};

export const sendConversationApi = async (payload) => {
  return sendRequest("POST", Api.sendConversation, payload);
};

export const getProfileDataApi = async (payload) => {
  return sendRequest("POST", Api.getProfileData, payload);
};

export const getOtherEmailByContactApi = async (payload) => {
  return sendRequest("POST", Api.getOtherEmailByContact, payload);
};

export const sendBulkEmailApi = async (payload) => {
  return sendRequest("POST", Api.sendBulkEmail, payload);
};

export const getAllTagsApi = async (payload) => {
  return sendRequest("POST", Api.getAllTags, payload);
};

export const removeContactTagApi = async (payload) => {
  return sendRequest("POST", Api.removeContactTag, payload);
};

export const addContactTagsApi = async (payload) => {
  return sendRequest("POST", Api.addContactTags, payload);
};

export const getContactDetailsApi = async (payload) => {
  return sendRequest("POST", Api.getContactDetails, payload);
};

export const updateContactCustomFieldApi = async (payload) => {
  return sendRequest("POST", Api.contactSingleCustomFieldUpdate, payload);
};

export const updateContactSourceApi = async (payload) => {
  return sendRequest("POST", Api.changeContactSourceType, payload);
};

export const getAllCampaignByContactApi = async (payload) => {
  return sendRequest("POST", Api.getAllCampaignByContact, payload);
};

export const getEligibleCampaignsApi = async (payload) => {
  return sendRequest("POST", Api.getEligibleCampaignForContact, payload);
};

export const moveCampaignContactApi = async (payload) => {
  return sendRequest("POST", Api.moveCampaignContact, payload);
};

export const campaignSingleContactRunApi = async (payload) => {
  return sendRequest("POST", Api.campaignSingleContactRun, payload);
};

export const campaignSingleContactPauseApi = async (payload) => {
  return sendRequest("POST", Api.campaignSingleContactPause, payload);
};

export const campaignSingleContactUnsubscribedApi = async (payload) => {
  return sendRequest("POST", Api.campaignSingleContactUnsubscribed, payload);
};

export const getPeopleActivityApi = async (payload) => {
  return sendRequest("POST", Api.getPeopleActivity, payload);
};

export const markAsDoneApi = async (payload) => {
  return sendRequest("POST", Api.markAsDone, payload);
};

export const submitUserActivityApi = async (payload) => {
  return sendRequest("POST", Api.submitUserActivity, payload);
};

export const deleteContactFromStageApi = async (payload) => {
  return sendRequest("POST", Api.contactDeleteFromStage, payload);
};

/* for deal module v2 */
export const getAllUserStagesApi = async (payload) => {
  return sendRequest("POST", Api.getAllUserStages, payload);
};

export const getPipelineStatsApi = async (payload) => {
  return sendRequest("POST", Api.getPipelineStats, payload);
};

export const getUserStageContactDataApi = async (payload) => {
  return sendRequest("POST", Api.getUserStageContactsV2, payload);
};

export const getUserStageStatsApi = async (payload) => {
  return sendRequest("POST", Api.getUserStageStats, payload);
};

export const getAllStageContactsV2Api = async (payload) => {
  return sendRequest("POST", Api.getAllStageContactsV2, payload);
};
export const getAllStageContactsGraphV2Api = async (payload) => {
  return sendRequest("POST", Api.getAllStageContactsGraphV2, payload);
};
export const getSubUserListV2Api = async (payload) => {
  return sendRequest("POST", Api.getSubUserList, payload);
};

export const updateDealBgColorApi = async (payload) => {
  return sendRequest("POST", Api.updateDealBgColor, payload);
};

export const getTeamUserListApi = async (payload) => {
  return sendRequest("POST", Api.getTealUserList, payload);
};

export const getUserProductsApi = () => {
  return sendRequest("GET", `${Api.userProducts}/list`);
};

export const getContactStageProductsApi = (payload) => {
  return sendRequest("GET", `${Api.contactStageProducts}/${payload}`);
};

export const updateSuccessDealApi = (data) => {
  return sendRequest("PUT", `${Api.updateSuccessDeal}/${data.id}`, data.payload);
};

export const fetchRecycleInfo = (payload) => {
  return HttpRequest.post(Api.recycleInfoApi, payload);
};