import React, {useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {connect} from 'react-redux';
import {updateDealFilerOptions, updateStageReloadFlag} from '../../actions/DealAction';
import {reloadDealStage, storePipelineId} from '../../actions/DealReducerAction';
import Utils from '../../helpers/Utils';
import '../../Notification/notification.css';
import "./css/deal-responsive.css";
import DealTopbar from './Deals/DealTopbar';
import GridView from './Deals/grid-view/GridView';
import ModalAddDeal from './Deals/modals/ModalAddDeal';
import {useHistory} from 'react-router-dom';
import TableViewV2App from './Deals/table-view-v2/TableViewV2App';
import GraphViewV2App from './Deals/graph-view-v2/GraphViewV2App';

const App = props => {
    const history = useHistory();
    const [stats, setStats] = useState({totalDeals : 0, totalDealValue: 0})
    const [dataViewType, setDataViewType]  = useState(1) // 2 = table, 3 = chart, 1 = grid
    const [showOptions, setShowOptions] = useState(true)
    const [needToReloadDeal, setNeedToReloadDeal] = useState(false)
    const [editDealData, setEditDealData] = useState(null)

    /* add deal */
    const showAddDealModal = false
    /* more option modal */
    const [showMoreOptionModal, setShowMoreOptionModal] = useState(false)

    useEffect(() => {
        // document.querySelector("body").classList.add("active__minimal_sidebar");
        if (window.setCollapsedMenu) {
            window.setCollapsedMenu(true);
        }
        document.title = `Deal | ${Utils.getAccountData('AuthUserAgencyName')}`;
		if(window.setActiveSidebar) {
			window.setActiveSidebar('deal');
		}

        if(history.location.state !== undefined){
            let state_value = history.location.state;
            if(typeof state_value === 'object'){
                if(state_value.from !== undefined && state_value.from === "direct-mail-setting-v2"){
                    setDataViewType(1);
                }
            }
            window.history.replaceState(null, '')
        }     
        
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleStatsValue = (value) => {
        setStats(value)
    }

    const handleChangeDataView = (value, type, event) => {
        if(event !== undefined && event != null){
            try{
                event.preventDefault();
            }catch(e){
                
            }
        }
        setShowOptions(value)
        setDataViewType(type)
    }

    const handleAddDealModal = (status = false, reload = false, pipeline = null, stage = null) => {
        
        if(typeof status === 'object'){
            setNeedToReloadDeal(false)
            let edit_data = {...status.editData}
            edit_data = {...edit_data, ...{pipelineList: props.pipelineList, pipelineId: props.pipelineId}}
            setEditDealData(edit_data)

            // setShowAddDealModal(status.status)

            window.globalAddDeal(status.status, {
                callback: (data) => {
                    if (data){
                        updateCallback(data.stageId, data.pipelineId);
                    }
                },
                data: edit_data,
                edit: true
            });

            if(status.reload){
                setNeedToReloadDeal(true)
            }
        }else{
            // setShowAddDealModal(status);

            window.globalAddDeal(status, {
                callback: (data) => {
                    if (data){
                        updateCallback(data.stageId, data.pipelineId);
                    }
                },
                data: {
                    pipelineId: pipeline,
                    user_stage_id: stage
                }
            });

            setEditDealData(null)

            if(reload && pipeline === null){
                setNeedToReloadDeal(true)
            }

            // setEditDealData(null)
            // if(pipeline !== null){
            //     // if(props.pipelineIdInDealList == pipeline){
            //     if(props.pipelineId == pipeline){
            //         setNeedToReloadDeal(true)
            //     }
            //     else{
            //         // props.storePipelineId(pipeline)
            //         props.updateDealFilerOptions(pipeline)
            //     }
            // }
        }
        // setEditDealData(null)
    }

    const handleReloadSpecificStage = (flag, stage_id) => {
        props.updateStageReloadFlag({
            reloadFlag: flag,
            stageId: stage_id
        })
    }

    const handleShowContactDetails = (deal) => {
        let dealWithId = deal;
        dealWithId['id'] = deal.contact_stages_id;
            window.handleGlobalContactDetailsForDealModal({
                open: true,
                from: 'deal-details',
                title: () => {
                    let title = "Contact details";
                    if((deal.first_name !== null && deal.first_name !== "") && (deal.last_name !== null && deal.last_name !== "")){
                        title = deal.first_name + ' ' + deal.last_name
                    }
                    else if(deal.first_name !== null && deal.first_name !== "") {
                        title = deal.first_name
                    }
                    else if(deal.last_name !== null && deal.last_name !== ""){
                        title = deal.last_name
                    }
                    else if(deal.email !== null && deal.email !== ""){
                        title = deal.email
                    }
                    else if(deal.number !== null && deal.number !== ""){
                        title = deal.number
                    }
                    return title;
                },
                id: deal.contact_id,
                // dealCallback: (status) => props.reloadDealStage(status),
                dealCallback: (status, stageId) => handleReloadSpecificStage(status, stageId),
                dealInfo: dealWithId,
                closeContactDetails: () => setShowMoreOptionModal(false)
            })
    }

    const updateCallback = (stage_id, pipeline__id, editStage = false) => {
        if(editStage){
            if(pipeline__id !== null && pipeline__id !== props.pipelineId){
                props.updateDealFilerOptions({
                    pipelineId: pipeline__id
                })
            }else{
                props.updateStageReloadFlag({
                    reloadFlag: true,
                    stageId: stage_id
                })
            }
            setEditDealData(null)
        }
        else{
            if(pipeline__id !== props.pipelineId){
                props.updateDealFilerOptions({
                    pipelineId: pipeline__id
                })
            }
            else{
                props.updateStageReloadFlag({
                    reloadFlag: true,
                    stageId: stage_id
                })
            }
        }
    }

    return (
        <div /* style={{backgroundColor: '#e5e5e5'}} */ className="deal-main-container deal_update">

            <div 
              className="deal-wrapper"
              style={dataViewType == 1 ? {padding: '0px 25px 8px 8px'} : {}}
            >
                <div className={`card deal-wrapper-card ${dataViewType == 1 ? 'deal-wrapper-card-grid' : ''} `}>
                    <div className={`card-content ${dataViewType == 1 ? 'deal-stage-wraper' : 'deal-wrapper-content'}`}>
                        <DealTopbar 
                            stats={stats} 
                            handleViewType={setDataViewType} 
                            dataViewType={dataViewType} 
                            showOptions={showOptions} 
                            handleChangeDataView={handleChangeDataView} 
                            handleAddDealModal={handleAddDealModal}
                        />
                        {dataViewType == 1 ?
                            <GridView 
                                handleStatsValue={handleStatsValue}
                                needToReloadDeal={needToReloadDeal}
                                handleReload={() => setNeedToReloadDeal(false)}
                                handleAddDealModal={handleAddDealModal}
                                // handleMoreOptionModal={setShowMoreOptionModal}
                                handleMoreOptionModal={handleShowContactDetails}
                            />
                        :
                        dataViewType == 2 ?
                            <TableViewV2App />
                        :
                          <GraphViewV2App />
                        
                        }
                    </div>
                </div>
            </div>
            {
                showAddDealModal && <ModalAddDeal 
                    editDealData={editDealData} 
                    open={showAddDealModal} 
                    onClose={handleAddDealModal}
                    updateCallback={updateCallback}
                />
            }
            
        </div>
    )
}

const mapStateToProps = state => ({
    pipelineIdInDealList: state.dealReducer.pipelineIdInDealList,
	searchTextInDealList: state.dealReducer.searchTextInDealList,
	calerdarSortingInDealList: state.dealReducer.calerdarSortingInDealList,
	statusInDealList: state.dealReducer.statusInDealList,
    dealContact: state.dealReducer.statusInDealList,
    dealContactId: state.dealReducer.dealContact,
    loggedOnUser: state.dealReducer.loggedOnUser,
    /* from new deal reducer */
    pipelineId: state.dealReducerV2.pipelineId,
    pipelineList: state.dealReducer.pipelineList
});
const mapDispatchToProps = dispatch => ({
    storePipelineId: (params, callback) => dispatch(storePipelineId(params, callback)),
    reloadDealStage: (params, callback) => dispatch(reloadDealStage(params, callback)),
    updateStageReloadFlag: (params) => dispatch(updateStageReloadFlag(params)),
    updateDealFilerOptions: (params) => dispatch(updateDealFilerOptions(params)),
})
const DealStages = connect(mapStateToProps, mapDispatchToProps)(App);
export default DealStages;