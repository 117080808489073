import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import { getUserStageContactDataApi } from "../../../../api/dealApi";
import Utils from "../../../../helpers/Utils";
import EachDeal from "./EachDeal";
import AddIcon from "@material-ui/icons/Add";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { IconList } from "../../../../constants/IconList";
import ListSkeleton from "../../../Common/Skeletons/ListSkeleton";
import { useIsMounted } from "../../../../hooks/IsMounted";
import useDelayCallback from "../../../../hooks/useDelayCallback";
import styled from "styled-components";
import { storeDealContact } from "../../../../actions/DealReducerAction";
import { updateStageReloadFlag } from "../../../../actions/DealAction";
import EachItem from "../grid-view-v2/eachi-item/EachItem";
import Styles from "../grid-view-v2/eachi-item/style.module.css";
import { withStyles } from "@material-ui/core/styles";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";

const StyledTooltip = withStyles({
  tooltip: {
    color: "#fff",
  },
  arrow: {
    color: "#15008A",
  },
  tooltipPlacementTop: {
    background: "#15008A",
  },
  tooltipPlacementBottom: {
    background: "#15008A",
  },
  tooltipPlacementRight: {
    background: "#15008A",
  },
})(BootstrapTooltip);

const EachStageContainerApp = ({
  item,
  index: stageIndex,
  pipelineId,
  search,
  dateTime,
  status,
  itemPerPage,
  startDrag,
  dragAndDropProcess,
  handleDragAndDropProcess,
  handleDealFullDataState,
  stageDealData,
  handleAddDealModal,
  storeDealContact,
  handleMoreOptionModal,
  reloadSpecificStage,
  stageIdToReload,
  updateStageReloadFlag,

  handlActions,
  handleDealProperties,
  selectedUser,
}) => {
  const isMounted = useIsMounted();
  const [totalDeals, setTotalDeals] = useState(0);
  const [totalDealValue, setTotalDealValue] = useState(0);
  const [loadingDeals, setLoadingDeals] = useState(true);
  const [isMoreDealLoading, setIsMoreDealLoading] = useState(false);
  const [needToLoadMore, setNeedToLoadMore] = useState(true);
  const [perPage] = useState(itemPerPage);
  const [page, setPage] = useState(0);
  /* optimize filtering */
  const [startFiltering, setStartFiltering] = useState(false);
  const [actualTotalDeals, setActualTotalDeals] = useState(0);
  const [actualTotalDealValue, setActualTotalDealValue] = useState(0);
  const [firstLanding, setFirstLanding] = useState(true);
  const isTempUser = Utils.getAccountData("isTemplateUser");
  const starterPack = Utils.getAccountData("starterPack");
  const stageRef = useRef(null);
  const [startingSession, setStartingSession] = useState(false);

  useEffect(() => {
    if (dragAndDropProcess !== null) {
      if (
        parseInt(dragAndDropProcess.destinationStageIndex) === stageIndex &&
        parseInt(dragAndDropProcess.sourceStageIndex) === stageIndex
      ) {
      } else if (
        parseInt(dragAndDropProcess.destinationStageIndex) === stageIndex && dragAndDropProcess.selectedDealData
      ) {
        setTotalDeals(totalDeals + 1);
        setTotalDealValue(
          totalDealValue + dragAndDropProcess.selectedDealData.deal_value
        );
        handleDragAndDropProcess();
      } else if (parseInt(dragAndDropProcess.sourceStageIndex) === stageIndex && dragAndDropProcess.selectedDealData) {
        setTotalDeals(totalDeals - 1);
        setTotalDealValue(
          totalDealValue - dragAndDropProcess.selectedDealData.deal_value
        );
        handleDragAndDropProcess();
      }
    }
  }, [dragAndDropProcess]);

  useEffect(() => {
    if (!firstLanding) {
      setStartFiltering(true);
      if (page === 0) {
        getStageContacts(true, true);
      } else {
        setPage(0);
      }
      setNeedToLoadMore(true);
    }
  }, [dateTime]);

  useDelayCallback(() => {
    if (isMounted) {
      if (totalDeals > 0) {
        setStartFiltering(true);
        if (page === 0) {
          getStageContacts(true, true);
        } else {
          setPage(0);
        }
        setNeedToLoadMore(true);
      }
    }
  }, [search]);

  useEffect(() => {
    if (page === 0) {
      getStageContacts(true);
    }
  }, [page]);

  useEffect(() => {
    if (!firstLanding) {
      if (page === 0) {
        getStageContacts(true);
      } else {
        setPage(0);
      }
    }
  }, [status]);

  useEffect(() => {
    if (reloadSpecificStage && stageIdToReload !== null) {
      if (page === 0) {
        getStageContacts(true);
      } else {
        setPage(0);
      }
      updateStageReloadFlag({
        reloadFlag: false,
        stageId: null,
      });
    }
  }, [reloadSpecificStage]);

  //   useDelayCallback(() => {
  //     if (isMounted) {
  //       if (totalDeals > 0) {
  //         setStartFiltering(true);
  //         if (page === 0) {
  //           getStageContacts(true, true);
  //         } else {
  //           setPage(0);
  //         }
  //         setNeedToLoadMore(true);
  //       }
  //     }
  //   }, [selectedUser]);

  const getStageContacts = (
    removeOldData = false,
    filtering = startFiltering
  ) => {
    getUserStageContactDataApi({
      pipelineId: pipelineId,
      dealStatus: status,
      startDate:
        dateTime.startDate != null
          ? moment(dateTime.startDate).format("YYYY-MM-DD")
          : null,
      endDate:
        dateTime.endDate != null
          ? moment(dateTime.endDate).format("YYYY-MM-DD")
          : null,
      search: search,
      page: page,
      perPage: perPage,
      userStageId: item.id,
      //   selectedUser: selectedUser,
    })
      .then((res) => {
        let response = res.data;
        if (response.status === "success") {
          if (!filtering) {
            if (response.stageStats !== null) {
              setTotalDealValue(
                response.stageStats.total_deals === null
                  ? 0
                  : response.stageStats.total_deals
              );
            }
            setTotalDeals(response.contacts.total);
          } else {
            setActualTotalDeals(response.contacts.total);
            setActualTotalDealValue(
              response.stageStats.total_deals === null
                ? 0
                : response.stageStats.total_deals
            );
          }

          handleDealFullDataState(
            stageIndex,
            response.contacts.data,
            removeOldData
          );
          if (response.contacts.data.length >= 10) {
            setPage(page + 1);
            setNeedToLoadMore(true);
          } else {
            setNeedToLoadMore(false);
          }

          if (search === "" && filtering) {
            if (response.stageStats !== null) {
              setTotalDealValue(
                response.stageStats.total_deals === null
                  ? 0
                  : response.stageStats.total_deals
              );
            }
            setTotalDeals(response.contacts.total);
            setStartFiltering(false);
          }
        } else {
          window.showNotification("ERROR", "Something went wrong!");
        }
        setLoadingDeals(false);
        setIsMoreDealLoading(false);
        setFirstLanding(false);
      })
      .catch((err) => {
        setLoadingDeals(false);
        setIsMoreDealLoading(false);
        setFirstLanding(false);
        window.showNotification("ERROR", "Something went wrong!");
      });
  };

  const onScrollLoadData = (container_id) => {
    if (startDrag) return;
    if (!needToLoadMore) return;
    if (isMoreDealLoading) return;
    let innrerHeight = document.getElementById(container_id).clientHeight;
    let dif =
      document.getElementById(container_id).scrollHeight -
      (innrerHeight + document.getElementById(container_id).scrollTop);
    if (dif < 100) {
      setIsMoreDealLoading(true);
      getStageContacts();
    }
  };

  const handleMoreOption = (deal, dealIndex, stageIndex) => {
    deal["status"] = deal.contact_stage_status;
    storeDealContact({
      deal: deal,
      dealIndex: dealIndex,
      stageIndex: stageIndex,
    });
    handleMoreOptionModal(deal);
  };

  const renderDeals = () => {
    let deals = [];
    if (
      stageDealData["all_deals"] !== undefined &&
      stageDealData["all_deals"] !== null &&
      Array.isArray(stageDealData["all_deals"])
    ) {
      stageDealData["all_deals"].map((eachDeal, dealIndex) => {
        deals.push(
          <EachItem
            key={`${dealIndex}-${eachDeal.contact_stages_id}`}
            stageWidth={stageRef?.current?.clientWidth}
            data={eachDeal}
            index={dealIndex}
            handleMoreOptionModal={() =>
              handleMoreOption(eachDeal, dealIndex, stageIndex)
            }
            handleAddDealModal={handleAddDealModal}
            handlActions={handlActions}
            stageIndex={stageIndex}
            stageId={item.id}
            handleDealProperties={handleDealProperties}
          />
          // <EachDeal
          // data={eachDeal}
          // key={dealIndex}
          // index={dealIndex}
          // handleMoreOptionModal={() => handleMoreOption(eachDeal, dealIndex, stageIndex)}
          // handleAddDealModal={handleAddDealModal}
          // />
        );
      });
    }
    if (deals.length > 0) {
      return deals;
    }
    return null;
  };

  const addStyle = (index, dragging = false) => {
    let content = document.getElementById(`dropable-context-${index}`);
    if (content !== null) {
      // if(dragging){
      //     content.style.overflow = "hidden";
      // }else{
      //     content.style.overflow = "auto";
      // }
      content.style.overflow = "auto";
    }
  };

  const handleClickSessionStart = () => {
    if (startingSession) {
      return;
    }

    setStartingSession(true);
    window.globalPowerDialerModal(true, {
      type: "deal-stage",
      value: item.id,
      name: item.stage,
      startDate:
        dateTime.startDate != null
          ? moment(dateTime.startDate).format("YYYY-MM-DD")
          : null,
      endDate:
        dateTime.endDate != null
          ? moment(dateTime.endDate).format("YYYY-MM-DD")
          : null,
      search: search,
      stageStatus: status,
      callback: (response, formData) => {
        if (response.success) {
          const win = window.open(
            `/power-dialer?listId=${response?.data}&listName=${formData.name
            }&from=dealStage&dealStageName=${item.stage
            }&for=startSession`,
            "_blank"
          );
          win.focus();
        }
        setStartingSession(false);
      },
    });
  };

  let LiItem = styled.li`
    &:after {
      border-left-color: ${item.color_code} !important;
    }
    background-color: ${item.color_code} !important;
  `;
  let TitleSpanItem = styled.span`
    color: ${item.text_color} !important;
  `;

  if (
    !item.color_code.includes("#") ||
    item.color_code == "#FFF" ||
    item.color_code == "#fff"
  ) {
    LiItem = styled.li`
      &:after {
        border-left-color: #e3ebfa !important;
      }
      background-color: #e3ebfa !important;
    `;
  }

  return (
    <div
      className='deal__update__card__container__alt'
      key={stageIndex}
      ref={stageRef}
    >
      <div className='each_stage_header_container_v2'>
        <LiItem className='deal__top__lebel__info__v2 deal__top__lebel__info__v2__active'>
          <div className='deal__top__lebel__info__left__v2'>
            <div className='deal__top__lebel__info__item__v2'>
              <TitleSpanItem>{item.stage}</TitleSpanItem>
            </div>
            <div className='deal__top__lebel__info__value__v2'>
              <TitleSpanItem className='deal__top__value__v2'>
                {startFiltering ? actualTotalDeals : totalDeals}{" "}
                {totalDeals > 1 ? "Deals" : "Deal"} <FiberManualRecordIcon />
              </TitleSpanItem>
              <TitleSpanItem>
                <StyledTooltip
                  arrow
                  title={
                    <span className={Styles.dealValue_Tooltip}>
                      {Utils.calculateCashPlain(
                        startFiltering ? actualTotalDealValue : totalDealValue
                      )}
                    </span>
                  }
                >
                  <span>
                    {Utils.calculateCash(
                      startFiltering ? actualTotalDealValue : totalDealValue
                    )}
                  </span>
                </StyledTooltip>
              </TitleSpanItem>
            </div>
          </div>
          <div className="d-flex align-items-center">
            {
              !loadingDeals &&
              totalDeals > 0 && (
                <BootstrapTooltip arrow placement="top" title="Start Dialing Session">
                  <span style={{ marginTop: '6px' }} className="deal__top__lebel__info__call__v2" onClick={handleClickSessionStart}>
                    <CallIcon height='26' width='26' />
                  </span>
                </BootstrapTooltip>
              )
            }
            {!isTempUser && !starterPack && (
              <span
                className='ml-2 deal__top__lebel__info__plus__v2 deal__top__lebel__info__plus__v2_add_deal accent--bg--text--color'
                onClick={() => handleAddDealModal(true, false, pipelineId, item.id)}
              >
                <AddIcon />
              </span>
            )}
          </div>
        </LiItem>
      </div>

      <Droppable
        droppableId={`dropable-context-${stageIndex}`}
        key={stageIndex}
        className='each-stage-dropable-context-v2 y-scroll'
      >
        {(provided, snapshot) => {
          return (
            <div
              key={stageIndex}
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: snapshot.isDraggingOver ? "lightblue" : "#f4f5f6",
              }}
              // id={'dropable-context-'+stageIndex}
              id={`dropable-context-${stageIndex}`}
              className={`awesome__scroll_bar__deal__v2  deal__update__drag__row__alt ${totalDeals === 0 ? "empty__container__v2" : ""
                } ${startFiltering && actualTotalDeals === 0
                  ? "empty__container__v2"
                  : ""
                } ${loadingDeals ? "empty__container__loading__v2" : ""}`}
              onScroll={() =>
                onScrollLoadData("dropable-context-" + stageIndex)
              }
            >
              {loadingDeals && (
                <>
                  <ListSkeleton width='100%' height={80} />
                  <ListSkeleton width='100%' height={80} />
                  <ListSkeleton width='100%' height={80} />
                  <ListSkeleton width='100%' height={80} />
                  <ListSkeleton width='100%' height={80} />
                </>
              )}
              {!loadingDeals && !startFiltering && totalDeals === 0 && (
                <div style={{ position: "absolute" }}>
                  {IconList.emptyStageContainer}
                  <p style={startDrag ? { color: "#000000" } : {}}>No Deals</p>
                </div>
              )}
              {!loadingDeals && startFiltering && actualTotalDeals === 0 && (
                <div style={{ position: "absolute" }}>
                  {IconList.emptyStageContainer}
                  <p style={startDrag ? { color: "#000000" } : {}}>
                    Actual total {totalDeals > 1 ? "Deals" : "Deal"}:{" "}
                    {totalDeals}
                  </p>
                  <p style={startDrag ? { color: "#000000" } : {}}>
                    Actual total value: {Utils.calculateCash(totalDealValue)}
                  </p>
                  <p style={startDrag ? { color: "#000000" } : {}}>
                    But no deal available with <b>{search}</b>
                  </p>
                </div>
              )}

              {!loadingDeals &&
                totalDeals > 0 &&
                addStyle(stageIndex, startDrag)}
              {!loadingDeals && totalDeals > 0 && renderDeals()}
              {isMoreDealLoading && (
                <div className='load__more__each__card'>Loading more</div>
              )}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </div>
  );
};
const mapStateToProps = (state) => ({
  /* from new deal reducer */
  pipelineId: state.dealReducerV2.pipelineId,
  search: state.dealReducerV2.search,
  dateTime: state.dealReducerV2.dateTime,
  status: state.dealReducerV2.status,
  reloadSpecificStage: state.dealReducerV2.reloadSpecificStage,
  stageIdToReload: state.dealReducerV2.stageIdToReload,
  selectedUser: state.dealReducerV2.selectedUser,
});
const mapDispatchToProps = (dispatch) => ({
  /* deal module new V2 */
  storeDealContact: (params, callback) =>
    dispatch(storeDealContact(params, callback)),
  updateStageReloadFlag: (params) => dispatch(updateStageReloadFlag(params)),
});
const EachStageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EachStageContainerApp);
export default EachStageContainer;

export const CallIcon = ({ height = "30", width = "30" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="#366aff" xmlns="http://www.w3.org/2000/svg" >
      <circle cx="15" cy="15" r="14.5" stroke="none" ></circle>
      <path d="M19.4388 17.1357L17.9592 16.9668C17.7852 16.9464 17.6089 16.9656 17.4434 17.0231C17.278 17.0806 17.1277 17.1749 17.0039 17.2988L15.9321 18.3706C14.2784 17.5296 12.9344 16.1855 12.0933 14.5319L13.171 13.4543C13.4215 13.2038 13.5438 12.8543 13.503 12.499L13.3341 11.031C13.3011 10.7469 13.1647 10.4848 12.9509 10.2947C12.7372 10.1045 12.461 9.99966 12.1749 10H11.1672C10.5089 10 9.96136 10.5476 10.0021 11.2058C10.3109 16.1804 14.2894 20.1531 19.2582 20.4618C19.9164 20.5026 20.464 19.955 20.464 19.2968V18.2891C20.4698 17.7007 20.0271 17.2056 19.4388 17.1357Z" fill="#fff"></path>
    </svg>
  );
};
