import Styles from './style.module.css'
const Header = () => {
  return (
    <div className={Styles.tableHeader}>
      <EachColumn title="Contact Info" className={Styles.info} />
      <EachColumn title="Deal Info" className={Styles.info} />
      <EachColumn title="Deal Value" className={`${Styles.middle} ${Styles.width180}`} />
      <EachColumn title="Time" hints="in this stage" className={`${Styles.middle} ${Styles.width230}`} />
      <EachColumn title="Actions" className={`${Styles.middle} ${Styles.width280}`} />
    </div>
  );
}
export default Header;
const EachColumn = ({title, className='', hints=''}) => {
  return (
    <div className={`${Styles.headerEach} ${className}`}>
      <span className={Styles.eachSpan}>{title}</span>
      {hints !== "" && <code className={Styles.small}>{hints}</code>}
    </div>
  );
}